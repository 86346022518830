import {
  Button,
  Flex,
  Scroller,
  Select,
  SubgridTable,
  Text,
} from "src/elements";
import { useInlineLoaders } from "src/components/InlineLoader";
import {
  PnlDecision,
  RoutePostMerchflow_merchflowId_FlowUpdate,
} from "@CommonApi/merchflow/_merchflowId_/flow-update";
import { MerchflowReviewUpdateStep, ProductUpdates } from "../store/types";
import { productToReadableFormat } from "../store/utils";
import { Dispatch, SetStateAction } from "react";

interface Props {
  productUpdates: ProductUpdates;
  setProductUpdates: Dispatch<SetStateAction<ProductUpdates>>;
  setStep: (step: MerchflowReviewUpdateStep) => void;
}

export const Step3Overview = ({
  productUpdates,
  setProductUpdates,
  setStep,
}: Props) => {
  const { isInlineLoading } = useInlineLoaders();

  const next = () => setStep("STEP_4_PLANOGRAM_RULES");

  return (
    <Flex width="100%" column>
      <Flex gap="20px" margin="20px 0" grow>
        <Flex column gap="10px">
          <Button
            color="greenSmoke"
            size="big"
            onClick={() => setStep("STEP_3A_SWAP_PRODUCTS")}
          >
            Swap Products
          </Button>

          <Button
            color="greenSmoke"
            size="big"
            onClick={() => setStep("STEP_3B_REMOVE_PRODUCTS")}
          >
            Remove Products
          </Button>

          <Button
            color="greenSmoke"
            size="big"
            onClick={() => setStep("STEP_3C_ADD_PRODUCTS")}
          >
            Add Products
          </Button>
        </Flex>

        <Scroller>
          <SubgridTable
            columns={[
              {
                id: "index",
                header: "Product Change",
                width: "120px",
              },
              {
                id: "type",
                header: "Type",
                width: "120px",
                renderer: (data) =>
                  data.row.type.slice(0, 1).toUpperCase() +
                  data.row.type.slice(1),
              },
              {
                id: "details",
                header: "Details",
                width: "3fr",
                renderer: (data) =>
                  (data.row.type === "remove" &&
                    productToReadableFormat(data.row.product)) ||
                  (data.row.type === "swap" && (
                    <Flex direction="column" gap="5px">
                      <Text variant="small2">
                        OUT: {productToReadableFormat(data.row.remove)}
                      </Text>

                      <Text variant="small2">
                        IN: {productToReadableFormat(data.row.add)}
                      </Text>
                    </Flex>
                  )) ||
                  (data.row.type === "add" &&
                    productToReadableFormat(data.row.productToAdd)) ||
                  "",
              },
              {
                id: "pnlDecision",
                header: "PNL Decision",
                width: "140px",
                renderer: (data) => (
                  <Select
                    options={
                      [
                        { label: "Default", value: "DEFAULT" },
                        { label: "Movement", value: "MOVEMENT" },
                        { label: "Sales", value: "SALES" },
                        { label: "Profit", value: "PROFIT" },
                      ] as { label: string; value: PnlDecision }[]
                    }
                    isDisabled={
                      data.row.type !== "swap" && data.row.type !== "add"
                    }
                    value={data.row.pnlDecision}
                    setValue={(value) => {
                      setProductUpdates((productUpdates) =>
                        productUpdates.map((productUpdate, index) => ({
                          ...productUpdate,
                          pnlDecision:
                            index === data.index
                              ? value
                              : productUpdate.pnlDecision,
                        })),
                      );
                    }}
                  />
                ),
              },
            ]}
            data={productUpdates}
          />
        </Scroller>
      </Flex>

      <Flex justify="right">
        <Button
          isLoading={isInlineLoading(RoutePostMerchflow_merchflowId_FlowUpdate)}
          onClick={next}
        >
          Next
        </Button>
      </Flex>
    </Flex>
  );
};
