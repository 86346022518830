import { FreezerWithItems } from "@CommonTypes/algo/freezer";
import { useAtomValue, useSetAtom } from "jotai";
import groupBy from "lodash/groupBy";
import { useMemo } from "react";
import {
  algoStep2ConvertFreezerWithItemsToContainers,
  replaceS2FreezerOutputContainers,
} from "src/adapters/algoStep2";
import { DynamicLogButton } from "src/components/DynamicLogButton";
import { Planogram } from "src/components/Planogram/Planogram";
import { PlanogramMetrics } from "src/components/PlanogramExtensions/PlanogramMetrics";
import { Button, Flex } from "src/elements";
import { useMerchflowNavigation } from "src/hooks";
import { Step1Step2Solution } from "src/omni-common/types/step2fixtures";
import { WithDebugInfo } from "src/pages/merchandise/steps/Step2Fixtures/store/types";
import { useMerchandiseApi } from "src/pages/merchandise/store/api";
import {
  isViewDetailAtom,
  pageAtom,
  poggerizeRequestAtom,
  poggerizeResultAtom,
  poggerResultAtom,
  selectedPreStep0DataAtom,
  selectedTemplateIdAtom,
  step0GroupsAtoms,
  step0RequestAtoms,
  step1RequestAtom,
  step1ResponsesAtom,
  viewDetailIndex,
} from "src/pages/merchandise/store/atoms";
import { createPog } from "src/pages/merchandise/store/utils";
import { omniAtom } from "src/utils/atoms";
import { isEmpty } from "src/utils/isEmpty";
import styled from "styled-components";

const BaseViewElement = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 20px;
  flex: 1;
`;

const SingleView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  gap: 20px;
`;

type SingleProps = {
  data: WithDebugInfo<Step1Step2Solution>[];
};

export const Single = ({ data }: SingleProps) => {
  const { callPoggerizePogger, createNewS2Solution } = useMerchandiseApi();

  const setIsDetailView = useSetAtom(isViewDetailAtom);
  const setPage = useSetAtom(pageAtom);
  const solutionIndex = useAtomValue(viewDetailIndex);
  const selectedTemplatedId = useAtomValue(selectedTemplateIdAtom);
  const preStep0 = useAtomValue(selectedPreStep0DataAtom);
  const step1Requests = useAtomValue(step1RequestAtom);
  const step1Responses = useAtomValue(step1ResponsesAtom);
  const step0Requests = useAtomValue(step0RequestAtoms);
  const step0Responses = useAtomValue(step0GroupsAtoms);
  const poggerizeReq = useAtomValue(poggerizeRequestAtom);
  const poggerizeResult = useAtomValue(poggerizeResultAtom);
  const poggerResult = useAtomValue(poggerResultAtom);
  const pogByTemplate = groupBy(preStep0, (d) => d.templateId);
  const { navigateToApplyToBase } = useMerchflowNavigation();

  const originalContainer = useMemo(
    () =>
      algoStep2ConvertFreezerWithItemsToContainers(
        data[solutionIndex]?.planogram?.freezer,
      ),
    [data, solutionIndex],
  );
  const containerAtom = useMemo(
    () =>
      omniAtom({
        key: "single/step2container",
        value: originalContainer,
      }),
    [data, solutionIndex],
  );

  const pogAtom = useMemo(
    () =>
      omniAtom({
        key: "single/step2Pog",
        value: createPog({
          planogram: pogByTemplate[selectedTemplatedId][0].planogram,
          unrangedItems: [],
          metrics: data[solutionIndex].metrics,
        }),
      }),
    [selectedTemplatedId, solutionIndex],
  );

  const editedContainer = useAtomValue(containerAtom);
  const editedPog = useAtomValue(pogAtom);

  if (isEmpty(data)) {
    return null;
  }

  const onClickBack = () => {
    setIsDetailView(false);
    setPage(Math.ceil((solutionIndex + 1) / 4));
  };

  const onOpenLog = () => {
    const requestIndex = step0Requests.findIndex(
      (s0req) => s0req.payload.template_id === selectedTemplatedId,
    );

    return {
      rendering: {
        containers: editedContainer,
        s2Pog: editedPog,
      },
      s2_response: data[solutionIndex],
      s2_request: data[solutionIndex].request,
      s1_response: step1Responses[requestIndex],
      s1_request: step1Requests[requestIndex],
      s0_response: step0Requests[requestIndex],
      s0_request: step0Responses[requestIndex],
      poggerize_request: poggerizeReq,
      poggerize_result: poggerizeResult,
      pogger_result: poggerResult,
    };
  };

  const generatePog = async () => {
    let freezer = data[solutionIndex].planogram.freezer;
    if (JSON.stringify(originalContainer) !== JSON.stringify(editedContainer)) {
      freezer = replaceS2FreezerOutputContainers(
        data[solutionIndex].planogram.freezer,
        editedContainer,
      ) as FreezerWithItems;
      await createNewS2Solution({
        solution_id: data[solutionIndex].solution_id,
        modified_solution: {
          metrics: data[solutionIndex].metrics,
          status: data[solutionIndex].status,
          meta: data[solutionIndex].meta,
          planogram: {
            freezer,
          },
        },
        parentS2Data: {
          planogram: pogByTemplate[selectedTemplatedId][0].planogram,
          candidates: pogByTemplate[selectedTemplatedId][0].candidates,
        },
      });
    } else {
      callPoggerizePogger(
        pogByTemplate[selectedTemplatedId][0].planogram,
        freezer,
        pogByTemplate[selectedTemplatedId][0].candidates,
        data[solutionIndex].solution_id,
      );
    }
  };

  return (
    <SingleView>
      <BaseViewElement>
        <Flex overflow="hidden" maxHeight="60vh" padding="0" grow>
          <Planogram
            containersAtom={containerAtom}
            pogAtom={pogAtom}
            isMetricsRecalculated={false}
            isEditable
          />
        </Flex>

        <Flex gap="20px" justify="space-between" align="center">
          <PlanogramMetrics pog={editedPog} />

          <Flex justify="between" gap="10px">
            <Button variant="inverted" color="yellow" onClick={onClickBack}>
              Back
            </Button>

            <DynamicLogButton dataFetcher={onOpenLog} />

            <Button onClick={generatePog}>Generate Base Planogram</Button>
            {poggerResult && (
              <Button onClick={() => navigateToApplyToBase()}>
                View Generated POG
              </Button>
            )}
          </Flex>
        </Flex>
      </BaseViewElement>
    </SingleView>
  );
};
