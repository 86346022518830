import { RouteGetCategoriesList } from "@CommonApi/categories/list";
import { useAtom } from "jotai";
import { useApi } from "src/api";
import { useInlineLoaders } from "src/components/InlineLoader";
import { categoriesAtom } from "./atoms";

export const useCategoriesPage = () => {
  const { getCategoriesListApi } = useApi();
  const { isInlineLoading } = useInlineLoaders();

  const [categories, setCategories] = useAtom(categoriesAtom);
  const isLoading =
    categories === undefined ||
    (categories === null && isInlineLoading(RouteGetCategoriesList));

  const loadCategories = async () => {
    setCategories(await getCategoriesListApi({ includeArchived: true }));
  };

  return { categories, isLoading, loadCategories };
};
