import { useAtom } from "jotai";
import { useEffect } from "react";
import { zIndexLevel } from "src/utils/zIndexLevels";
import styled from "styled-components";

import { Toast } from "./components/Toast";
import { toastsAtom } from "./store/atoms";

const ToastsContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  left: 10px;
  z-index: ${zIndexLevel.TOASTS};
`;

export const Toasts = () => {
  const [toasts, setToasts] = useAtom(toastsAtom);

  // Effect to cleanup all toasts if all are marked to be deleted.
  useEffect(() => {
    if (
      toasts.length > 0 &&
      toasts.filter((toast) => toast.state === "delete").length ===
        toasts.length
    ) {
      setToasts([]);
    }
  }, [toasts]);

  return (
    <ToastsContainer>
      {toasts.map((toast, i) => (
        <Toast key={i} toast={toast} />
      ))}
    </ToastsContainer>
  );
};
