import {
  OneStoreView,
  OneStoreViewStatus,
} from "@CommonTypes/merchflow/OneStoreView";
import { storesFilters } from "./atoms";

export const getFilteredStores = (
  stores: OneStoreView[],
  filters: typeof storesFilters,
) => {
  return stores.filter((store) => {
    // Return on space.
    const returnOnSpaceValue =
      store.store_pog?.store_pog_data.metrics?.returnOnSpace ?? NaN;
    const returnOnSpaceOption = filters.find(
      (filter) => filter.id === "returnOnSpace",
    )?.value;

    if (
      returnOnSpaceOption === "Greater than 90%" &&
      (isNaN(returnOnSpaceValue) || returnOnSpaceValue <= 90)
    ) {
      return false;
    }

    if (
      returnOnSpaceOption === "Between 75% to 90%" &&
      (isNaN(returnOnSpaceValue) ||
        returnOnSpaceValue < 75 ||
        returnOnSpaceValue > 90)
    ) {
      return false;
    }

    if (
      returnOnSpaceOption === "Lower than 75%" &&
      (isNaN(returnOnSpaceValue) || returnOnSpaceValue >= 75)
    ) {
      return false;
    }

    // Core Range
    const coreRangeValue =
      store.store_pog?.store_pog_data.metrics?.coreRange ?? NaN;
    const coreRangeOption = filters.find(
      (filter) => filter.id === "coreRange",
    )!.value;

    if (
      coreRangeOption === "Equal to 100%" &&
      (isNaN(coreRangeValue) || coreRangeValue !== 100)
    ) {
      return false;
    }

    if (
      coreRangeOption === "Between 90% to 99%" &&
      (isNaN(coreRangeValue) || coreRangeValue < 90 || coreRangeValue > 99)
    ) {
      return false;
    }

    if (
      coreRangeOption === "Lower than 90%" &&
      (isNaN(coreRangeValue) || coreRangeValue >= 90)
    ) {
      return false;
    }

    // Shelf alignment
    const shelfAlignmentOption = filters.find(
      (filter) => filter.id === "shelfAlignment",
    )!.value;
    const shelfAlignmentValue =
      store.store_pog?.store_pog_data.metrics?.shelfAlignment ?? NaN;

    if (
      shelfAlignmentOption === "Greater than 90%" &&
      (isNaN(shelfAlignmentValue) || shelfAlignmentValue <= 90)
    ) {
      return false;
    }

    if (
      shelfAlignmentOption === "Between 75% to 90%" &&
      (isNaN(shelfAlignmentValue) ||
        shelfAlignmentValue < 75 ||
        shelfAlignmentValue > 90)
    ) {
      return false;
    }

    if (
      shelfAlignmentOption === "Lower than 75%" &&
      (isNaN(shelfAlignmentValue) || shelfAlignmentValue >= 75)
    ) {
      return false;
    }

    // DOS / MOS
    const dosMosOption = filters.find(
      (filter) => filter.id === "dosMos",
    )!.value;
    const dosMosValue = store.store_pog?.store_pog_data.metrics?.dosMos ?? NaN;

    if (
      dosMosOption === "Greater than 95%" &&
      (isNaN(dosMosValue) || dosMosValue <= 95)
    ) {
      return false;
    }

    if (
      dosMosOption === "Between 90% to 95%" &&
      (isNaN(dosMosValue) || dosMosValue < 90 || dosMosValue > 95)
    ) {
      return false;
    }

    if (
      dosMosOption === "Lower than 90%" &&
      (isNaN(dosMosValue) || dosMosValue >= 90)
    ) {
      return false;
    }

    // Size
    const sizeOption = filters.find((filter) => filter.id === "size")!
      .value as string[];

    if (
      sizeOption.length > 0 &&
      (!store.store_pog ||
        !sizeOption.includes(
          String(store.store_pog.store_pog_data.planogram.bays.length),
        ))
    ) {
      return false;
    }

    // Store planograms
    const storePlanogramsOption = filters.find(
      (filter) => filter.id === "storePlanograms",
    )!.value;
    if (
      storePlanogramsOption === "Show all" &&
      !store.store_pog &&
      store.status !== OneStoreViewStatus.GENERATING_STORE_POG
    ) {
      return false;
    }

    if (
      storePlanogramsOption === "Show only Approved" &&
      store.status !== OneStoreViewStatus.ONE_STORE_POG_APPROVED &&
      store.status !== OneStoreViewStatus.MULTIPLE_STORE_POGS_APPROVED
    ) {
      return false;
    }

    if (
      storePlanogramsOption === "Show only Un-Approved" &&
      store.status !== OneStoreViewStatus.NO_STORE_POGS_APPROVED
    ) {
      return false;
    }

    return true;
  });
};
