import { AlgoS1FreezerOutput } from "@CommonTypes/algo/s1";
import {
  AlgoS2Container,
  AlgoS2FreezerOutput,
  AlgoS2SectionOutput,
} from "@CommonTypes/algo/s2";
import { PlanogramContainer } from "@CommonTypes/merchflow/pog/container";
import { algoS2FreezerItemLabelCdtsRegex } from "@CommonUtils/pog/container";
import { Container } from "src/components/Planogram/store/types";
import { FreezerWithItems } from "src/omni-common/types/algo/freezer";
import { colorObj } from "src/pages/merchandise/store/atoms";
import { getRandomDarkerColor } from "src/pages/merchandise/store/utils";
import { uuid } from "src/utils";

export const convertS1FreezerWithItemsToS2Containers = (
  freezer: AlgoS1FreezerOutput,
) => {
  const containers: AlgoS2Container[] = [];
  for (const section of freezer.sections) {
    for (const item of section.items) {
      containers.push({
        id: item.item_id,
        variants: item.item_id.split(",").map((variant) => variant.trim()),
        start_x: item.start_x,
        end_x: item.start_x + item.width,
        width: item.width,
        start_y: item.start_y,
        end_y: item.start_y + item.height,
        height: item.height,
      });
    }
  }
  return containers;
};

export const algoStep2ConvertFreezerWithItemsToContainers = (
  freezer: FreezerWithItems,
) => {
  if (!freezer) {
    return [];
  }
  const containers: Container[] = [];

  freezer.sections.forEach((section) =>
    section.items.forEach((item) => {
      //@ts-ignore
      const cdt0Color = colorObj[item.cdt0];

      //@ts-ignore
      if (!colorObj[`${item.cdt0}-${item.cdt1}`]) {
        let colorMultiplier = 2;
        let newColor = getRandomDarkerColor(cdt0Color, colorMultiplier);

        while (Object.values(colorObj).includes(newColor)) {
          newColor = getRandomDarkerColor(newColor, colorMultiplier);

          // find lighter color instead of dark
          if (newColor === "#000000") {
            colorMultiplier = -2;
            newColor = getRandomDarkerColor(newColor, colorMultiplier);
          }

          // run out of light color, show error
          if (newColor === "#ffffff" || newColor.length > 7) {
            throw Error("Run out of color");
          }
        }

        //@ts-ignore
        colorObj[`${item.cdt0}-${item.cdt1}`] = newColor;
      }

      containers.push({
        uniqueId: uuid(),
        label: item.item_id,
        //@ts-ignore
        color: colorObj[`${item.cdt0}-${item.cdt1}`],
        xLeft: item.start_x,
        xRight: item.start_x + item.width,
        yBottom: item.start_y,
        yTop: item.start_y + item.height,
      });
    }),
  );

  return containers;
};

export const replaceS2FreezerOutputContainers = (
  s2FreezerOutput: AlgoS2FreezerOutput,
  containers: PlanogramContainer[],
) => {
  const modifiedS2FreezerOutput: AlgoS2FreezerOutput = JSON.parse(
    JSON.stringify(s2FreezerOutput),
  );

  // Delete previous freezer containers.
  for (const section of modifiedS2FreezerOutput.sections) {
    section.items = [];
  }

  containerLooper: for (const container of containers) {
    const cdts = algoS2FreezerItemLabelCdtsRegex.exec(container.label);

    if (!cdts || !cdts.groups || !modifiedS2FreezerOutput.sections[0]) return;

    for (const section of modifiedS2FreezerOutput.sections) {
      if (checkIfContainerOverlapsWithS2Section(section, container)) {
        section.items.push({
          item_id: container.label,
          start_x: container.xLeft,
          start_y: container.yBottom,
          width: container.xRight - container.xLeft,
          height: container.yTop - container.yBottom,
          cdt0: cdts.groups.cdt0,
          cdt1: cdts.groups.cdt1,
          cdt2: cdts.groups.cdt2,
          rotated: false,
        });
        continue containerLooper;
      }
    }
  }

  return modifiedS2FreezerOutput;
};

const checkIfContainerOverlapsWithS2Section = (
  section: AlgoS2SectionOutput,
  container: PlanogramContainer,
) => {
  const horizontalOverlap =
    Math.min(section.start_x + section.width, container.xRight) >
    Math.max(section.start_x, container.xLeft);
  const verticalOverlap =
    Math.min(section.start_y + section.height, container.yTop) >
    Math.max(section.start_y, container.yBottom);

  return horizontalOverlap && verticalOverlap;
};
