import { TemplatePogData } from "@CommonTypes/merchflow/pog/pog";
import { FiltersSidebarFilters } from "src/components/FiltersSidebar/store/types";

export const merchflowTemplatesFilters: FiltersSidebarFilters<TemplatePogData> =
  [
    {
      id: "returnOnSpace",
      title: "Return on Space",
      style: "radios",
      filters: [
        { label: "Greater than 90%", value: "x>90" },
        { label: "Between 75% to 90%", value: "75<x<90" },
        { label: "Lower than 75%", value: "x<75" },
      ],
      filterFunction: (
        filterable: TemplatePogData,
        value?: "x>90" | "75<=x<=90" | "x<75",
      ) => {
        const returnOnSpace =
          filterable.base_pog?.base_pog_data.metrics.returnOnSpace;
        if (!value) return true;
        if (!returnOnSpace) return false;

        switch (value) {
          case "x<75":
            return returnOnSpace < 75;
          case "75<=x<=90":
            return returnOnSpace >= 75 && returnOnSpace <= 90;
          case "x>90":
            return returnOnSpace > 90;
          default:
            return true;
        }
      },
    },
    {
      id: "coreRange",
      title: "Core Range",
      style: "radios",
      filters: [
        { label: "Equal to 100%", value: "x=100" },
        { label: "Between 90% to 100%", value: "90<=x<100" },
        { label: "Lower than 90%", value: "x<90" },
      ],
      filterFunction: (
        filterable: TemplatePogData,
        value?: "x=100" | "90<=x<100" | "x<90",
      ) => {
        const coreRange = filterable.base_pog?.base_pog_data.metrics.coreRange;
        if (!value) return true;
        if (!coreRange) return false;

        switch (value) {
          case "x=100":
            return coreRange === 100;
          case "90<=x<100":
            return coreRange >= 90 && coreRange < 100;
          case "x<90":
            return coreRange < 90;
          default:
            return true;
        }
      },
    },
    {
      id: "shelfAlignment",
      title: "Shelf Alignment",
      style: "radios",
      filters: [
        { label: "Greater than 90%", value: "x>90" },
        { label: "Between 75% to 90%", value: "75<=x<90" },
        { label: "Lower than 75%", value: "x<75" },
      ],
      filterFunction: (
        filterable: TemplatePogData,
        value?: "x>90" | "75<=x<=90" | "x<75",
      ) => {
        const shelfAlignment =
          filterable.base_pog?.base_pog_data.metrics.shelfAlignment;
        if (!value) return true;
        if (!shelfAlignment) return false;

        switch (value) {
          case "x<75":
            return shelfAlignment < 75;
          case "75<=x<=90":
            return shelfAlignment >= 75 && shelfAlignment <= 90;
          case "x>90":
            return shelfAlignment > 90;
          default:
            return true;
        }
      },
    },
    {
      id: "dosMos",
      title: "DOS / MOS",
      style: "radios",
      filters: [
        { label: "Greater than 95%", value: "x>95" },
        { label: "Between 90% to 95%", value: "90<=x<=95" },
        { label: "Lower than 90%", value: "x<90" },
      ],
      filterFunction: (
        filterable: TemplatePogData,
        value?: "x>95" | "90<=x<=95" | "x<90",
      ) => {
        const dosMos = filterable.base_pog?.base_pog_data.metrics.coreRange;
        if (!value) return true;
        if (!dosMos) return false;

        switch (value) {
          case "x>95":
            return dosMos > 95;
          case "90<=x<=95":
            return dosMos >= 90 && dosMos <= 95;
          case "x<90":
            return dosMos < 90;
          default:
            return true;
        }
      },
    },
    {
      id: "size",
      title: "Size",
      style: "buttons",
      filters: [
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
        { label: "5", value: "5" },
        { label: "6", value: "6" },
        { label: "7", value: "7" },
        { label: "8", value: "8" },
        { label: "9", value: "9" },
        { label: "10", value: "10" },
        { label: "11", value: "11" },
        { label: "12", value: "12" },
        { label: "13", value: "13" },
        { label: "14", value: "14" },
        { label: "15", value: "15" },
        { label: "16", value: "16" },
        { label: "17", value: "17" },
        { label: "18", value: "18" },
        { label: "19", value: "19" },
        { label: "20", value: "20" },
        { label: "21", value: "21" },
        { label: "22", value: "22" },
        { label: "23", value: "23" },
        { label: "24", value: "24" },
        { label: "25", value: "25" },
        { label: "26", value: "26" },
        { label: "27", value: "27" },
        { label: "28", value: "28" },
        { label: "29", value: "29" },
        { label: "30", value: "30" },
      ],
      filterFunction: (filterable: TemplatePogData, value?: string[]) => {
        if (!value || value.length === 0) return true;
        if (!filterable.base_pog?.base_pog_data) return false;

        return value.includes(
          String(filterable.base_pog.base_pog_data.planogram.bays.length),
        );
      },
    },
    {
      id: "templatePlanorams",
      title: "Template Planograms",
      style: "radios",
      filters: [
        { label: "Show all", value: "all" },
        { label: "Show only Active", value: "active" },
        { label: "Show only Favorite", value: "favorite" },
      ],
      value: "active",
      isExpanded: true,
      filterFunction: (
        filterable: TemplatePogData,
        value: "all" | "active" | "favorite",
      ) => {
        return value === "active"
          ? filterable.base_pog !== undefined
          : value === "favorite"
            ? filterable.total_favourite_base_pog !== undefined &&
              filterable.total_favourite_base_pog > 0
            : true;
      },
    },
  ];
