export const pages = {
  login: "/login",
  logout: "/logout",
  home: "/",
  categories: "/categories",
  products: "/products",
  fixtures: "/fixtures",
  playground: "/playground",
  freezer: "/freezer",
  icons: "/icons",
  merchflows_merchflowId_S0_s0UserTriggerId_:
    "/merchflows/:merchflowId/s0/:s0UserTriggerId",
  merchflows_merchflowId_S1_s1UserTriggerId_:
    "/merchflows/:merchflowId/s1/:s1UserTriggerId",
  merchflows_merchflowId_S2_s1UserTriggerId_Templates_templateId_:
    "/merchflows/:merchflowId/s2/:s1UserTriggerId/templates/:templateId",
  merchflows_merchflowId_S2Solution_s2SolutionId_:
    "/merchflows/:merchflowId/s2-solution/:s2SolutionId",
  merchflows_merchflowId_S2Templates_templateId_:
    "/merchflows/:merchflowId/s2/templates/:templateId",
  merchflows_merchflowId_S2_s2SolutionId_:
    "/merchflows/:merchflowId/s2/:s2SolutionId",
  merchflows_merchflowId_BasePog_basePogId_:
    "/merchflows/:merchflowId/base-pog/:basePogId",
  merchflows_merchflowId_Stores: "/merchflows/:merchflowId/stores",
  merchflows_merchflowId_StoresAll: "/merchflows/:merchflowId/stores/all",
  merchflows_merchflowId_Stores_storeCode_:
    "/merchflows/:merchflowId/stores/:storeCode",
  merchflows_merchflowId_Stores_storeCode_Pogs_storePogId_:
    "/merchflows/:merchflowId/stores/:storeCode/pogs/:storePogId",
  merchflowTemplates: "/merchflows/:merchflowId/templates",
  merchflowTemplateBasePogs: "/merchflows/:merchflowId/templates/:templateId",
  merchflowsPogThumbnail_basePogId_:
    "/merchflows/pog-thumbnail/base-pog/:basePogId",
  merchflowsPogThumbnail_storePogId_:
    "/merchflows/pog-thumbnail/store-pog/:storePogId",

  merchandise: "/merchandise",
  merchandiseDetail: "/merchandise/:merchFlowId",
  merchandiseDetailWithStep: "/merchandise/:merchFlowId/:merchFlowStep",
  stores: "/stores",
  storeSummary: "/store-summary",
  users: "/users",
};
