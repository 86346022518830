import { PlanogramPegboard } from "@CommonTypes/merchflow/pog/pegboard";
import React from "react";
import { Pegboard } from "./Pegboard";

interface Props {
  pegboards: PlanogramPegboard[];
  width: number;
  scaleX: number;
  scaleY: number;
}

export const Pegboards = ({ pegboards, width, scaleX, scaleY }: Props) => (
  <React.Fragment>
    {pegboards?.map((pegboard, i) => (
      <Pegboard
        key={i}
        pegboard={pegboard}
        pegboardWidth={width}
        scaleX={scaleX}
        scaleY={scaleY}
      />
    ))}
  </React.Fragment>
);
