import { useRef } from "react";
import { Color, Flex } from "src/elements";
import { Icon } from "src/elements/Icon/Icon";
import { useHoverClickable } from "src/utils/hoverClickable";
import styled from "styled-components";

const Arrows = styled(Flex)`
  position: absolute;
  top: 0;
  right: 0;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  width: 18px;
  padding: 2px;
`;

const ArrowBox = styled(Flex)`
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  border-radius: 3px;
  user-select: none;

  ${({ isDisabled }) =>
    !isDisabled &&
    `
  &:hover {
    background-color: ${Color.primaryHover};
  }
  
  &:active {
    background-color: ${Color.primaryActive};
    
  }
  `}
`;

interface Props {
  value: number;
  setValue: (value: number) => void;
  isDisabled: boolean;
  min?: number;
  max?: number;
}

export const NumberArrows = ({
  value,
  setValue,
  isDisabled: _isDisabled,
  min,
  max,
}: Props) => {
  const refUp = useRef<HTMLDivElement>(null);
  const refDown = useRef<HTMLDivElement>(null);

  const { isHovered: isHoveredUp } = useHoverClickable(refUp);
  const { isHovered: isHoveredDown } = useHoverClickable(refDown);

  const isMinArrowDisabled =
    _isDisabled || (min !== undefined && value - 1 < min);

  const isMaxArrowDisabled =
    _isDisabled || (max !== undefined && value + 1 > max);

  const onIncrease = () => {
    setValue(value + 1);
  };

  const onDecrease = () => {
    setValue(value - 1);
  };

  return (
    <Arrows>
      <ArrowBox
        ref={refUp}
        isDisabled={isMaxArrowDisabled}
        onClick={onIncrease}
      >
        <Icon
          name="triangleUp"
          color={
            (isMaxArrowDisabled && Color.textDisabled) ||
            (isHoveredUp && Color.white) ||
            Color.primary
          }
        />
      </ArrowBox>

      <ArrowBox
        ref={refDown}
        isDisabled={isMinArrowDisabled}
        onClick={onDecrease}
      >
        <Icon
          name="triangleDown"
          color={
            (isMinArrowDisabled && Color.textDisabled) ||
            (isHoveredDown && Color.white) ||
            Color.primary
          }
        />
      </ArrowBox>
    </Arrows>
  );
};
