import React from "react";
import styled from "styled-components";

import { Color } from "../Color/Color";

const ScrollerElement = styled.div<Props>`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  min-height: 0;
  flex-grow: 1;
  max-height: ${({ maxHeight }) => maxHeight};
  scrollbar-gutter: ${({ gutter }) => gutter};

  // Firefox style.
  scrollbar-color: ${Color.primary} ${Color.white};
  scrollbar-width: ${({ size }) => (size === "thin" ? "thin" : "auto")};
`;

interface Props {
  size?: "regular" | "thin";
  maxHeight?: string;
  children: React.ReactNode;
  gutter?: "auto" | "stable" | "stable both-edges";
}

export const Scroller = ({
  size = "thin",
  maxHeight,
  gutter = "stable",
  children,
}: Props) => (
  <ScrollerElement size={size} maxHeight={maxHeight} gutter={gutter}>
    {children}
  </ScrollerElement>
);
