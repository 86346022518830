import { Pog } from "@CommonTypes/merchflow/pog/pog";
import hexToRgba from "hex-to-rgba";
import { PrimitiveAtom, atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import throttle from "lodash/throttle";
import { ReactNode, useCallback, useEffect } from "react";
import { Planogram } from "src/components";
import { highlightsModeAtom } from "src/components/Planogram/store/atoms";
import { Container } from "src/components/Planogram/store/types";
import { PlanogramHovererDetails } from "src/components/PlanogramExtensions/PlanogramHovererDetails/PlanogramHovererDetails";
import { Button, Color, Flex, Icon, Modal } from "src/elements";
import { ButtonDropdownOption } from "src/elements/Button/store/types";
import { Metrics } from "src/pages/MerchflowTemplates/components/Metrics";
import styled from "styled-components";

type NavigationProps =
  | {
      hasNavigation: true;
      indexAtom: PrimitiveAtom<number>;
    }
  | {
      hasNavigation?: undefined | false;
      indexAtom?: undefined;
    };

type Props = {
  dropdown: {
    options: ButtonDropdownOption[];
    label: string;
  };
  modalName:
    | "StoreAll_StoreReviewAction"
    | "Stores_StoreReviewAction"
    | "TemplateReviewAction";
  numberOfPog: number;
  pogAtom: PrimitiveAtom<Pog>;
  containersAtom: PrimitiveAtom<Container[]>;
  footer: ReactNode;
  showWarning?: boolean;
};

const PogImgWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const PogImgOverlay = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${hexToRgba(Color.yellow, 0.3)};
  inset: 0;
  width: 100%;
  height: 100%;
`;

export const ReviewActionModal = ({
  dropdown,
  modalName,
  pogAtom,
  indexAtom = atom(0),
  containersAtom,
  footer,
  hasNavigation,
  numberOfPog,
  showWarning = false,
}: Props & NavigationProps) => {
  // const [activeTab, _] = useState(tabs[0]);
  const pogValue = useAtomValue(pogAtom);
  const [pogIndex, setPogIndex] = useAtom(indexAtom);
  const setHighlightsMode = useSetAtom(highlightsModeAtom);

  const onNext = useCallback(
    throttle(() => {
      setPogIndex((curIndex) => Math.min(numberOfPog - 1, curIndex + 1));
    }, 1000),
    [],
  );

  const onPrev = useCallback(
    throttle(() => {
      setPogIndex((curIndex) => Math.max(0, curIndex - 1));
    }, 1000),
    [],
  );

  useEffect(() => {
    setHighlightsMode(false);
  }, []);

  return (
    <Modal
      name={modalName}
      title="Merchandise Flow Review"
      width="90vw"
      height="95vh"
    >
      <Flex height="100%" padding="12px" width="100%" justify="space-between">
        {/* 240 = 216 (metric) + 24 (padding) */}
        <Flex direction="column" minWidth="calc(90vw - 240px)">
          <PogImgWrapper>
            <Planogram
              key={pogAtom.toString()}
              pogAtom={pogAtom}
              containersAtom={containersAtom}
              isPog
              isEditable
            />

            <PlanogramHovererDetails />

            {showWarning && (
              <PogImgOverlay>
                <Icon name="alert" color={Color.yellow} size={80} />
              </PogImgOverlay>
            )}
          </PogImgWrapper>

          <Flex justify="left" margin="8px">
            {footer}
            {/* <Tabs>
              {tabs.map((t) => (
                <Tab key={t} isActive={t === activeTab} onClick={noop}>
                  {t}
                </Tab>
              ))}
            </Tabs> */}
          </Flex>
        </Flex>

        <Flex column padding="8px" justify="space-between" height="100%">
          <div>
            <Flex width="100%">
              <Button
                width="100%"
                dropdown={dropdown.options}
                variant="borderOnly"
              >
                {dropdown.label}
              </Button>
            </Flex>
            <Metrics title="" metrics={pogValue.metrics} />
          </div>

          {hasNavigation && (
            <Flex justify="space-between">
              <Button
                onClick={onPrev}
                variant="borderless"
                isDisabled={pogIndex === 0}
                iconLeft={{ name: "arrowLeft" }}
              >
                Prev
              </Button>

              <Button
                onClick={onNext}
                variant="borderless"
                isDisabled={pogIndex === numberOfPog - 1}
                iconRight={{ name: "arrowRight" }}
              >
                Next
              </Button>
            </Flex>
          )}
        </Flex>
      </Flex>
    </Modal>
  );
};
