import { ResponseGetMerchflows_merchflowId_BasePog_basePogId_ } from "@CommonApi/merchflows/_merchflowId_/base-pog/_basePogId_";
import { PogNonCircular } from "@CommonTypes/merchflow/pog/pog";

export const RouteGetMerchflowBasePog_basePogId_ =
  "/merchflow/base-pog/:basePogId";

export type ResponseGetMerchflowBasePog_basePogId_ =
  ResponseGetMerchflows_merchflowId_BasePog_basePogId_;

export const RoutePutMerchflowBasePog_basePogId_ =
  "/merchflow/base-pog/:basePogId";

export interface PayloadPutMerchflowBasePog_basePogId_ {
  pog: PogNonCircular;
  save_as_new?: boolean;
}

export interface ResponsePutMerchflowBasePog_basePogId_ {
  base_pog_id: number;
}
