import React from "react";
import { Checkbox, Color, Flex, Text } from "src/elements";
import { TextVariant } from "src/elements/Text";
import styled from "styled-components";

const CellElement = styled(Flex)<{
  span?: number;
  width?: string;
  borderColor: string;
  leftBorder?: boolean;
  topBorder?: boolean;
  isWithoutBorder?: boolean;
}>`
  grid-column: ${({ span }) => span && `span ${span}`};
  padding: 8px;
  border-width: 0 1px 1px 0;
  border-top-width: ${({ topBorder }) => topBorder && "1px"};
  border-left-width: ${({ leftBorder }) => leftBorder && "1px"};
  border: ${({ isWithoutBorder }) => isWithoutBorder && "none !important;"};
`;

interface Props {
  span?: number;
  color?: string;
  background?: string;
  justify?: "left" | "center" | "right";
  align?: "start" | "center" | "end";
  variant?: TextVariant;
  borderColor?: string;
  leftBorder?: boolean;
  topBorder?: boolean;
  isWithoutBorder?: boolean;
  children: React.ReactNode;
  onClick?: () => void;
}

export const SubgridCell = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      span,
      color,
      background,
      justify,
      align,
      variant = "small2",
      borderColor = Color.lightGrayHover,
      leftBorder,
      topBorder,
      isWithoutBorder,
      children,
      onClick,
    },
    ref,
  ) => (
    <CellElement
      ref={ref}
      span={span}
      background={background}
      justify={justify}
      align={align}
      borderColor={borderColor}
      leftBorder={leftBorder}
      topBorder={topBorder}
      isWithoutBorder={isWithoutBorder}
      onClick={onClick}
    >
      {(typeof children === "string" || typeof children === "number") && (
        <Text variant={variant} color={color} textAlign={justify}>
          {children}
        </Text>
      )}

      {typeof children === "object" && <>{children}</>}

      {typeof children === "boolean" && (
        <Checkbox
          width="min-content"
          isChecked={children}
          setIsChecked={() => {}}
          isSoftDisabled
        />
      )}
    </CellElement>
  ),
);
