import {
  Category,
  ResponseGetCategoriesList,
} from "@CommonApi/categories/list";
import { omniAtom } from "src/utils/atoms";

export const addCategoryModalAtom = omniAtom({
  key: "modals/addCategory",
  value: null as null | {
    categories: ResponseGetCategoriesList;
    category?: Category;
  },
});
