export const enum Color {
  // Main
  white = "#FFFFFF",

  // Text
  textMain = "#00130A",
  textSecondary = "#575757",
  textDisabled = "#E4E4E4",

  // Border
  spaceGray = "#C3C5C7",
  spaceGrayHover = "#A9A9A9",

  // Light Gray
  lightGray = "#F7F7F7",
  lightGrayHover = "#EEEEEE",
  lightGrayDisabled = "#FCFCFC",

  // Opacity Sky
  opacitySky = "#F9FAFC",
  opacitySkyHover = "#EDEFF5",
  opacitySkyActive = "#A9BBCB",

  // Button - Primary
  primary = "#3F8E6A",
  primaryHover = "#27704F",
  primaryActive = "#226144",
  primaryDisabled = "#BFE6DE",

  // Button - Green Smoke
  greenSmoke = "#DDEAE4",
  greenSmokeHover = "#C6D9D0",
  greenSmokeActive = "#B0CBBE",
  greenSmokeDisabled = "#EEF4F1",

  // Button - Yellow
  yellow = "#FFAE35",
  yellowHover = "#E29C33",
  yellowActive = "#D89531",
  yellowDisabled = "#EBCA98",

  // Button - Danger
  danger = "#EF8763",
  dangerHover = "#DA7856",
  dangerActive = "#CC7050",
  dangerDisabled = "#F5BCA9",

  // Button - Red
  red = "#EE7766",
  redHover = "#D96959",
  redActive = "#CB6051",
  redDisabled = "#F6BBB2",

  // Toasts
  success = "#7FD059",

  // Planogram
  planogram = "#FCF7F0",

  // Transparent
  transparent = "transparent",
}
