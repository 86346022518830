export const columns = [
  { id: "filterNo", header: "Filter", width: "50px" },
  { id: "checked", header: "", width: "50px" },
  { id: "cdt", header: "CDT", width: "150px" },
  { id: "label", header: "CDT LABEL", width: "250px" },
  { id: "blockingType", header: "CDT BLOCKING TYPE", width: "180px" },
  { id: "location", header: "CDT LOCATION", width: "250px" },
  { id: "result", header: "RESULT", width: "1fr" },
  { id: "delete", header: "", width: "min-content" },
];

export const cdtTypeOptions = [
  {
    value: "cdt0",
    label: "CDT0",
  },
  {
    value: "cdt1",
    label: "CDT1",
  },
  {
    value: "cdt2",
    label: "CDT2",
  },
];

export const blockOptions = [
  {
    value: "vertical",
    label: "Vertical",
  },
  {
    value: "horizontal",
    label: "Horizontal",
  },
  {
    value: "default",
    label: "Square",
  },
];

export const locationOptions = [
  {
    value: "top",
    label: "Top",
  },
  {
    value: "middle",
    label: "Middle",
  },
  {
    value: "bottom",
    label: "Bottom",
  },
];
