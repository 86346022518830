import { useState } from "react";
import { useSearchParams } from "react-router-dom";

export const useQueryState = <T>(key: string, defaultValue: T) => {
  const [queryParams, setQueryParams] = useSearchParams();

  const [innerValue, setInnerValue] = useState(
    (queryParams.get(key) as T) || defaultValue,
  );

  const setValue = (value: T) => {
    const newSearchParams = { ...Object.fromEntries(queryParams.entries()) };

    if (value === null) {
      delete newSearchParams[key];
    } else {
      newSearchParams[key] = String(value);
    }

    setQueryParams(newSearchParams);
    setInnerValue(value);
  };

  return [innerValue, setValue] as [T, (value: T) => void];
};
