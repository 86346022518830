export const highlightsSpecialFields: {
  label: string;
  formula: string;
  sublabel?: string;
}[] = [
  {
    label: "Price",
    formula: "price",
  },
  {
    label: "Facings",
    sublabel: "Planogram",
    formula: "facings",
  },
  {
    label: "Min days of supply",
    formula: "minDaysOfSupply",
  },
  {
    label: "Max days of supply",
    formula: "maxDaysOfSupply",
  },
  {
    label: "Quantity",
    formula: "quantity",
  },
  {
    label: "Sales amount",
    formula: "salesAmount",
  },
  {
    label: "Size",
    formula: "size",
  },
  {
    label: "Brand",
    formula: "brand",
  },
  {
    label: "Variant",
    formula: "variant",
  },
  {
    label: "Category Code",
    formula: "categoryCode",
  },
  {
    label: "Sub-Category",
    formula: "subCategory",
  },
  {
    label: "UPC",
    formula: "upc",
  },
  {
    label: "Color",
    formula: "color",
  },
  {
    label: "Orientation",
    formula: "orientation",
  },
  {
    label: "No. Units in Tray",
    formula: "noOfUnitsInTray",
  },
  {
    label: "No. Units in Case",
    formula: "noOfUnitsInCase",
  },
  {
    label: "Squeeze Width",
    formula: "squeezeWidthPct",
  },
  {
    label: "Squeeze Height",
    formula: "squeezeHeightPct",
  },
  {
    label: "Squeeze Depth",
    formula: "squeezeDepthPct",
  },
  {
    label: "Overhang Squeeze Depth Flag",
    formula: "overhangSqueezeDepthFlag",
  },
  {
    label: "CDT 1",
    formula: "cdt1",
  },
  {
    label: "CDT 2",
    formula: "cdt2",
  },
  {
    label: "CDT 3",
    formula: "cdt3",
  },
  {
    label: "Min Facings",
    formula: "minFacings",
  },
  {
    label: "Max Facings",
    formula: "maxFacings",
  },
  {
    label: "Facings Rows",
    formula: "facingsRows",
  },
  {
    label: "MOS",
    formula: "mos",
    sublabel: "Planogram",
  },
  {
    label: "DOS",
    formula: "dos",
    sublabel: "Planogram",
  },
  {
    label: "Capacity",
    formula: "capacity",
    sublabel: "Planogram",
  },
  {
    label: "MOS",
    formula: "minOnShelf",
  },
  {
    label: "New item flag",
    formula: "newItemFlag",
  },
  {
    label: "Core Range",
    formula: "inCoreRange",
  },
];

export const highlightsOperators = [
  { label: "+", formula: "+" },
  { label: "-", formula: "-" },
  { label: "*", formula: "*" },
  { label: "/", formula: "/" },
  { label: "(", formula: "(" },
  { label: ")", formula: ")" },
  { label: "=", formula: "===" },
  { label: "<", formula: "<" },
  { label: ">", formula: ">" },
  { label: "<=", formula: "<=" },
  { label: ">=", formula: ">=" },
  { label: "true", formula: "true" },
  { label: "false", formula: "false" },
  { label: "and", formula: "&&" },
  { label: "or", formula: "||" },
];

export const higlightsSpecialFieldsFormulas = highlightsSpecialFields.map(
  (field) => field.formula,
);
