import { useRef } from "react";
import { Color, Icon } from "src/elements";
import { useHoverClickable } from "src/utils/hoverClickable";
import styled from "styled-components";

const SelectableElement = styled.div<{
  isSelected: boolean;
  isHovered: boolean;
}>`
  position: absolute;
  top: -10px;
  right: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ isSelected, isHovered }) =>
    (isSelected && Color.primary) ||
    (isHovered && Color.greenSmoke) ||
    Color.white};
  width: 24px;
  height: 24px;
  border: 1px solid ${Color.primary};
  border-radius: 50%;
  cursor: pointer;
  user-select: none;
  z-index: 1;
`;

interface Props {
  isSelected: boolean;
  setIsSelected: (isSelected: boolean) => void;
}

export const Selectable = ({ isSelected, setIsSelected }: Props) => {
  const refSelectable = useRef<HTMLDivElement>(null);
  const { isHovered } = useHoverClickable(refSelectable);

  return (
    <SelectableElement
      ref={refSelectable}
      isSelected={isSelected}
      isHovered={isHovered}
      onClick={() => {
        setIsSelected(!isSelected);
      }}
    >
      <Icon
        name="checkmark"
        size={18}
        color={isSelected ? Color.white : Color.primary}
      />
    </SelectableElement>
  );
};
