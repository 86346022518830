import { MerchFlowCoreRange } from "@CommonApi/merchandise";
import { useEffect, useMemo } from "react";
import { useModals } from "src/components/Modals";
import {
  Button,
  DateInput,
  Flex,
  Input,
  Radios,
  Select,
  Text,
  VerticalDivider,
} from "src/elements";
import { MerchflowReviewStep } from "../store/types";

import {
  ResponseGetMerchflowPsaBasePogs,
  RouteGetMerchflowPsaBasePogs,
} from "@CommonApi/merchflow/psa/base-pogs";
import {
  ResponsePostMerchflows,
  RoutePostMerchflows,
} from "@CommonApi/merchflows";
import {
  ResponseGetMerchflowsCategories,
  RouteGetMerchflowsCategories,
} from "@CommonApi/merchflows/categories";
import { ResponseGetMerchflowsFilters } from "@CommonApi/merchflows/filters";
import { useMerchflowsApi } from "src/api/merchflows";
import { useInlineLoaders } from "src/components/InlineLoader";

interface Props {
  setStep: (step: MerchflowReviewStep) => void;
  setStartFlowResponse: (response: ResponsePostMerchflows | null) => void;
  setAvailableFilters: (filters: ResponseGetMerchflowsFilters | null) => void;
  name: string;
  setName: (name: string) => void;
  notes: string;
  setNotes: (notes: string) => void;
  goLiveDate: Date | null;
  setGoLiveDate: (goLiveDate: Date | null) => void;
  deactivationDate: Date | null;
  setDeactivationDate: (goDeactivationDate: Date | null) => void;
  coreRange: MerchFlowCoreRange;
  setCoreRange: (coreRange: MerchFlowCoreRange) => void;
  category: string | null;
  setCategory: (category: string | null) => void;
  subcategory: string | null;
  setSubcategory: (subCategory: string | null) => void;
  availableCategories: ResponseGetMerchflowsCategories | null;
  psaBasePogs: ResponseGetMerchflowPsaBasePogs | null;
  psaBasePog: string | null;
  setPsaBasePog: (psaBasePog: string | null) => void;
}

export const Step1Details = ({
  setStep,
  setStartFlowResponse,
  setAvailableFilters,
  name,
  setName,
  notes,
  setNotes,
  goLiveDate,
  setGoLiveDate,
  deactivationDate,
  setDeactivationDate,
  coreRange,
  setCoreRange,
  category,
  setCategory,
  subcategory,
  setSubcategory,
  availableCategories,
  psaBasePogs,
  psaBasePog,
  setPsaBasePog,
}: Props) => {
  const { postMerchflowsApi, getMerchflowsFiltersApi } = useMerchflowsApi();
  const { isInlineLoading } = useInlineLoaders();
  const { closeModal: _closeModal } = useModals();

  const isCreateFlowLoading = isInlineLoading(RoutePostMerchflows);
  const isCategoriesLoading = isInlineLoading(RouteGetMerchflowsCategories);
  const isPsaBasePogsLoading = isInlineLoading(RouteGetMerchflowPsaBasePogs);

  const minDeactivationDate = useMemo(() => {
    if (!goLiveDate) {
      return new Date("2000-01-01");
    }

    const deactivationDate = new Date(goLiveDate);
    deactivationDate.setDate(deactivationDate.getDate() + 1);

    return deactivationDate;
  }, [goLiveDate]);

  const categories = useMemo(() => {
    if (availableCategories) {
      return Object.keys(availableCategories);
    }

    return [];
  }, [availableCategories]);

  const subCategories = useMemo(() => {
    if (availableCategories) {
      // All possible sub-categories.
      return Object.values(availableCategories).reduce(
        (allSubCategories, categorySubCategories) => [
          ...allSubCategories,
          ...categorySubCategories,
        ],
        [],
      );
    }

    return [];
  }, [availableCategories]);

  // Set first subcategory as default once its loaded.
  useEffect(() => {
    if (availableCategories) {
      for (const subcategories of Object.values(availableCategories)) {
        setSubcategory(subcategories[0] || null);
        break;
      }
    }
  }, [availableCategories]);

  // Effect to update category based on selected sub-category.
  useEffect(() => {
    if (!availableCategories || !subcategory) return;

    for (const _category of Object.keys(availableCategories)) {
      if (availableCategories[_category].includes(subcategory)) {
        if (_category !== category) {
          setCategory(_category);
        }
      }
    }
  }, [subcategory]);

  // Effect to update sub-category based on selected category.
  useEffect(() => {
    if (!availableCategories || !category) return;
    // Check is correct sub-category is already assigned.
    for (const _subcategory of availableCategories[category]) {
      if (_subcategory === subcategory) {
        return;
      }
    }

    // Assign first sub-category from selected category.
    if (availableCategories[category].length > 0) {
      setSubcategory(availableCategories[category][0]);
    }
  }, [category]);

  const closeModal = () => {
    _closeModal("ModalMerchflowPSAReview");
  };

  const startNewFlow = async () => {
    if (!category || !subcategory || !goLiveDate || !psaBasePog) return;

    setStartFlowResponse(
      await postMerchflowsApi({
        retailer_category_code: category,
        category_code: subcategory,
        name,
        notes,
        core_range: { option: coreRange },
        go_live_date: goLiveDate.toISOString(),
        deactivation_date: deactivationDate?.toISOString() || null,
        psa_base_pog_file_name: psaBasePog,
      }),
    );

    setStep("STEP_2_STORES");

    setAvailableFilters(
      await getMerchflowsFiltersApi({
        retailer_category_code: category,
        category_code: subcategory,
        base_store_code: psaBasePog
          .split(".psa")[0]
          .split(`${category} ${subcategory} `)[1],
      }),
    );
  };

  return (
    <Flex column flexGrow={1}>
      <Flex padding="20px" gap="20px" justify="center">
        <Flex column gap="20px">
          <Text variant="body1">1. Please input your flow details.</Text>

          <Input title="Name" value={name} setValue={setName} isRequired />

          <Flex gap="20px">
            <DateInput
              width="180px"
              title="Go-Live Date"
              value={goLiveDate}
              setValue={setGoLiveDate}
              min={new Date("2000-01-01")}
              isRequired
            />

            <DateInput
              width="180px"
              title="Deactivation Date"
              value={deactivationDate}
              setValue={setDeactivationDate}
              min={minDeactivationDate}
              error={
                goLiveDate !== null &&
                deactivationDate !== null &&
                deactivationDate < goLiveDate &&
                "Deactivation date needs to be after go-live date"
              }
            />
          </Flex>

          <Input title="Notes" value={notes} setValue={setNotes} />

          <Radios
            label="2. Please select your Core Range options."
            direction="column"
            options={[
              {
                label: "All products in Core Range",
                value: MerchFlowCoreRange.ALL,
              },
              {
                label: "No products in Core Range",
                value: MerchFlowCoreRange.NONE,
              },
              {
                label: "All allocated products in Core Range",
                value: MerchFlowCoreRange.PARTIAL,
              },
            ]}
            value={coreRange}
            setValue={setCoreRange}
          />
        </Flex>

        <VerticalDivider />

        <Flex width="360px" column gap="20px">
          <Text variant="body1">3. Please select your category.</Text>

          <Flex gap="20px">
            <Select
              width="50%"
              title="Category"
              value={category}
              placeholder="Category"
              options={categories.map((category) => ({
                label: category,
                value: category,
              }))}
              setValue={setCategory}
              isLoading={isCategoriesLoading}
              isRequired
            />

            <Select
              width="50%"
              title="Subcategory"
              value={subcategory}
              setValue={setSubcategory}
              placeholder="Subcategory"
              options={subCategories.map((subCategory) => ({
                label: subCategory,
                value: subCategory,
              }))}
              isLoading={isCategoriesLoading}
              isRequired
            />
          </Flex>

          <Text variant="body1">
            4. Please select your reference base planogram.
          </Text>

          <Select
            title="Base Planogram"
            isRequired
            isSearchable
            value={psaBasePog}
            setValue={setPsaBasePog}
            placeholder={"Base Planogram"}
            options={
              psaBasePogs?.map((filename) => ({
                label: filename,
                value: filename,
              })) || []
            }
            isLoading={isCategoriesLoading || isPsaBasePogsLoading}
          />
        </Flex>
      </Flex>

      <Flex flexGrow={1} />

      <Flex gap="10px" justify="right">
        <Button variant="inverted" color="red" onClick={closeModal}>
          Cancel
        </Button>

        <Button
          isDisabled={
            !name || !goLiveDate || !category || !subcategory || !psaBasePog
          }
          isLoading={isCreateFlowLoading}
          onClick={startNewFlow}
        >
          Start New Flow
        </Button>
      </Flex>
    </Flex>
  );
};
