import { useAtomValue, useSetAtom } from "jotai";
import { settingsAtom } from "src/components/FeatureFlagProvider/store/atoms";
import { useModals } from "src/components/Modals";
import {
  reportFiltersAtom,
  reportIdAtom,
} from "src/modals/PowerBiReportModal/store/atoms";
import { Filter } from "src/pages/merchandise/store/types";

export const usePowerBiReportModal = () => {
  const setFilters = useSetAtom(reportFiltersAtom);
  const setReportId = useSetAtom(reportIdAtom);
  const settings = useAtomValue(settingsAtom);
  const { openModal } = useModals();

  const openBasePogReport = (filters: Filter[]) => {
    setReportId(settings?.base_pog_report_id!);
    setFilters(filters);
    openModal("PowerBiReportModal");
  };

  const openAllStoresReport = (filters: Filter[]) => {
    setReportId(settings?.store_pog_report_id!);
    setFilters(filters);
    openModal("PowerBiReportModal");
  };

  const openOneStoreReport = (filters: Filter[]) => {
    setReportId(settings?.single_store_pog_report_id!);
    setFilters(filters);
    openModal("PowerBiReportModal");
  };

  return { openBasePogReport, openAllStoresReport, openOneStoreReport };
};
