import { useAtom, useAtomValue } from "jotai";
import groupBy from "lodash/groupBy";
import { useEffect, useRef } from "react";
import { useInlineLoaders } from "src/components/InlineLoader";
import {
  Button,
  Color,
  Flex,
  Pagination,
  Scroller,
  Spinner,
} from "src/elements";
import { detailsAtom } from "src/modals/NewFlowReview/store/atoms";
import { useMerchandiseApi } from "src/pages/merchandise/store/api";
import {
  allPreStep0DataAtom,
  storeLevelPogPaginationAtom,
  storeLevelPogStatusRowsAtom,
} from "src/pages/merchandise/store/atoms";
import { lightenDarkenColor, pages } from "src/utils";

import { Legend } from "./components/Legend";
import { selectedStorePogsAtom } from "./store/atoms";
import { StorePropPOG } from "./StorePropPOG";
import { routeToUrl } from "src/utils/axios";

export const StoreLevelPog = () => {
  const { callGetStoreLevelPog, exportStorePogsApi } = useMerchandiseApi();
  const { isInlineLoading } = useInlineLoaders();

  const allPreStep0 = useAtomValue(allPreStep0DataAtom);
  const rows = useAtomValue(storeLevelPogStatusRowsAtom);
  const [pagination, setPagination] = useAtom(storeLevelPogPaginationAtom);
  const [selectedStorePogs, setSelectedStorePogs] = useAtom(
    selectedStorePogsAtom,
  );
  const [details] = useAtom(detailsAtom);

  const isFirstRender = useRef(true);
  const pogByTemplate = groupBy(allPreStep0, (d) => d.storeCode);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (rows.some((pog) => !["DONE", "ERROR"].includes(pog.status))) {
      timeout = setTimeout(() => {
        loadStorePogs();
      }, 10000);
    }
    return () => clearTimeout(timeout);
  }, [rows]);

  useEffect(() => {
    if (!isFirstRender.current) {
      loadStorePogs();
    }
    isFirstRender.current = false;
  }, [pagination.offset]);

  const loadStorePogs = () => {
    callGetStoreLevelPog(pagination.offset);
  };

  if (isInlineLoading("loadNewStoreProp")) {
    return (
      <Flex flexGrow={1} justify="center" align="center">
        <Spinner />
      </Flex>
    );
  }

  const exportSelectedStorePogs = () => {
    exportStorePogsApi(details!.id, {
      type: "psa",
      store_pogs_ids: selectedStorePogs.map(
        (selectedStorePog) => selectedStorePog.id,
      ),
    });
  };

  const exportApprovedStorePogs = () => {
    exportStorePogsApi(details!.id, {
      type: "psa",
      approved_store_pogs: true,
    });
  };

  return (
    <Flex column gap="10px" flexGrow={1} minHeight="0">
      <Flex width="100%" minHeight="37px" justify="right" gap="10px">
        <Button
          isLoading={isInlineLoading("merchFlow/store-pogs/exports")}
          dropdown={[
            {
              label: "Export selected",
              onClick: exportSelectedStorePogs,
              isHidden: selectedStorePogs.length === 0,
            },
            {
              label: "Export approved",
              onClick: exportApprovedStorePogs,
            },
          ]}
        >
          Exports
        </Button>

        <Button
          color="greenSmoke"
          url={routeToUrl(pages.merchflows_merchflowId_StoresAll, {
            merchflowId: details!.id,
          })}
          urlInNewTab
        >
          View All Stores
        </Button>
      </Flex>

      <Flex
        column
        gap="20px"
        minHeight="0"
        flexGrow={1}
        padding="10px 0 10px 10px"
        borderColor={Color.primary}
        borderRadius="5px"
      >
        <Flex flexGrow={1} minHeight="0">
          <Scroller>
            <Flex gap="10px" flexWrap="wrap" minHeight="0" flexGrow={1}>
              {rows.map((row, i) => (
                <StorePropPOG
                  key={i}
                  row={row}
                  s2Planogram={pogByTemplate[row.store_code][0].planogram}
                />
              ))}
            </Flex>
          </Scroller>
        </Flex>
      </Flex>

      <Flex justify="space-between" align="center">
        <Flex gap="20px">
          <Legend
            label="Template"
            background={Color.planogram}
            border={lightenDarkenColor(Color.planogram, -20)}
          />

          <Legend
            label="Store Approved"
            background={Color.greenSmoke}
            border={Color.primary}
          />

          <Legend
            label="Multiple Approved Stores"
            background={Color.redDisabled}
            border={Color.red}
          />
        </Flex>

        <Pagination
          page={pagination.currentPage}
          setPage={(page) =>
            setPagination({ ...pagination, currentPage: page })
          }
          totalPages={pagination.totalPage}
          isLoading={isInlineLoading("loadNewStoreProp")}
        />
      </Flex>
    </Flex>
  );
};
