import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useMerchflowStoresApi } from "src/api/merchflows/stores";
import { Page } from "src/components";
import { useInlineLoaders } from "src/components/InlineLoader";
import {
  Button,
  Color,
  Flex,
  Pagination,
  Repeater,
  Scroller,
  Skeleton,
} from "src/elements";
import styled from "styled-components";

import { MerchflowHeader } from "../../components/merchflows/MerchflowHeader/MerchflowHeader";

import { ResponseGetMerchflows_merchflowId_Details } from "@CommonApi/merchflows/review/_merchflowId_";
import { useModals } from "src/components/Modals";
import { pages } from "src/utils";
import { routeToUrl } from "src/utils/axios";
import { useNavigation } from "src/utils/navigation";
import {
  PogOverview,
  pogOverviewHeight,
  pogOverviewWidth,
} from "../Merchflows_merchflowId_Stores_storeCode/components/PogOverview";
import { MerchflowStepsNavigator } from "../../components/merchflows/MerchflowStepsNavigator/MerchflowStepsNavigator";
import { useStores_StoresData } from "./hooks";
import { merchflows_MerchflowId_StoresAtom } from "./store/atoms";
import { sleep } from "src/utils/sleep";
import { getStoresPerRequest } from "../Merchflows_merchflowId_StoresAll/store/utils";
import { StoresViewMode } from "../Merchflows_merchflowId_StoresAll/store/types";
import {
  OneStoreViewStatus,
  OneStoreView,
} from "@CommonTypes/merchflow/OneStoreView";
import { ResponseGetMerchflowStoreProp_storePropagationId_ } from "@CommonApi/merchflow/store-prop/_storePropagationId_";
import { StoresGridLegend } from "../../components/merchflows/Legends";
import { StorePogsExporter } from "src/components/StorePogsExporter/StorePogsExporter";
import { useApi } from "src/api";
import { RoutePutMerchflows_MerchflowId_StoresApprovals } from "@CommonApi/merchflows/_merchflowId_/stores/approvals";

const PogsContainer = styled(Flex)`
  border: 1px solid ${Color.lightGrayHover};
  border-radius: 3px;
  flex-grow: 1;
  min-height: 0;
`;
const PogWrapper = styled.div`
  display: inline-block;
`;

const storesPerRequest = getStoresPerRequest(StoresViewMode.GRID);

export const PageMerchflows_merchflowId_Stores = () => {
  const { merchflowId: _merchflowId } = useParams<{
    merchflowId: string;
  }>();
  const [searchParams] = useSearchParams();

  const { getMerchflowDetailsApi, putMerchflowApprovalsApi } = useApi();
  const { getMerchflowStoresApi } = useMerchflowStoresApi();
  const { isInlineLoading } = useInlineLoaders();

  const [data, setData] = useAtom(merchflows_MerchflowId_StoresAtom);
  const { openModal } = useModals();
  const { setIndex } = useStores_StoresData();
  const { navigate } = useNavigation();

  const [details, setDetails] =
    useState<ResponseGetMerchflows_merchflowId_Details | null>(null);
  const [selection, setSelection] = useState<number[]>([]);
  const [page, setPage] = useState(Number(searchParams.get("page")) || 1);

  const merchflowId = Number(_merchflowId);
  const isApprovalsLoading = isInlineLoading(
    RoutePutMerchflows_MerchflowId_StoresApprovals,
  );
  const isStoresLoading =
    data === undefined && isInlineLoading("merchflow/:merchflowId/stores");

  useEffect(() => {
    loadDetails();

    // Clean up data.
    return () => {
      setData(undefined);
    };
  }, []);

  const loadDetails = async () => {
    setDetails(await getMerchflowDetailsApi(merchflowId));
  };

  // Trigger stores loading.
  useEffect(() => {
    loadStores(page);
  }, [page]);

  // Re-trigger stores loading if some store pog is still generating.
  useEffect(() => {
    loadGeneratingStores(data);
  }, [data]);

  const loadStores = (page: number) => {
    getMerchflowStoresApi(setData, merchflowId, {
      page,
      size: storesPerRequest,
    });
  };

  const loadGeneratingStores = async (
    data: ResponseGetMerchflowStoreProp_storePropagationId_ | null | undefined,
  ) => {
    if (data) {
      for (const store of data.data) {
        if (store.status === OneStoreViewStatus.GENERATING_STORE_POG) {
          await sleep(10000);
          return loadStores(page);
        }
      }
    }
  };

  const approveSelectedStorePogs = async () => {
    await putMerchflowApprovalsApi(merchflowId, {
      approved: selection.map((storePogId) => ({ store_pog_id: storePogId })),
    });
    setSelection([]);
    loadStores(page);
  };

  const unapproveSelectedStorePogs = async () => {
    await putMerchflowApprovalsApi(merchflowId, {
      unapproved: selection.map((storePogId) => ({ store_pog_id: storePogId })),
    });
    setSelection([]);
    loadStores(page);
  };

  const onClickStore = (index: number, view: OneStoreView) => {
    setIndex(index);
    if (
      [
        OneStoreViewStatus.MULTIPLE_STORE_POGS_APPROVED,
        OneStoreViewStatus.NO_STORE_POGS_APPROVED,
        OneStoreViewStatus.ONE_STORE_POG_APPROVED,
      ].includes(view.status)
    ) {
      navigate(
        routeToUrl(pages.merchflows_merchflowId_Stores_storeCode_, {
          merchflowId,
          storeCode: view.store!,
        }),
      );
      return;
    }
    openModal("Stores_StoreReviewAction");
  };

  return (
    <Page>
      <MerchflowHeader merchflowId={merchflowId} />

      <MerchflowStepsNavigator
        activeStep="STORES"
        merchflowId={merchflowId}
        basePogId={details?.latest_base_pog_id || undefined}
      />

      <Flex minHeight="0" grow column gap="10px">
        <Flex gap="10px" justify="between">
          <Flex gap="10px">
            <Button
              color="greenSmoke"
              url={routeToUrl(pages.merchflows_merchflowId_StoresAll, {
                merchflowId,
              })}
            >
              All Stores
            </Button>
          </Flex>

          <Flex gap="10px">
            <Button
              isLoading={isApprovalsLoading}
              dropdown={[
                {
                  label: "Approve selected",
                  onClick: approveSelectedStorePogs,
                  isDisabled: selection.length === 0,
                },
                {
                  label: "Unapprove selected",
                  onClick: unapproveSelectedStorePogs,
                  isDisabled: selection.length === 0,
                },
              ]}
            >
              Approvals
            </Button>

            <StorePogsExporter
              merchflowId={merchflowId}
              storePogsIds={selection}
            />
          </Flex>
        </Flex>

        <PogsContainer>
          <Scroller>
            <Flex padding="20px" flexWrap="wrap" gap="20px">
              {isStoresLoading && (
                <Repeater times={storesPerRequest}>
                  <Skeleton
                    width={pogOverviewWidth}
                    height={pogOverviewHeight}
                  />
                </Repeater>
              )}

              {!isStoresLoading &&
                data &&
                data.data.map((store: OneStoreView, i) => (
                  <PogWrapper key={i} onClick={() => onClickStore(i, store)}>
                    <PogOverview
                      status={store.status}
                      label={`Store: ${store.store}`}
                      badge={
                        store.store_pog_amount !== undefined &&
                        store.store_pog_amount > 1
                          ? store.store_pog_amount
                          : undefined
                      }
                      pog={
                        (store.status === OneStoreViewStatus.S2 &&
                          store.s2_solution?.s2_solution_data.pog) ||
                        (store.status === OneStoreViewStatus.BASE_POG &&
                          store.base_pog?.base_pog_data) ||
                        ((store.status ===
                          OneStoreViewStatus.NO_STORE_POGS_APPROVED ||
                          store.status ===
                            OneStoreViewStatus.ONE_STORE_POG_APPROVED ||
                          store.status ===
                            OneStoreViewStatus.MULTIPLE_STORE_POGS_APPROVED) &&
                          store.store_pog?.store_pog_data) ||
                        undefined
                      }
                      containers={
                        store.status === OneStoreViewStatus.S2
                          ? store.s2_solution?.s2_solution_data.containers
                          : undefined
                      }
                      selectionId={
                        store.store_pog_amount === 1
                          ? store.store_pog?.store_pog_id
                          : undefined
                      }
                      selection={selection}
                      setSelection={setSelection}
                      loadingMessage="Loading planogram..."
                    />
                  </PogWrapper>
                ))}
            </Flex>
          </Scroller>
        </PogsContainer>

        <Flex justify="between">
          <StoresGridLegend />

          <Pagination
            page={page}
            totalPages={data?.totalPages || 0}
            setPage={setPage}
            isLoading={data === null && isStoresLoading}
          />
        </Flex>
      </Flex>
    </Page>
  );
};
