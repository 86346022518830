import { Flex, Text } from "src/elements";

interface Props {
  label: string;
  background: string;
  border: string;
}

export const Legend = ({ label, background, border }: Props) => (
  <Flex gap="5px" align="center">
    <Flex
      height="14px"
      width="14px"
      background={background}
      borderColor={border}
      borderRadius="3px"
      justify="center"
      align="center"
    />

    <Text variant="small2">{label}</Text>
  </Flex>
);
