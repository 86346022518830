import { useAtom } from "jotai";
import {
  merchflowHeaderFroggerVersionAtom,
  merchflows_merchflowId_DetailsAtom,
} from "./atoms";
import { useApi } from "src/api";

export const useMerchflowHeader = () => {
  const { getMerchflowDetailsApi } = useApi();

  const [merchflowDetails, setMerchflowDetails] = useAtom(
    merchflows_merchflowId_DetailsAtom,
  );
  const [froggerVersion, setFroggerVersion] = useAtom(
    merchflowHeaderFroggerVersionAtom,
  );

  const loadDetails = async (merchflowId: number) => {
    if (merchflowDetails?.id !== merchflowId) {
      setMerchflowDetails(await getMerchflowDetailsApi(merchflowId));
    }
  };

  const saveNotes = (notes: string) => {
    if (!merchflowDetails) return;
    setMerchflowDetails({ ...merchflowDetails, notes });
  };

  return {
    merchflowDetails,
    loadDetails,
    saveNotes,
    froggerVersion,
    setFroggerVersion,
  };
};
