import { useEffect, useState } from "react";

import { Button, Flex, Input, Modal, Select } from "src/elements";
import { useApi } from "src/api";
import {
  Category,
  ResponseGetCategoriesList,
  RouteGetCategoriesList,
} from "@CommonApi/categories/list";
import { useInlineLoaders } from "src/components/InlineLoader";
import { RoutePostCategories } from "@CommonApi/categories";
import { useModals } from "src/components/Modals";
import { useAtom } from "jotai";
import { addCategoryModalAtom } from "./store/atoms";

export const AddCategoryModal = () => {
  const [data, setData] = useAtom(addCategoryModalAtom);

  useEffect(() => {
    return () => {
      setData(null);
    };
  }, []);

  return data ? (
    <AddCategoryModalInner
      categories={data.categories}
      category={data.category}
    />
  ) : null;
};

interface Props {
  categories: ResponseGetCategoriesList;
  category?: Category;
}

const AddCategoryModalInner = ({ categories, category }: Props) => {
  const { postAddCategoryApi, putUpdateCategoryApi } = useApi();
  const { isInlineLoading } = useInlineLoaders();
  const { closeModal } = useModals();

  const [categoryName, setCategoryName] = useState(category?.code || "");
  const [categoryGroup, setCategoryGroup] = useState(
    category?.category_group || "",
  );
  const [newCategoryGroup, setNewCategoryGroup] = useState("");
  const [isAddingNewCategoryGroup, setIsAddingNewCategoryGroup] =
    useState(false);
  const [isSaveSuccessful, setIsSaveSuccessful] = useState(false);

  const toggleAddingNewCategoryGroup = () => {
    setIsAddingNewCategoryGroup(!isAddingNewCategoryGroup);
  };

  const saveNewOrUpdateCategory = async () => {
    const response = category
      ? await putUpdateCategoryApi({
          catId: category.id,
          categoryGroup,
          code: categoryName,
        })
      : await postAddCategoryApi({
          categoryGroup,
          code: categoryName,
        });

    if (response) {
      setIsSaveSuccessful(true);

      setTimeout(() => {
        closeModal("AddCategoryModal");
      }, 1000);
    }
  };

  return (
    <Modal
      name="AddCategoryModal"
      title={category ? `Edit Category ${category.code}` : "Add New Category"}
      width="400px"
    >
      <Flex padding="20px" gap="20px" column>
        {!category && (
          <Input
            title="Category Name"
            value={categoryName}
            setValue={setCategoryName}
          />
        )}

        <Flex gap="10px">
          {isAddingNewCategoryGroup ? (
            <Input
              title="New Category Group"
              placeholder="New Category Group"
              value={newCategoryGroup}
              setValue={setNewCategoryGroup}
            />
          ) : (
            <Select
              title="Category Group"
              placeholder="Category Group"
              value={categoryGroup}
              setValue={setCategoryGroup}
              options={
                categories?.map((category) => ({
                  label: category.catGroup,
                  value: category.catGroup,
                })) || []
              }
              isLoading={isInlineLoading(RouteGetCategoriesList)}
            />
          )}

          <Button
            variant="inverted"
            size="small"
            color={isAddingNewCategoryGroup ? "yellow" : "greenSmoke"}
            iconRight={{
              name: isAddingNewCategoryGroup ? "clear" : "plus",
            }}
            onClick={toggleAddingNewCategoryGroup}
          >
            {isAddingNewCategoryGroup
              ? "Select existing group"
              : "New Category Group"}
          </Button>
        </Flex>

        <Flex justify="center" gap="10px">
          <Button
            onClick={saveNewOrUpdateCategory}
            isDisabled={!categoryName || (!categoryGroup && !newCategoryGroup)}
            isLoading={isInlineLoading(RoutePostCategories)}
            isSuccessful={isSaveSuccessful}
          >
            {category ? "Save changes" : "Save New Category"}
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};
