import hexToRgba from "hex-to-rgba";
import React, { useRef } from "react";
import { Transition } from "src/utils";
import styled from "styled-components";
import { Icon } from "../Icon/Icon";
import { Flex } from "../Box/Box";
import { Color } from "../Color/Color";
import { Text } from "../Text/Text";
import { useHoverClickable } from "src/utils/hoverClickable";

const Container = styled(Flex)<{ isDisabled: boolean }>`
  align-items: center;
  gap: 8px;
  cursor: pointer;
  pointer-events: ${({ isDisabled }) => isDisabled && "none"};
`;

const Background = styled(Flex)<{
  backgroundColor: string;
}>`
  display: flex;
  min-width: 30px;
  width: 30px;
  min-height: 18px;
  max-height: 18px;
  align-items: center;
  padding: 3px;
  border-radius: 18px;
  transition: background-color ${Transition.fast};
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

const Handle = styled.div<{ handleColor: string; isChecked: boolean }>`
  display: flex;
  width: 12px;
  height: 12px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${({ handleColor }) => handleColor};
  transition: transform ${Transition.fast};
  transform: translateX(${({ isChecked }) => (isChecked ? "100%" : "0%")});
`;

interface Props {
  isChecked: boolean;
  setIsChecked: (checked: boolean) => void;
  label?: string;
  isDisabled?: boolean;
}

export const Toggle: React.FC<Props> = ({
  isChecked: _isChecked,
  setIsChecked,
  label,
  isDisabled = false,
}) => {
  const refToggle = useRef<HTMLDivElement>(null);
  const { isHovered, isActive } = useHoverClickable(refToggle);
  const isChecked = (!_isChecked && isActive) || (_isChecked && !isActive);

  const backgroundColor =
    (isDisabled && Color.lightGrayHover) ||
    (!_isChecked && isActive && hexToRgba(Color.primary, 0.7)) ||
    (!_isChecked && isHovered && Color.spaceGrayHover) ||
    (_isChecked && isActive && hexToRgba(Color.primary, 0.7)) ||
    (_isChecked && Color.primary) ||
    Color.spaceGray;

  const handleColor = (isDisabled && Color.lightGray) || Color.white;

  const checkmarkColor = (isDisabled && Color.textDisabled) || Color.primary;

  const toggleChecked = () => {
    setIsChecked(!_isChecked);
  };

  return (
    <Flex userSelect="none">
      <Container
        ref={refToggle}
        isDisabled={isDisabled}
        onClick={toggleChecked}
      >
        <Background backgroundColor={backgroundColor}>
          <Handle handleColor={handleColor} isChecked={isChecked}>
            <Flex
              width="100%"
              height="100%"
              justify="center"
              align="center"
              opacity={isChecked ? 1 : 0}
              transition={`opacity ${Transition.fast}`}
            >
              <Icon name="checkmark" size={12} color={checkmarkColor} />
            </Flex>
          </Handle>
        </Background>

        {label && (
          <Text
            variant="small2"
            whiteSpace="nowrap"
            color={isDisabled ? Color.textDisabled : Color.textSecondary}
          >
            {label}
          </Text>
        )}
      </Container>
    </Flex>
  );
};
