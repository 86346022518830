import { Pog } from "@CommonTypes/merchflow/pog/pog";
import { PrimitiveAtom, useAtom, useSetAtom } from "jotai";
import { Planogram } from "src/components/Planogram/Planogram";
import { PlanogramMetrics } from "src/components/PlanogramExtensions/PlanogramMetrics";
import { Button, Color, Flex } from "src/elements";
import {
  isViewDetailAtom,
  pageAtom,
  viewDetailIndex,
} from "src/pages/merchandise/store/atoms";
import { pages } from "src/utils";
import { routeToUrl } from "src/utils/axios";

type SolutionProp = {
  merchflowId: number;
  solutionId: number;
  containerAtom: any;
  pogAtom: PrimitiveAtom<Pog>;
  index: number;
};

export const Solution = ({
  merchflowId,
  solutionId,
  containerAtom,
  pogAtom,
  index,
}: SolutionProp) => {
  const [pog] = useAtom(pogAtom);
  const setIsDetailView = useSetAtom(isViewDetailAtom);
  const setSolutionIndex = useSetAtom(viewDetailIndex);
  const setPage = useSetAtom(pageAtom);

  const handleClickView = () => {
    setIsDetailView(true);
    setSolutionIndex(index);
    setPage(index + 1);
  };

  return (
    <Flex column gap="5px">
      <Flex
        maxHeight="30vh"
        width="100%"
        border={`1px solid ${Color.primary}`}
        borderRadius="5px"
        background="#FCF7F0"
        padding="0"
        grow
      >
        <Planogram
          containersAtom={containerAtom}
          pogAtom={pogAtom}
          key={pogAtom.toString()}
          isMetricsRecalculated={false}
        />
      </Flex>

      <Flex justify="space-between">
        <PlanogramMetrics pog={pog} size="small" />

        <Flex gap="10px">
          <Button
            variant="inverted"
            size="small"
            url={routeToUrl(pages.merchflows_merchflowId_S2_s2SolutionId_, {
              merchflowId,
              s2SolutionId: solutionId,
            })}
            urlInNewTab
            dropdown={[
              { label: "View with old version", onClick: handleClickView },
            ]}
          >
            View
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};
