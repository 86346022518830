import { useAtom, useSetAtom } from "jotai";
import { Toggle } from "src/elements";
import {
  planogramFixturesModeAtom,
  planogramSelectedShelfAtom,
  planogramSidepanelTabAtom,
} from "./store/atoms";
import { PlanogramSidepanelTab } from "./store/types";

interface Props {
  isDisabled?: boolean;
}

export const PlanogramFixturesMode = ({ isDisabled }: Props) => {
  const [fixturesMode, _setFixturesMode] = useAtom(planogramFixturesModeAtom);
  const setSidepanelTab = useSetAtom(planogramSidepanelTabAtom);
  const setSelectedShelf = useSetAtom(planogramSelectedShelfAtom);

  const setFixturesMode = (fixturesMode: boolean) => {
    _setFixturesMode(fixturesMode);
    setSelectedShelf(null);
    setSidepanelTab(
      fixturesMode
        ? PlanogramSidepanelTab.FIXTURES_DETAILS
        : PlanogramSidepanelTab.HIGHLIGHTS,
    );
  };

  return (
    <Toggle
      label="Fixtures"
      isChecked={fixturesMode}
      setIsChecked={setFixturesMode}
      isDisabled={isDisabled}
    />
  );
};
