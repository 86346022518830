import { minimumResizeSize } from "src/components/Planogram/store/constants";
import { ResizeSide } from "src/components/Planogram/store/types";

interface Props {
  xLeft: number;
  xRight: number;
  yBottom: number;
  yTop: number;
  side: ResizeSide;
}

export const resizeSizeChecker = ({
  xLeft,
  xRight,
  yBottom,
  yTop,
  side,
}: Props) => {
  if (
    (side === "left" || side === "top-left" || side === "bottom-left") &&
    xRight - xLeft < minimumResizeSize
  ) {
    xLeft = xRight - minimumResizeSize;
  }

  if (
    (side === "right" || side === "top-right" || side === "bottom-right") &&
    xRight - xLeft < minimumResizeSize
  ) {
    xRight = xLeft + minimumResizeSize;
  }

  if (
    (side === "top" || side === "top-left" || side === "top-right") &&
    yTop - yBottom < minimumResizeSize
  ) {
    yTop = yBottom + minimumResizeSize;
  }

  if (
    (side === "bottom" || side === "bottom-left" || side === "bottom-right") &&
    yTop - yBottom < minimumResizeSize
  ) {
    yBottom = yTop - minimumResizeSize;
  }

  return {
    xLeft,
    xRight,
    yBottom,
    yTop,
  };
};
