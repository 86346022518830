import { useEffect, useState } from "react";
import { StepsNavigator } from "src/components";
import { Flex, Modal } from "src/elements";
import { Step3Overview } from "./components/Step3Overview";
import { Step1Violations } from "./components/Step1Violations";
import { MerchflowReviewUpdateStep, ProductUpdates } from "./store/types";
import { useAtomValue, useSetAtom } from "jotai";
import { merchflowReviewUpdateAtom } from "./store/atoms";
import { useApi } from "src/api";
import { Step3ASwapProducts } from "./components/Step3ASwapProducts";
import { Step3BRemoveProducts } from "./components/Step3BRemoveProducts";
import { Step3CAddProducts } from "./components/Step3CAddProducts";
import { Step4PlanogramRules } from "./components/Step4PlanogramRules";
import { ResponseGetMerchflow_merchflowId_FlowUpdateProducts } from "@CommonApi/merchflow/_merchflowId_/flow-update/products";
import { ResponseGetViolationMerchflow_merchflowId_ } from "@CommonApi/violation/merchflow/_merchflowId_";
import { useAsyncState } from "src/hooks/useAsyncState";

export const ModalMerchflowReviewUpdate = () => {
  const data = useAtomValue(merchflowReviewUpdateAtom);

  return data ? (
    <ModalMerchflowReviewUpdateInner
      merchflowId={data.merchflowId}
      violations={data.violations}
    />
  ) : null;
};

interface Props {
  merchflowId: number;
  violations?: ResponseGetViolationMerchflow_merchflowId_;
}

const ModalMerchflowReviewUpdateInner = ({
  merchflowId,
  violations,
}: Props) => {
  const { getUpdateFlowProductsApi } = useApi();

  const setData = useSetAtom(merchflowReviewUpdateAtom);

  const [step, setStep] =
    useState<MerchflowReviewUpdateStep>("STEP_1_VIOLATIONS");
  const [productUpdates, setProductUpdates] = useState<ProductUpdates>([]);
  const [products, setProducts] =
    useAsyncState<ResponseGetMerchflow_merchflowId_FlowUpdateProducts>(
      undefined,
    );

  useEffect(() => {
    setProducts(getUpdateFlowProductsApi(merchflowId));
  }, []);

  return (
    <Modal
      name="ModalMerchflowReviewUpdate"
      width="1200px"
      title="Update Flow Review"
      onClose={() => {
        setData(null);
      }}
    >
      <Flex height="70vh" width="100%" padding="20px" column gap="40px" grow>
        <StepsNavigator
          steps={[
            {
              title: "Violations",
              isActive: step === "STEP_1_VIOLATIONS",
              onClick: () => setStep("STEP_1_VIOLATIONS"),
            },
            {
              title: "Store Selection",
              isActive: step === "STEP_2_STORE_SELECTION",
              onClick: () => setStep("STEP_2_STORE_SELECTION"),
            },
            {
              title: "Product Review",
              isActive:
                step === "STEP_3_PRODUCT_REVIEW" ||
                step === "STEP_3A_SWAP_PRODUCTS" ||
                step === "STEP_3B_REMOVE_PRODUCTS" ||
                step === "STEP_3C_ADD_PRODUCTS",
              onClick: () => setStep("STEP_3_PRODUCT_REVIEW"),
            },
            {
              title: "Planogram Rules",
              isActive: step === "STEP_4_PLANOGRAM_RULES",
              onClick: () => setStep("STEP_4_PLANOGRAM_RULES"),
            },
          ]}
        />
        <Flex width="100%" grow>
          {step === "STEP_1_VIOLATIONS" && (
            <Step1Violations
              merchflowId={merchflowId}
              violations={violations}
              setStep={setStep}
            />
          )}

          {step === "STEP_3_PRODUCT_REVIEW" && (
            <Step3Overview
              productUpdates={productUpdates}
              setProductUpdates={setProductUpdates}
              setStep={setStep}
            />
          )}

          {step === "STEP_3A_SWAP_PRODUCTS" && products && (
            <Step3ASwapProducts
              addableProducts={products.addable_products}
              removableProducts={products.removable_products}
              setProductUpdates={setProductUpdates}
              setStep={setStep}
            />
          )}

          {step === "STEP_3B_REMOVE_PRODUCTS" && products && (
            <Step3BRemoveProducts
              removableProducts={products.removable_products}
              setProductUpdates={setProductUpdates}
              setStep={setStep}
            />
          )}

          {step === "STEP_3C_ADD_PRODUCTS" && products && (
            <Step3CAddProducts
              addableProducts={products.addable_products}
              removableProducts={products.removable_products}
              setProductUpdates={setProductUpdates}
              setStep={setStep}
            />
          )}

          {step === "STEP_4_PLANOGRAM_RULES" && (
            <Step4PlanogramRules
              merchflowId={merchflowId}
              productUpdates={productUpdates}
            />
          )}
        </Flex>
      </Flex>
    </Modal>
  );
};
