import { useSetAtom } from "jotai";

import { loadersAtom } from "./atoms";

export const useLoaders = () => {
  const setLoaders = useSetAtom(loadersAtom);

  const addLoader = (loader: string) => {
    // @ts-ignore
    setLoaders((loaders) => [...loaders, loader]);
  };

  const removeLoader = (loader: string) => {
    setLoaders((loaders) => loaders.filter((l) => l !== loader));
  };

  return {
    addLoader,
    removeLoader,
  };
};
