export const SelectBaseSizeSvg = () => (
  <svg
    width="36"
    height="35"
    viewBox="0 0 36 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="18" cy="17.1709" r="17.1533" fill="#BFE6DE" />
    <path
      d="M27.966 16.1743V14.181C27.966 9.19795 25.9728 7.20471 20.9897 7.20471H15.01C10.0269 7.20471 8.03369 9.19795 8.03369 14.181V20.1607C8.03369 25.1438 10.0269 27.1371 15.01 27.1371H16.0066M8.06362 13.6827H27.9661M8.06362 20.6591H17.9999M14.5217 27.1271V7.21469M21.498 17.1609V7.21469"
      stroke="#3F8E6A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
