import { useAtom, useSetAtom } from "jotai";
import { useEffect, useMemo, useState } from "react";
import { useModals } from "src/components/Modals";
import {
  Button,
  Color,
  Flex,
  Modal,
  Radios,
  Spinner,
  Text,
} from "src/elements";
import { pogDeleteProductDataAtom } from "./store/atoms";
import { ProductImage } from "src/components/Planogram/components/Item/Image";
import { ResponseMerchFlowApplyFilters } from "@CommonApi/merchandise";
import { useInlineLoaders } from "src/components/InlineLoader";
import { RouteGetMerchflowsReviewFlows_merchflowId_ } from "@CommonApi/merchflows/review/flows/_merchflowId_";
import { deletedProductAtom } from "src/components/Planogram/store/atoms";
import { PlanogramItem } from "@CommonTypes/merchflow/pog/pog";

enum StoreDeleteOption {
  ALL = "All Stores",
  CURRENT = "Current Store",
  SELECTED = "Selected Stores",
}

export const ModalPogDeleteProduct = () => {
  const [data, setData] = useAtom(pogDeleteProductDataAtom);

  useEffect(() => {
    return () => {
      setData(null);
    };
  }, []);

  if (!data) {
    return null;
  }

  return (
    <ModalPogDeleteProductInner
      item={data.item}
      availableStores={data.availableStores}
    />
  );
};

interface Props {
  item: PlanogramItem;
  availableStores: ResponseMerchFlowApplyFilters["filter_config"];
}

const ModalPogDeleteProductInner = ({ item, availableStores }: Props) => {
  const { isInlineLoading } = useInlineLoaders();
  const { closeModal: _closeModal } = useModals();

  const setDeletedProduct = useSetAtom(deletedProductAtom);

  const [storeDeleteOption, setStoreDeleteOption] = useState(
    StoreDeleteOption.ALL,
  );

  const isStoresLoading = isInlineLoading(
    RouteGetMerchflowsReviewFlows_merchflowId_,
  );

  const affectedStores = useMemo(() => {
    return availableStores?.stores.map((store) => store.store) || [];
  }, [availableStores]);

  const closeModal = () => {
    _closeModal("ModalPogDeleteProduct");
  };

  const deleteProduct = () => {
    setDeletedProduct({
      item,
      storeCodes: affectedStores,
    });
    closeModal();
  };

  return (
    <Modal name="ModalPogDeleteProduct" width="400px" title={"Delete Product"}>
      <Flex column padding="20px" gap="20px">
        <Flex column justify="center" gap="5px">
          <ProductImage
            productCode={item.productCode}
            fixedSize="100px"
            size="small"
          />

          <Text variant="h5" color={Color.textMain}>
            {item.productCode}
          </Text>
        </Flex>

        <Flex gap="10px" column>
          <Text variant="body1">
            Which stores should this SKU be removed from?
          </Text>

          {isStoresLoading && <Spinner size="big" isCentered />}

          {!isStoresLoading && (
            <Radios
              direction="column"
              value={storeDeleteOption}
              setValue={setStoreDeleteOption}
              options={[
                { label: StoreDeleteOption.ALL, value: StoreDeleteOption.ALL },
                {
                  label: StoreDeleteOption.CURRENT,
                  value: StoreDeleteOption.CURRENT,
                  isDisabled: true,
                },
                {
                  label: StoreDeleteOption.SELECTED,
                  value: StoreDeleteOption.SELECTED,
                  isDisabled: true,
                },
              ]}
            />
          )}
        </Flex>

        <Flex gap="20px">
          <Button
            width="50%"
            color="primary"
            variant="inverted"
            onClick={closeModal}
          >
            Cancel
          </Button>

          <Button
            isDisabled={isStoresLoading}
            width="50%"
            color="red"
            onClick={deleteProduct}
          >
            Delete
            {!isStoresLoading &&
              ` (${affectedStores.length} ${
                affectedStores.length === 1 ? " store" : " stores"
              })`}
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};
