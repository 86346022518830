import { useAtom } from "jotai";
import { changeSummaryDataAtom } from "./store/atoms";
import { useEffect } from "react";
import { Color, Flex, Modal, SubgridTable, Text } from "src/elements";
import { ResponseGetMerchflow_merchflowId_FlowUpdateChangeSummary } from "@CommonApi/merchflow/_merchflowId_/flow-update/change-summary";

export const ModalChangeSummary = () => {
  const [changeSummary, setChangeSummary] = useAtom(changeSummaryDataAtom);

  useEffect(() => {
    return () => {
      setChangeSummary(null);
    };
  }, []);

  return changeSummary ? (
    <ModalChangeSummaryInner changeSummary={changeSummary} />
  ) : null;
};

interface Props {
  changeSummary: ResponseGetMerchflow_merchflowId_FlowUpdateChangeSummary;
}

const ModalChangeSummaryInner = ({ changeSummary }: Props) => (
  <Modal name="ModalChangeSummary" title="Change Summary" width="550px">
    <Flex column gap="20px" padding="20px">
      <Text variant="body1" color={Color.primary}>
        Product Changes: Store POG #{changeSummary.new_pog_id}
      </Text>

      <Flex width="500px" justify="center">
        <SubgridTable
          columns={[
            {
              id: "product_code",
              header: "Product ID",
              justify: "center",
            },
            {
              id: "change_type",
              header: "Change Type",
              justify: "center",
              renderer: (data) =>
                data.value.slice(0, 1).toUpperCase() +
                data.value.slice(1).toLowerCase(),
            },
            {
              id: "merch_style",
              header: "Merch Style",
              justify: "center",
              renderer: (data) =>
                data.value.slice(0, 1).toUpperCase() +
                data.value.slice(1).toLowerCase(),
            },
            { id: "old_facings", justify: "center", header: "Old Facings" },
            { id: "new_facings", justify: "center", header: "New Facings" },
          ]}
          data={changeSummary.change_summary}
        />
      </Flex>
    </Flex>
  </Modal>
);
