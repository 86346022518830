import { snappableDistance } from "../../../store/constants";
import {
  DragBounds,
  Scaleable,
  SnapChecker,
  Snappable,
} from "../../../store/types";
import { numberIsBetween } from "./numberIsBetween";

interface Props extends DragBounds, Scaleable {
  snappables: Snappable[];
}

export const moveSnappablesChecker = ({
  xLeft,
  xRight,
  yBottom,
  yTop,
  snappables,
  scaleX,
  scaleY,
}: Props) => {
  const width = xRight - xLeft;
  const height = yTop - yBottom;

  let xLeftNew = xLeft;
  let xRightNew = xRight;
  let yBottomNew = yBottom;
  let yTopNew = yTop;

  snappables.forEach(
    ({
      xLeft: snappableXLeft,
      xRight: snappableXRight,
      yBottom: snappableYBottom,
      yTop: snappableYTop,
    }) => {
      const snapChecker: SnapChecker = {
        xLeft,
        xRight,
        yBottom,
        yTop,
        snappableXLeft,
        snappableXRight,
        snappableYBottom,
        snappableYTop,
        scaleX,
        scaleY,
      };

      // Snap draggable left to right of snappable.
      if (snapLeftToRight(snapChecker)) {
        xLeftNew = snappableXRight;
        xRightNew = snappableXRight + width;
      }

      // Snap draggable right to left of snappable.
      if (snapRightToLeft(snapChecker)) {
        xLeftNew = snappableXLeft - width;
        xRightNew = snappableXLeft;
      }

      // Snap draggable top to bottom of snappable.
      if (snapTopToBottom(snapChecker)) {
        yTopNew = snappableYBottom;
        yBottomNew = snappableYBottom - height;
      }

      // Snap draggable bottom to top of snappable.
      if (snapBottomToTop(snapChecker)) {
        yBottomNew = snappableYTop;
        yTopNew = snappableYTop + height;
      }

      // Snap draggable top to top of snappable.
      if (snapTopToTop(snapChecker)) {
        yTopNew = snappableYTop;
        yBottomNew = snappableYTop - height;
      }

      // Snap draggable bottom to bottom of snappable.
      if (snapBottomToBottom(snapChecker)) {
        yBottomNew = snappableYBottom;
        yTopNew = snappableYBottom + height;
      }

      // Snap draggable left to left of snappable.
      if (snapLeftToLeft(snapChecker)) {
        xLeftNew = snappableXLeft;
        xRightNew = snappableXLeft + width;
      }

      // Snap draggable right to right of snappable.
      if (snapRightToRight(snapChecker)) {
        xLeftNew = snappableXRight - width;
        xRightNew = snappableXRight;
      }
    },
  );

  return {
    xLeft: xLeftNew,
    xRight: xRightNew,
    yBottom: yBottomNew,
    yTop: yTopNew,
  };
};

export const resizeSnappablesChecker = ({
  xLeft,
  xRight,
  yBottom,
  yTop,
  snappables,
  scaleX,
  scaleY,
}: Props) => {
  let xLeftNew = xLeft;
  let xRightNew = xRight;
  let yBottomNew = yBottom;
  let yTopNew = yTop;

  snappables.forEach(
    ({
      xLeft: snappableXLeft,
      xRight: snappableXRight,
      yBottom: snappableYBottom,
      yTop: snappableYTop,
    }) => {
      const snapChecker: SnapChecker = {
        xLeft,
        xRight,
        yBottom,
        yTop,
        snappableXLeft,
        snappableXRight,
        snappableYBottom,
        snappableYTop,
        scaleX,
        scaleY,
      };

      // Snap draggable left to right of snappable.
      if (snapLeftToRight(snapChecker)) {
        xLeftNew = snappableXRight;
      }

      // Snap draggable right to left of snappable.
      if (snapRightToLeft(snapChecker)) {
        xRightNew = snappableXLeft;
      }

      // Snap draggable top to bottom of snappable.
      if (snapTopToBottom(snapChecker)) {
        yTopNew = snappableYBottom;
      }

      // Snap draggable bottom to top of snappable.
      if (snapBottomToTop(snapChecker)) {
        yBottomNew = snappableYTop;
      }

      // Snap draggable top to top of snappable.
      if (snapTopToTop(snapChecker)) {
        yTopNew = snappableYTop;
      }

      // Snap draggable bottom to bottom of snappable.
      if (snapBottomToBottom(snapChecker)) {
        yBottomNew = snappableYBottom;
      }

      // Snap draggable left to left of snappable.
      if (snapLeftToLeft(snapChecker)) {
        xLeftNew = snappableXLeft;
      }

      // Snap draggable right to right of snappable.
      if (snapRightToRight(snapChecker)) {
        xRightNew = snappableXRight;
      }
    },
  );

  return {
    xLeft: xLeftNew,
    xRight: xRightNew,
    yBottom: yBottomNew,
    yTop: yTopNew,
  };
};

const snapLeftToRight = ({
  xLeft,
  yBottom,
  yTop,
  snappableXRight,
  snappableYBottom,
  snappableYTop,
  scaleX,
}: SnapChecker) => {
  return (
    numberIsBetween(
      Math.round(Math.abs(xLeft - snappableXRight) * scaleX),
      -snappableDistance,
      snappableDistance,
    ) &&
    (numberIsBetween(yBottom, snappableYBottom, snappableYTop) ||
      numberIsBetween(yTop, snappableYBottom, snappableYTop) ||
      numberIsBetween(snappableYBottom, yBottom, yTop) ||
      numberIsBetween(snappableYTop, yBottom, yTop))
  );
};

const snapRightToLeft = ({
  xRight,
  yBottom,
  yTop,
  snappableXLeft,
  snappableYBottom,
  snappableYTop,
  scaleX,
}: SnapChecker) => {
  return (
    numberIsBetween(
      Math.round(Math.abs(xRight - snappableXLeft) * scaleX),
      -snappableDistance,
      snappableDistance,
    ) &&
    (numberIsBetween(yBottom, snappableYBottom, snappableYTop) ||
      numberIsBetween(yTop, snappableYBottom, snappableYTop) ||
      numberIsBetween(snappableYBottom, yBottom, yTop) ||
      numberIsBetween(snappableYTop, yBottom, yTop))
  );
};

const snapTopToBottom = ({
  xLeft,
  xRight,
  yTop,
  snappableXLeft,
  snappableXRight,
  snappableYBottom,
  scaleY,
}: SnapChecker) => {
  return (
    numberIsBetween(
      Math.round(Math.abs(yTop - snappableYBottom) * scaleY),
      -snappableDistance,
      snappableDistance,
    ) &&
    (numberIsBetween(xLeft, snappableXLeft, snappableXRight) ||
      numberIsBetween(snappableXLeft, xLeft, xRight) ||
      numberIsBetween(xRight, snappableXLeft, snappableXRight) ||
      numberIsBetween(snappableXRight, xLeft, xRight))
  );
};

const snapBottomToTop = ({
  xLeft,
  xRight,
  yBottom,
  snappableXLeft,
  snappableXRight,
  snappableYTop,
  scaleY,
}: SnapChecker) => {
  return (
    numberIsBetween(
      Math.round(Math.abs(yBottom - snappableYTop) * scaleY),
      -snappableDistance,
      snappableDistance,
    ) &&
    (numberIsBetween(xLeft, snappableXLeft, snappableXRight) ||
      numberIsBetween(snappableXLeft, xLeft, xRight) ||
      numberIsBetween(xRight, snappableXLeft, snappableXRight) ||
      numberIsBetween(snappableXRight, xLeft, xRight))
  );
};

const snapTopToTop = (snapChecker: SnapChecker) => {
  const {
    xLeft,
    xRight,
    yTop,
    snappableXLeft,
    snappableXRight,
    snappableYTop,
    scaleY,
  } = snapChecker;

  return (
    numberIsBetween(
      Math.round(Math.abs(yTop - snappableYTop) * scaleY),
      -snappableDistance,
      snappableDistance,
    ) &&
    (numberIsBetween(xLeft, snappableXLeft, snappableXRight) ||
      numberIsBetween(snappableXLeft, xLeft, xRight) ||
      numberIsBetween(xRight, snappableXLeft, snappableXRight) ||
      numberIsBetween(snappableXRight, xLeft, xRight) ||
      snapLeftToRight(snapChecker) ||
      snapRightToLeft(snapChecker))
  );
};

const snapBottomToBottom = (snapChecker: SnapChecker) => {
  const {
    xLeft,
    xRight,
    yBottom,
    snappableXLeft,
    snappableXRight,
    snappableYBottom,
    scaleY,
  } = snapChecker;

  return (
    numberIsBetween(
      Math.round(Math.abs(yBottom - snappableYBottom) * scaleY),
      -snappableDistance,
      snappableDistance,
    ) &&
    (numberIsBetween(xLeft, snappableXLeft, snappableXRight) ||
      numberIsBetween(snappableXLeft, xLeft, xRight) ||
      numberIsBetween(xRight, snappableXLeft, snappableXRight) ||
      numberIsBetween(snappableXRight, xLeft, xRight) ||
      snapLeftToRight(snapChecker) ||
      snapRightToLeft(snapChecker))
  );
};

const snapLeftToLeft = (snapChecker: SnapChecker) => {
  const {
    xLeft,
    yBottom,
    yTop,
    snappableXLeft,
    snappableYBottom,
    snappableYTop,
    scaleX,
  } = snapChecker;

  return (
    numberIsBetween(
      Math.round(Math.abs(xLeft - snappableXLeft) * scaleX),
      -snappableDistance,
      snappableDistance,
    ) &&
    (numberIsBetween(yBottom, snappableYBottom, snappableYBottom) ||
      numberIsBetween(snappableYBottom, yBottom, yTop) ||
      numberIsBetween(yTop, snappableYBottom, snappableYTop) ||
      numberIsBetween(snappableYTop, yBottom, yTop) ||
      snapTopToBottom(snapChecker) ||
      snapBottomToTop(snapChecker))
  );
};

const snapRightToRight = (snapChecker: SnapChecker) => {
  const {
    xRight,
    yBottom,
    yTop,
    snappableXRight,
    snappableYBottom,
    snappableYTop,
    scaleX,
  } = snapChecker;

  return (
    numberIsBetween(
      Math.round(Math.abs(xRight - snappableXRight) * scaleX),
      -snappableDistance,
      snappableDistance,
    ) &&
    (numberIsBetween(yBottom, snappableYBottom, snappableYBottom) ||
      numberIsBetween(snappableYBottom, yBottom, yTop) ||
      numberIsBetween(yTop, snappableYBottom, snappableYTop) ||
      numberIsBetween(snappableYTop, yBottom, yTop) ||
      snapTopToBottom(snapChecker) ||
      snapBottomToTop(snapChecker))
  );
};
