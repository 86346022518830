import { PlanogramContainer } from "@CommonTypes/merchflow/pog/container";
import { PogNonCircular } from "@CommonTypes/merchflow/pog/pog";

export const RoutePostMerchflowCdt2Mudmap = "/merchflow/cdt2-mudmap";

export interface PayloadPostMerchflowCdt2Mudmap {
  merchflow_id: number;
  solution_id: number;
  containers: PlanogramContainer[];
  pog_fixtures: PogNonCircular;
}

export interface ResponsePostMerchflowCdt2Mudmap {
  base_pog_id: number;
}
