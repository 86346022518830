import { PlanogramContainer } from "@CommonTypes/merchflow/pog/container";
import { PlanogramNonCircular } from "@CommonTypes/merchflow/pog/pog";

export const RouteGetMerchflows_merchflowId_S2_s1UserTriggerId_Templates_templateId_ =
  "/merchflows/:merchflowId/s2/:s1UserTriggerId/templates/:templateId";

export type ResponseGetMerchflows_merchflowId_S2_s1UserTriggerId_Templates_templateId_ =
  (
    | { status: "NOT_STARTED" | "IN_PROGRESS" | "ERROR" }
    | {
        s2_solution_id: number;
        status: "DONE";
        solution: {
          planogram: PlanogramNonCircular;
          containers: PlanogramContainer[];
        };
      }
  )[];
