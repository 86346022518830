import { useAtom, useAtomValue } from "jotai";
import _isEmpty from "lodash/isEmpty";
import { PowerBIEmbed } from "powerbi-client-react";
import { usePowerBiReport } from "src/hooks/usePowerBiReport";

import styled from "styled-components";
import { reportFiltersAtom, reportIdAtom } from "./store/atoms";
import { Modal } from "src/elements";
import { useEffect } from "react";

const Wrapper = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  .report-style-class {
    height: 100%;
  }
`;

export const PowerBiReportModal = () => {
  const [filters, setFilters] = useAtom(reportFiltersAtom);
  const reportId = useAtomValue(reportIdAtom);
  const { eventHandlersMap, reportConfig } = usePowerBiReport({
    reportId,
    filters: _isEmpty(filters) ? undefined : filters,
  });

  useEffect(() => {
    return () => {
      setFilters([]);
    };
  }, []);

  return (
    <Modal
      name="PowerBiReportModal"
      title="Merchflow PowerBI Report"
      width="90vw"
      height="95vh"
    >
      <Wrapper>
        {reportConfig ? (
          <PowerBIEmbed
            cssClassName="report-style-class"
            embedConfig={reportConfig}
            eventHandlers={eventHandlersMap}
          />
        ) : null}
      </Wrapper>
    </Modal>
  );
};
