import { PlanogramPegboardItem } from "@CommonTypes/merchflow/pog/pegboard";
import { Flex } from "src/elements";
import styled from "styled-components";

const PegboardItemElement = styled(Flex)<{
  anchorNoLeft: number;
  anchorNoTop: number;
  holesGapHorizontal: number;
  holesGapVertical: number;
  pegboardItemWidth: number;
  pegboardItemHeight: number;
  scaleX: number;
  scaleY: number;
}>`
  position: absolute;
  left: ${({ anchorNoLeft, holesGapHorizontal, scaleX }) =>
    `calc((${anchorNoLeft} - 1) * ${holesGapHorizontal}px * ${scaleX})`};
  margin-left: ${({ pegboardItemWidth, scaleX }) =>
    `calc(${pegboardItemWidth / -2}px * ${scaleX})`};
  top: ${({ anchorNoTop, holesGapVertical, scaleY }) =>
    `calc((${anchorNoTop} - 1) * ${holesGapVertical}px * ${scaleY})`};
  width: ${({ pegboardItemWidth, scaleX }) =>
    `calc(${pegboardItemWidth}px * ${scaleX})`};
  height: ${({ pegboardItemHeight, scaleY }) =>
    `calc(${pegboardItemHeight}px * ${scaleY})`};
  background: #00a8ff;
  opacity: 0.5;
`;

interface Props {
  pegboardItem: PlanogramPegboardItem;
  holesGapHorizontal: number;
  holesGapVertical: number;
  scaleX: number;
  scaleY: number;
}

export const PegboardItem = ({
  pegboardItem,
  holesGapHorizontal,
  holesGapVertical,
  scaleX,
  scaleY,
}: Props) => {
  const anchorNoTop = pegboardItem.holes[0].holeNoTop;
  const anchorNoLeft = (() => {
    let medianHoleLeft = 0;

    for (const hole of pegboardItem.holes) {
      medianHoleLeft += hole.holeNoLeft;
    }

    return medianHoleLeft / pegboardItem.holes.length;
  })();

  return (
    <PegboardItemElement
      anchorNoLeft={anchorNoLeft}
      anchorNoTop={anchorNoTop}
      holesGapHorizontal={holesGapHorizontal}
      holesGapVertical={holesGapVertical}
      pegboardItemWidth={pegboardItem.width}
      pegboardItemHeight={pegboardItem.height}
      scaleX={scaleX}
      scaleY={scaleY}
    />
  );
};
