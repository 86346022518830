import { ResponseGetMerchflowStorePog_storePogId_ } from "@CommonApi/merchflow/store/pog/_storePogId_";
import { Pog } from "@CommonTypes/merchflow/pog/pog";
import { omniAtom } from "src/utils/atoms";

const prefix = "merchflows/:merchflowId/stores/:storeCode/pogs/:storePogId";

export const merchflows_merchflowId_Stores_storeCode_Pogs_storePogId_Atom =
  omniAtom({
    prefix,
    key: "data",
    value: undefined as
      | undefined
      | null
      | ResponseGetMerchflowStorePog_storePogId_,
  });

export const merchflows_merchflowId_Stores_storeCode_Pogs_storePogId_PogAtom =
  omniAtom({
    prefix,
    key: "pog",
    value: null as null | Pog,
  });
