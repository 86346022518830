import { useAtom } from "jotai";
import { Toggle } from "src/elements";
import { planogramImagesModeAtom } from "./store/atoms";

export const PlanogramImagesMode = () => {
  const [imagesMode, setImagesMode] = useAtom(planogramImagesModeAtom);

  return (
    <Toggle
      label="Product Images"
      isChecked={imagesMode}
      setIsChecked={setImagesMode}
    />
  );
};
