import { useAtomValue } from "jotai";
import { usePOG } from "src/components/Planogram/store/actions";
import { PogAtom } from "src/components/Planogram/store/types";
import {
  Color,
  Flex,
  Grid,
  Radios,
  Scroller,
  SmallNumberInput,
  SubgridTable,
  Text,
} from "src/elements";

import { ProductImage } from "../../../Item/Image";
import { PlanogramItem } from "@CommonTypes/merchflow/pog/pog";
import { planogramSelectedItemAtom } from "src/components/PlanogramExtensions/store/atoms";
import { formatDimension } from "src/utils/formatDimension";

const DetailRow = ({
  label,
  value,
}: {
  label: string;
  value: string | number;
}) => (
  <>
    <Text variant="caption1" color={Color.primaryActive}>
      {label}:
    </Text>

    <Text variant="caption1" color={Color.textMain}>
      {value}
    </Text>
  </>
);

interface Props {
  pogAtom: PogAtom;
}

export const ProductDetails = ({ pogAtom }: Props) => {
  const { savePogChanges } = usePOG(pogAtom);

  const selectedItem = useAtomValue(planogramSelectedItemAtom);

  if (!selectedItem) {
    return null;
  }

  const updateFacings = (facings: number) => {
    const operator = facings > selectedItem.facings ? 1 : -1;

    if (facings / selectedItem.facingsRows > 1) {
      while ((facings / selectedItem.facingsRows) % 1 !== 0) {
        facings += operator;
      }
    }

    selectedItem.facings = facings;
    savePogChanges();
  };

  const updateFacingsRows = (facingsRows: number) => {
    const operator = facingsRows > selectedItem.facingsRows ? 1 : -1;

    let facings = selectedItem.facings;

    if (facings > facingsRows) {
      while ((facings / facingsRows) % 1 !== 0) {
        facings += operator;
      }
    }

    selectedItem.facingsRows = facingsRows;
    selectedItem.facings = facings;
    savePogChanges();
  };

  return (
    <Scroller gutter="stable both-edges">
      <Flex padding="10px 0" column gap="10px">
        <Flex>
          <ProductImage
            productCode={selectedItem.productCode}
            fixedSize="100px"
            size="small"
          />

          <Flex direction="column" flexGrow={1} padding="0 10px" gap="10px">
            <Text variant="small1" color={Color.primaryActive}>
              {selectedItem.name}
            </Text>

            <Grid columns="1fr auto" justify="space-between" gap="10px">
              <DetailRow label="Item ID" value={selectedItem.productCode} />
              <DetailRow label="Category" value={selectedItem.categoryCode} />
              <DetailRow label="Brand" value={selectedItem.brand} />
              <DetailRow label="CDT0" value={selectedItem.variant || "-"} />
              <DetailRow label="CDT1" value={selectedItem.cdt1 || "-"} />
              <DetailRow label="CDT2" value={selectedItem.cdt2 || "-"} />
              <DetailRow label="CDT3" value={selectedItem.cdt3 || "-"} />
            </Grid>
          </Flex>
        </Flex>

        <Grid columns="1fr 1fr" gap="10px" margin="10px 0 0 0">
          <SmallNumberInput
            label="Facings:"
            variant="sidebuttons"
            textVariant="caption1"
            textColor={Color.primaryActive}
            width="20px"
            value={selectedItem.facings}
            setValue={updateFacings}
            min={1}
          />

          <SmallNumberInput
            label="Rows of Facings:"
            variant="sidebuttons"
            textVariant="caption1"
            textColor={Color.primaryActive}
            width="20px"
            value={selectedItem.facingsRows}
            setValue={updateFacingsRows}
            min={1}
            max={9}
          />

          <SmallNumberInput
            label="Min. Facings:"
            variant="sidebuttons"
            textVariant="caption1"
            textColor={Color.primaryActive}
            width="20px"
            value={selectedItem.minFacings}
            setValue={(value) => {
              selectedItem.minFacings = value;
              savePogChanges();
            }}
            min={1}
          />

          <SmallNumberInput
            label="Max. Facings:"
            variant="sidebuttons"
            textVariant="caption1"
            textColor={Color.primaryActive}
            width="20px"
            value={selectedItem.maxFacings}
            setValue={(value) => {
              selectedItem.maxFacings = value;
              savePogChanges();
            }}
          />

          <SmallNumberInput
            label="Min. on Shelf:"
            variant="sidebuttons"
            textVariant="caption1"
            textColor={Color.primaryActive}
            width="20px"
            value={selectedItem.minOnShelf}
            setValue={(value) => {
              selectedItem.minOnShelf = value;
              savePogChanges();
            }}
            min={0}
          />

          <Flex />

          <SmallNumberInput
            label="Min. DoS:"
            variant="sidebuttons"
            textVariant="caption1"
            textColor={Color.primaryActive}
            width="20px"
            value={selectedItem.minDaysOfSupply}
            setValue={(value) => {
              selectedItem.minDaysOfSupply = value;
              savePogChanges();
            }}
            min={0}
          />

          <SmallNumberInput
            label="Max. DoS:"
            variant="sidebuttons"
            textVariant="caption1"
            textColor={Color.primaryActive}
            width="20px"
            value={selectedItem.maxDaysOfSupply}
            setValue={(value) => {
              selectedItem.maxDaysOfSupply = value;
              savePogChanges();
            }}
          />
        </Grid>

        <Radios
          label="Merchandise Style:"
          options={[
            {
              label: "Unit",
              value: "UNIT",
            },
            {
              label: "Tray",
              value: "TRAY",
            },
            {
              label: "Case",
              value: "CASE",
            },
          ]}
          value={selectedItem.merchandisingStyle}
          setValue={(value) => {
            selectedItem.merchandisingStyle =
              value as PlanogramItem["merchandisingStyle"];
            savePogChanges();
          }}
          size="small"
        />

        <Flex gap="10px">
          <Radios
            label="Orientation:"
            size="small"
            options={Object.values(
              selectedItem.validOrientations || [selectedItem.orientation],
            ).map((orientation) => ({
              label: orientation,
              value: orientation,
            }))}
            value={selectedItem.orientation}
            setValue={(orientation) => {
              selectedItem.orientation = orientation;
              savePogChanges();
            }}
          />
        </Flex>

        <Flex margin="10px 0 0 0">
          <SubgridTable
            columns={[
              { id: "style", header: "Style" },
              { id: "height", header: "Height", justify: "right" },
              { id: "width", header: "Width", justify: "right" },
              { id: "depth", header: "Depth", justify: "right" },
              { id: "units", header: "No. Of Units", justify: "right" },
            ]}
            data={[
              {
                style: "Unit",
                height: formatDimension(selectedItem.unitHeight),
                width: formatDimension(selectedItem.unitWidth),
                depth: formatDimension(selectedItem.unitDepth),
                units: 1,
              },
              {
                style: "Tray",
                height: formatDimension(selectedItem.trayHeight),
                width: formatDimension(selectedItem.trayWidth),
                depth: formatDimension(selectedItem.trayDepth),
                units: selectedItem.noOfUnitsInTray,
              },
              {
                style: "Case",
                height: formatDimension(selectedItem.caseHeight),
                width: formatDimension(selectedItem.caseWidth),
                depth: formatDimension(selectedItem.caseDepth),
                units: selectedItem.noOfUnitsInCase,
              },
            ]}
          />
        </Flex>
      </Flex>
    </Scroller>
  );
};
