import { Step1Step2Solution } from "src/omni-common/types/step2fixtures";
import { WithDebugInfo } from "src/pages/merchandise/steps/Step2Fixtures/store/types";
import { omniAtom } from "src/utils/atoms";

export type S2Filter = {
  filterNo: number;
  id: string;
  checked: boolean;
  cdt: "cdt0" | "cdt1" | "cdt2" | "";
  label: string;
  blockingType: string;
  location: string;
  pog: WithDebugInfo<Step1Step2Solution>[];
};

const prefix = "modals/filterS2";

export const s2FiltersAtom = omniAtom({
  key: "s2FiltersAtom",
  prefix,
  value: [] as S2Filter[],
});
