import { Color, Text } from "src/elements";
import styled from "styled-components";

const LabelElement = styled.div<{ zIndex?: number }>`
  position: absolute;
  top: -11px;
  left: 7px;
  display: flex;
  gap: 3px;
  backdrop-filter: blur(3px);
  padding: 1px 3px;
  border-radius: 3px;
  cursor: default;
  z-index: ${({ zIndex }) => zIndex};
`;

interface Props {
  label?: string;
  isActive?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  error?: false | string;
  zIndex?: number;
}

export const InputTitle = ({
  label,
  isActive,
  isRequired,
  isDisabled,
  error: isError,
  zIndex,
}: Props) => {
  if (!label) return null;

  return (
    <LabelElement zIndex={zIndex}>
      <Text
        variant="small1"
        color={
          (isError && Color.red) ||
          (isDisabled && Color.textDisabled) ||
          (isActive && Color.primary) ||
          Color.textSecondary
        }
        whiteSpace="nowrap"
      >
        {label}:
      </Text>

      {isRequired && (
        <Text
          variant="small1"
          color={(isDisabled && Color.textDisabled) || Color.red}
        >
          *
        </Text>
      )}
    </LabelElement>
  );
};
