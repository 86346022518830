import { useAtom, useSetAtom } from "jotai";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  FiltersSidebar,
  Metric,
  Page,
  PageStatus,
  ViewAsStoreTemplate,
} from "src/components";
import { useInlineLoaders } from "src/components/InlineLoader";
import {
  Button,
  Color,
  Flex,
  Pagination,
  Scroller,
  Select,
  HorizontalDivider,
  SubgridTable,
  Icon,
  Checkbox,
} from "src/elements";
import styled from "styled-components";

import { MerchflowHeader } from "../../components/merchflows/MerchflowHeader/MerchflowHeader";

import { useModals } from "src/components/Modals";
import { pages } from "src/utils";
import { routeToUrl } from "src/utils/axios";
import { useNavigation } from "src/utils/navigation";
import { PogOverview } from "../Merchflows_merchflowId_Stores_storeCode/components/PogOverview";
import { useStores_AllStoreData } from "./store/hooks";

import { storesFiltersAtom } from "../../components/FiltersSidebar/store/atoms";
import { getFilteredStores } from "../../components/FiltersSidebar/store/utils";
import { RouteGetMerchflows_MerchflowId_StoresAll } from "@CommonApi/merchflows/_merchflowId_/stores/all";
import { StoresViewMode } from "./store/types";
import { useQueryState } from "src/hooks/queryState";
import { getStoresPerRequest } from "./store/utils";
import {
  StoresGridLegend,
  StoresListLegend,
} from "../../components/merchflows/Legends";
import { usePowerBiReportModal } from "src/modals/PowerBiReportModal/hooks";
import { highlightsModeAtom } from "src/components/Planogram/store/atoms";
import { useViolationsReportModal } from "src/modals/ViolationsReport/store/hooks";
import { ResponseGetMerchflows_merchflowId_Details } from "@CommonApi/merchflows/review/_merchflowId_";
import { isPogHavingRangeRecommendations } from "@CommonUtils/pog/pog";
import { SelectStorePogTrigger } from "./components/SelectStorePogTrigger";
import { StorePogsExporter } from "src/components/StorePogsExporter/StorePogsExporter";
import { useApi } from "src/api";
import { RoutePutMerchflows_MerchflowId_StoresApprovals } from "@CommonApi/merchflows/_merchflowId_/stores/approvals";

import { merchflowStoresFilters } from "./store/filters";
import { useFiltersSidebar } from "src/components/FiltersSidebar/store/hooks";
import { useAsyncState } from "src/hooks/useAsyncState";
import { getStoresAllTableColumns } from "./store/columns";
import {
  merchflows_merchflowId_StoresAllAtom,
  merchflows_merchflowId_StoresAllHistoryAtom,
} from "./store/atoms";
import {
  OneStoreView,
  OneStoreViewStatus,
} from "@CommonTypes/merchflow/OneStoreView";

const PogWrapper = styled.div`
  display: inline-block;
`;

const PogsContainer = styled(Flex)`
  min-height: 0;
  flex-grow: 1;
  flex-direction: column;
  border: 1px solid ${Color.lightGrayHover};
  border-radius: 3px;
`;

const ApprovedBubble = styled(Flex)`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${Color.primary};
`;

export const PageMerchflows_merchflowId_StoresAll = () => {
  const { merchflowId: _merchflowId } = useParams<{
    merchflowId: string;
  }>();
  const merchflowId = Number(_merchflowId);

  const { putMerchflowApprovalsApi, getMerchflowDetailsApi } = useApi();
  const { isInlineLoading } = useInlineLoaders();
  const { openModal } = useModals();
  const [viewMode, setViewMode] = useQueryState<StoresViewMode>(
    "view",
    StoresViewMode.LIST,
  );
  const {
    setIndex,
    searchParams,
    setData,
    startPoolingStoreData,
    clearPooling,
    setPage,
    page,
  } = useStores_AllStoreData({ size: getStoresPerRequest(viewMode) });
  const { navigate } = useNavigation();
  const { openAllStoresReport } = usePowerBiReportModal();
  const { openViolationsReportModal } = useViolationsReportModal();
  const { openFilters } = useFiltersSidebar();

  const [details, setDetails] =
    useAsyncState<ResponseGetMerchflows_merchflowId_Details>();
  const [data] = useAtom(merchflows_merchflowId_StoresAllAtom);
  const [filters] = useAtom(storesFiltersAtom);
  const setStoresAllHistory = useSetAtom(
    merchflows_merchflowId_StoresAllHistoryAtom,
  );
  const setHighlightsMode = useSetAtom(highlightsModeAtom);

  const changeViewMode = (v: StoresViewMode) => {
    setPage(1);
    setViewMode(v);
  };

  const [selection, setSelection] = useState<number[]>([]);
  const [filteredStores, setFilteredStores] = useState<OneStoreView[] | null>(
    null,
  );
  const [isAllSelected, _setIsAllSelected] = useState(false);

  const visibleStores = useMemo(() => {
    return (
      filteredStores?.slice(
        0,
        // (page - 1) * getStoresPerRequest(viewMode),
        page * getStoresPerRequest(viewMode),
      ) || []
    );
  }, [page, filteredStores, viewMode]);

  const isDataLoading =
    data === undefined ||
    isInlineLoading(RouteGetMerchflows_MerchflowId_StoresAll) ||
    data?.data.find(
      (store) =>
        (store.status === OneStoreViewStatus.GENERATING_STORE_POG &&
          !store.store_pog) ||
        (store.store_pog?.thumbnail.status === "IN_PROGRESS" &&
          viewMode === StoresViewMode.GRID),
    ) !== undefined;
  const isApprovalsLoading = isInlineLoading(
    RoutePutMerchflows_MerchflowId_StoresApprovals,
  );
  const isViolations = details?.is_violated === true;

  // Load stores
  useEffect(() => {
    startPoolingStoreData();
    return () => {
      clearPooling();
    };
  }, [searchParams, page, viewMode]);

  // Load merchflow details
  useEffect(() => {
    setHighlightsMode(false);
    setDetails(getMerchflowDetailsApi(merchflowId));

    return () => {
      setData(null);
    };
  }, []);

  // Re-apply visible stores after data is loaded.
  useEffect(() => {
    setFilteredStores(data?.data ? getFilteredStores(data.data, filters) : []);
  }, [data]);

  const setIsAllSelected = (isAllSelected: boolean) => {
    _setIsAllSelected(isAllSelected);
    setSelection([]);
  };

  const tableData = useMemo(() => {
    return (data?.data || []).map((store) => ({
      storeCode: store.store,
      storeStatus:
        (store.status === OneStoreViewStatus.ONE_STORE_POG_APPROVED && (
          <Icon name="checkmarkCircle" size={14} />
        )) ||
        (store.status === OneStoreViewStatus.MULTIPLE_STORE_POGS_APPROVED && (
          <Icon name="alert" color={Color.red} size={14} />
        )) ||
        "",
      templateId: store.base_pog?.base_pog_id,
      planogramId: store.store_pog?.store_pog_id,
      planogramApproved:
        (store.status === OneStoreViewStatus.ONE_STORE_POG_APPROVED && (
          <Flex height="min-content" minHeight="14px" align="center">
            <ApprovedBubble />
          </Flex>
        )) ||
        "",
      returnOnSpace: (
        <Metric
          percent={store.store_pog?.store_pog_data.metrics?.returnOnSpace}
          isInline
        />
      ),
      coreRange: (
        <Metric
          percent={store.store_pog?.store_pog_data.metrics?.coreRange}
          isInline
        />
      ),

      shelfAlignment: (
        <Metric
          percent={store.store_pog?.store_pog_data.metrics?.shelfAlignment}
          isInline
        />
      ),

      dosMos: (
        <Metric
          percent={store.store_pog?.store_pog_data.metrics?.dosMos}
          isInline
        />
      ),

      size: store.s2_solution?.s2_solution_data.pog?.planogram.bays.length || 0,
    }));
  }, [data, page]);

  const onClickStore = (index: number, view: OneStoreView) => {
    setIndex(index);
    if (
      [
        OneStoreViewStatus.MULTIPLE_STORE_POGS_APPROVED,
        OneStoreViewStatus.NO_STORE_POGS_APPROVED,
        OneStoreViewStatus.ONE_STORE_POG_APPROVED,
      ].includes(view.status)
    ) {
      navigate(
        routeToUrl(pages.merchflows_merchflowId_Stores_storeCode_, {
          merchflowId,
          storeCode: view.store,
        }),
      );
      return;
    }
    openModal("StoreAll_StoreReviewAction");
  };

  const approveSelectedStorePogs = async () => {
    const approved = await putMerchflowApprovalsApi(
      merchflowId,
      isAllSelected
        ? {
            approveAllExclude: selection.map((storePogId) => ({
              store_pog_id: storePogId,
            })),
          }
        : {
            approved: selection.map((storePogId) => ({
              store_pog_id: storePogId,
            })),
          },
    );

    if (approved) {
      setIsAllSelected(false);
      setSelection([]);
      startPoolingStoreData();
    }
  };

  const unapproveSelectedStorePogs = async () => {
    const unapproved = await putMerchflowApprovalsApi(
      merchflowId,
      isAllSelected
        ? {
            unapproveAllExclude: selection.map((storePogId) => ({
              store_pog_id: storePogId,
            })),
          }
        : {
            unapproved: selection.map((storePogId) => ({
              store_pog_id: storePogId,
            })),
          },
    );

    if (unapproved) {
      setIsAllSelected(false);
      setSelection([]);
      startPoolingStoreData();
    }
  };

  const openReport = () => {
    if (
      !visibleStores[0].filter_config_id ||
      !visibleStores[0].base_pog?.base_pog_id
    )
      return;

    openAllStoresReport([
      {
        table: "gen_id_table",
        column: "filter_config_id",
        value: [visibleStores[0].filter_config_id],
      },
      {
        table: "base_pog_id",
        column: "base_pog_id",
        value: [visibleStores[0].base_pog.base_pog_id],
      },
    ]);
  };

  return (
    <Page>
      <MerchflowHeader merchflowId={merchflowId} />

      <Flex gap="10px" justify="between" margin="10px 0 0 0">
        <Flex gap="10px">
          <Button
            color="greenSmoke"
            iconRight={{ name: "filter" }}
            isDisabled={isDataLoading}
            onClick={openFilters}
          >
            Filters
          </Button>
        </Flex>

        <Flex gap="10px">
          <Button
            color={isViolations ? "yellow" : "greenSmoke"}
            onClick={() => openViolationsReportModal(merchflowId)}
            isDisabled={isDataLoading || isViolations !== true}
          >
            Violations
          </Button>

          <Button
            color="greenSmoke"
            variant="inverted"
            onClick={openReport}
            isDisabled={isDataLoading}
          >
            View Report
          </Button>

          <Button
            isLoading={isApprovalsLoading}
            isDisabled={isDataLoading}
            dropdown={[
              {
                label: "Approve selected",
                onClick: approveSelectedStorePogs,
                isDisabled: selection.length === 0 && isAllSelected === false,
              },
              {
                label: "Unapprove selected",
                onClick: unapproveSelectedStorePogs,
                isDisabled: selection.length === 0 && isAllSelected === false,
              },
            ]}
          >
            Approvals
          </Button>

          <StorePogsExporter
            merchflowId={merchflowId}
            storePogsIds={selection}
            isDisabled={isDataLoading}
          />
        </Flex>
      </Flex>

      <Flex grow column gap="10px">
        <PogsContainer>
          <Flex width="100%" padding="15px 10px 10px 10px" justify="between">
            <ViewAsStoreTemplate merchflowId={merchflowId} />

            <Flex width="100%" justify="right" gap="10px">
              <SelectStorePogTrigger merchflowId={merchflowId} />

              <Select
                width="100px"
                title="Show as"
                value={viewMode}
                setValue={changeViewMode}
                placeholder={StoresViewMode.GRID}
                options={[
                  {
                    label: "Grid",
                    value: StoresViewMode.GRID,
                  },
                  { label: "List", value: StoresViewMode.LIST },
                ]}
              />
            </Flex>
          </Flex>

          <HorizontalDivider color={Color.lightGrayHover} />

          {isDataLoading && (!data || viewMode === StoresViewMode.LIST) && (
            <PageStatus label="Loading stores..." icon="spinner" />
          )}

          {data && (
            <Flex grow column gap="5px">
              {viewMode === StoresViewMode.GRID && (
                <Flex padding="20px 20px 0 20px">
                  <Checkbox
                    isChecked={isAllSelected}
                    setIsChecked={setIsAllSelected}
                    label="Select All"
                    isCheckboxLeft
                  />
                </Flex>
              )}

              <Scroller>
                <Flex padding="20px" flexWrap="wrap" gap="25px">
                  {viewMode === StoresViewMode.GRID &&
                    visibleStores.map((store, i) => (
                      <PogWrapper
                        key={i}
                        onClick={() => onClickStore(i, store)}
                      >
                        <PogOverview
                          status={store.status}
                          label={`Store: ${store.store}`}
                          badge={
                            store.store_pog_amount !== undefined &&
                            store.store_pog_amount > 1 &&
                            String(store.store_pog_amount)
                          }
                          pog={
                            (store.status === OneStoreViewStatus.S2 &&
                              store.s2_solution?.s2_solution_data.pog) ||
                            (store.status === OneStoreViewStatus.BASE_POG &&
                              store.base_pog?.base_pog_data) ||
                            // Thumbnail missing, fallback store pog rendering.
                            store.store_pog?.store_pog_data ||
                            undefined
                          }
                          containers={
                            store.status === OneStoreViewStatus.S2 &&
                            store.s2_solution?.s2_solution_data.containers
                          }
                          selectionId={store.store_pog?.store_pog_id}
                          isAllSelected={isAllSelected}
                          selection={selection}
                          setSelection={setSelection}
                          warning={
                            store.store_pog &&
                            isPogHavingRangeRecommendations(
                              store.store_pog.store_pog_data,
                            ) &&
                            "Range recommendation"
                          }
                          thumbnail={store.store_pog?.thumbnail}
                          loadingMessage="Loading planogram..."
                        />
                      </PogWrapper>
                    ))}

                  {!isDataLoading && viewMode === StoresViewMode.LIST && (
                    <SubgridTable
                      isSortable
                      page={page}
                      rowsPerPage={getStoresPerRequest(viewMode)}
                      columns={getStoresAllTableColumns({
                        merchflowId,
                        setStoresAllHistory,
                        selection,
                        setSelection,
                        isAllSelected,
                        setIsAllSelected,
                      })}
                      data={tableData}
                    />
                  )}
                </Flex>
              </Scroller>
            </Flex>
          )}
        </PogsContainer>

        <Flex justify="between">
          {viewMode === StoresViewMode.GRID && <StoresGridLegend />}

          {viewMode === StoresViewMode.LIST && <StoresListLegend />}

          <Pagination
            page={page}
            totalPages={data?.totalPages || 1}
            setPage={setPage}
            isLoading={isDataLoading}
          />
        </Flex>
      </Flex>

      {data && (
        <FiltersSidebar
          filters={merchflowStoresFilters}
          data={data.data}
          setFilteredData={setFilteredStores}
        />
      )}
    </Page>
  );
};
