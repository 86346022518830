export const mapItemToOptions = (i: any) => ({
  value: i,
  label: i,
});

export const buildFilterQuery = (filterData: any) => {
  const selectedCdt = filterData.cdt;
  if (!selectedCdt || !filterData.label) {
    return null;
  }

  return {
    [selectedCdt]: filterData.label,
    ...(filterData.blockingType
      ? {
          [`${selectedCdt}_blockingType`]: filterData.blockingType,
        }
      : {}),
    ...(filterData.location
      ? {
          [`${selectedCdt}_location`]: filterData.location,
        }
      : {}),
  };
};
export const filterPogByQuery = (query: any) => (s2: any) => {
  if (!s2.cdtBlockingTypeList || !query) {
    return false;
  }
  return s2.cdtBlockingTypeList.some((item: any) => {
    return Object.keys(query).every((key) => item[key] === query[key]);
  });
};
