import { Pog, PlanogramBay } from "@CommonTypes/merchflow/pog/pog";
import { PlanogramShelf } from "@CommonTypes/merchflow/pog/shelf";
import { PrimitiveAtom, useAtom } from "jotai";

import { getNewOwaShelfId } from "./PlanogramShelf";

export const usePlanogramBayActions = (pogAtom: PrimitiveAtom<Pog>) => {
  const [pog, setPog] = useAtom(pogAtom);

  const addNewShelf = (bayNo: number, notchNo?: number) => {
    const shelfId = getNewOwaShelfId();

    setPog((pog) => {
      const newShelf: PlanogramShelf & { isNew: true } = {
        ...pog.planogram.bays[bayNo - 1].shelves[0],
        uniqueId: shelfId,
        mergedLeft: null,
        mergedRight: null,
        items: [],
        notchNo: notchNo || 1,
        isNew: true,
      };

      pog.planogram.bays[bayNo - 1].shelves.push(newShelf);
      return { ...pog };
    });

    return shelfId;
  };

  const validateBayShelves = (shelfId: string) => {
    setPog((pog) => {
      bayLooper: for (const bay of pog.planogram.bays) {
        for (const shelf of bay.shelves) {
          delete (shelf as PlanogramShelf & { isNew?: boolean }).isNew;
          if (shelf.uniqueId !== shelfId) continue;

          // Sort shelves into correct order.
          bay.shelves = bay.shelves.sort((s1, s2) =>
            s1.notchNo < s2.notchNo ? -1 : 1,
          );

          break bayLooper;
        }
      }

      return { ...pog };
    });
  };

  const getBayNo = (bay: PlanogramBay) => {
    for (let bayNo = 1; bayNo <= pog.planogram.bays.length; bayNo++) {
      if (pog.planogram.bays[bayNo - 1].uniqueId === bay.uniqueId) {
        return bayNo;
      }
    }

    return 1;
  };

  return { addNewShelf, validateBayShelves, getBayNo };
};
