import { PlanogramPegboard } from "@CommonTypes/merchflow/pog/pegboard";
import { Color, Flex } from "src/elements";
import styled from "styled-components";
import { PegboardItem } from "./PegboardItem";
import { PegboardHoles } from "./PegboardHoles";

const PegboardElement = styled(Flex)<{
  yBottom: number;
  pegboardHeight: number;
  pegboardWidth: number;
  scaleY: number;
  scaleX: number;
}>`
  position: absolute;
  width: ${({ pegboardWidth, scaleX }) =>
    `calc(${pegboardWidth}px * ${scaleX})`};
  bottom: ${({ yBottom, scaleY }) => `calc(${yBottom}px * ${scaleY})`};
  height: ${({ pegboardHeight, scaleY }) =>
    `calc(${pegboardHeight}px * ${scaleY})`};
  background-color: ${Color.lightGrayHover};
  border-radius: 2px;
  box-shadow: 0px 2.7px 2.7px 0px rgba(77, 77, 77, 0.2);
`;

const PegboardHolesOffset = styled(Flex)<{
  holesOffsetHorizontal: number;
  holesOffsetVertical: number;
  scaleX: number;
  scaleY: number;
}>`
  position: absolute;
  left: ${({ holesOffsetHorizontal, scaleX }) =>
    `calc(${holesOffsetHorizontal}px * ${scaleX})`};
  top: ${({ holesOffsetVertical, scaleY }) =>
    `calc(${holesOffsetVertical}px * ${scaleY})`};
  width: ${({ holesOffsetHorizontal, scaleX }) =>
    `calc(100% - 1 * ${holesOffsetHorizontal}px * ${scaleX})`};
  height: ${({ holesOffsetVertical, scaleY }) =>
    `calc(100% - 1 * ${holesOffsetVertical}px * ${scaleY})`};
`;

interface Props {
  pegboard: PlanogramPegboard;
  pegboardWidth: number;
  scaleX: number;
  scaleY: number;
}

export const Pegboard = ({
  pegboard,
  pegboardWidth,
  scaleX,
  scaleY,
}: Props) => (
  <PegboardElement
    yBottom={pegboard.yBottom}
    pegboardHeight={pegboard.height}
    pegboardWidth={pegboardWidth}
    scaleX={scaleX}
    scaleY={scaleY}
  >
    <PegboardHolesOffset
      holesOffsetHorizontal={pegboard.holesOffsetHorizontal}
      holesOffsetVertical={pegboard.holesOffsetVertical}
      scaleX={scaleX}
      scaleY={scaleY}
    >
      <PegboardHoles
        pegboardWidthWithoutOffsets={
          pegboardWidth - 2 * pegboard.holesOffsetHorizontal
        }
        pegboardHeightWithoutOffsets={
          pegboard.height - 2 * pegboard.holesOffsetVertical
        }
        holesGapHorizontal={pegboard.holesGapHorizontal}
        holesGapVertical={pegboard.holesGapVertical}
      />

      {pegboard.items.map((pegboardItem, i) => (
        <PegboardItem
          key={i}
          pegboardItem={pegboardItem}
          holesGapHorizontal={pegboard.holesGapHorizontal}
          holesGapVertical={pegboard.holesGapVertical}
          scaleX={scaleX}
          scaleY={scaleY}
        />
      ))}
    </PegboardHolesOffset>
  </PegboardElement>
);
