import { useAtomValue } from "jotai";
import lodashGet from "lodash/get";
import groupBy from "lodash/groupBy";
import { useMemo } from "react";
import { algoStep1ConvertFreezerWithItemsToContainers } from "src/adapters/algoStep1";
import { DynamicLogButton } from "src/components/DynamicLogButton";
import { useModals } from "src/components/Modals";
import { Button, Color, Flex, Grid, Modal, Scroller, Text } from "src/elements";
import {
  selectedPreStep0DataAtom,
  step0GroupsAtoms,
  step1OriginalResponseAtom,
  step1RequestAtom,
  step1ResponsesAtom,
  step2DataAtom,
  step2RequestAtom,
} from "src/pages/merchandise/store/atoms";
import { createPog } from "src/pages/merchandise/store/utils";
import { omniAtom } from "src/utils/atoms";

import { detailsAtom } from "../NewFlowReview/store/atoms";
import { BaseSizeBlock } from "./BaySizeBlock";
import { SelectBaseSizeSvg } from "./ContainerSvg";

export const SelectBaySize = () => {
  const { closeModal } = useModals();
  const details = useAtomValue(detailsAtom);
  const step1ProcessedData = useAtomValue(step1ResponsesAtom);
  const step1OriginalData = useAtomValue(step1OriginalResponseAtom);
  const step1Request = useAtomValue(step1RequestAtom);
  const step2Request = useAtomValue(step2RequestAtom);
  const step2Data = useAtomValue(step2DataAtom);
  const prestep0Data = useAtomValue(selectedPreStep0DataAtom);
  const step0Group = useAtomValue(step0GroupsAtoms);
  const s1SolutionByTemplateId = useMemo(
    () => groupBy(step1ProcessedData, (i) => i.templateId),
    [step1ProcessedData],
  );
  const s2SolutionByTemplateId = groupBy(step2Data, (i) => i.templateId);

  const pogAtomByTemplateId = useMemo(
    () =>
      Object.values(prestep0Data).reduce((acc, s0) => {
        return {
          ...acc,
          [s0.templateId]: omniAtom({
            key: "selectBaySize/planogram",
            value: createPog({ planogram: s0.planogram, unrangedItems: [] }),
          }),
        };
      }, {}),
    [prestep0Data],
  );

  const containerS1AtomByTemplateId = useMemo(
    () =>
      Object.values(s1SolutionByTemplateId).reduce((acc, s1) => {
        return {
          ...acc,
          [s1[0].templateId]: omniAtom({
            key: "selectBaySize/container",
            value: algoStep1ConvertFreezerWithItemsToContainers(
              s1[0].planogram.freezer,
            ),
          }),
        };
      }, {}),
    [step1ProcessedData],
  );

  const dataFetcher = () => {
    return prestep0Data.reduce((total, s0, index) => {
      return {
        ...total,
        [s0.templateId]: {
          s0_response: step0Group[index],
          s1_response: step1OriginalData[index],
          s1_request: step1Request[index],
          s2_response: s2SolutionByTemplateId[s0.templateId],
          all_s2_request: step2Request,
          isSource: index === 0,
        },
      };
    }, {});
  };
  return (
    <Modal name="SelectBaySize" title="Select Bay Size" width="1200px">
      <Flex column align="center">
        <SelectBaseSizeSvg />
        <Text color={Color.primary} variant="body1">
          Flow successfully applied to Base Merch Flows
        </Text>
        <Text color={Color.red} variant="body1">
          From which size would you like to create Base Planogram ?
        </Text>
        <Scroller maxHeight="500px">
          <Grid
            columns="repeat(3, 1fr)"
            width="100%"
            gap="20px"
            margin="20px 0 0 0"
          >
            {Object.keys(s1SolutionByTemplateId)
              .sort()
              .map((name) => (
                <BaseSizeBlock
                  key={name}
                  name={name}
                  merchflowId={details!.id}
                  //@ts-ignore
                  pogAtom={pogAtomByTemplateId[name]}
                  //@ts-ignore
                  containersAtom={containerS1AtomByTemplateId[name]}
                  noSolutions={
                    lodashGet(s2SolutionByTemplateId, name)
                      ? s2SolutionByTemplateId[name].length
                      : 0
                  }
                />
              ))}
          </Grid>
        </Scroller>

        <Flex margin="8px 0 0 0" width="100%" justify="right" gap="20px">
          <DynamicLogButton dataFetcher={dataFetcher} />
          <Button
            variant="inverted"
            color="red"
            onClick={() => closeModal("SelectBaySize")}
          >
            Close
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};
