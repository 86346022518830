import { useAtom, useSetAtom } from "jotai";
import { useEffect, useRef, useState } from "react";
import { useModals } from "src/components/Modals";
import {
  Button,
  Color,
  ColorInput,
  Flex,
  Input,
  Modal,
  Scroller,
  Text,
  Toggle,
} from "src/elements";
import styled from "styled-components";

import { FormulaInserter } from "./components/FormulaInserter";
import { EditableHighlight } from "./store/types";
import { highlightsOperators, highlightsSpecialFields } from "./store/utils";
import { highlightModalDataAtom, savedHighlightAtom } from "./store/atoms";
import { uuid } from "src/utils";

const FormulaBlock = styled(Flex)`
  flex-direction: column;
  flex-grow: 1;
  border: 1px solid ${Color.lightGrayHover};
  border-radius: 3px;
  overflow: hidden;
`;

interface Props {
  data: EditableHighlight;
}

export const ModalHighlight = () => {
  const [data, setData] = useAtom(highlightModalDataAtom);

  useEffect(() => {
    return () => {
      setData(null);
    };
  }, []);

  if (!data) {
    return <></>;
  }

  return <ModalHighlightInner data={data} />;
};

const ModalHighlightInner = ({ data }: Props) => {
  const { closeModal: _closeModal } = useModals();

  const setSavedHighlight = useSetAtom(savedHighlightAtom);

  const [id] = useState(data.highlight?.id || uuid());
  const [name, setName] = useState(data.highlight?.name || "");
  const [color, setColor] = useState(data.highlight?.color || "#ff0000");
  const [formula, setFormula] = useState(data.highlight?.formula || "");
  const [enabled, setEnabled] = useState(
    data.highlight ? data.highlight.enabled : true,
  );
  const [isModified, setIsModified] = useState(false);

  const refFormula = useRef<HTMLInputElement>(null);

  const insertBlock = (block: string) => {
    const input = refFormula.current;
    let caretStart = input?.selectionStart;
    let caretEnd = input?.selectionEnd;
    if (
      !input ||
      typeof caretStart !== "number" ||
      typeof caretEnd !== "number"
    ) {
      return;
    }

    if (caretStart === 0 && caretEnd === 0) {
      caretStart = formula.length;
      caretEnd = formula.length;
    }

    const newFormula =
      formula.substring(0, caretStart) +
      ` ${block} ` +
      formula.substring(caretEnd).trimStart();

    setFormula(newFormula);
    setIsModified(true);
    input.focus();
  };

  const closeModal = () => {
    _closeModal("ModalHighlight");
  };

  const saveHighlight = () => {
    setSavedHighlight({
      highlight: { id, enabled, name, color, formula },
      level: data.level,
    });
    closeModal();
  };

  return (
    <Modal
      name="ModalHighlight"
      width="340px"
      height="752px"
      title={
        !id
          ? data.level === "BASE"
            ? "New Base Highlight"
            : "New Store Highlight"
          : data.level === "BASE"
            ? "Edit Base Highlight"
            : "Edit Store Highlight"
      }
      confirmModal={
        isModified && {
          title: "Discard highlight changes",
          description:
            "Are you sure that you want to discard all highlight changes?",
          buttons: [
            { label: "Back", color: "primary", variant: "inverted" },
            { label: "Discard", color: "red", onClick: closeModal },
          ],
        }
      }
    >
      <Flex minHeight="0" column padding="20px 10px 10px 10px" gap="20px" grow>
        <Flex gap="10px">
          <Input
            title="Name"
            placeholder={formula || "New Highlight"}
            value={name}
            setValue={(name) => {
              setName(name);
              setIsModified(true);
            }}
          />

          <ColorInput
            variant="input"
            color={color}
            setColor={(color) => {
              setColor(color);
              setIsModified(true);
            }}
          />

          <Toggle
            isChecked={enabled}
            setIsChecked={(enabled) => {
              setEnabled(enabled);
              setIsModified(true);
            }}
          />
        </Flex>

        <Input
          refInput={refFormula}
          title="Formula"
          value={formula}
          setValue={(formula) => {
            setFormula(formula);
            setIsModified(true);
          }}
        />

        <Flex minHeight="0" grow gap="10px">
          <Flex minHeight="0" column grow width="100%">
            <Text variant="body2" color={Color.textSecondary}>
              Fields
            </Text>

            <FormulaBlock>
              <Scroller>
                <Flex column padding="5px">
                  {highlightsSpecialFields
                    .sort((f1, f2) => (f1.label < f2.label ? -1 : 1))
                    .map((field, i) => (
                      <FormulaInserter
                        key={i}
                        label={field.label}
                        sublabel={field.sublabel}
                        onClick={() => insertBlock(field.formula)}
                      />
                    ))}
                </Flex>
              </Scroller>
            </FormulaBlock>
          </Flex>

          <Flex column width="min-content" grow>
            <Flex padding="0 10px 0 0">
              <Text variant="body2" color={Color.textSecondary}>
                Functions
              </Text>
            </Flex>

            <FormulaBlock>
              <Scroller>
                <Flex column padding="5px">
                  {highlightsOperators.map((operator, i) => (
                    <FormulaInserter
                      key={i}
                      label={operator.label}
                      isTextCentered
                      onClick={() => insertBlock(operator.formula)}
                    />
                  ))}
                </Flex>
              </Scroller>
            </FormulaBlock>
          </Flex>
        </Flex>

        <Flex width="100%" gap="10px">
          <Button
            width="100%"
            variant={isModified ? "default" : "inverted"}
            isDisabled={formula === ""}
            onClick={saveHighlight}
          >
            Save
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};
