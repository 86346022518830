import { useAtom, useAtomValue, useSetAtom } from "jotai";
import _isEqual from "lodash/isEqual";
import _uniqBy from "lodash/uniqBy";
import { useEffect, useState } from "react";
import { useModals } from "src/components/Modals";
import { Button, Color, Flex, Modal, Text } from "src/elements";
import { useResetMerchFlowPage } from "src/hooks";
import {
  s2FilterResultAtom,
  selectedTemplateIdAtom,
  step2DataAtom,
} from "src/pages/merchandise/store/atoms";

import { useNewFlowReview } from "../NewFlowReview/store/api";
import { s2FiltersAtom } from "./atoms";
import FilterTable from "./components/FilterTable";

export const AppliedFlowFilterOptions = () => {
  const { details } = useNewFlowReview();
  const { closeModal } = useModals();
  const [dataFilters, setDataFilters] = useAtom(s2FiltersAtom);

  const [tempFilters, setTempFiters] = useState(dataFilters);
  const step2Solutions = useAtomValue(step2DataAtom);
  const selectedTemplate = useAtomValue(selectedTemplateIdAtom);
  const { resetStep2Solution } = useResetMerchFlowPage();

  const selectedS2 = step2Solutions.filter(
    (s2) => s2.templateId === selectedTemplate,
  );
  const setStep2FilterResult = useSetAtom(s2FilterResultAtom);

  const uniqFlows = _uniqBy(tempFilters.map((i) => i.pog).flat(), (v) =>
    JSON.stringify(v.planogram.freezer),
  );

  const flowsInAllFilters = uniqFlows.filter((flow) =>
    tempFilters.every((filter) =>
      filter.checked
        ? filter.pog.some((s2) => _isEqual(flow.planogram, s2.planogram))
        : true,
    ),
  );

  const onConfirmFilter = () => {
    resetStep2Solution();
    setDataFilters(tempFilters);
    setStep2FilterResult(flowsInAllFilters);
    closeModal("AppliedFlowFilterOptions");
  };

  useEffect(() => {
    setDataFilters(tempFilters);
    setStep2FilterResult(flowsInAllFilters);
  }, [step2Solutions]);

  if (!details) return null;

  return (
    <Modal
      name="AppliedFlowFilterOptions"
      title="Applied Flow Filter Options"
      width="1200px"
    >
      <Flex gap="20px" column grow>
        <Text variant={"h5"} color={Color.primary} textAlign="center">
          Flow_{details.id} succesfully applied to Base Merch Flows
        </Text>

        <FilterTable dataFilters={tempFilters} setDataFilters={setTempFiters} />

        <Flex justify="right" gap="15px">
          <Button
            variant="inverted"
            color="red"
            onClick={() => closeModal("AppliedFlowFilterOptions")}
          >
            Cancel
          </Button>

          <Button onClick={onConfirmFilter}>
            {"Show "}
            {tempFilters.length > 0
              ? flowsInAllFilters.length
              : selectedS2.length}
            {" flows"}
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};
