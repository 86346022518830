import { Pog } from "@CommonTypes/merchflow/pog/pog";
import { PrimitiveAtom, useAtom } from "jotai";

import { Panel } from "../../../Panel/Panel";
import { Highlights } from "./panels/Highlights/Highlights";
import { ProductDetails } from "./panels/ProductDetails/ProductDetails";
import { UnrangedItems } from "./panels/UnrangedItems/UnrangedItems";
import { ResponseMerchFlowApplyFilters } from "@CommonApi/merchandise";
import { PlanogramSidepanelTab } from "src/components/PlanogramExtensions/store/types";
import { planogramSidepanelTabAtom } from "src/components/PlanogramExtensions/store/atoms";

const tabs = [
  PlanogramSidepanelTab.PRODUCT_DETAILS,
  PlanogramSidepanelTab.FLOATING_SHELF,
  PlanogramSidepanelTab.HIGHLIGHTS,
];

interface Props {
  scaleX: number;
  scaleY: number;
  pogAtom: PrimitiveAtom<Pog>;
  merchflowId?: number;
  currentStoreCode?: string;
  availableStores: ResponseMerchFlowApplyFilters["filter_config"] | null;
}

export const ProductsPanel = ({
  scaleX,
  scaleY,
  pogAtom,
  merchflowId,
  currentStoreCode,
  availableStores,
}: Props) => {
  const [tab, setTab] = useAtom(planogramSidepanelTabAtom);

  return (
    <Panel tabs={tabs} tab={tab} setTab={setTab} width="320px">
      {tab === PlanogramSidepanelTab.FLOATING_SHELF && (
        <UnrangedItems
          pogAtom={pogAtom}
          scaleX={scaleX}
          scaleY={scaleY}
          merchflowId={merchflowId}
          currentStoreCode={currentStoreCode}
          availableStores={availableStores}
        />
      )}

      {tab === PlanogramSidepanelTab.PRODUCT_DETAILS && (
        <ProductDetails pogAtom={pogAtom} />
      )}

      {tab === PlanogramSidepanelTab.HIGHLIGHTS && (
        <Highlights merchflowId={merchflowId} />
      )}
    </Panel>
  );
};
