import { pages } from "src/utils";
import { omniAtom } from "src/utils/atoms";
import { ResponseGetMerchflow_merchflowId_S2Template_templateId_ } from "@CommonApi/merchflow/_merchflowId_/s2/template/_templateId_";

const prefix = pages.merchflows_merchflowId_S2Templates_templateId_;

export const merchflows_merchflowId_S2Templates_templateCode_Atom = omniAtom({
  prefix,
  key: "data",
  value: undefined as
    | undefined
    | null
    | ResponseGetMerchflow_merchflowId_S2Template_templateId_,
});
