import { useAtomValue } from "jotai";
import groupBy from "lodash/groupBy";
import { useMemo } from "react";
import { algoStep2ConvertFreezerWithItemsToContainers } from "src/adapters/algoStep2";
import { Color, Grid, Render } from "src/elements";
import { Step1Step2Solution } from "src/omni-common/types/step2fixtures";
import {
  selectedPreStep0DataAtom,
  selectedTemplateIdAtom,
} from "src/pages/merchandise/store/atoms";
import { createPog } from "src/pages/merchandise/store/utils";
import { omniAtom } from "src/utils/atoms";
import styled from "styled-components";

import { Solution } from "./QuadrantSolution";
import { detailsAtom } from "src/modals/NewFlowReview/store/atoms";

const QuadrantView = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto 1fr;
  height: 100%;
  width: 100%;
  gap: 15px;
`;

const HorizontalSeparator = styled.div`
  width: 100%;
  height: 3px;
  background-color: ${Color.primary};
`;

const VerticalSeparator = styled.div`
  width: 3px;
  height: 100%;
  background-color: ${Color.primary};
`;

type QuadrantProps = {
  page: number;
  data: (Step1Step2Solution & { templateId: string; solution_id: number })[];
};
export const Quadrant = ({ page, data }: QuadrantProps) => {
  const details = useAtomValue(detailsAtom);
  const selectedTemplatedId = useAtomValue(selectedTemplateIdAtom);
  const preStep0 = useAtomValue(selectedPreStep0DataAtom);
  const pogByTemplate = groupBy(preStep0, (d) => d.templateId);

  const [solutionIds, containerAtoms, pogAtom] = useMemo(() => {
    const step2InView = data.slice(4 * (page - 1), 4 * page);
    return [
      step2InView.map((d) => d.solution_id),
      step2InView.map((d) =>
        omniAtom({
          key: "quadrant/step2Container",
          value: algoStep2ConvertFreezerWithItemsToContainers(
            d.planogram.freezer,
          ),
        }),
      ),
      step2InView.map((d) =>
        omniAtom({
          key: "quadrant/step2Pog",
          value: createPog({
            planogram: pogByTemplate[selectedTemplatedId][0].planogram,
            unrangedItems: [],
            metrics: d.metrics,
          }),
        }),
      ),
    ];
  }, [selectedTemplatedId, page, data]);

  if (data.length === 0) {
    return null;
  }

  return (
    <>
      <QuadrantView>
        <Grid gap="20px" columns="1fr auto 1fr">
          <Solution
            merchflowId={details!.id}
            solutionId={solutionIds[0]}
            containerAtom={containerAtoms[0]}
            pogAtom={pogAtom[0]}
            index={page * 4 - 4}
          />

          <Render when={containerAtoms.length > 1}>
            <VerticalSeparator />

            <Solution
              merchflowId={details!.id}
              solutionId={solutionIds[1]}
              containerAtom={containerAtoms[1]}
              pogAtom={pogAtom[1]}
              index={page * 4 - 3}
            />
          </Render>
        </Grid>

        <Render when={containerAtoms.length > 2}>
          <HorizontalSeparator />

          <Grid gap="20px" columns="1fr auto 1fr">
            <Solution
              merchflowId={details!.id}
              solutionId={solutionIds[2]}
              containerAtom={containerAtoms[2]}
              pogAtom={pogAtom[2]}
              index={page * 4 - 2}
            />
            <Render when={containerAtoms.length > 3}>
              <VerticalSeparator />
              <Solution
                merchflowId={details!.id}
                solutionId={solutionIds[3]}
                containerAtom={containerAtoms[3]}
                pogAtom={pogAtom[3]}
                index={page * 4 - 1}
              />
            </Render>
          </Grid>
        </Render>
      </QuadrantView>
    </>
  );
};
