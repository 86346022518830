import { useAtomValue } from "jotai";
import { useMemo } from "react";
import { Planogram } from "src/components";
import { useModals } from "src/components/Modals";
import { Button, Flex, Modal } from "src/elements";
import { useStorePogApi } from "src/hooks";
import { adapterPogNonCircularToPog } from "src/omni-common/adapters/pog";
import { emptyContainersAtom } from "src/pages/merchandise/store/atoms";
import { StoreLevelPogRow } from "src/pages/merchandise/store/types";
import { omniAtom } from "src/utils/atoms";

import { editStorePogAtom } from "./atoms";
import { PlanogramHovererDetails } from "src/components/PlanogramExtensions/PlanogramHovererDetails/PlanogramHovererDetails";

export const EditStorePogModal = () => {
  const { closeModal: _closeModal } = useModals();
  const { saveEditedPog } = useStorePogApi();

  const rowData = useAtomValue(editStorePogAtom);

  const pogAtom = useMemo(() => {
    const { pog } = rowData as StoreLevelPogRow;

    return omniAtom({
      key: "storePogEdit",
      value: adapterPogNonCircularToPog(pog),
    });
  }, [rowData]);

  const editedPog = useAtomValue(pogAtom);

  const closeModal = () => {
    _closeModal("EditStorePogModal");
  };

  const saveEdit = async () => {
    if (!rowData) return;
    await saveEditedPog(editedPog);
    closeModal();
  };

  return (
    <Modal name="EditStorePogModal" title="Logs">
      <Flex column gap="20px" grow>
        {pogAtom && (
          <Flex column gap="20px" grow>
            <Planogram
              pogAtom={pogAtom}
              containersAtom={emptyContainersAtom}
              isEditable
              isPog
              isPanelVisible
            />

            <PlanogramHovererDetails />
          </Flex>
        )}

        <Flex justify="right" gap="12px">
          <Button onClick={closeModal} variant="inverted">
            Close
          </Button>

          <Button onClick={saveEdit}>Save</Button>
        </Flex>
      </Flex>
    </Modal>
  );
};
