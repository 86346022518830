import { adapterPogNonCircularToPog } from "@CommonAdapters/pog";
import { atom, useAtomValue } from "jotai";
import { useInlineLoaders } from "src/components/InlineLoader";
import { useModals } from "src/components/Modals";
import { Flex, Spinner, Text } from "src/elements";
import { useStores_StoresData } from "src/pages/Merchflows_merchflowId_Stores/hooks";
import {
  Stores__storePogIndexAtom,
  merchflows_MerchflowId_StoresAtom,
} from "src/pages/Merchflows_merchflowId_Stores/store/atoms";
import { emptyContainersAtom } from "src/pages/merchandise/store/atoms";
import { pages } from "src/utils";
import { routeToUrl } from "src/utils/axios";
import { useNavigation } from "src/utils/navigation";
import { ReviewActionModal } from "./ReviewActionModal";
import { OneStoreViewStatus } from "@CommonTypes/merchflow/OneStoreView";
import { RoutePutMerchflows_MerchflowId_StoresApprovals } from "@CommonApi/merchflows/_merchflowId_/stores/approvals";
import { emptyPogNonCircular } from "src/pages/MerchflowTemplateBasePogs/store/atom";

export const Stores_StoreReviewAction = () => {
  const { closeModal } = useModals();
  const storePropApiResult = useAtomValue(merchflows_MerchflowId_StoresAtom);
  const storePogIndex = useAtomValue(Stores__storePogIndexAtom);
  const { navigate } = useNavigation();
  const { isInlineLoading } = useInlineLoaders();
  const { merchflowId } = useStores_StoresData();

  const storePropData = storePropApiResult?.data || [];

  const currentStore = storePropData[storePogIndex];

  const pogAtom = atom(
    adapterPogNonCircularToPog(
      currentStore.status === OneStoreViewStatus.BASE_POG
        ? currentStore.base_pog!.base_pog_data
        : currentStore.s2_solution!.s2_solution_data.pog || emptyPogNonCircular,
    ),
  );
  const containersAtom =
    currentStore.status === OneStoreViewStatus.S2
      ? atom(currentStore.s2_solution!.s2_solution_data.containers || [])
      : emptyContainersAtom;

  const onClose = () => {
    closeModal("Stores_StoreReviewAction");
  };

  // const navigateToEditMerchflow = () => {
  //   // TODO wait for BE to provide basepog ID
  //   window.open(`${pages.merchandise}/${merchflowId}`);
  //   onClose();
  // };

  const navigateToBasePogPage = () => {
    onClose();
    navigate(
      routeToUrl(pages.merchflows_merchflowId_BasePog_basePogId_, {
        merchflowId: merchflowId!,
        basePogId: currentStore.base_pog?.base_pog_id!,
      }),
    );
  };
  const navigateToCreateTemplatePlanogram = () => {
    onClose();
    navigate(
      routeToUrl(pages.merchflows_merchflowId_S2Templates_templateId_, {
        merchflowId,
        s2Solutionid: currentStore.template_id,
      }),
    );
  };

  const dropdown = [
    // { label: "Edit Merch flow", onClick: navigateToEditMerchflow },
    currentStore.status === OneStoreViewStatus.BASE_POG
      ? {
          label: "Edit Template Planogram",
          onClick: navigateToBasePogPage,
        }
      : {
          label: "Create Template Planogram",
          onClick: navigateToCreateTemplatePlanogram,
        },
  ];

  return (
    <>
      {isInlineLoading(RoutePutMerchflows_MerchflowId_StoresApprovals) && (
        <Spinner isFullscreen />
      )}
      <ReviewActionModal
        modalName="Stores_StoreReviewAction"
        dropdown={{ label: "STORE OPTIONS", options: dropdown }}
        containersAtom={containersAtom}
        pogAtom={pogAtom}
        numberOfPog={storePropData.length}
        footer={
          <Flex gap="5px">
            <Text>Stores: {currentStore.store}</Text>

            {currentStore.base_pog?.base_pog_id && (
              <Text>
                | Base Planogram ID: {currentStore.base_pog.base_pog_id}
              </Text>
            )}

            <Text>| TemplateID: {currentStore.template_id}</Text>
          </Flex>
        }
      />
    </>
  );
};
