import uniq from "lodash/uniq";
import { useEffect, useState } from "react";
import { useInlineLoaders } from "src/components/InlineLoader";
import { useModals } from "src/components/Modals";
import { useToasts } from "src/components/Toasts";
import { Button, Color, Flex, Modal, Spinner, Text } from "src/elements";
import { useContinueMerchFlow } from "src/hooks";
import { ResponseMerchFlowApplyFilters } from "src/omni-common/api/merchandise";
import {
  getFilterConfigTemplateStatus,
  getFlowReviewFilterConfigReq,
} from "src/pages/merchandise/store/requests";

import { useNewFlowReview } from "../NewFlowReview/store/api";

export const ChangeFilterConfigModal = () => {
  const { closeModal: _closeModal } = useModals();
  const [listFilterConfig, setListFilterConfig] = useState<
    ResponseMerchFlowApplyFilters[]
  >([]);
  const [templateData, setTemplateData] = useState<any>([]);
  const { details, filtersApplied } = useNewFlowReview();
  const { addInlineLoader, removeInlineLoader, isInlineLoading } =
    useInlineLoaders();
  const { toast } = useToasts();
  const { continueMerchFlow } = useContinueMerchFlow();

  useEffect(() => {
    if (!details) return;
    const reviewId = details.id;
    addInlineLoader("loadFilterConfigModal");
    getFlowReviewFilterConfigReq(reviewId)
      .then((r) => r.data)
      .then((list) => {
        Promise.all(
          list.map((config) =>
            getFilterConfigTemplateStatus(config.id).then((r) => r.data),
          ),
        )
          .then((d) => {
            setTemplateData(d as any);
            setListFilterConfig(list);
          })
          .catch((e) => {
            toast({
              title: "Something went wrong",
              error: e,
              type: "error",
            });
          })
          .finally(() => {
            removeInlineLoader("loadFilterConfigModal");
          });
      });
  }, []);

  const closeModal = () => {
    _closeModal("ChangeFilterConfigModal");
  };

  const loadFilterConfig = async (config: ResponseMerchFlowApplyFilters) => {
    if (!details) return;
    await continueMerchFlow(details, undefined, config.id);
    closeModal();
  };

  return (
    <Modal name="ChangeFilterConfigModal" title="View Filter Config">
      <Flex gap="20px" justify="around" grow>
        {isInlineLoading("loadFilterConfigModal") ? (
          <Flex>
            <Spinner />
          </Flex>
        ) : (
          listFilterConfig.map((config, index) => (
            <Flex key={config.id} column maxWidth="50%">
              <Text variant="h6" color={Color.primary}>
                Filter config {config.id}{" "}
                {config.id === filtersApplied?.id ? "(Selected)" : ""}
              </Text>
              <Text variant="h6" color={Color.primary}>
                Selected Template:{" "}
              </Text>
              <Text>
                {uniq(
                  config.filter_config.stores.map((store) => store.template_id),
                )
                  .sort()
                  .join(", ")}
              </Text>
              <Text variant="h6" color={Color.primary}>
                Status: {templateData[index]?.status}
              </Text>
              <Button
                variant="inverted"
                onClick={() => loadFilterConfig(config)}
              >
                Load
              </Button>
            </Flex>
          ))
        )}
      </Flex>
    </Modal>
  );
};
