import { useRef } from "react";
import styled from "styled-components";

import { Color, Flex, Icon, Text } from "src/elements";
import { useHoverClickable } from "src/utils/hoverClickable";

const Container = styled(Flex)<{
  width: string;
  useParent?: boolean;
  isCheckboxLeft?: boolean;
  isDisabled?: boolean;
  isSoftDisabled?: boolean;
}>`
  width: ${({ width }) => width};
  position: ${({ useParent }) => (useParent ? "static" : "relative")};
  flex-direction: ${({ isCheckboxLeft }) =>
    isCheckboxLeft ? "row-reverse" : "row"};
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  pointer-events: ${({ isDisabled, isSoftDisabled }) =>
    (isDisabled || isSoftDisabled) && "none"};
`;

const ClickArea = styled(Flex)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

interface Props {
  isChecked: boolean;
  setIsChecked: (checked: boolean) => void;
  label?: string;
  isDisabled?: boolean;
  isSoftDisabled?: boolean;
  isCheckboxLeft?: boolean;
  isHeader?: boolean;
  width?: string;
  useParent?: boolean;
}

export const Checkbox = ({
  isChecked,
  setIsChecked,
  label,
  isDisabled,
  isSoftDisabled,
  isCheckboxLeft,
  isHeader,
  width = "100%",
  useParent,
}: Props) => {
  const refClickArea = useRef<HTMLDivElement>(null);
  const { isHovered, isActive } = useHoverClickable(refClickArea);

  const checkboxColor =
    (isDisabled && Color.textDisabled) ||
    (isHovered && isActive && Color.primaryActive) ||
    Color.primary;

  const checkboxIcon =
    (isChecked && "checkboxOn") ||
    (isHovered && isActive && "checkboxOn") ||
    (isHovered && "checkboxHovered") ||
    "checkboxOff";

  const textColor =
    (isDisabled && Color.textDisabled) ||
    (isHeader && Color.primaryActive) ||
    Color.textSecondary;

  const textVariant = (isHeader && "body1") || "body2";

  const onClick = () => {
    setIsChecked(!isChecked);
  };

  return (
    <Container
      useParent={useParent}
      width={width}
      isCheckboxLeft={isCheckboxLeft}
      isDisabled={isDisabled}
      isSoftDisabled={isSoftDisabled}
    >
      {label && (
        <Flex flexGrow={1}>
          <Text variant={textVariant} color={textColor}>
            {label}
          </Text>
        </Flex>
      )}

      <Flex minWidth="14px">
        <Icon name={checkboxIcon} color={checkboxColor} />
      </Flex>

      <ClickArea ref={refClickArea} isDisabled={isDisabled} onClick={onClick} />
    </Container>
  );
};
