import React from "react";
import { Color, Flex, Icon, Text } from "src/elements";
import styled from "styled-components";

import { smallNumberInputSize } from "./store/constants";
import { TextVariant } from "../Text";
import { textVariants } from "../Text/store/variants";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
`;

const InputElement = styled.input<{
  width: string;
  variant: "default" | "sidebuttons";
}>`
  padding: 0;
  width: ${({ width }) => width};
  height: ${smallNumberInputSize}px;
  border-width: 1px;
  border-style: solid;
  border-radius: ${({ variant }) => variant === "default" && "3px"};
  border-color: ${Color.primary};
  outline: none;

  text-align: center;
  font-size: ${textVariants.small3.fontSize};
  font-weight: ${textVariants.small3.fontWeight};
`;

const SideButtonElement = styled.div<{ side: "left" | "right" }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${smallNumberInputSize}px;
  height: ${smallNumberInputSize}px;
  background-color: ${Color.primary};
  user-select: none;
  cursor: pointer;
  border-radius: ${({ side }) =>
    side === "left" ? "3px 0 0 3px" : "0 3px 3px 0"};

  &:hover {
    background-color: ${Color.primaryHover};
  }

  &:active {
    background-color: ${Color.primaryActive};
  }
`;

const SideButton: React.FC<{ side: "left" | "right"; onClick: () => void }> = ({
  side,
  onClick,
}) => (
  <SideButtonElement side={side} onClick={onClick}>
    <Icon
      name={side === "left" ? "minus" : "plus"}
      size={7}
      color={Color.white}
    />
  </SideButtonElement>
);

interface Props {
  value: number;
  setValue: (value: number) => void;
  min?: number;
  max?: number;
  label?: string;
  width?: string | "dynamic";
  variant?: "default" | "sidebuttons";
  textVariant?: TextVariant;
  textColor?: string;
}

export const SmallNumberInput: React.FC<Props> = ({
  value,
  setValue,
  min,
  max,
  label,
  width = "dynamic",
  variant = "default",
  textVariant = "small2",
  textColor = Color.textMain,
}) => {
  const getWidth = () => {
    if (width === "dynamic") {
      return `${String(value).length * 5 + (smallNumberInputSize - 5)}px`;
    }

    return width;
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(Number(event.target.value));
  };

  const increase = () => {
    if (max === undefined || value + 1 <= max) {
      setValue(value + 1);
    }
  };

  const decrease = () => {
    if (min === undefined || value - 1 >= min) {
      setValue(value - 1);
    }
  };

  return (
    <Container>
      {label && (
        <Text variant={textVariant} whiteSpace="nowrap" color={textColor}>
          {label}
        </Text>
      )}

      <Flex>
        {variant === "sidebuttons" && (
          <SideButton side="left" onClick={decrease} />
        )}

        <InputElement
          type="number"
          value={value}
          min={min}
          max={max}
          onChange={onChange}
          width={getWidth()}
          variant={variant}
        />

        {variant === "sidebuttons" && (
          <SideButton side="right" onClick={increase} />
        )}
      </Flex>
    </Container>
  );
};
