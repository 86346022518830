import { useAtom, useSetAtom } from "jotai";
import groupBy from "lodash/groupBy";
import { useInlineLoaders } from "src/components/InlineLoader";
import { useToasts } from "src/components/Toasts";
import {
  MergeRule,
  NewFlowReviewStep,
  PayloadUploadCoreRange,
} from "src/modals/NewFlowReview/store/types";
import {
  MerchFlowCoreRange,
  ResponseMerchFlowApplyFilters,
} from "src/omni-common/api/merchandise";
import {
  colorObj,
  ffDataRequestsAtom,
  pmDataRequestsAtom,
  posDataRequestsAtom,
  resetColor,
} from "src/pages/merchandise/store/atoms";
import { uuid } from "src/utils";

import { getContainerColor } from "@CommonUtils/pog/container";
import {
  getAllInputsReq,
  postBeginInputsUploadReq,
  postFinishInputsUploadReq,
  putPresignedFileUploadReq,
} from "../../../api/Inputs";
import {
  categoriesAtom,
  categoryAtom,
  coreRangeAtom,
  detailsAtom,
  filtersAppliedAtom,
  filtersAvailableAtom,
  filtersBaySizesAtom,
  filtersClustersAtom,
  filtersFixturesHeightsAtom,
  filtersFixturesWidthsAtom,
  filtersOverrideCDTAtom,
  filtersStatesAtom,
  mergeRulesAtom,
  mergeRulesFlowHierarchyAtom,
  mergeRulesVariantsAvailableAtom,
  nameAtom,
  notesAtom,
  setupStepAtom,
  subCategoryAtom,
} from "./atoms";
import {
  getMerchFlowLoadCategoriesReq,
  getMerchFlowLoadFiltersReq,
  getMerchFlowLoadVariantsReq,
} from "./requests";
import { env } from "src/utils/env";

export const useNewFlowReview = () => {
  const { addInlineLoader, removeInlineLoader } = useInlineLoaders();
  const { toast } = useToasts();

  const [setupStep, setSetupStep] = useAtom(setupStepAtom);
  const [categories, setCategories] = useAtom(categoriesAtom);
  const [name, setName] = useAtom(nameAtom);
  const [notes, setNotes] = useAtom(notesAtom);
  const [category, setCategory] = useAtom(categoryAtom);
  const [subCategory, setSubCategory] = useAtom(subCategoryAtom);
  const [coreRange, setCoreRange] = useAtom(coreRangeAtom);
  const [details, setDetails] = useAtom(detailsAtom);
  const [filtersAvailable, setFiltersAvailable] = useAtom(filtersAvailableAtom);
  const [filtersSelectedBaySizes, setFiltersSelectedBaySizes] =
    useAtom(filtersBaySizesAtom);
  const [filtersSelectedClusters, setFiltersSelectedClusters] =
    useAtom(filtersClustersAtom);
  const [filtersSelectedStates, setFiltersSelectedStates] =
    useAtom(filtersStatesAtom);
  const [filtersSelectedHeights, setFiltersSelectedHeights] = useAtom(
    filtersFixturesHeightsAtom,
  );
  const [filtersSelectedWidths, setFiltersSelectedWidths] = useAtom(
    filtersFixturesWidthsAtom,
  );
  const [overrideCDT, setOverrideCDT] = useAtom(filtersOverrideCDTAtom);
  const [filtersApplied, setFiltersApplied] = useAtom(filtersAppliedAtom);
  const [variantsAvailable, setVariantsAvailable] = useAtom(
    mergeRulesVariantsAvailableAtom,
  );
  const [mergeRules, setMergeRules] = useAtom(mergeRulesAtom);
  const [flowHierarchy, setFlowHierarchy] = useAtom(
    mergeRulesFlowHierarchyAtom,
  );

  // TODO - clean these up, these should be probably not needed, we should use prebuilt pogs/containers instead
  const setFfDataRequests = useSetAtom(ffDataRequestsAtom);
  const setPmDataRequests = useSetAtom(pmDataRequestsAtom);
  const [, setPosDataRequests] = useAtom(posDataRequestsAtom);
  const loadCategoriesApi = async () => {
    addInlineLoader("merchFlow/categories");

    try {
      const { data } = await getMerchFlowLoadCategoriesReq();
      setCategories(data);
    } catch (error) {
      toast({
        title: "Faled to load categories",
        error,
      });
    }

    removeInlineLoader("merchFlow/categories");
  };

  const uploadCoreRangeApi = async ({
    file,
    categoryId,
  }: PayloadUploadCoreRange) => {
    addInlineLoader("merchFlow/coreRange");

    try {
      const {
        data: {
          corerange: { fileName, url },
        },
      } = await postBeginInputsUploadReq(categoryId);

      await putPresignedFileUploadReq(url, file);

      const {
        data: { corerangeUploadId },
      } = await postFinishInputsUploadReq({
        files: [
          {
            fileName,
            label: fileName,
          },
        ],
        isValidationRequired: false,
      });

      setCoreRange({
        option: MerchFlowCoreRange.CSV,
        uploadId: Number(String(corerangeUploadId)),
      });
    } catch (error) {
      toast({
        title: "Failed to upload core range file",
        error,
      });
    }

    removeInlineLoader("merchFlow/coreRange");
  };

  const loadFiltersApi = async () => {
    addInlineLoader("merchFlow/loadFilters");

    try {
      const { data } = await getMerchFlowLoadFiltersReq({
        retailer_category_code: category!,
        category_code: subCategory!,
      });

      setFiltersAvailable(data);
    } catch (error) {
      toast({
        title: "Failed to load available filters",
        error,
      });
    }

    removeInlineLoader("merchFlow/loadFilters");
  };

  const loadMergeRulesApi = async (
    oldFilter?: ResponseMerchFlowApplyFilters,
  ) => {
    addInlineLoader("merchFlow/loadMergeRules");

    try {
      const usedFilters = oldFilter ?? filtersApplied;

      if (!usedFilters) {
        throw Error("Missing filter_config data");
      }
      const { data: dataVariants } = await getMerchFlowLoadVariantsReq({
        filterConfigId: usedFilters.id,
      });

      resetColor();
      dataVariants.forEach((variant) => {
        const newColor = getContainerColor(variant, Object.values(colorObj));
        //@ts-ignore
        colorObj[variant] = newColor;
      });

      setVariantsAvailable(dataVariants);

      const inputsPayload = usedFilters!.filter_config.stores.reduce(
        (total, store) => {
          total.ffUploadIds.push(store.ffUploadId);
          total.pmUploadIds.push(store.pmUploadId);
          total.posUploadIds.push(store.posUploadId);
          return total;
        },
        {
          ffUploadIds: [] as number[],
          pmUploadIds: [] as number[],
          posUploadIds: [] as number[],
        },
      );

      const { data: storesData } = await getAllInputsReq(inputsPayload);

      const pmGroupByUploadId = groupBy(storesData.pm, "upload_id");
      const ffGroupByUploadId = groupBy(storesData.ff, "upload_id");
      const posGroupByUploadId = groupBy(storesData.pos, "upload_id");

      let pmListByUploadId = inputsPayload.pmUploadIds.map((uploadId) => {
        if (!pmGroupByUploadId[uploadId]) {
          toast({
            title: "Product Master file missing",
            message: `Product Master file with upload id ${uploadId} missing`,
            type: "warning",
          });
        }

        return pmGroupByUploadId[uploadId];
      });
      let ffListByUploadId = inputsPayload.ffUploadIds.map((uploadId) => {
        if (!ffGroupByUploadId[uploadId]) {
          toast({
            title: "Fixture file missing",
            message: `Fixture file with upload id ${uploadId} missing`,
            type: "warning",
          });
        }
        return ffGroupByUploadId[uploadId];
      });
      let posListByUploadId = inputsPayload.posUploadIds.map((uploadId) => {
        if (!posGroupByUploadId[uploadId]) {
          toast({
            title: "Position file missing",
            message: `Position file with upload id ${uploadId} missing`,
            type: "warning",
          });
        }
        return posGroupByUploadId[uploadId];
      });

      for (let i = 0; i < pmListByUploadId.length; i++) {
        if (
          pmListByUploadId[i] === undefined ||
          ffListByUploadId[i] === undefined ||
          posListByUploadId[i] === undefined
        ) {
          pmListByUploadId[i] = null as any;
          ffListByUploadId[i] = null as any;
          posListByUploadId[i] = null as any;
        }
      }

      ffListByUploadId = ffListByUploadId.filter((ff) => ff !== null);
      pmListByUploadId = pmListByUploadId
        .filter((pm) => pm !== null)
        .map((pm) => pm.filter((item) => item.oos === ""));
      posListByUploadId = posListByUploadId.filter((pos) => pos !== null);

      // Save all fixtures, product masters, positions data for later use
      setFfDataRequests(ffListByUploadId);
      setPmDataRequests(pmListByUploadId);
      setPosDataRequests(posListByUploadId);

      // Find reasonable merge rules from fixtures and position data
      const mergeRules: MergeRule[] = dataVariants.map((variant) => ({
        variants: [variant],
      }));

      setMergeRules(mergeRules);
      removeInlineLoader("merchFlow/loadMergeRules");
      return {
        ffDataRequests: ffListByUploadId,
        pmDataRequests: pmListByUploadId,
      };
    } catch (error) {
      toast({
        title: "Failed to load default merge rules",
        error,
      });
    }

    removeInlineLoader("merchFlow/loadMergeRules");
    return {
      ffDataRequests: [],
      pmDataRequests: [],
    };
  };

  const startNewMerchFlow = () => {
    setName((env.DEV && uuid()) || "");
    setSetupStep(NewFlowReviewStep.STEP_1_1_INPUTS);
  };

  const getStoresMatchingSelectedFilters = () => {
    return filtersAvailable!.stores.filter(
      (store) =>
        (filtersSelectedBaySizes.length > 0
          ? filtersSelectedBaySizes.includes(store.bay_size)
          : true) &&
        (filtersSelectedClusters.length > 0
          ? filtersSelectedClusters.includes(store.cluster)
          : true) &&
        (filtersSelectedStates.length > 0
          ? filtersSelectedStates.includes(store.state)
          : true) &&
        (filtersSelectedHeights.length > 0
          ? filtersSelectedHeights.includes(store.section_height)
          : true) &&
        (filtersSelectedWidths.length > 0
          ? filtersSelectedWidths.includes(store.section_width)
          : true),
    );
  };

  return {
    // States.
    setupStep,
    setSetupStep,
    details,
    setDetails,
    categories,
    name,
    setName,
    notes,
    setNotes,
    category,
    setCategory,
    subCategory,
    setSubCategory,
    coreRange,
    setCoreRange,
    filtersAvailable,
    setFiltersAvailable,
    filtersSelectedBaySizes,
    setFiltersSelectedBaySizes,
    filtersSelectedClusters,
    setFiltersSelectedClusters,
    filtersSelectedHeights,
    setFiltersSelectedHeights,
    filtersSelectedWidths,
    setFiltersSelectedWidths,
    setFiltersApplied,
    filtersSelectedStates,
    setFiltersSelectedStates,
    overrideCDT,
    setOverrideCDT,
    filtersApplied,
    variantsAvailable,
    setVariantsAvailable,
    flowHierarchy,
    setFlowHierarchy,
    mergeRules,
    setMergeRules,

    // Actions.
    startNewMerchFlow,
    getStoresMatchingSelectedFilters,

    // APIs.
    loadCategoriesApi,
    uploadCoreRangeApi,
    loadFiltersApi,
    loadMergeRulesApi,
  };
};
