import { Pog } from "@CommonTypes/merchflow/pog/pog";
import { PlanogramShelf } from "@CommonTypes/merchflow/pog/shelf";
import { PrimitiveAtom, useAtom } from "jotai";
import { planogramSelectedShelfAtom } from "src/components/PlanogramExtensions/store/atoms";
import {
  Color,
  Flex,
  Grid,
  HorizontalDivider,
  Scroller,
  SubGrid,
  Text,
} from "src/elements";
import {
  getMergedShelfWidth,
  getShelfXLeft,
  getShelfYBottom,
} from "src/omni-common/utils/pog/shelf";
import { formatDimension } from "src/utils/formatDimension";
import styled from "styled-components";

const CellElement = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${Color.primary};
  padding: 2px 5px;

  &:nth-of-type(odd) {
    border-right: 1px solid ${Color.primary};
  }

  &:nth-last-child(1),
  &:nth-last-child(2) {
    border-bottom: none;
  }
`;

const Cell = ({ children }: { children?: string | number }) => (
  <CellElement>
    <Text variant="caption1" color={Color.primaryActive}>
      {children}
    </Text>
  </CellElement>
);

const ShelfTable = ({ shelf }: { shelf: PlanogramShelf }) => (
  <SubGrid gridColumn="1/3" borderColor={Color.primary}>
    <Cell>Name</Cell>
    <Cell>{shelf.uniqueId}</Cell>

    <Cell>Type</Cell>
    <Cell>Regular</Cell>

    <Cell>X</Cell>
    <Cell>{formatDimension(getShelfXLeft(shelf))}</Cell>

    <Cell>Y</Cell>
    <Cell>{formatDimension(getShelfYBottom(shelf))}</Cell>

    <Cell>Notch No</Cell>
    <Cell>{shelf.notchNo}</Cell>

    <Cell>Width</Cell>
    <Cell>{formatDimension(getMergedShelfWidth(shelf))}</Cell>

    {/* JDA considers thickness as height and so we have to do the same, kinda silly tbh */}
    <Cell>Height</Cell>
    <Cell>{formatDimension(shelf.thickness)}</Cell>

    <Cell>Depth</Cell>
    <Cell>{formatDimension(shelf.depth)}</Cell>
  </SubGrid>
);

interface Props {
  pogAtom: PrimitiveAtom<Pog>;
}

export const ShelvesDetails = ({ pogAtom }: Props) => {
  const [pog] = useAtom(pogAtom);
  const [selectedShelf] = useAtom(planogramSelectedShelfAtom);

  const shelves = pog.planogram.bays.reduce(
    (shelves, bay) => [...shelves, ...bay.shelves],
    [] as PlanogramShelf[],
  );

  return (
    <Scroller gutter="stable both-edges">
      <Flex column gap="10px">
        {selectedShelf && (
          <>
            <Flex column>
              <Text variant="small1" color={Color.primary}>
                Selected Shelf
              </Text>

              <Grid
                padding="10px 0"
                columns="auto 1fr"
                width="100%"
                gap="20px 0"
              >
                <ShelfTable shelf={selectedShelf} />
              </Grid>
            </Flex>

            <HorizontalDivider />
          </>
        )}

        <Grid padding="10px 0" columns="auto 1fr" width="100%" gap="20px 0">
          {shelves
            .filter((shelf) => selectedShelf?.uniqueId !== shelf.uniqueId)
            .map((shelf, i) => (
              <ShelfTable key={i} shelf={shelf} />
            ))}
        </Grid>
      </Flex>
    </Scroller>
  );
};
