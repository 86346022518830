import { useAtom, useSetAtom } from "jotai";
import { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import {
  merchflows_merchflowId_StoresAllAtom,
  Stores_StoreAll_storePogIndexAtom,
} from "./atoms";
import { useApi } from "src/api";
import { OneStoreViewStatus } from "@CommonTypes/merchflow/OneStoreView";
import { sleep } from "src/utils/sleep";

export const useStores_AllStoreData = ({ size }: { size: number }) => {
  const { merchflowId: _merchflowId, storeCode: _storeCode } = useParams<{
    merchflowId: string;
    storeCode: string;
  }>();

  const { getMerchflowStoresAllApi } = useApi();

  const [searchParams] = useSearchParams();

  const [data, setData] = useAtom(merchflows_merchflowId_StoresAllAtom);
  const setIndex = useSetAtom(Stores_StoreAll_storePogIndexAtom);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const [page, setPage] = useState(Number(searchParams.get("page")) || 1);

  const storeCode = String(_storeCode);
  const merchflowId = Number(_merchflowId);

  const loadStores = async () => {
    const storePogTriggerId = searchParams.get("store_pog_trigger_id")
      ? Number(searchParams.get("store_pog_trigger_id"))
      : undefined;

    const response = await getMerchflowStoresAllApi(merchflowId, {
      page,
      size,
      store_pog_trigger_id: storePogTriggerId,
    });

    setData(response);

    // Keep reloading after 10s, if some store is not ready.
    if (response?.data) {
      return response.data.some(
        (store) =>
          (store.status === OneStoreViewStatus.GENERATING_STORE_POG &&
            !store.store_pog) ||
          store.store_pog?.thumbnail.status === "IN_PROGRESS",
      );
    }
    return true;
  };

  const clearPooling = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  };

  const startPoolingStoreData = async () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    const isNeedReload = await loadStores();
    if (isNeedReload) {
      sleep(10000);
      intervalRef.current = setInterval(async () => {
        const isNotDone = await loadStores();
        if (!isNotDone) {
          clearPooling();
        }
      }, 10000);
    }
  };

  return {
    setIndex,
    data,
    setData,
    page,
    setPage,
    loadStores,
    storeCode,
    merchflowId,
    searchParams,
    startPoolingStoreData,
    clearPooling,
  };
};
