import hexToRgba from "hex-to-rgba";
import React, { SyntheticEvent } from "react";
import { Flex } from "src/elements/Box/Box";
import { Color } from "src/elements/Color/Color";
import { HorizontalDivider } from "src/elements/Dividers";
import { Text } from "src/elements/Text/Text";
import styled from "styled-components";

import { ButtonDropdownOption } from "../store/types";
import { Button } from "../Button";
import { Icon } from "src/elements/Icon/Icon";

const DropdownElement = styled(Flex)`
  margin: 5px 0;
  flex-direction: column;
  background-color: ${Color.white};
  border-radius: 3px;
  cursor: default;
`;

const DropdownOption = styled(Flex)<{
  hoverColor: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  isWithButtons: boolean;
}>`
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  min-height: 35px;
  padding: 0 10px;
  cursor: pointer;
  user-select: none;
  padding-right: ${({ isWithButtons }) => isWithButtons && "5px"};

  &:hover {
    background-color: ${({ hoverColor }) => hexToRgba(hoverColor, 0.2)};
  }

  ${({ isDisabled, isLoading }) =>
    (isDisabled || isLoading) &&
    `
  pointer-events: none;
  opacity: 0.3;`}
`;

interface Props {
  dropdown: ButtonDropdownOption[];
  textColor: string;
  borderColor: string;
}

export const Dropdown = ({ dropdown, textColor, borderColor }: Props) => (
  <DropdownElement
    width="100%"
    borderColor={borderColor}
    onClick={(event: SyntheticEvent) => event.stopPropagation()}
  >
    {dropdown
      .filter((option) => option.isHidden !== true)
      .map((option, i) => (
        <React.Fragment key={i}>
          {i !== 0 && (
            <Flex margin="0 5px">
              <HorizontalDivider color={Color.lightGrayHover} />
            </Flex>
          )}

          <DropdownOption
            hoverColor={borderColor}
            onClick={option.onClick}
            isDisabled={option.isDisabled}
            isLoading={option.isLoading}
            isWithButtons={option.buttons !== undefined}
          >
            <Text variant="small1" color={textColor} whiteSpace="nowrap">
              {option.label}
            </Text>

            <Flex gap="5px">
              {option.buttons?.map((optionButton, i) => (
                <Button
                  key={i}
                  size="small"
                  variant="inverted"
                  color={optionButton.color || "greenSmoke"}
                  onClick={optionButton.onClick}
                >
                  {optionButton.label}
                </Button>
              ))}
            </Flex>

            {option.isLoading && <Icon name="spinner" size={14} />}
          </DropdownOption>
        </React.Fragment>
      ))}
  </DropdownElement>
);
