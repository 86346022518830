import { Pog, Planogram, PlanogramItem } from "@CommonTypes/merchflow/pog/pog";
import {
  getShelfXLeft,
  getShelfXRight,
  getShelfYBottom,
  getShelfYTop,
} from "src/omni-common/utils/pog/shelf";

import { DragBounds, Snappable } from "./types";

export const getOuterBounds = (planogram: Planogram) => {
  return {
    xLeft: 0,
    xRight: planogram.width,
    yBottom: Math.min(
      planogram.bays[0].notchOffset,
      planogram.bays[0].baseHeight,
    ),
    yTop: planogram.height,
  } as DragBounds;
};

export const getSnappableFixtures = (planogram: Planogram) => {
  const snappables: Snappable[] = [];

  planogram.bays.forEach((bay) => {
    snappables.push({
      xLeft: bay.xLeft,
      xRight: bay.xRight,
      yBottom: bay.yBottom,
      yTop: bay.yTop,
    });

    bay.shelves.forEach((shelf) => {
      snappables.push({
        xLeft: getShelfXLeft(shelf),
        xRight: getShelfXRight(shelf),
        yBottom: getShelfYBottom(shelf),
        yTop: getShelfYTop(shelf),
      });
    });
  });

  return snappables;
};

export const removeItemFromPlanogramAndUnrangedItems = ({
  pog,
  itemId,
}: {
  pog: Pog;
  itemId: string;
}) => {
  // Remove old item from unranged items.
  const indexAtUnrangedItems = pog.unrangedItems.findIndex(
    (item) => item.uniqueId === itemId,
  );
  if (indexAtUnrangedItems !== -1) {
    pog.unrangedItems.splice(indexAtUnrangedItems, 1);
  }

  // Remove old item from planogram.
  for (const bay of pog.planogram.bays) {
    for (const shelf of bay.shelves) {
      const oldItemIndex = shelf.items.findIndex(
        (item) => item.uniqueId === itemId,
      );
      if (oldItemIndex !== -1) {
        shelf.items.splice(oldItemIndex, 1);
      }
    }
  }
};

export const findPogItem = (pog: Pog, uniqueId: string) => {
  for (const bay of pog.planogram.bays) {
    for (const shelf of bay.shelves) {
      for (const item of shelf.items) {
        if (item.uniqueId === uniqueId) {
          return item;
        }
      }
    }
  }

  return null;
};

export const findPogShelf = ({
  pog,
  shelfId,
}: {
  pog: Pog;
  shelfId: string;
}) => {
  for (const bay of pog.planogram.bays) {
    for (const shelf of bay.shelves) {
      if (shelf.uniqueId === shelfId) {
        return shelf;
      }
    }
  }

  return null;
};

export const isPlanogramItemMatching = (
  item: PlanogramItem,
  search: string,
) => {
  return (
    search === "" ||
    item.name.toLowerCase().includes(search.toLowerCase()) ||
    item.productCode.toLowerCase().includes(search.toLowerCase())
  );
};
