import { MerchFlowCoreRange } from "@CommonApi/merchandise";
import { useEffect, useState } from "react";
import { StepsNavigator } from "src/components";
import { Flex, Modal } from "src/elements";
import { uuid } from "src/utils";
import { Step1Details } from "./components/Step1Details";
import { MerchflowReviewStep } from "./store/types";
import { ResponseGetMerchflowPsaBasePogs } from "@CommonApi/merchflow/psa/base-pogs";
import { useMerchflowsApi } from "src/api/merchflows";
import { ResponseGetMerchflowsCategories } from "@CommonApi/merchflows/categories";
import { Step2Stores } from "./components/Step2Stores";
import { ResponsePostMerchflows } from "@CommonApi/merchflows";
import { ResponseGetMerchflowsFilters } from "@CommonApi/merchflows/filters";

export const ModalMerchflowPSAReview = () => {
  const { getMerchflowsCategoriesApi, getMerchflowPsaBasePogsApi } =
    useMerchflowsApi();
  const [step, setStep] = useState<MerchflowReviewStep>("STEP_1_DETAILS");

  // DETAILS DATA.
  const [startFlowResponse, setStartFlowResponse] =
    useState<ResponsePostMerchflows | null>(null);
  const [name, setName] = useState(uuid());
  const [notes, setNotes] = useState("");
  const [goLiveDate, setGoLiveDate] = useState<Date | null>(new Date());
  const [deactivationDate, setDeactivationDate] = useState<Date | null>(null);
  const [coreRange, setCoreRange] = useState<MerchFlowCoreRange>(
    MerchFlowCoreRange.PARTIAL,
  );
  const [category, setCategory] = useState<string | null>(null);
  const [subcategory, setSubcategory] = useState<string | null>(null);
  const [availableCategories, setAvailableCategories] =
    useState<ResponseGetMerchflowsCategories | null>(null);
  const [psaBasePogs, setPsaBasePogs] =
    useState<ResponseGetMerchflowPsaBasePogs | null>(null);
  const [psaBasePog, setPsaBasePog] = useState<string | null>(null);

  // STORES DATA.
  const [availableFilters, setAvailableFilters] =
    useState<ResponseGetMerchflowsFilters | null>(null);

  useEffect(() => {
    loadCategories();
  }, []);

  const loadCategories = async () => {
    setAvailableCategories(await getMerchflowsCategoriesApi());
  };

  const loadPsaBasePogs = async (category: string, subcategory: string) => {
    setPsaBasePogs(await getMerchflowPsaBasePogsApi(category, subcategory));
  };

  // Load psa base pogs filenames based on selected categories.
  useEffect(() => {
    if (category && subcategory) {
      loadPsaBasePogs(category, subcategory);
    }
  }, [category, subcategory]);

  return (
    <Modal name="ModalMerchflowPSAReview" width="1200px" title="New PSA Flow">
      <Flex minHeight="70vh" padding="20px" column gap="40px">
        <StepsNavigator
          steps={[
            {
              title: "Details",
              isActive: step === "STEP_1_DETAILS",
              onClick: () => setStep("STEP_1_DETAILS"),
            },
            {
              title: "Stores Selection",
              isActive: step === "STEP_2_STORES",
            },
          ]}
        />

        <Flex grow>
          {step === "STEP_1_DETAILS" && (
            <Step1Details
              setStep={setStep}
              setStartFlowResponse={setStartFlowResponse}
              setAvailableFilters={setAvailableFilters}
              name={name}
              setName={setName}
              notes={notes}
              setNotes={setNotes}
              goLiveDate={goLiveDate}
              setGoLiveDate={setGoLiveDate}
              deactivationDate={deactivationDate}
              setDeactivationDate={setDeactivationDate}
              coreRange={coreRange}
              setCoreRange={setCoreRange}
              category={category}
              setCategory={setCategory}
              subcategory={subcategory}
              setSubcategory={setSubcategory}
              availableCategories={availableCategories}
              psaBasePogs={psaBasePogs}
              psaBasePog={psaBasePog}
              setPsaBasePog={setPsaBasePog}
            />
          )}

          {step === "STEP_2_STORES" && (
            <Step2Stores
              setStep={setStep}
              startFlowResponse={startFlowResponse}
              availableFilters={availableFilters}
            />
          )}
        </Flex>
      </Flex>
    </Modal>
  );
};
