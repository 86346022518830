import { PspRules } from "@CommonTypes/backend/PspRules";

export const RoutePostMerchflow_merchflowId_FlowUpdate =
  "/merchflow/:merchflowId/flow-update";

export interface PayloadPostMerchflow_merchflowId_FlowUpdate {
  products_to_insert: ProductToInsert[];
  products_to_remove: ProductToRemove[];
  products_to_swap: ProductToSwap[];
  psp_rules: PspRules;
}

export interface ResponsePostMerchflow_merchflowId_FlowUpdate {
  store_pog_trigger_id: number;
  success_result_count: number;
  partial_result_count: number;
  fail_result_count: number;
  in_progress_count: number;
  merchflow_id: number;
  created_at: string;
  finished_at?: string;
  payload?: object;
  user_email?: string;
  error_details?: object[];
}

export interface ProductToRemove {
  product_code: string;
  pnl_decision: PnlDecision;
}

export interface ProductToInsert {
  product_code: string;
  adjacent_product_code: string;
  place_to: "LEFT" | "RIGHT";
  facings: "AUTO" | number;
  reducible_product_code?: string;
  fit_strategy:
    | "KEEP_ALL_FACINGS"
    | "CAN_REDUCE_FACINGS"
    | "CAN_DELETE_RANGED_PRODUCTS";
  pnl_decision: PnlDecision;
}

export interface ProductToSwap {
  product_code_to_add: string;
  product_code_to_remove: string;
  pnl_decision: PnlDecision;
}

export type PnlDecision = "DEFAULT" | "MOVEMENT" | "SALES" | "PROFIT";
