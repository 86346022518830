import {
  ResponseGetMerchflow_merchflowId_S2Template_templateId_,
  RouteGetMerchflow_merchflowId_S2Template_templateId_,
} from "@CommonApi/merchflow/_merchflowId_/s2/template/_templateId_";
import {
  PayloadPostMerchflowCdt2Mudmap,
  ResponsePostMerchflowCdt2Mudmap,
  RoutePostMerchflowCdt2Mudmap,
} from "@CommonApi/merchflow/cdt2-mudmap";
import { useInlineLoaders } from "src/components/InlineLoader";
import { useToasts } from "src/components/Toasts";
import { API, getRequest, postRequest } from "src/utils/axios";

export const getMerchflows_merchflowId_S2Templates_templateId_Req = (
  merchflowId: number,
  templateId: string,
) => {
  return getRequest<ResponseGetMerchflow_merchflowId_S2Template_templateId_>(
    `${API}${RouteGetMerchflow_merchflowId_S2Template_templateId_}`,
    {
      params: {
        merchflowId,
        templateId,
      },
      queryParams: {
        page: 1,
        size: 1000,
      },
    },
  );
};

export const postMerchflowCdt2MudmapReq = (
  payload: PayloadPostMerchflowCdt2Mudmap,
) => {
  return postRequest<ResponsePostMerchflowCdt2Mudmap>(
    `${API}${RoutePostMerchflowCdt2Mudmap}`,
    {
      payload,
    },
  );
};

export const useMerchflowS2Api = () => {
  const { toast } = useToasts();
  const { addInlineLoader, removeInlineLoader } = useInlineLoaders();

  const getMerchflowS2SolutionsByTemplateIdApi = async ({
    merchflowId,
    templateId,
    setData,
  }: {
    merchflowId: number;
    templateId: string;
    setData: (
      data: ResponseGetMerchflow_merchflowId_S2Template_templateId_ | null,
    ) => void;
  }) => {
    addInlineLoader("GET_merchflows/:merchflowId/s2/templates/:templateId");
    let success = false;

    try {
      const { data } =
        await getMerchflows_merchflowId_S2Templates_templateId_Req(
          merchflowId,
          templateId,
        );
      setData(data);
      success = true;
    } catch (error) {
      toast({
        title: "Failed to load S2 solution",
        error,
      });
    }

    removeInlineLoader("GET_merchflows/:merchflowId/s2/templates/:templateId");
    return success;
  };

  const postMerchflowCdt2MudmapApi = async (
    payload: PayloadPostMerchflowCdt2Mudmap,
  ) => {
    addInlineLoader("POST_merchflow/cdt2-mudmap");

    try {
      const {
        data: { base_pog_id },
      } = await postMerchflowCdt2MudmapReq(payload);
      removeInlineLoader("POST_merchflow/cdt2-mudmap");
      return { basePogId: base_pog_id };
    } catch (error) {
      toast({
        title: "Failed to generate base planogram",
        error,
      });
    }

    removeInlineLoader("POST_merchflow/cdt2-mudmap");
    return null;
  };

  return {
    getMerchflowS2SolutionsByTemplateIdApi,
    postMerchflowCdt2MudmapApi,
  };
};
