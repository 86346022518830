import { Highlight as HighlightType } from "@CommonApi/merchflows/_merchflowId_/highlights/base-pogs";
import { PrimitiveAtom, useAtomValue, useSetAtom } from "jotai";
import { useRef } from "react";
import { useModals } from "src/components/Modals";
import { highlightsModeAtom } from "src/components/Planogram/store/atoms";
import { Button, Flex, Text, Toggle } from "src/elements";
import { useHighlightModal } from "src/modals/Highlight/store/hooks";
import { EditableHighlight } from "src/modals/Highlight/store/types";
import { useHoverClickable } from "src/utils/hoverClickable";
import styled from "styled-components";

const HighlightColor = styled(Flex)<{ color: string }>`
  min-width: 10px;
  min-height: 10px;
  background-color: ${({ color }) => color};
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  margin-top: 2px;
`;

interface Props {
  highlight: HighlightType;
  level: EditableHighlight["level"];
  highlightsAtom: PrimitiveAtom<HighlightType[] | null>;
}

export const Highlight = ({ highlight, level, highlightsAtom }: Props) => {
  const { openHighlightModal } = useHighlightModal();
  const { openConfirmModal } = useModals();

  const highlightsMode = useAtomValue(highlightsModeAtom);
  const setHighlights = useSetAtom(highlightsAtom);

  const refHighlight = useRef<HTMLDivElement>(null);
  const { isHovered } = useHoverClickable(refHighlight);

  const deleteHighlight = () => {
    openConfirmModal({
      title: "Delete Highlight?",
      description: "Are you sure you want to delete this highlight?",
      buttons: [
        { label: "Back", color: "primary", variant: "inverted" },
        { label: "Delete", color: "red", onClick: confirmDeleteHighlight },
      ],
    });
  };

  const confirmDeleteHighlight = () => {
    setHighlights((highlights) => {
      return highlights!.filter((_highlight) => _highlight.id !== highlight.id);
    });
  };

  const toggleHighlight = (isChecked: boolean) => {
    setHighlights((highlights) => {
      return highlights!.map((_highlight) => ({
        ..._highlight,
        enabled:
          _highlight.id === highlight.id ? isChecked : _highlight.enabled,
      }));
    });
  };

  const editHighlight = () => {
    openHighlightModal({
      highlight,
      level,
    });
  };

  return (
    <Flex ref={refHighlight} padding="0 5px" align="center" gap="5px">
      <HighlightColor color={highlight.color} />

      <Flex minWidth="0" grow>
        <Text variant="small2" whiteSpace="nowrap" textOverflow="ellipsis">
          {highlight.name || highlight.formula}
        </Text>
      </Flex>

      <Flex minHeight="36px" padding="0 5px" align="center" gap="1px">
        {isHovered && (
          <>
            <Button
              variant="borderless"
              size="small"
              onClick={editHighlight}
              iconRight={{ name: "edit" }}
            />

            <Button
              variant="borderless"
              size="small"
              color="red"
              onClick={deleteHighlight}
              iconRight={{ name: "delete" }}
            />
          </>
        )}
      </Flex>

      <Toggle
        isChecked={highlight.enabled}
        setIsChecked={(enabled) => toggleHighlight(enabled)}
        isDisabled={highlightsMode === false}
      />
    </Flex>
  );
};
