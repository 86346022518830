import { Category } from "@CommonApi/categories/list";
import {
  ResponsePostMerchflowUploadPsa,
  RoutePostMerchflowUploadPsa,
} from "@CommonApi/merchflow/upload-psa";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { useMerchflowsApi } from "src/api/merchflows";
import { FileUploader } from "src/components";
import { useInlineLoaders } from "src/components/InlineLoader";
import { useModals } from "src/components/Modals";
import { Button, Flex, Modal } from "src/elements";
import { importCsvModalDataAtom } from "./store/atoms";

interface Props {
  category: Category;
  categoryId: number;
}

export const ModalImportCsv = () => {
  const [data, setData] = useAtom(importCsvModalDataAtom);

  useEffect(() => {
    return () => {
      setData(null);
    };
  }, []);

  if (!data) {
    return null;
  }

  return <ModalImportCsvInner category={data} categoryId={data.id} />;
};

const ModalImportCsvInner = ({ category, categoryId }: Props) => {
  const { closeModal: _closeModal } = useModals();
  const { isInlineLoading } = useInlineLoaders();
  const { postMerchflowUploadPsaApi } = useMerchflowsApi();

  const [isSuccess, setIsSuccess] = useState(false);
  const [ffFile, setFfFile] = useState<File | null>(null);
  const [pmFile, setPmFile] = useState<File | null>(null);
  const [response, setResponse] =
    useState<ResponsePostMerchflowUploadPsa | null>(null);

  const isUploadPsaLoading = isInlineLoading(RoutePostMerchflowUploadPsa);

  useEffect(() => {
    if (response) {
      setIsSuccess(true);

      setTimeout(() => {
        closeModal();
      }, 2000);
    }
  }, [response]);

  const closeModal = () => {
    _closeModal("ModalImportCsv");
  };

  const uploadCsv = async () => {
    if (!ffFile || !pmFile) return;

    const response = await postMerchflowUploadPsaApi({
      psa_file: ffFile, // or pmFile
      category_id: String(categoryId),
      store_id: "1", // TODO change when we have real API
    });
    setResponse(response);

    if (response) {
      setIsSuccess(true);
    }
  };

  return (
    <Modal
      name="ModalImportCsv"
      title={`Import CSV for Category: ${category.code}`}
      width="500px"
    >
      <Flex padding="10px" gap="16px">
        <FileUploader
          fileLabel="FF"
          fileType="csv"
          onChange={setFfFile}
          isLoading={isUploadPsaLoading}
        />
        <FileUploader
          fileLabel="PM"
          fileType="csv"
          onChange={setPmFile}
          isLoading={isUploadPsaLoading}
        />
      </Flex>
      <Flex margin="0 10px 16px">
        <Button
          width="100%"
          isDisabled={!ffFile || !pmFile || isSuccess}
          onClick={uploadCsv}
        >
          Upload
        </Button>
      </Flex>
    </Modal>
  );
};
