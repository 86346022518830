import { PspRules } from "@CommonTypes/backend/PspRules";
import {
  Flex,
  Color,
  Text,
  Toggle,
  HorizontalDivider,
  Scroller,
} from "src/elements";
import { Transition } from "src/utils";

interface Props {
  pspRules: PspRules;
  setPspRules: (pspRules: PspRules) => void;
  title: string;
  rulesParent: string;
  area: string;
  rules: {
    title: string;
    rule: string;
    isDisabled?: boolean;
    isHidden?: boolean;
    customNo?: { label: string; value: string };
    customYes?: { label: string; value: string };
  }[];
}

export const RulesSection = ({
  title,
  pspRules,
  setPspRules,
  rulesParent,
  area,
  rules,
}: Props) => {
  const toggleRule = (rule: string, checked: any) => {
    const newPspRules = { ...pspRules };

    //@ts-ignore
    newPspRules[rulesParent][rule] = checked;

    setPspRules(newPspRules);
  };

  return (
    <Flex
      width="100%"
      background={Color.lightGray}
      column
      borderRadius="3px"
      overflow="hidden"
      gridArea={area}
    >
      <Flex
        background={Color.lightGrayHover}
        padding="5px 10px"
        justify="center"
      >
        <Text variant="body1">{title}</Text>
      </Flex>

      <HorizontalDivider color={Color.textDisabled} />

      <Scroller>
        <Flex column gap="40px" padding="10px">
          {rules
            .filter((rule) => !rule.isHidden)
            .map((rule) => {
              const isChecked = rule.customYes?.value
                ? //@ts-ignore
                  pspRules[rulesParent][rule.rule] === rule.customYes.value
                : //@ts-ignore
                  pspRules[rulesParent][rule.rule];

              return (
                <Flex key={rule.rule} column gap="10px" justify="center">
                  <Text variant="small1" textAlign="center">
                    {rule.title}
                  </Text>

                  <Flex gap="10px">
                    <Flex
                      opacity={isChecked ? 0 : 1}
                      transition={`opacity ${Transition.fast}`}
                      cursor="default"
                    >
                      <Text
                        variant="body2"
                        color={
                          (rule.isDisabled && Color.textDisabled) ||
                          Color.textSecondary
                        }
                      >
                        {rule.customNo?.label || "No"}
                      </Text>
                    </Flex>

                    <Toggle
                      isDisabled={rule.isDisabled}
                      isChecked={isChecked}
                      setIsChecked={(checked) => {
                        toggleRule(
                          rule.rule,
                          rule.customYes && rule.customNo
                            ? checked
                              ? rule.customYes.value
                              : rule.customNo.value
                            : checked,
                        );
                      }}
                    />

                    <Flex
                      opacity={isChecked ? 1 : 0}
                      transition={`opacity ${Transition.fast}`}
                      cursor="default"
                    >
                      <Text
                        variant="body2"
                        color={
                          (rule.isDisabled && Color.textDisabled) ||
                          Color.textSecondary
                        }
                      >
                        {rule.customYes?.label || "Yes"}
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              );
            })}
        </Flex>
      </Scroller>
    </Flex>
  );
};
