import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useMemo } from "react";
import { useModals } from "src/components/Modals";
import { Color, Flex, Icon, Pagination, Select, Text } from "src/elements";
import { usePlaceVariantFilters, useResetMerchFlowPage } from "src/hooks";
import {
  isViewDetailAtom,
  selectedPreStep0DataAtom,
  selectedTemplateIdAtom,
  stepAtom,
} from "src/pages/merchandise/store/atoms";
import { MerchandiseStep } from "src/pages/merchandise/store/types";
import styled from "styled-components";

import { Quadrant } from "./Quadrant";
import { Single } from "./Single";
import { usePaginationPage } from "src/elements/Pagination";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
`;

export const SelectBase = () => {
  const { openModal } = useModals();
  const [selectedTemplatedId, setSelectedTemplateId] = useAtom(
    selectedTemplateIdAtom,
  );
  const setStep = useSetAtom(stepAtom);
  const { resetStep2Solution } = useResetMerchFlowPage();
  const preStep0 = useAtomValue(selectedPreStep0DataAtom);
  const { selectedStep2, s2TemplateOptions } = usePlaceVariantFilters();

  const onChangeDropDownTemplate = (v: string) => {
    if (v === selectedTemplatedId) return;
    setSelectedTemplateId(v);
    resetStep2Solution();
    setStep(MerchandiseStep.SELECT_BASE);
  };

  const isViewDetail = useAtomValue(isViewDetailAtom);

  const pages = !isViewDetail
    ? Math.ceil(selectedStep2.length / 4)
    : selectedStep2.length;

  const [page, setPage] = usePaginationPage();

  const openFilter = () => {
    openModal("AppliedFlowFilterOptions");
  };

  const storeId = useMemo(
    () => preStep0.find((d) => d.templateId === selectedTemplatedId)?.storeId,
    [selectedTemplatedId],
  );

  return (
    <Container>
      <Flex width="100%" justify="between">
        <Flex gap="8px" onClick={openFilter}>
          <Text>Store ID: {storeId}</Text>

          <Icon name="filter" />

          <Text color={Color.primary}>
            Filter: Showing {selectedStep2.length}
          </Text>
        </Flex>

        <Flex width="300px">
          <Select
            options={s2TemplateOptions}
            placeholder="Template"
            setValue={onChangeDropDownTemplate}
            value={selectedTemplatedId}
          />
        </Flex>
      </Flex>

      {!isViewDetail ? (
        <Quadrant page={page} data={selectedStep2} />
      ) : (
        <Single data={selectedStep2} />
      )}

      <Pagination page={page} setPage={setPage} totalPages={pages} />
    </Container>
  );
};
