import { MerchFlowCoreRange } from "@CommonApi/merchandise";
import { RoutePostMerchflows } from "@CommonApi/merchflows";
import {
  ResponseGetMerchflowsCategories,
  RouteGetMerchflowsCategories,
} from "@CommonApi/merchflows/categories";
import { useEffect, useMemo } from "react";
import { useMerchflowsApi } from "src/api/merchflows";
import { useInlineLoaders } from "src/components/InlineLoader";
import { useModals } from "src/components/Modals";
import {
  Button,
  DateInput,
  Flex,
  Input,
  Radios,
  Select,
  Text,
  VerticalDivider,
} from "src/elements";
import { MerchflowReviewStep } from "../store/types";
import { ResponseGetMerchflowsFilters } from "@CommonApi/merchflows/filters";
import { useSetAtom } from "jotai";
import { detailsAtom } from "src/modals/NewFlowReview/store/atoms";

interface Props {
  setStep: (step: MerchflowReviewStep) => void;
  merchflowId: number | null;
  setMerchflowId: (merchflowId: number | null) => void;
  name: string;
  setName: (name: string) => void;
  notes: string;
  setNotes: (notes: string) => void;
  goLiveDate: Date | null;
  setGoLiveDate: (goLiveDate: Date | null) => void;
  deactivationDate: Date | null;
  setDeactivationDate: (goDeactivationDate: Date | null) => void;
  coreRange: MerchFlowCoreRange;
  setCoreRange: (coreRange: MerchFlowCoreRange) => void;
  category: string | null;
  setCategory: (category: string | null) => void;
  subcategory: string | null;
  setSubcategory: (subCategory: string | null) => void;
  availableCategories: ResponseGetMerchflowsCategories | null;
  setAvailableFilters: (
    availableFilters: ResponseGetMerchflowsFilters | null,
  ) => void;
}

export const Step1Details = ({
  setStep,
  merchflowId,
  setMerchflowId,
  name,
  setName,
  notes,
  setNotes,
  goLiveDate,
  setGoLiveDate,
  deactivationDate,
  setDeactivationDate,
  coreRange,
  setCoreRange,
  category,
  setCategory,
  subcategory,
  setSubcategory,
  availableCategories,
  setAvailableFilters,
}: Props) => {
  const setDetails = useSetAtom(detailsAtom); // TODO - REMOVE WITH OMF-672

  const { postMerchflowsApi, getMerchflowsFiltersApi } = useMerchflowsApi();
  const { isInlineLoading } = useInlineLoaders();
  const { closeModal: _closeModal } = useModals();

  const isCategoriesLoading = isInlineLoading(RouteGetMerchflowsCategories);
  const isCreateReviewLoading = isInlineLoading(RoutePostMerchflows);

  const minDeactivationDate = useMemo(() => {
    if (!goLiveDate) {
      return new Date("2000-01-01");
    }

    const deactivationDate = new Date(goLiveDate);
    deactivationDate.setDate(deactivationDate.getDate() + 1);

    return deactivationDate;
  }, [goLiveDate]);

  const categories = useMemo(() => {
    if (availableCategories) {
      return Object.keys(availableCategories);
    }

    return [];
  }, [availableCategories]);

  const subCategories = useMemo(() => {
    if (availableCategories) {
      // All possible sub-categories.
      return Object.values(availableCategories).reduce(
        (allSubCategories, categorySubCategories) => [
          ...allSubCategories,
          ...categorySubCategories,
        ],
        [],
      );
    }

    return [];
  }, [availableCategories]);

  // Effect to update category based on selected sub-category.
  useEffect(() => {
    if (!availableCategories || !subcategory) return;

    for (const _category of Object.keys(availableCategories)) {
      if (availableCategories[_category].includes(subcategory)) {
        if (_category !== category) {
          setCategory(_category);
        }
      }
    }
  }, [subcategory]);

  // Effect to update sub-category based on selected category.
  useEffect(() => {
    if (!availableCategories || !category) return;
    // Check is correct sub-category is already assigned.
    for (const _subcategory of availableCategories[category]) {
      if (_subcategory === subcategory) {
        return;
      }
    }

    // Assign first sub-category from selected category.
    if (availableCategories[category].length > 0) {
      setSubcategory(availableCategories[category][0]);
    }
  }, [category]);

  const closeModal = () => {
    _closeModal("ModalMerchflowReview");
  };

  const nextStep = () => {
    setStep("STEP_2_FILTERS");
  };

  const createReview = async () => {
    if (!category || !subcategory || !name || !goLiveDate) return;

    const response = await postMerchflowsApi({
      retailer_category_code: category,
      category_code: subcategory,
      name,
      notes,
      core_range: {
        option: coreRange,
      },
      go_live_date: goLiveDate.toISOString(),
      deactivation_date: deactivationDate?.toISOString() || null,
    });

    if (response) {
      setDetails(response as any); // TODO - REMOVE WITH OMF-672
      setMerchflowId(response.id);
      nextStep();
      setAvailableFilters(
        await getMerchflowsFiltersApi({
          retailer_category_code: response.retailer_category_code,
          category_code: response.category_code,
        }),
      );
    }
  };

  return (
    <Flex column flexGrow={1}>
      <Flex padding="20px" gap="20px" justify="center">
        <Flex column gap="20px">
          <Text variant="body1">1. Please input your Flow details.</Text>

          <Input title="Name" value={name} setValue={setName} isRequired />

          <Flex gap="20px">
            <DateInput
              width="180px"
              title="Go-Live Date"
              value={goLiveDate}
              setValue={setGoLiveDate}
              min={new Date("2000-01-01")}
              isRequired
            />

            <DateInput
              width="180px"
              title="Deactivation Date"
              value={deactivationDate}
              setValue={setDeactivationDate}
              min={minDeactivationDate}
              error={
                goLiveDate !== null &&
                deactivationDate !== null &&
                deactivationDate < goLiveDate &&
                "Deactivation date needs to be after go-live date"
              }
            />
          </Flex>

          <Input title="Notes" value={notes} setValue={setNotes} />

          <Radios
            label="2. Please select your Core Range options."
            direction="column"
            options={[
              {
                label: "All products in Core Range",
                value: MerchFlowCoreRange.ALL,
              },
              {
                label: "No products in Core Range",
                value: MerchFlowCoreRange.NONE,
              },
              {
                label: "All allocated products in Core Range",
                value: MerchFlowCoreRange.PARTIAL,
              },
            ]}
            value={coreRange}
            setValue={setCoreRange}
          />
        </Flex>

        <VerticalDivider />

        <Flex column gap="20px">
          <Text variant="body1">3. Please select your dataset.</Text>

          <Flex gap="20px">
            <Select
              width="180px"
              title="Category"
              value={category}
              placeholder="Category"
              options={categories.map((category) => ({
                label: category,
                value: category,
              }))}
              setValue={setCategory}
              isLoading={isCategoriesLoading}
              isRequired
            />

            <Select
              width="180px"
              title="Sub-Category"
              value={subcategory}
              setValue={setSubcategory}
              placeholder="Sub-Category"
              options={subCategories.map((subCategory) => ({
                label: subCategory,
                value: subCategory,
              }))}
              isLoading={isCategoriesLoading}
              isRequired
            />
          </Flex>
        </Flex>
      </Flex>

      <Flex flexGrow={1} />

      <Flex gap="10px" justify="right" gridColumn="1 / -1">
        <Button variant="inverted" color="red" onClick={closeModal}>
          Cancel
        </Button>

        {merchflowId === null ? (
          <Button
            isDisabled={
              !name ||
              !goLiveDate ||
              !category ||
              !subcategory ||
              (goLiveDate !== null &&
                deactivationDate !== null &&
                deactivationDate < goLiveDate)
            }
            isLoading={isCreateReviewLoading}
            onClick={createReview}
          >
            Start New Flow
          </Button>
        ) : (
          <Button onClick={nextStep}>Next</Button>
        )}
      </Flex>
    </Flex>
  );
};
