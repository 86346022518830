import { useEffect } from "react";
import { Page, PageStatus } from "src/components";

import { Button, Color, Flex, Pagination, Scroller, Text } from "src/elements";
import { useModals } from "src/components/Modals";
import { useStoresPage } from "./store/hooks";
import { StoreRow } from "./components/StoreRow";

export const PageStores = () => {
  const { openModal } = useModals();

  const { stores, loadStores, isLoading, page, setPage } = useStoresPage();

  useEffect(() => {
    loadStores();
  }, [page]);

  return (
    <Page>
      <Flex padding="10px 0 0 0" justify="between" align="center">
        <Text variant="h3">Stores</Text>

        <Button onClick={() => openModal("AddStoreModal")}>Add store</Button>
      </Flex>

      {stores === null && (
        <PageStatus label="Users not found" icon="alert" color={Color.red} />
      )}

      <Flex column grow>
        {isLoading && <PageStatus label="Loading stores..." icon="spinner" />}

        {!isLoading && stores && (
          <Scroller>
            {stores.data.map((store) => (
              <StoreRow key={store.id} store={store} />
            ))}
          </Scroller>
        )}

        <Pagination
          page={page}
          totalPages={stores?.totalPages || 1}
          setPage={setPage}
          isLoading={isLoading}
        />
      </Flex>
    </Page>
  );
};
