export const enum zIndexLevel {
  L1 = 100,
  L2 = 200,
  L3 = 300,
  L4 = 400,
  L5 = 500, // used for select element
  L6 = 600,
  L7 = 700,
  L8 = 800,
  L9 = 900,
  L10 = 1000, // used for background actions
  MODALS = 1001,
  L11 = 1100, // used for settings popover
  L12 = 1200, // confirm modals
  TOASTS = 1250,
  L13 = 1300, // loader
  L14 = 1400,
  L15 = 1500,
  L16 = 1600,
  L17 = 1700,
  L18 = 1800,
  L19 = 1900,
  L20 = 2000, // used for DragWrapper in Modal
  MENUS = 2100,
  DROPDOWNS = 2200,
  ABSOLUTE_LAYER = 2201,
  FULLSCREEN_LOADER = 2300,
}
