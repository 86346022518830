import {
  ParamsGetMerchflowPsaBasePogs,
  ResponseGetMerchflowPsaBasePogs,
  RouteGetMerchflowPsaBasePogs,
} from "@CommonApi/merchflow/psa/base-pogs";
import {
  ResponseGetMerchflowStores,
  RouteGetMerchflowStores,
} from "@CommonApi/merchflow/stores";
import {
  PayloadFormPostMerchflowUploadPsa,
  ResponsePostMerchflowUploadPsa,
  RoutePostMerchflowUploadPsa,
} from "@CommonApi/merchflow/upload-psa";
import {
  PayloadPostMerchflows,
  ResponsePostMerchflows,
  RoutePostMerchflows,
} from "@CommonApi/merchflows";
import {
  PayloadPutMerchflows_filterConfigId_MergeRules,
  ResponsePutMerchflows_filterConfigId_MergeRules,
  RoutePutMerchflows_filterConfigId_MergeRules,
} from "@CommonApi/merchflows/_filterConfigId_/merge-rules";
import {
  PayloadPutMerchflows_merchflowId_Filters,
  ResponsePutMerchflows_merchflowId_Filters,
  RoutePutMerchflows_merchflowId_Filters,
} from "@CommonApi/merchflows/_reviewId_/filters";
import {
  ResponseGetMerchflowsCategories,
  RouteGetMerchflowsCategories,
} from "@CommonApi/merchflows/categories";
import {
  PayloadPutMerchflowsFilterConfig_filterConfigId_,
  ResponsePutMerchflowsFilterConfig_filterConfigId_,
  RoutePutMerchflowsFilterConfig_filterConfigId_,
} from "@CommonApi/merchflows/filter-config/_filterConfigId_";
import {
  ParamsGetMerchflowsFilters,
  ResponseGetMerchflowsFilters,
  RouteGetMerchflowsFilters,
} from "@CommonApi/merchflows/filters";
import {
  PayloadPostMerchflowsMergeRulesInput,
  ResponsePostMerchflowsMergeRulesInput,
  RoutePostMerchflowsMergeRulesInput,
} from "@CommonApi/merchflows/merge-rules-input";
import {
  ParamsGetMerchflowsVariants,
  ResponseGetMerchflowsVariants,
  RouteGetMerchflowsVariants,
} from "@CommonApi/merchflows/variants";
import { useInlineLoaders } from "src/components/InlineLoader";
import { useToasts } from "src/components/Toasts";
import {
  API,
  getRequest,
  postFormDataRequest,
  postRequest,
  putRequest,
} from "src/utils/axios";
import {
  PayloadPostStoreSummary,
  RoutePostImportStoreSummary,
} from "@CommonApi/store-summary";

const getMerchflowsCategoriesReq = () =>
  getRequest<ResponseGetMerchflowsCategories>(
    `${API}${RouteGetMerchflowsCategories}`,
  );

const getMerchflowsFiltersReq = (queryParams: ParamsGetMerchflowsFilters) =>
  getRequest<ResponseGetMerchflowsFilters>(
    `${API}${RouteGetMerchflowsFilters}`,
    { queryParams },
  );

const getMerchflowsVariantsReq = (queryParams: ParamsGetMerchflowsVariants) =>
  getRequest<ResponseGetMerchflowsVariants>(
    `${API}${RouteGetMerchflowsVariants}`,
    { queryParams },
  );

const getFlowReviewPsaBasePogsReq = (
  queryParams: ParamsGetMerchflowPsaBasePogs,
) => {
  return getRequest<ResponseGetMerchflowPsaBasePogs>(
    `${API}${RouteGetMerchflowPsaBasePogs}`,
    { queryParams },
  );
};

const postMerchflowsReq = (payload: PayloadPostMerchflows) =>
  postRequest<ResponsePostMerchflows>(`${API}${RoutePostMerchflows}`, {
    payload,
  });

const postMerchflowsMergeRulesInputReq = (
  payload: PayloadPostMerchflowsMergeRulesInput,
) =>
  postRequest<ResponsePostMerchflowsMergeRulesInput>(
    `${API}${RoutePostMerchflowsMergeRulesInput}`,
    {
      payload,
    },
  );

const putMerchflows_merchflowId_FiltersReq = (
  payload: PayloadPutMerchflows_merchflowId_Filters,
) =>
  putRequest<ResponsePutMerchflows_merchflowId_Filters>(
    `${API}${RoutePutMerchflows_merchflowId_Filters}`,
    {
      payload,
      params: {
        merchflowId: payload.flowId,
      },
    },
  );

const putMerchflows_merchflowId_MergeRulesReq = (
  filterConfigId: number,
  payload: PayloadPutMerchflows_filterConfigId_MergeRules,
) =>
  putRequest<ResponsePutMerchflows_filterConfigId_MergeRules>(
    `${API}${RoutePutMerchflows_filterConfigId_MergeRules}`,
    {
      payload,
      params: {
        filterConfigId,
      },
    },
  );

const postMerchflowUploadPsaReq = (
  payload: PayloadFormPostMerchflowUploadPsa,
) => {
  return postFormDataRequest<ResponsePostMerchflowUploadPsa>(
    `${API}${RoutePostMerchflowUploadPsa}`,
    { payload },
  );
};

const postMerchflowImportStoreSummaryReq = (
  payload: PayloadPostStoreSummary,
) => {
  return postFormDataRequest<string>(`${API}${RoutePostImportStoreSummary}`, {
    payload,
  });
};

export const putMerchflowsFilterConfig_filterConfigIdReq = (
  filterConfigId: number,
  payload: PayloadPutMerchflowsFilterConfig_filterConfigId_,
) =>
  putRequest<ResponsePutMerchflowsFilterConfig_filterConfigId_>(
    `${API}${RoutePutMerchflowsFilterConfig_filterConfigId_}`,
    {
      payload,
      params: {
        filterConfigId,
      },
    },
  );

export const useMerchflowsApi = () => {
  const { toast } = useToasts();
  const { addInlineLoader, removeInlineLoader } = useInlineLoaders();

  const getMerchflowsCategoriesApi = async () => {
    addInlineLoader(RouteGetMerchflowsCategories);
    let response: ResponseGetMerchflowsCategories | null = null;

    try {
      response = (await getMerchflowsCategoriesReq()).data;
    } catch (error) {
      toast({
        title: "Failed to load categories",
        error,
      });
    }

    removeInlineLoader(RouteGetMerchflowsCategories);
    return response;
  };

  const getMerchflowsFiltersApi = async (
    queryParams: ParamsGetMerchflowsFilters,
  ) => {
    addInlineLoader(RouteGetMerchflowsFilters);
    let response: ResponseGetMerchflowsFilters | null = null;

    try {
      response = (await getMerchflowsFiltersReq(queryParams)).data;
    } catch (error) {
      toast({
        title: "Failed to load filters",
        error,
      });
    }

    removeInlineLoader(RouteGetMerchflowsFilters);
    return response;
  };

  const getMerchflowsVariantsApi = async (
    queryParams: ParamsGetMerchflowsVariants,
  ) => {
    addInlineLoader(RouteGetMerchflowsVariants);
    let response: ResponseGetMerchflowsVariants | null = null;

    try {
      response = (await getMerchflowsVariantsReq(queryParams)).data;
    } catch (error) {
      toast({
        title: "Failed to load merge rules",
        error,
      });
    }

    removeInlineLoader(RouteGetMerchflowsVariants);
    return response;
  };

  const getMerchflowPsaBasePogsApi = async (
    category: string,
    subcategory: string,
  ) => {
    let response: ResponseGetMerchflowPsaBasePogs | null = null;
    addInlineLoader(RouteGetMerchflowPsaBasePogs);

    try {
      response = (
        await getFlowReviewPsaBasePogsReq({
          retailer_category_code: category,
          category_code: subcategory,
        })
      ).data;
    } catch (error) {
      toast({
        title: "Failed to load psa base pogs filenames",
        error,
      });
    }

    removeInlineLoader(RouteGetMerchflowPsaBasePogs);
    return response;
  };

  const postMerchflowsApi = async (payload: PayloadPostMerchflows) => {
    addInlineLoader(RoutePostMerchflows);
    let response: ResponsePostMerchflows | null = null;

    try {
      response = (await postMerchflowsReq(payload)).data;
    } catch (error) {
      toast({
        title: "Failed to create new review",
        error,
      });
    }

    removeInlineLoader(RoutePostMerchflows);
    return response;
  };

  const postMerchflowsMergeRulesInputApi = async (
    payload: PayloadPostMerchflowsMergeRulesInput,
  ) => {
    addInlineLoader(RoutePostMerchflowsMergeRulesInput);
    let response: ResponsePostMerchflowsMergeRulesInput | null = null;

    try {
      response = (await postMerchflowsMergeRulesInputReq(payload)).data;
    } catch (error) {
      toast({
        title: "Failed to load fixtures and products information",
        error,
      });
    }

    removeInlineLoader(RoutePostMerchflowsMergeRulesInput);
    return response;
  };

  const putMerchflows_merchflowId_FiltersApi = async (
    payload: PayloadPutMerchflows_merchflowId_Filters,
  ) => {
    addInlineLoader(RoutePutMerchflows_merchflowId_Filters);
    let response: ResponsePutMerchflows_merchflowId_Filters | null = null;

    try {
      response = (await putMerchflows_merchflowId_FiltersReq(payload)).data;
    } catch (error) {
      toast({
        title: "Failed to apply review filters",
        error,
      });
    }

    removeInlineLoader(RoutePutMerchflows_merchflowId_Filters);
    return response;
  };

  const putMerchflowsFilterConfig_filterConfigIdApi = async (
    filterConfigId: number,
    payload: PayloadPutMerchflowsFilterConfig_filterConfigId_,
  ) => {
    addInlineLoader(RoutePutMerchflowsFilterConfig_filterConfigId_);
    let response: ResponsePutMerchflowsFilterConfig_filterConfigId_ | null =
      null;

    try {
      response = (
        await putMerchflowsFilterConfig_filterConfigIdReq(
          filterConfigId,
          payload,
        )
      ).data;
    } catch (error) {
      toast({
        title: "Failed to update filters",
        error,
      });
    }

    removeInlineLoader(RoutePutMerchflowsFilterConfig_filterConfigId_);
    return response;
  };

  const putMerchflows_merchflowId_MergeRulesApi = async (
    filterConfigId: number,
    payload: PayloadPutMerchflows_filterConfigId_MergeRules,
  ) => {
    addInlineLoader(RoutePutMerchflows_filterConfigId_MergeRules);
    let response: ResponsePutMerchflows_filterConfigId_MergeRules | null = null;

    try {
      response = (
        await putMerchflows_merchflowId_MergeRulesReq(filterConfigId, payload)
      ).data;
    } catch (error) {
      toast({
        title: "Failed to apply merge rules",
        error,
      });
    }

    removeInlineLoader(RoutePutMerchflows_filterConfigId_MergeRules);
    return response;
  };

  const postMerchflowUploadPsaApi = async (
    payload: PayloadFormPostMerchflowUploadPsa,
  ) => {
    addInlineLoader(RoutePostMerchflowUploadPsa);
    let response: ResponsePostMerchflowUploadPsa | null = null;

    try {
      response = (await postMerchflowUploadPsaReq(payload)).data;
    } catch (error) {
      toast({
        title: "Failed to upload PSA file",
        error,
      });
    }

    removeInlineLoader(RoutePostMerchflowUploadPsa);
    return response;
  };
  const postMerchflowImportStoreSummaryApi = async (
    payload: PayloadPostStoreSummary,
  ) => {
    addInlineLoader(RoutePostMerchflowUploadPsa);
    let response: string | null = null;

    try {
      response = (await postMerchflowImportStoreSummaryReq(payload)).data;
    } catch (error) {
      toast({
        title: "Failed to upload Store Summary file",
        error,
      });
    }

    removeInlineLoader(RoutePostMerchflowUploadPsa);
    return response;
  };
  const getMerchflowStoresReq = ({
    retailerCategoryCode,
    categoryCode,
  }: {
    retailerCategoryCode: string;
    categoryCode: string;
  }) =>
    getRequest<ResponseGetMerchflowStores>(`${API}${RouteGetMerchflowStores}`, {
      queryParams: {
        categoryCode,
        retailerCategoryCode,
      },
    });

  const getMerchflowStoresApi = async (params: {
    retailerCategoryCode: string;
    categoryCode: string;
  }) => {
    addInlineLoader(RouteGetMerchflowStores);
    let response: ResponseGetMerchflowStores | null = null;

    try {
      response = (await getMerchflowStoresReq(params)).data;
    } catch (error) {
      toast({
        title: "Failed to get store summary data",
        error,
      });
    }

    removeInlineLoader(RouteGetMerchflowStores);
    return response;
  };

  return {
    getMerchflowsCategoriesApi,
    getMerchflowsFiltersApi,
    getMerchflowsVariantsApi,
    getMerchflowPsaBasePogsApi,
    postMerchflowsApi,
    postMerchflowsMergeRulesInputApi,
    postMerchflowUploadPsaApi,
    putMerchflows_merchflowId_FiltersApi,
    putMerchflowsFilterConfig_filterConfigIdApi,
    putMerchflows_merchflowId_MergeRulesApi,
    getMerchflowStoresApi,
    postMerchflowImportStoreSummaryApi,
  };
};
