import { PlanogramContainer } from "@CommonTypes/merchflow/pog/container";
import { PlanogramNonCircular } from "@CommonTypes/merchflow/pog/pog";

export const RouteGetMerchflows_merchflowId_S2_s2SolutionId_ =
  "/merchflows/:merchflowId/s2/:s2SolutionId";

export interface ResponseGetMerchflows_merchflowId_S2_s2SolutionId_ {
  s1_user_trigger_id: number;
  template_id: string;
  solution: {
    planogram: PlanogramNonCircular;
    containers: PlanogramContainer[];
  };
}
