import {
  PayloadPutMerchflows_MerchflowId_StoresApprovals,
  RoutePutMerchflows_MerchflowId_StoresApprovals,
} from "@CommonApi/merchflows/_merchflowId_/stores/approvals";
import { BasePog } from "@CommonTypes/omnihq-db/merchantSchema";
import { allSqlColumns } from "src/omni-common/api/_common/sqlThings";
import {
  PayloadS2PlaceVariantsBatchStart,
  ResponseS2PlaceVariantsBatchStart,
  UrlS2PlaceVariantsBatchStart,
} from "src/omni-common/api/frogger/place_variants/batch/start";
import {
  AllBasePogResponse,
  ResponseMerchFlowApplyFilters,
  ResponseMerchFlowDetails,
} from "src/omni-common/api/merchandise";
import {
  ParamsMerchFlowPoggerPoggerResponse,
  ResponseMerchFlowPoggerPoggerResponse,
  UrlMerchFlowPoggerPoggerResponse,
} from "src/omni-common/api/merchflow/pogger/pogger_response";

import {
  StorePogAllPayload,
  StorePogAllResponse,
} from "src/omni-common/api/store-pog/all";
import {
  PlaceVariantsStartResp,
  ResponseAlgoStep2FromBeBatch,
  Step1Step2Solution,
} from "src/omni-common/types/step2fixtures";
import { BasePogWithId } from "src/pages/MerchflowTemplateBasePogs/store/types";
import { API, getRequest, postRequest, putRequest } from "src/utils/axios";

import {
  PayloadS1PlaceContainersAllTemplates,
  ResponseS1PlaceContainersAllTemplates,
  RouteS1PlaceContainersAllTemplates,
} from "@CommonApi/frogger/place_containers_all_templates";
import {
  PayloadPostMerchflowApplyToBases,
  RoutePostMerchflowApplyToBases,
} from "@CommonApi/merchflow/apply-to-bases";
import {
  PayloadPostMerchflows_MerchflowId_ExportsBatch,
  ResponseGetMerchflows_MerchflowId_ExportsBatch,
  ResponsePostMerchflows_MerchflowId_ExportsBatch,
  RouteGetMerchflows_MerchflowId_ExportsBatch,
  RoutePostMerchflows_MerchflowId_ExportsBatch,
} from "@CommonApi/merchflows/_merchflowId_/exports/batch";
import {
  PayloadPostMerchflows_MerchflowId_ExportsSingle,
  ResponsePostMerchflows_MerchflowId_ExportsSingle,
  RoutePostMerchflows_MerchflowId_ExportsSingle,
} from "@CommonApi/merchflows/_merchflowId_/exports/single";
import { RouteGetMerchflowsReviewFlows_merchflowId_ } from "@CommonApi/merchflows/review/flows/_merchflowId_";
import {
  ResponseGetMerchflowsReviews,
  RouteGetMerchflowsReviews,
} from "@CommonApi/merchflows/reviews";
import {
  PayloadPutBasePogSelectedStatus,
  RoutePutBasePogSelectedStatus,
} from "@CommonApi/pogger/base-pog/status";
import {
  PogNonCircular,
  TemplatePogData,
} from "@CommonTypes/merchflow/pog/pog";
import { Container } from "src/components/Planogram/store/types";
import { HttpStatusCode } from "../../../omni-common/utils/HttpStatusCode";
import {
  EditStorePogPayload,
  PayloadAlgoStep0,
  PayloadPoggerize,
  WithDebugInfo,
} from "../steps/Step2Fixtures/store/types";
import {
  ResponseGetAlgoStep2Solution,
  ResponseGetMerchFlowTemplateTable,
} from "./types";

export const postAlgoStep0Req = ({
  payload,
  froggerVersion,
}: {
  payload: PayloadAlgoStep0;
  froggerVersion: string;
}) =>
  postRequest<any>(`${API}/frogger/group_and_size_variants`, {
    payload,
    queryParams: { frogger_version: froggerVersion },
  });

export const postAlgoStep1Req = ({
  payload,
  froggerVersion,
}: {
  payload: PayloadS1PlaceContainersAllTemplates;
  froggerVersion: string;
}) =>
  postRequest<ResponseS1PlaceContainersAllTemplates>(
    `${API}${RouteS1PlaceContainersAllTemplates}`,
    {
      payload,
      queryParams: { frogger_version: froggerVersion },
    },
  );

export const postAlgoStep2ReqBatch = async ({
  payload,
  froggerVersion,
}: {
  payload: PayloadS2PlaceVariantsBatchStart;
  froggerVersion: string;
}): Promise<ResponseAlgoStep2FromBeBatch[]> => {
  const placeVariantsStartResponses = (
    await postRequest<ResponseS2PlaceVariantsBatchStart>(
      `${API}${UrlS2PlaceVariantsBatchStart}`,
      {
        payload,
        queryParams: { frogger_version: froggerVersion },
      },
    )
  ).data as PlaceVariantsStartResp[];

  const errors = placeVariantsStartResponses
    .filter((p) => !p.merchflow_template_place_variants_id)
    .map((p) => `${p.error}`)
    .join(",");

  if (errors !== "") {
  } else {
    const timeoutMinutes = 20;
    const start = Date.now();

    while (Date.now() - start < timeoutMinutes * 60 * 1000) {
      await new Promise((r) => setTimeout(() => r(""), 10000));
      const response = await postRequest<ResponseAlgoStep2FromBeBatch[]>(
        `${API}/frogger/place_variants/batch/get`,
        {
          payload: placeVariantsStartResponses,
        },
      );

      if (response.status !== HttpStatusCode.OK) continue;
      return response.data;
    }
  }

  return [];
};

export const postPoggerizeReq = ({
  payload,
  froggerVersion,
}: {
  payload: PayloadPoggerize;
  froggerVersion: string;
}) =>
  postRequest<any>(`${API}/frogger/poggerize`, {
    payload,
    queryParams: { frogger_version: froggerVersion },
  });

export const postPoggerReq = (payload: any) =>
  postRequest<any>(`${API}/pogger/build_sync`, { payload });

export const postPoggerAsyncReq = async (payload: any) => {
  await postRequest<any>(`${API}/pogger/build_async`, { payload });

  const timeoutMinutes = 20;
  const start = Date.now();

  while (Date.now() - start < timeoutMinutes * 60 * 1000) {
    await new Promise((r) => setTimeout(() => r(""), 10000));
    const response = await getRequest<ResponseMerchFlowPoggerPoggerResponse>(
      `${API}${UrlMerchFlowPoggerPoggerResponse}`,
      {
        queryParams: {
          base_pog_id: payload.base_pog_id,
        } as ParamsMerchFlowPoggerPoggerResponse,
      },
    );

    if (response.status !== HttpStatusCode.OK) continue;
    return response.data.data;
  }

  throw "Pogger timed out";
};

export const postPogModifiedReq = (payload: any) =>
  postRequest<BasePog>(`${API}/pogger/pogger_modified`, { payload });

export const postApplyToBasesReq = ({
  payload,
  froggerVersion,
}: {
  payload: PayloadPostMerchflowApplyToBases;
  froggerVersion: string;
}) =>
  postRequest<any>(`${API}${RoutePostMerchflowApplyToBases}`, {
    payload,
    queryParams: { frogger_version: froggerVersion },
  });

export const postContainerModified = (payload: any) =>
  putRequest<any>(
    `${API}/merchflows/solutions/group-and-size-variants-modified`,
    { payload },
  );

export const postFixtureModified = (payload: any) =>
  putRequest<any>(`${API}/merchflows/solutions/fixture-modified`, {
    payload,
  });

export const STORE_LEVEL_PAGE_SIZE = 30;

// todo - should we have `store-pog/all` also in `omni-common` ?
const apiStorePogAll = (payload: StorePogAllPayload) =>
  postRequest<StorePogAllResponse>(`${API}/store-pog/all`, {
    payload,
  });

export const getStoreLevelPogStatus = (payload: StorePogAllPayload) =>
  postRequest<StorePogAllResponse>(`${API}/store-pog/all`, {
    payload: {
      project: [
        "id",
        "status",
        "store",
        "template_id",
        "filter_config_id",
        "is_approved",
      ],
      limit: STORE_LEVEL_PAGE_SIZE,
      ...payload,
    },
  });

export const getOneStorePogData = (id: number) =>
  apiStorePogAll({
    project: [allSqlColumns], // we requested all columns from SQL
    where: { id },
  }).then((rs) => rs.data);

export const getAllReview = ({ page = 1, size = 20 }) => {
  return getRequest<ResponseGetMerchflowsReviews>(
    `${API}${RouteGetMerchflowsReviews}`,
    { queryParams: { page, size } },
  );
};

export const getFlowReviewFilterConfigReq = (merchflowId: number) => {
  return getRequest<ResponseMerchFlowApplyFilters[]>(
    `${API}${RouteGetMerchflowsReviewFlows_merchflowId_}`,
    { params: { merchflowId } },
  );
};

export const getStorePogReport = (
  type: string,
  payload: {
    category_code: string;
    store_code: string;
    template_id: string;
    filter_config_id: number | string;
  },
) => {
  return postRequest<any>(`${API}/merchflows/export/${type}`, {
    payload,
  });
};

export const putEditStorePog = (payload: EditStorePogPayload) =>
  putRequest<any>(`${API}/store-pog/pog-modified`, {
    payload,
  });

export const postExportPlanogramPSA = (
  merchflowId: number,
  payload: PayloadPostMerchflows_MerchflowId_ExportsSingle,
) => {
  return postRequest<ResponsePostMerchflows_MerchflowId_ExportsSingle>(
    `${API}${RoutePostMerchflows_MerchflowId_ExportsSingle}`,
    {
      payload,
      params: {
        merchflowId,
      },
    },
  );
};

export const getMerchFlowTemplateTable = (merchflow_id: number) =>
  postRequest<ResponseGetMerchFlowTemplateTable>(
    `${API}/merchflows/merchflow-template/all`,
    {
      payload: {
        project: ["*"],
        limit: 100,
        where: {
          merchflow_id,
        },
        offset: 0,
      },
    },
  ).then((rs) => rs.data);

export const getAlgoStep2Solutions = async (filter_config_id: number) => {
  let result: WithDebugInfo<Step1Step2Solution>[] = [];
  let curPage = 1;
  let total = 1;
  do {
    const { totalPages, solutions, logs } =
      await getRequest<ResponseGetAlgoStep2Solution>(
        `${API}/frogger/place_variants`,
        {
          queryParams: {
            filter_config_id,
            page: curPage,
            size: 100,
          },
        },
      ).then((rs) => rs.data);

    const solutionIdRequestMapping = logs.reduce(
      (obj, log) => {
        log.solution_ids.forEach((id) => {
          obj[id] = log.request_path;
        });
        return obj;
      },
      {} as { [key: number]: any },
    );

    result = result.concat(
      solutions.map((s) => ({
        ...s.solution,
        templateId: s.template_id,
        request: solutionIdRequestMapping[s.solution_id],
        solution_id: s.solution_id,
      })),
    );
    total = totalPages;
    curPage++;
  } while (curPage <= total);
  return result;
};

export const getMerchFlowDetails = async (merchFlowId: string) => {
  return getRequest<ResponseMerchFlowDetails>(
    `${API}/merchflows/review/${merchFlowId}`,
  ).then((r) => r.data);
};

export const getFilterConfigTemplateStatus = (
  filter_config_id: string | number,
) => {
  return getRequest<any>(`${API}/merchflows/view-template`, {
    queryParams: {
      filter_config_id,
    },
  });
};

export const postDownloadStorePogs = (
  merchflowId: number,
  payload: PayloadPostMerchflows_MerchflowId_ExportsBatch,
) =>
  postRequest<ResponsePostMerchflows_MerchflowId_ExportsBatch>(
    `${API}${RoutePostMerchflows_MerchflowId_ExportsBatch}`,
    {
      payload,
      params: {
        merchflowId,
      },
    },
  );

export const getDownloadStorePogs = (merchflowId: number, batchId: number) =>
  getRequest<ResponseGetMerchflows_MerchflowId_ExportsBatch>(
    `${API}${RouteGetMerchflows_MerchflowId_ExportsBatch}`,
    {
      params: {
        merchflowId,
        batchId,
      },
    },
  );

export const getAllBasePogVersion = (filter_config_id: number) =>
  getRequest<AllBasePogResponse[]>(`${API}/pogger/base_pog/all`, {
    queryParams: { filter_config_id },
  }).then((data) => data.data);

export const getTemplateBasePogOfFlow = (merchflow_id: number) =>
  getRequest<TemplatePogData[]>(`${API}/merchflows/template-all`, {
    queryParams: { merchflow_id },
  }).then((data) => data.data);

export const getAllBasePogOfTemplate = (queryParams: {
  template_id: string;
  merchflow_id: number;
}) =>
  getRequest<BasePogWithId[]>(`${API}/merchflows/template/all-base-pogs`, {
    queryParams,
  }).then((rs) => rs.data);

export const putUpdateBasePogStatus = (
  payload: PayloadPutBasePogSelectedStatus,
) =>
  putRequest(`${API}${RoutePutBasePogSelectedStatus}`, {
    payload: {
      ...payload,
    },
  }).then((data) => data.data);

type PostUpdateSolutionResponse = {
  created_at: string;
  id: number;
  merchflow_template_id: number;
  order_in_response: number;
  parent_solution_id: number;
  request_path: string;
  response_path: string;
  solution: Step1Step2Solution;
  solution_fe_format: {
    pog: PogNonCircular;
    containers: Container[];
  };
};

export const postUpdateSolution = (payload: {
  parent_solution_id: number;
  modified_solution: any;
}) =>
  postRequest<PostUpdateSolutionResponse>(`${API}/frogger/solution`, {
    payload,
  }).then((rs) => rs.data);
