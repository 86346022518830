import hexToRgba from "hex-to-rgba";
import { useMemo, useRef } from "react";
import styled from "styled-components";

import { Color } from "src/elements";
import { zIndexLevel } from "src/utils";
import { useHoverClickable } from "src/utils/hoverClickable";
import { AnchorLayer } from "../AnchorLayer/AnchorLayer";
import { useAnchorLayer } from "../AnchorLayer/store/hooks";
import { InputTitle } from "../Input/components/InputTitle";
import { Dropdown } from "./components/Dropdown";
import { Option } from "./components/Option";
import { selectHeight } from "./store/constants";
import { SelectOption } from "./store/types";

const SelectContainer = styled.div<{
  width?: string;
  isHovered: boolean;
  isDisabled: boolean;
}>`
  position: relative;
  width: ${({ width }) => width};
  transition-property: box-shadow;
  transition-duration: 100ms;
  transition-timing-function: ease;

  box-shadow: ${({ isHovered }) =>
    isHovered && `0px 0px 7px ${hexToRgba(Color.spaceGray, 0.3)}`};

  pointer-events: ${({ isDisabled }) => isDisabled && "none"};
`;

const SelectElement = styled.div<{ isDisabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  height: ${selectHeight}px;
  border: ${({ isDisabled }) =>
    `1px solid ${(isDisabled && Color.textDisabled) || Color.spaceGray}`};
  border-radius: 4px;
  cursor: pointer;

  // Target placeholder option
  > div:last-of-type {
    border-radius: 4px;
  }
`;

interface Props<T> {
  options: SelectOption<T>[];
  value: T;
  setValue: (value: T) => void;
  placeholder?: string;
  title?: string;
  width?: string;
  isSearchable?: boolean;
  isLoading?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
}

export const Select = <T extends any>({
  options,
  value,
  setValue,
  placeholder,
  title,
  width = "100%",
  isSearchable = false,
  isLoading = false,
  isRequired = false,
  isDisabled = false,
}: Props<T>) => {
  const refSelect = useRef<HTMLDivElement>(null);
  const { isHovered } = useHoverClickable(refSelect);
  const { refLayer, isRendering, isUsingClick, layerState, hideAnchorLayer } =
    useAnchorLayer({
      refTrigger: refSelect,
      isTopOfParent: true,
      isUsingClick: true,
    });
  const isOpen = layerState !== null;

  const selectedValueLabel = useMemo(() => {
    return options.find((option) => option.value === value)?.label;
  }, [options, value]);

  const onSelect = (value: T) => {
    setValue(value);
    hideAnchorLayer();
  };

  return (
    <SelectContainer
      width={width}
      isHovered={isHovered && !isOpen}
      isDisabled={isDisabled}
    >
      <InputTitle
        label={title}
        isActive={isOpen !== null}
        isDisabled={isDisabled}
        isRequired={isRequired}
        zIndex={isOpen ? zIndexLevel.ABSOLUTE_LAYER + 1 : undefined}
      />

      <SelectElement ref={refSelect} isDisabled={isDisabled}>
        <Option
          label={
            selectedValueLabel ||
            (placeholder && `Select ${placeholder}...`) ||
            ""
          }
          icon={{
            name: isLoading ? "spinner" : "triangleDown",
            color: isDisabled ? Color.textDisabled : Color.primary,
          }}
          isPlaceholder={!selectedValueLabel}
          isDisabled={isDisabled}
          isOpener
        />
      </SelectElement>

      <AnchorLayer
        refLayer={refLayer}
        layerState={layerState}
        isRendering={isRendering}
        isUsingClick={isUsingClick}
        isUsingParentWidth
        hideAnchorLayer={hideAnchorLayer}
      >
        <Dropdown
          value={value}
          options={options}
          onSelect={onSelect}
          isLoading={isLoading}
          isSearchable={isSearchable}
          closeDropdown={hideAnchorLayer}
        />
      </AnchorLayer>
    </SelectContainer>
  );
};
