import { adapterPogToPogNonCircular } from "@CommonAdapters/pog";
import { PlanogramContainer } from "@CommonTypes/merchflow/pog/container";
import { atom, useAtom, useSetAtom } from "jotai";
import { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { Page, Planogram } from "src/components";
import { useInlineLoaders } from "src/components/InlineLoader";
import { useModals } from "src/components/Modals";
import { Button, Color, Flex, Spinner, Text, Icon } from "src/elements";
import { useModifiedPog } from "src/hooks/useModifiedPogAtom";
import { MerchflowHeader } from "../../components/merchflows/MerchflowHeader/MerchflowHeader";
import { MerchflowStepsNavigator } from "../../components/merchflows/MerchflowStepsNavigator/MerchflowStepsNavigator";
import {
  merchflows_merchflowId_Stores_storeCode_Pogs_storePogId_Atom,
  merchflows_merchflowId_Stores_storeCode_Pogs_storePogId_PogAtom,
} from "./store/atoms";
import { useNavigation } from "src/utils/navigation";
import { routeToUrl } from "src/utils/axios";
import { pages } from "src/utils";
import { RoutePostMerchflows_MerchflowId_ExportsSingle } from "@CommonApi/merchflows/_merchflowId_/exports/single";
import { PogAtom } from "src/components/Planogram/store/types";
import { highlightsModeAtom } from "src/components/Planogram/store/atoms";
import { PlanogramFixturesMode } from "src/components/PlanogramExtensions/PlanogramFixturesMode";
import { PlanogramMetrics } from "src/components/PlanogramExtensions/PlanogramMetrics";
import { PlanogramItemSearcher } from "src/components/PlanogramExtensions/PlanogramItemSearcher";
import { PlanogramHovererDetails } from "src/components/PlanogramExtensions/PlanogramHovererDetails/PlanogramHovererDetails";
import {
  RouteGetMerchflowStorePog_storePogId_,
  RoutePutMerchflowStorePog_storePogId_,
} from "@CommonApi/merchflow/store/pog/_storePogId_";
import { useApi } from "src/api";
import { RoutePutMerchflows_MerchflowId_StoresApprovals } from "@CommonApi/merchflows/_merchflowId_/stores/approvals";
import { PayloadPostMerchflows_MerchflowId_ExportsBatch } from "@CommonApi/merchflows/_merchflowId_/exports/batch";

export const PageMerchflows_merchflowId_Stores_storeCode_Pogs_storePogId_ =
  () => {
    const {
      merchflowId: _merchflowId,
      storePogId: _storePogId,
      storeCode: _storeCode,
    } = useParams<{
      merchflowId: string;
      storeCode: string;
      storePogId: string;
    }>();
    const merchflowId = Number(_merchflowId);
    const storeCode = _storeCode!;
    const storePogId = Number(_storePogId);

    const {
      getMerchflowStorePogApi,
      putMerchflowStorePogApi,
      postExportBatchPogsApi,
    } = useApi();
    const { putMerchflowApprovalsApi } = useApi();
    const { isInlineLoading } = useInlineLoaders();
    const { openConfirmModal } = useModals();
    const { navigate } = useNavigation();

    const [data, setData] = useAtom(
      merchflows_merchflowId_Stores_storeCode_Pogs_storePogId_Atom,
    );
    const dataRef = useRef(data);
    const [isApprovalDone, setIsApprovalDone] = useState(false);
    const [isSavingDone, setIsSavingDone] = useState(false);

    const isPogAvailable = data?.status === "DONE";
    const isRequestLoading = isInlineLoading(
      RouteGetMerchflowStorePog_storePogId_,
    );
    const isPlanogramLoading =
      data === undefined ||
      (data === null && isRequestLoading) ||
      (data &&
        (data.status === "NOT_STARTED" ||
          data.status === "POGGER_IN_PROGRESS" ||
          data.status === "POGGERIZE_IN_PROGRESS")) ||
      false;
    const isSaveLoading = isInlineLoading(
      RoutePutMerchflowStorePog_storePogId_,
    );
    const isExportLoading = isInlineLoading(
      RoutePostMerchflows_MerchflowId_ExportsSingle,
    );
    const isApprovalLoading = isInlineLoading(
      RoutePutMerchflows_MerchflowId_StoresApprovals,
    );

    const containersAtom = useMemo(() => atom([] as PlanogramContainer[]), []);
    const {
      setPog: setStorePog,
      pog: storePog,
      revertPog,
      isModified,
      savePogAsOriginal,
    } = useModifiedPog(
      merchflows_merchflowId_Stores_storeCode_Pogs_storePogId_PogAtom,
    );

    useEffect(() => {
      loadStorePog(storePogId);

      // Cleanup.
      return () => {
        setData(null);
        setStorePog(null);
      };
    }, [storePogId]);

    const loadStorePog = async (storePogId: number) => {
      setData(await getMerchflowStorePogApi(storePogId));
    };

    // Save POG to atom once.
    useEffect(() => {
      if (data?.pog && (dataRef.current === null || storePog === null)) {
        setStorePog(data.pog);
        dataRef.current = data;
      }
    }, [data]);

    // Keep loading pog data if the pog generation is ongoing.
    useEffect(() => {
      if (
        data?.status === "NOT_STARTED" ||
        data?.status === "POGGER_IN_PROGRESS" ||
        data?.status === "POGGERIZE_IN_PROGRESS"
      ) {
        setTimeout(() => {
          loadStorePog(storePogId);
        }, 10_000);
      }
    }, [data]);

    const exportStorePog = (
      type: PayloadPostMerchflows_MerchflowId_ExportsBatch["type"],
    ) => {
      postExportBatchPogsApi(merchflowId, {
        store_pogs_ids: [storePogId],
        type,
      });
    };

    const onApproval = async () => {
      if (data?.is_approved === true) {
        const unapproved = await putMerchflowApprovalsApi(merchflowId, {
          unapproved: [{ store_pog_id: storePogId }],
        });

        if (unapproved) {
          setIsApprovalDone(true);

          setTimeout(() => {
            setIsApprovalDone(false);
            setData((data) => ({
              ...data!,
              is_approved: false,
            }));
          }, 1000);
        }
      }

      if (data?.is_approved === false) {
        const approved = await putMerchflowApprovalsApi(merchflowId, {
          approved: [{ store_pog_id: storePogId }],
        });

        if (approved) {
          setIsApprovalDone(true);
          setTimeout(() => {
            setIsApprovalDone(false);
            setData((data) => ({
              ...data!,
              is_approved: true,
            }));
          }, 1000);
        }
      }
    };

    const onSave = async (saveAsNew: boolean) => {
      if (!storePog) return;

      const newStorePogId = await putMerchflowStorePogApi({
        storePogId,
        payload: {
          pog: adapterPogToPogNonCircular(storePog),
          save_as_new: saveAsNew,
        },
      });

      if (newStorePogId) {
        setIsSavingDone(true);
        setTimeout(() => {
          setIsSavingDone(false);

          if (saveAsNew && newStorePogId !== storePogId) {
            navigate(
              routeToUrl(
                pages.merchflows_merchflowId_Stores_storeCode_Pogs_storePogId_,
                {
                  merchflowId,
                  storeCode,
                  storePogId: newStorePogId,
                },
              ),
            );
          }

          savePogAsOriginal();
        }, 1000);
      }
    };

    const onRevertChanges = () => {
      openConfirmModal({
        title: "Revert modified planogram changes?",
        description:
          "All unsaved changes made to the planogram will be lost. This is irreversible.",
        buttons: [
          {
            label: "Keep Changes",
            color: "primary",
            variant: "inverted",
          },
          {
            label: "Revert Changes",
            color: "red",
            onClick: () => {
              revertPog();
            },
          },
        ],
      });
    };

    const setHighlightsMode = useSetAtom(highlightsModeAtom);

    useEffect(() => {
      setHighlightsMode(true);
    }, []);

    return (
      <Page>
        <MerchflowHeader
          merchflowId={merchflowId}
          storeCode={storeCode}
          storePogId={storePogId}
        />

        <MerchflowStepsNavigator
          activeStep="STORES"
          merchflowId={merchflowId}
          basePogId={data?.base_pog_id}
        />

        <Flex minHeight="0" gap="10px" grow column>
          {isPlanogramLoading && (
            <Spinner
              size="big"
              label="Store planogram is loading..."
              isCentered
            />
          )}

          {data === null && !isPlanogramLoading && (
            <Flex
              justify="center"
              align="center"
              column
              gap="10px"
              width="100%"
              height="100%"
            >
              <Icon name="alert" color={Color.yellow} size={40} />

              <Text variant="h3" color={Color.yellow}>
                Store planogram not found
              </Text>
            </Flex>
          )}

          {data?.status === "ERROR" && (
            <Flex
              justify="center"
              align="center"
              column
              gap="10px"
              width="100%"
              height="100%"
            >
              <Icon name="alert" color={Color.red} size={40} />

              <Text variant="h3" color={Color.red}>
                Store planogram generation failed
              </Text>
            </Flex>
          )}

          {data &&
            (data.status === "NOT_STARTED" ||
              data.status === "POGGER_IN_PROGRESS" ||
              data.status === "POGGERIZE_IN_PROGRESS") && (
              <Spinner
                size="big"
                label="Store planogram is being generated..."
                isCentered
              />
            )}

          {data?.status === "DONE" && storePog && (
            <>
              <Flex justify="between" align="center">
                <PlanogramItemSearcher />

                <PlanogramFixturesMode />
              </Flex>

              <Planogram
                merchflowId={merchflowId}
                currentStoreCode={storeCode}
                pogAtom={
                  merchflows_merchflowId_Stores_storeCode_Pogs_storePogId_PogAtom as PogAtom
                }
                containersAtom={containersAtom}
                isEditable
                isPanelVisible
                isPog
                isWithBorder
                isDuplicateDisabled
              />

              <PlanogramHovererDetails />
            </>
          )}
        </Flex>

        <Flex justify="between" align="center">
          <PlanogramMetrics pog={storePog} isLoading={isPlanogramLoading} />

          <Flex gap="10px">
            {isModified && (
              <>
                <Button
                  color="red"
                  variant="inverted"
                  isDisabled={!isPogAvailable || isSaveLoading || isSavingDone}
                  onClick={onRevertChanges}
                >
                  Revert Changes
                </Button>

                <Button
                  isEnabled={isPogAvailable}
                  isLoading={isSaveLoading}
                  isSuccessful={isSavingDone}
                  onClick={() => onSave(false)}
                  dropdown={[
                    {
                      label: "Save New Version",
                      onClick: () => onSave(true),
                    },
                  ]}
                >
                  Save
                </Button>
              </>
            )}

            {!isModified && (
              <>
                <Button
                  color="greenSmoke"
                  isEnabled={isPogAvailable}
                  isLoading={isExportLoading}
                  variant="inverted"
                  onClick={() => exportStorePog("csv")}
                >
                  Export
                </Button>

                <Button
                  color="primary"
                  onClick={onApproval}
                  isEnabled={isPogAvailable}
                  isLoading={isApprovalLoading}
                  isSuccessful={isApprovalDone}
                >
                  {data?.is_approved ? "Unapprove" : "Approve"}
                </Button>
              </>
            )}
          </Flex>
        </Flex>
      </Page>
    );
  };
