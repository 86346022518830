import React, { ReactNode } from "react";
import styled from "styled-components";

interface CommonProps {
  className?: string;

  position?: "static" | "relative" | "absolute" | "fixed" | "sticky";
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
  zIndex?: string | number;

  width?: string;
  maxWidth?: string;
  minWidth?: string;
  height?: string;
  minHeight?: string;
  maxHeight?: string;

  margin?: string;
  padding?: string;
  background?: string;
  backgroundHovered?: string;

  border?: string;
  borderTop?: string;
  borderRight?: string;
  borderBottom?: string;
  borderLeft?: string;
  borderRadius?: string;
  borderWidth?: string;
  borderColor?: string;
  borderStyle?: "solid" | "dashed";

  overflow?: "auto" | "visible" | "hidden" | "scroll";
  visibility?: "visible" | "hidden";
  opacity?: number;
  transition?: string;

  grow?: boolean;
  flexGrow?: number;
  flexShrink?: number;

  gridColumn?: string;
  gridRow?: string;
  gridArea?: string;

  whiteSpace?: string;
  pointerEvents?: "auto" | "none";
  userSelect?: "all" | "auto" | "none" | "text";
  cursor?: "inherit" | "auto" | "pointer" | "default" | "grab";

  rotate?: number;
  boxShadow?: string;

  isDisabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  onPointerDown?: React.MouseEventHandler<HTMLDivElement>;
  onPointerEnter?: React.MouseEventHandler<HTMLDivElement>;
  onContextMenu?: React.MouseEventHandler<HTMLDivElement>;
  children?:
    | React.ReactChild
    | React.ReactChild[]
    | boolean
    | JSX.Element
    | null
    | ReactNode;
}

export interface BoxProps extends CommonProps {
  display?: "block" | "inline" | "flex" | "inline-flex" | "grid";
}

export const BoxElement = styled.div<BoxProps>`
  display: ${({ display }) => display};

  position: ${({ position }) => position};
  top: ${({ top }) => top};
  right: ${({ right }) => right};
  bottom: ${({ bottom }) => bottom};
  left: ${({ left }) => left};
  z-index: ${({ zIndex }) => zIndex};

  width: ${({ width }) => width};
  max-width: ${({ maxWidth }) => maxWidth};
  min-width: ${({ minWidth }) => minWidth};
  height: ${({ height }) => height};
  min-height: ${({ minHeight, grow, flexGrow }) =>
    minHeight || ((grow === true || flexGrow !== undefined) && "0")};
  max-height: ${({ maxHeight }) => maxHeight};

  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  background: ${({ background }) => background};

  ${({ backgroundHovered }) =>
    backgroundHovered && `&:hover { background: ${backgroundHovered}; }`};

  border: ${({ border, borderColor }) =>
    border || (borderColor && `1px solid ${borderColor}`)};
  border-top: ${({ borderTop }) => borderTop};
  border-right: ${({ borderRight }) => borderRight};
  border-bottom: ${({ borderBottom }) => borderBottom};
  border-left: ${({ borderLeft }) => borderLeft};
  border-radius: ${({ borderRadius }) => borderRadius};
  border-width: ${({ borderWidth }) => borderWidth};
  border-color: ${({ borderColor }) => borderColor};
  border-style: ${({ borderStyle }) => borderStyle};

  overflow: ${({ overflow }) => overflow};
  visibility: ${({ visibility }) => visibility};
  opacity: ${({ opacity }) => opacity};
  transition: ${({ transition }) => transition};

  flex-grow: ${({ grow, flexGrow }) => (grow && "1") || flexGrow};
  flex-shrink: ${({ flexShrink }) => flexShrink};

  grid-column: ${({ gridColumn }) => gridColumn};
  grid-row: ${({ gridRow }) => gridRow};
  grid-area: ${({ gridArea }) => gridArea};

  white-space: ${({ whiteSpace }) => whiteSpace};
  pointer-events: ${({ pointerEvents }) => pointerEvents};
  user-select: ${({ userSelect, isDisabled, onClick }) =>
    userSelect || ((isDisabled || onClick) && "none")};
  cursor: ${({ cursor, isDisabled, onClick }) =>
    cursor || (isDisabled && "default") || (onClick && "pointer")};

  transform: ${({ rotate }) => rotate && `rotate(${rotate}deg)`};
  box-shadow: ${({ boxShadow }) => boxShadow};
`;

export const Box = React.forwardRef<HTMLDivElement, BoxProps>((props, ref) => (
  <BoxElement
    ref={ref}
    {...props}
    {...(props.isDisabled && { onClick: undefined })}
  >
    {props.children}
  </BoxElement>
));

export interface FlexProps extends CommonProps {
  direction?: "row" | "column";
  column?: boolean;
  flexWrap?: "nowrap" | "wrap" | "wrap-reverse";
  justify?:
    | "normal"
    | "center"
    | "left"
    | "right"
    | "baseline"
    | "evenly"
    | "between"
    | "around"
    | "space-evenly"
    | "space-between"
    | "space-around"
    | "stretch";
  align?:
    | "normal"
    | "start"
    | "top"
    | "end"
    | "bottom"
    | "center"
    | "baseline"
    | "stretch"
    | "between"
    | "around"
    | "space-between"
    | "space-around";
  alignContent?: "baseline";
  gap?: string;
}

const convertJustify = (justify?: FlexProps["justify"]) => {
  return (
    (justify === "evenly" && "space-evenly") ||
    (justify === "around" && "space-around") ||
    (justify === "between" && "space-between") ||
    justify
  );
};

const convertAlign = (align?: FlexProps["align"]) => {
  return (
    (align === "top" && "start") ||
    (align === "bottom" && "end") ||
    (align === "around" && "space-around") ||
    (align === "between" && "space-between") ||
    align
  );
};

export const Flex = styled(Box)<FlexProps>`
  display: ${({ display }) => display || "flex"};
  flex-direction: ${({ column, direction }) =>
    (column && "column") || direction};
  flex-wrap: ${({ flexWrap }) => flexWrap};
  justify-content: ${({ column, direction, justify, align }) =>
    !column && direction !== "column"
      ? convertJustify(justify)
      : convertAlign(align)};
  align-items: ${({ column, direction, justify, align }) =>
    !column && direction !== "column"
      ? convertAlign(align)
      : convertJustify(justify)};
  align-content: ${({ alignContent }) => alignContent};
  gap: ${({ gap }) => gap};
`;

interface GridProps {
  rows?: string;
  columns?: string;
  areas?: string;
  gap?: string;
  justify?: FlexProps["justify"];
  align?: FlexProps["align"];
  alignContent?: FlexProps["alignContent"];
}

export const Grid = styled(Box)<GridProps>`
  display: grid;
  grid-template-rows: ${({ rows }) => rows};
  grid-template-columns: ${({ columns }) => columns};
  grid-template-areas: ${({ areas }) => areas};
  gap: ${({ gap }) => gap};
  justify-content: ${({ justify }) => convertJustify(justify)};
  align-items: ${({ align }) => convertAlign(align)};
  align-content: ${({ alignContent }) => alignContent};
`;

export const SubGrid = styled(Grid)`
  grid-template-columns: subgrid;
`;
