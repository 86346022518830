import styled from "styled-components";
import { SubgridColumn, SubgridSortedBy } from "../store/types";
import { SubgridHeader } from "./SubgridHeader";
import { Grid } from "src/elements";

const Head = styled(Grid)`
  grid-column: 1 / -1;
  grid-template-columns: subgrid;
  position: sticky;
  top: 0;
  z-index: 1;
`;

interface Props<T> {
  columns: SubgridColumn<T>[];
  isSortable: boolean;
  sortedBy: SubgridSortedBy;
  setSortedBy: (sortedBy: SubgridSortedBy) => void;
}

export const SubgridHead = <T extends any>({
  columns,
  isSortable,
  sortedBy,
  setSortedBy,
}: Props<T>) => (
  <Head>
    {columns.map((column, i) => (
      <SubgridHeader
        key={i}
        column={column}
        isSortable={isSortable}
        sortedBy={sortedBy}
        setSortedBy={setSortedBy}
        isLeftBorder={i === 0}
        isTopBorder
      />
    ))}
  </Head>
);
