import { PlanogramItem } from "@CommonTypes/merchflow/pog/pog";
import React from "react";
import { clickDuration } from "src/components/Planogram/store/constants";
import { MouseButton } from "src/types/mouseButtons";

import {
  DraggableItem,
  DraggableItemOriginal,
  DraggableItemWithDrop,
} from "./types";
import { PlanogramSidepanelTab } from "src/components/PlanogramExtensions/store/types";

// Object to keep information abour original parent or previous element to insert it back into correct position before state changes.
let draggableOriginalHolder: HTMLElement | null = null;

// Object to keep original draggable dom, cursor and element positions.
let draggableOriginal: DraggableItemOriginal | null = null;

// Object to hold dummy placeholder element that is render in place where the item will be dropped.
let draggablePlaceholder: DraggableItem | null;

// Object to hold dom element that is dragging and also dropper function to call when dragging stops.
let draggable: DraggableItemWithDrop | null = null;

// Timeout to be able to stop dragging before it starts (to register as lick to edit item).
let timeoutToCreateDraggable: NodeJS.Timeout | null = null;

let editItemCallback: (() => void) | null = null;

let dragging = false;

export const onDragStart = ({
  event,
  refItemContainer,
  refItem,
  item,
  setSidepanelTab: setPlanogramSidepanelTab,
  editItem,
}: {
  event: React.MouseEvent<HTMLDivElement>;
  refItemContainer: React.RefObject<HTMLDivElement>;
  refItem: React.RefObject<HTMLDivElement>;
  item: PlanogramItem;
  setSidepanelTab: (panelTabType: PlanogramSidepanelTab) => void;
  editItem: () => void;
}) => {
  if (
    event.button !== MouseButton.PRIMARY ||
    !refItemContainer.current ||
    !refItem.current ||
    Boolean(editItemCallback) ||
    Boolean(draggableOriginal) ||
    Boolean(draggablePlaceholder) ||
    Boolean(draggableOriginalHolder) ||
    dragging
  ) {
    return;
  }

  editItemCallback = editItem;

  // Positions need to be calculated from actual item (because dropper is taking extra space while rendering on shelf).
  const { left: positionXLeft, top: positionYTop } =
    refItem.current.getBoundingClientRect();

  const { clientX: cursorXLeft, clientY: cursorYTop } = event;

  // Create draggable with a delay to make it able to click items.
  timeoutToCreateDraggable = setTimeout(() => {
    if (refItemContainer.current) {
      draggableOriginal = {
        domContainer: refItemContainer.current,
        dom: refItemContainer.current,
        item,
        cursorXLeft,
        cursorYTop,
        positionXLeft,
        positionYTop,
      };

      createDraggable(draggableOriginal);
      createDragMoveEventListener();
      createDragStopEventListener();
      setPlanogramSidepanelTab(PlanogramSidepanelTab.FLOATING_SHELF);
    }

    timeoutToCreateDraggable = null;
  }, clickDuration);

  // Create drag stop to prevent dragging if needed.
  createDragStopEventListener();
};

export const onUnrangedItemDragStart = ({
  event,
  refUnrangedItem,
  refItemContainer,
  item,
}: {
  event: React.MouseEvent<HTMLDivElement>;
  refUnrangedItem: React.RefObject<HTMLDivElement>;
  refItemContainer: React.RefObject<HTMLDivElement>;
  item: PlanogramItem;
}) => {
  if (
    event.button !== MouseButton.PRIMARY ||
    !refItemContainer.current ||
    !refUnrangedItem.current
  ) {
    return;
  }

  const { clientX: cursorXLeft, clientY: cursorYTop } = event;

  // Find dimensions from invisible item element.
  const { width, height } = refItemContainer.current.getBoundingClientRect();

  draggableOriginal = {
    domContainer: refUnrangedItem.current,
    dom: refItemContainer.current,
    item,
    cursorXLeft,
    cursorYTop,
    // Start showing draggable item at center of cursor.
    positionXLeft: cursorXLeft - width / 2,
    positionYTop: cursorYTop - height / 2,
  };

  createDraggable(draggableOriginal);
  createDragMoveEventListener();
  createDragStopEventListener();
};

const createDraggable = (draggableOriginal: DraggableItemOriginal) => {
  dragging = true;

  // Save original holders to make sure we can insert original back into DOM later.
  draggableOriginalHolder = draggableOriginal.domContainer
    .previousSibling as HTMLElement;

  const { positionXLeft: draggableXLeft, positionYTop: draggableyTop } =
    draggableOriginal;

  // Create placeholder and render on screen in place of original item.
  const domPlaceholder = draggableOriginal.dom.cloneNode(
    true,
  ) as HTMLDivElement;
  draggablePlaceholder = {
    dom: domPlaceholder,
    item: draggableOriginal.item,
  };
  domPlaceholder.setAttribute("data-placeholder", "");
  draggableOriginal.domContainer.after(domPlaceholder);

  // Create draggable and render on screen in exact place of the original item.
  const domDraggable = draggableOriginal.dom.cloneNode(true) as HTMLDivElement;
  draggable = {
    dom: domDraggable,
    item: draggableOriginal.item,
  };
  domDraggable.setAttribute("data-draggable", "");
  domDraggable.setAttribute(
    "style",
    `--xLeft: ${draggableXLeft}px; --yTop: ${draggableyTop}px`,
  );
  document.body.appendChild(draggable.dom);

  // Remove original element from DOM to make sure items on shelf are rendered with correct spacings.
  draggableOriginal.domContainer.remove();

  document.body.setAttribute("data-dnd", "");
};

// Create event listener on page to be able to drag item around.
const createDragMoveEventListener = () => {
  document.addEventListener("pointermove", onDragMove);
};

const onDragMove = (event: MouseEvent) => {
  if (!draggableOriginal || !draggable) {
    return;
  }

  const { clientX: cursorXLeft, clientY: cursorYTop } = event;

  const differenceCursorXLeft = draggableOriginal.cursorXLeft - cursorXLeft;
  const differenceCursorYTop = draggableOriginal.cursorYTop - cursorYTop;

  const positionXLeftNew =
    draggableOriginal.positionXLeft - differenceCursorXLeft;
  const positionYTopNew = draggableOriginal.positionYTop - differenceCursorYTop;

  draggable.dom.setAttribute(
    "style",
    `--xLeft: ${Math.round(positionXLeftNew)}px; --yTop: ${Math.round(
      positionYTopNew,
    )}px`,
  );
};

// Create event listener on page that only fires once to finish item dragging.
const createDragStopEventListener = () => {
  document.addEventListener("pointerup", onDragStop, { once: true });
};

const onDragStop = () => {
  // Prevent next item dragging immediately.
  setTimeout(() => {
    dragging = false;
  }, 50);

  if (timeoutToCreateDraggable) {
    if (editItemCallback) {
      editItemCallback();
    }
    clearTimeout(timeoutToCreateDraggable);
  }

  // Cleanup page.
  document.removeEventListener("pointermove", onDragMove);
  document.body.removeAttribute("data-dnd");

  // Remove dragged and placeholder items, insert original item back into original location.
  // This is required for react to render items properly on next render cycle.
  draggable?.dom.remove();
  draggablePlaceholder?.dom.remove();

  if (draggableOriginal?.domContainer) {
    draggableOriginalHolder?.after(draggableOriginal.domContainer);
  }

  // Activate onDrop function that actually modifies the state to trigger re-render.
  if (draggable?.onDrop && draggableOriginal?.item) {
    draggable.onDrop(draggableOriginal.item);
  }

  // Cleanup all dragging related objects.
  draggable = null;
  draggablePlaceholder = null;
  draggableOriginal = null;
  draggableOriginalHolder = null;
  editItemCallback = null;
};

// Move placeholder around based on the item you are dragging over.
// Save dropper function to be called when dragging stops.
export const onDropOverToItem = ({
  refItemContainer,
  side,
  onDrop,
}: {
  refItemContainer: React.RefObject<HTMLDivElement>;
  side: "left" | "right";
  onDrop: (item: PlanogramItem) => void;
}) => {
  if (
    !draggable ||
    !draggableOriginal ||
    !draggablePlaceholder ||
    !refItemContainer.current
  ) {
    return;
  }

  draggable.onDrop = onDrop;

  if (side === "left") {
    refItemContainer.current.before(draggablePlaceholder.dom);
  } else if (side === "right") {
    refItemContainer.current.after(draggablePlaceholder.dom);
  }
};

// Move placeholder around based on the shelf you are dragging over.
// Save dropper function to be called when dragging stops.
export const onDropOverToShelf = ({
  refItems,
  onDrop,
}: {
  refItems: React.RefObject<HTMLDivElement>;
  onDrop: (item: PlanogramItem) => void;
}) => {
  if (
    !draggable ||
    !draggableOriginal ||
    !draggablePlaceholder ||
    !refItems.current
  ) {
    return;
  }

  draggable.onDrop = onDrop;
  refItems.current.append(draggablePlaceholder.dom);
};

// Move placeholder around over to infront of other unranged items.
// Save dropper function to be called when dragging stops.
export const onDropOverToUnrangedItems = ({
  refUnrangedItems,
  onDrop,
}: {
  refUnrangedItems: React.RefObject<HTMLDivElement>;
  onDrop: (item: PlanogramItem) => void;
}) => {
  if (
    !draggable ||
    !draggableOriginal ||
    !draggablePlaceholder ||
    !refUnrangedItems.current
  ) {
    return;
  }

  draggable.onDrop = onDrop;

  refUnrangedItems.current.insertBefore(
    draggablePlaceholder.dom,
    refUnrangedItems.current.firstElementChild,
  );
};
