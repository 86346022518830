import { PlanogramItem } from "@CommonTypes/merchflow/pog/pog";
import {
  getPlanogramItemMOSPercentage,
  getPlanogramItemDaysOfSupplyPercentage,
  getPlanogramItemCapacity,
} from "@CommonUtils/pog/item";
import { useMemo } from "react";
import { Color, Text, VerticalDivider } from "src/elements";

interface Props {
  item: PlanogramItem;
}

export const ItemDetails = ({ item }: Props) => {
  const [dos, mos, capacity] = useMemo(() => {
    let dos = 0;
    let mos = 0;
    let capacity = 0;

    if (item && item.shelf) {
      for (const bay of item.shelf.bay.planogram.bays) {
        for (const shelf of bay.shelves) {
          for (const _item of shelf.items) {
            if (_item.productCode === item.productCode) {
              mos += getPlanogramItemMOSPercentage(_item);
              dos += getPlanogramItemDaysOfSupplyPercentage(_item);
              capacity += getPlanogramItemCapacity(_item);
            }
          }
        }
      }
    }

    return [Math.floor(dos), Math.floor(mos), Math.floor(capacity)];
  }, [item]);

  return (
    <>
      <Text whiteSpace="nowrap" textOverflow="ellipsis">
        {item.productCode} - {item.name}
      </Text>

      {item.shelf && (
        <>
          <VerticalDivider />

          <Text
            whiteSpace="nowrap"
            color={mos >= 100 ? Color.primary : Color.red}
          >
            MOS: {mos}%
          </Text>
        </>
      )}

      {item.shelf && (
        <>
          <VerticalDivider />

          <Text
            whiteSpace="nowrap"
            color={dos >= 100 ? Color.primary : Color.red}
          >
            DOS: {dos}%
          </Text>
        </>
      )}

      <>
        <VerticalDivider />

        <Text whiteSpace="nowrap">Movement: {item.quantity.toFixed(2)}</Text>
      </>

      <>
        <VerticalDivider />

        <Text whiteSpace="nowrap">Sales: {item.salesAmount.toFixed(2)}</Text>
      </>

      <>
        <VerticalDivider />

        <Text whiteSpace="nowrap">
          Profit: {item.profit !== null ? item.profit.toFixed(2) : "-"}
        </Text>
      </>

      {item.shelf && (
        <>
          <VerticalDivider />

          <Text whiteSpace="nowrap">Capacity: {capacity}</Text>
        </>
      )}
    </>
  );
};
