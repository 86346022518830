import { ResponsePostMerchflows } from "@CommonApi/merchflows";
import { ResponsePutMerchflows_merchflowId_Filters } from "@CommonApi/merchflows/_reviewId_/filters";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { useModals } from "src/components/Modals";
import { useResetMerchFlowPage } from "src/hooks/useResetMerchFlowPage";
import { useNewFlowReview } from "src/modals/NewFlowReview/store/api";
import {
  detailsAtom,
  filtersAppliedAtom,
  mergeRulesAtom,
} from "src/modals/NewFlowReview/store/atoms";
import { useMerchandiseApi } from "src/pages/merchandise/store/api";
import {
  ffDataRequestsAtom,
  pmDataRequestsAtom,
  posDataRequestsAtom,
} from "src/pages/merchandise/store/atoms";
import { uuid } from "src/utils";

export const useMerchflowReviewModal = () => {
  const { openModal } = useModals();
  const { resetMerchflow } = useResetMerchFlowPage();

  const openMerchflowReviewModal = () => {
    resetMerchflow();
    openModal("ModalMerchflowReview");
  };

  return { openMerchflowReviewModal };
};

// TODO - REMOVE WITH OMF-672
export const useForwardToOldBaseGeneration = () => {
  const { prepareAndCallStep0 } = useMerchandiseApi();
  const { loadMergeRulesApi } = useNewFlowReview();

  const [details, setDetails] = useAtom(detailsAtom);
  const [filtersApplied, setFiltersApplied] = useAtom(filtersAppliedAtom);
  const [requestsFf] = useAtom(ffDataRequestsAtom);
  const [requestsPm] = useAtom(pmDataRequestsAtom);
  const [requestsPos] = useAtom(posDataRequestsAtom);
  const [mergeRules, setMergeRules] = useAtom(mergeRulesAtom);

  const [detailsFiltersApplied, setDetailsFiltersApplied] = useState<
    string | null
  >(null);

  useEffect(() => {
    if (detailsFiltersApplied) {
      loadMergeRulesApi();
    }
  }, [detailsFiltersApplied]);

  useEffect(() => {
    if (
      details &&
      filtersApplied &&
      requestsFf &&
      requestsPm &&
      requestsPos &&
      mergeRules
    ) {
      prepareAndCallStep0();
    }
  }, [
    details,
    filtersApplied,
    requestsFf,
    requestsPm,
    requestsPos,
    mergeRules,
  ]);

  const forwardToOldBaseGeneration = async ({
    additionalDetails,
    filtersApplied,
    mergeRules,
  }: {
    additionalDetails: ResponsePostMerchflows;
    filtersApplied: ResponsePutMerchflows_merchflowId_Filters;
    mergeRules: string[][];
  }) => {
    setDetails({
      ...(additionalDetails as any),
      latest_filter_config: [filtersApplied as any],
    });
    setFiltersApplied(filtersApplied as any);
    setMergeRules(mergeRules.map((variants) => ({ variants })));
    setDetailsFiltersApplied(uuid());
  };

  return { forwardToOldBaseGeneration };
};
