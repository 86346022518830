import { useSetAtom } from "jotai";
import { useModals } from "src/components/Modals";
import { categoriesModalStoreAtom } from "./atoms";
import { ResponseGetStoresList } from "@CommonApi/stores/list";

export const useStoreCategoriesModal = () => {
  const { openModal } = useModals();
  const setCategoriesData = useSetAtom(categoriesModalStoreAtom);

  const openStoreCategoriesModal = (
    store: ResponseGetStoresList["data"][0],
  ) => {
    setCategoriesData(store);
    openModal("StoreCategoriesModal");
  };

  return { openStoreCategoriesModal };
};
