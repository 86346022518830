import React from "react";
import styled from "styled-components";

import { Flex } from "../Box/Box";
import { Skeleton } from "../Skeleton/Skeleton";
import { TextVariant } from "./store/types";
import { textVariants } from "./store/variants";
import { Color } from "../Color/Color";

const TextElement = styled.span<
  { variant: TextVariant } & Pick<
    TextProps,
    | "bold"
    | "textAlign"
    | "textOverflow"
    | "textTransform"
    | "wordBreak"
    | "whiteSpace"
    | "lineClamp"
  >
>`
  display: inline;
  font-size: ${({ variant }) => textVariants[variant].fontSize};
  font-weight: ${({ bold, variant }) =>
    bold ? "bold" : textVariants[variant].fontWeight};
  line-height: ${({ variant }) => textVariants[variant].lineHeight};
  color: ${({ color, variant }) =>
    color || textVariants[variant].color || Color.textMain};
  text-align: ${({ textAlign }) => textAlign};
  text-transform: ${({ textTransform, variant }) =>
    textTransform || textVariants[variant].textTransform};
  text-overflow: ${({ textOverflow }) => textOverflow};
  overflow: ${({ textOverflow }) => textOverflow && "hidden"};
  letter-spacing: ${({ variant }) => textVariants[variant].letterSpacing};
  white-space: ${({ whiteSpace }) => whiteSpace};
  word-break: ${({ wordBreak }) => wordBreak};

  ${({ lineClamp }) =>
    lineClamp !== undefined &&
    `
      display: -webkit-box;
      -webkit-line-clamp: ${lineClamp};
      -webkit-box-orient: vertical;
      overflow: hidden;
    `}
`;

export interface TextProps {
  variant?: TextVariant;
  color?: string;
  bold?: boolean;
  textAlign?: "left" | "center" | "right";
  whiteSpace?: "nowrap" | "wrap";
  textOverflow?: "clip" | "ellipsis";
  textTransform?: "capitalize" | "lowercase" | "uppercase";
  wordBreak?: "break-all" | "break-word";
  lineClamp?: number;
  isLoading?: boolean;
  className?: string;
  children: React.ReactNode;
}

export const Text = ({
  variant = "body3",
  color,
  bold,
  textAlign,
  textOverflow,
  textTransform,
  whiteSpace,
  wordBreak,
  lineClamp,
  className,
  isLoading,
  children,
}: TextProps) => {
  if (isLoading) {
    return (
      <Flex height={textVariants[variant].lineHeight} align="center">
        <Skeleton minWidth="10px" height={textVariants[variant].lineHeight} />
      </Flex>
    );
  }

  return (
    <TextElement
      className={className}
      data-variant={variant}
      variant={variant}
      color={color}
      bold={bold}
      textAlign={textAlign}
      textOverflow={textOverflow}
      textTransform={textTransform}
      whiteSpace={whiteSpace}
      wordBreak={wordBreak}
      lineClamp={lineClamp}
    >
      {children}
    </TextElement>
  );
};
