import { Page } from "src/components";
import { MerchflowHeader } from "../../components/merchflows/MerchflowHeader/MerchflowHeader";
import { useParams } from "react-router-dom";
import { MerchflowStepsNavigator } from "../../components/merchflows/MerchflowStepsNavigator/MerchflowStepsNavigator";
import { useAtom } from "jotai";
import { useEffect } from "react";

import {
  Color,
  Flex,
  Grid,
  HorizontalDivider,
  Pagination,
  VerticalDivider,
} from "src/elements";
import { merchflows_merchflowId_S2Templates_templateCode_Atom } from "./store/atoms";
import styled from "styled-components";
import { OneQuadrantView } from "./components/OneQuadrantView";
import { useMerchflowS2Api } from "src/api/merchflows/s2";
import { useInlineLoaders } from "src/components/InlineLoader";
import { usePaginationPage } from "src/elements/Pagination/store/utils";
import { PlanogramHovererDetails } from "src/components/PlanogramExtensions/PlanogramHovererDetails/PlanogramHovererDetails";

const QuadGrid = styled(Grid)`
  width: 100%;
  height: 100%;
  grid-template-areas:
    "top-left ver-divider1 top-right"
    "hor-divider hor-divider hor-divider"
    "bottom-left ver-divider2 bottom-right";
  grid-template-columns: calc(50% - 1px) auto calc(50% - 1px);
  grid-template-rows: 1fr auto 1fr;
  border: 1px solid ${Color.greenSmoke};
  border-radius: 3px;
`;

export const PageMerchflows_merchflowId_S2Templates_templateId_ = () => {
  const { merchflowId: _merchflowId, templateId: _templateId } = useParams<{
    merchflowId: string;
    templateId: string;
  }>();
  const merchflowId = Number(_merchflowId);
  const templateId = _templateId!;

  const {
    getMerchflowS2SolutionsByTemplateIdApi:
      getMerchflowS2SolutionsByTemplateCodeApi,
  } = useMerchflowS2Api();
  const { isInlineLoading } = useInlineLoaders();
  const [page, setPage] = usePaginationPage();

  const [data, setData] = useAtom(
    merchflows_merchflowId_S2Templates_templateCode_Atom,
  );

  const isDataLoading =
    data === undefined ||
    isInlineLoading("GET_merchflows/:merchflowId/s2/templates/:templateId");

  // Load S2 solutions.
  useEffect(() => {
    getMerchflowS2SolutionsByTemplateCodeApi({
      merchflowId,
      templateId,
      setData,
    });

    // Clear S2 solutions.
    return () => {
      setData(undefined);
    };
  }, []);

  const getPaginatedIndex = (index: number) => index + (page - 1) * 4;

  return (
    <Page>
      <MerchflowHeader merchflowId={merchflowId} templateId={templateId} />

      <MerchflowStepsNavigator activeStep="S2" merchflowId={merchflowId} />

      <Flex justify="right">
        {/* <Select
          width="200px"
          value={templateCode}
          setValue={setTemplateCode}
          placeholder="Template"
          title="Template"
          options={
            data
              ? data[getPaginatedIndex(0)].templates_codes.map(
                  (templateCode) => ({
                    value: templateCode,
                    label: templateCode,
                  }),
                )
              : [{ value: templateCode, label: templateCode }]
          }
          isLoading={isDataLoading}
        /> */}
      </Flex>

      <Flex minHeight="0" grow>
        <QuadGrid>
          <OneQuadrantView
            merchflowId={merchflowId}
            s2Solution={
              data === null
                ? null
                : data
                  ? data.data[getPaginatedIndex(0)]
                  : undefined
            }
            isLoading={isDataLoading}
          />

          <VerticalDivider size={2} color={Color.greenSmoke} />

          <OneQuadrantView
            merchflowId={merchflowId}
            s2Solution={
              data === null
                ? null
                : data
                  ? data.data[getPaginatedIndex(1)]
                  : undefined
            }
            isLoading={isDataLoading}
          />

          <Flex gridArea="hor-divider">
            <HorizontalDivider size={2} color={Color.greenSmoke} />
          </Flex>

          <OneQuadrantView
            merchflowId={merchflowId}
            s2Solution={
              data === null
                ? null
                : data
                  ? data.data[getPaginatedIndex(2)]
                  : undefined
            }
            isLoading={isDataLoading}
          />

          <VerticalDivider size={2} color={Color.greenSmoke} />

          <OneQuadrantView
            merchflowId={merchflowId}
            s2Solution={
              data === null
                ? null
                : data
                  ? data.data[getPaginatedIndex(3)]
                  : undefined
            }
            isLoading={isDataLoading}
          />
        </QuadGrid>
      </Flex>

      <PlanogramHovererDetails />

      <Pagination
        page={page}
        totalPages={data ? Math.ceil(data.data.length / 4) : 1}
        setPage={setPage}
        isUsingInput
        isLoading={isDataLoading}
      />
    </Page>
  );
};
