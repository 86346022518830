import { Dispatch, SetStateAction } from "react";
import { Checkbox, Flex, Link, Text } from "src/elements";
import { SubgridColumn } from "src/elements/SubgridTable/store/types";
import { pages } from "src/utils";
import { routeToUrl } from "src/utils/axios";
import { getCurrentUrl } from "src/utils/history";

export const getStoresAllTableColumns = ({
  merchflowId,
  setStoresAllHistory,
  selection,
  setSelection,
  isAllSelected,
  setIsAllSelected,
}: {
  merchflowId: number;
  setStoresAllHistory: Dispatch<
    SetStateAction<{
      [merchflowId: number]: string;
    }>
  >;
  selection: number[];
  setSelection: (selection: number[]) => void;
  isAllSelected: boolean;
  setIsAllSelected: (isAllSelected: boolean) => void;
}) => {
  return [
    {
      id: "selection",
      header: "selection",
      justify: "center",
      headerRenderer: (
        <Checkbox
          width="min-content"
          isChecked={isAllSelected}
          setIsChecked={setIsAllSelected}
        />
      ),
      renderer: ({ row: { planogramId } }) => (
        <Checkbox
          width="min-content"
          isChecked={
            planogramId !== undefined
              ? isAllSelected
                ? selection.includes(planogramId)
                  ? false
                  : true
                : selection.includes(planogramId)
              : false
          }
          setIsChecked={() => {
            if (planogramId !== undefined) {
              if (selection.includes(planogramId)) {
                setSelection(selection.filter((s) => s !== planogramId));
              } else {
                setSelection([...selection, planogramId]);
              }
            }
          }}
          isDisabled={planogramId === undefined}
        />
      ),
    },
    {
      id: "storeCode",
      header: "Store Code",
      renderer: ({ value }) => (
        <Link
          to={`${routeToUrl(pages.merchflows_merchflowId_Stores_storeCode_, {
            merchflowId,
            storeCode: value,
          })}`}
          onClick={() => {
            setStoresAllHistory((history) => ({
              ...history,
              [merchflowId]: getCurrentUrl(),
            }));
          }}
        >
          <Text variant="small2">{value}</Text>
        </Link>
      ),
    },
    {
      id: "storeStatus",
      header: "Store Status",
      justify: "center",
    },
    {
      id: "template",
      header: "Template",
      subColumns: [{ id: "templateId", header: "ID", justify: "center" }],
    },
    {
      id: "planogram",
      header: "Latest Planogram",
      subColumns: [
        {
          id: "planogramId",
          header: "ID",
          justify: "center",
        },
        {
          id: "planogramApproved",
          header: "Approved",
          justify: "center",
        },
      ],
    },
    {
      id: "returnOnSpace",
      header: "Return on Space",
      width: "120px",
      justify: "center",
    },
    {
      id: "coreRange",
      header: "Core Range",
      width: "120px",
      justify: "center",
    },
    {
      id: "shelfAlignment",
      header: "Shelf Alignment",
      width: "120px",
      justify: "center",
    },
    {
      id: "dosMos",
      header: "DOS/MOS",
      width: "120px",
      justify: "center",
    },
    { id: "size", header: "Size", justify: "center" },
  ] satisfies SubgridColumn<{ planogramId?: number }>[];
};
