import { Pog } from "@CommonTypes/merchflow/pog/pog";
import { Color, Flex, ProgressBar, Text } from "src/elements";

type Props = {
  title?: string;
  metrics?: Pog["metrics"];
};

export const Metrics = ({ title, metrics }: Props) => {
  return (
    <Flex
      padding="5px"
      width="200px"
      direction="column"
      justify="between"
      gap="10px"
    >
      {title && (
        <Text variant="h5" color={Color.primary}>
          {title}
        </Text>
      )}

      <Flex direction="column" gap="3px">
        <Text variant="caption1">Return on Space:</Text>
        <ProgressBar percent={metrics?.returnOnSpace} height="28px" isInline />
      </Flex>

      <Flex direction="column" gap="3px">
        <Text variant="caption1">Core Range:</Text>
        <ProgressBar percent={metrics?.coreRange} height="28px" isInline />
      </Flex>

      <Flex direction="column" gap="3px">
        <Text variant="caption1">Shelf Alignment:</Text>
        <ProgressBar percent={metrics?.shelfAlignment} height="28px" isInline />
      </Flex>

      <Flex direction="column" gap="3px">
        <Text variant="caption1">DOS / MOS:</Text>
        <ProgressBar percent={metrics?.dosMos} height="28px" isInline />
      </Flex>
    </Flex>
  );
};
