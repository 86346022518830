import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useEffect, useMemo } from "react";
import { useInlineLoaders } from "src/components/InlineLoader";
import { useLoaders } from "src/components/Loaders";
import { Color, Select, Text } from "src/elements";
import {
  useBasePogApi,
  usePlaceVariantFilters,
  useResetMerchFlowPage,
} from "src/hooks";
import { adapterPogNonCircularToPog } from "src/omni-common/adapters/pog";
import { omniAtom } from "src/utils/atoms";

import { AllBasePogResponse } from "@CommonApi/merchandise";
import { useSearchParams } from "react-router-dom";
import { useToasts } from "src/components/Toasts";
import { useMerchandiseApi } from "../../store/api";
import {
  filterConfigBasePogsAtom,
  isViewDetailAtom,
  pageAtom,
  poggerResultAtom,
  poggerizeResultAtom,
  savedPogAtomAtom,
  selectedTemplateIdAtom,
  viewDetailIndex,
} from "../../store/atoms";

export const BasePogVersion = () => {
  const { loadAllBasePog } = useBasePogApi();
  const { isInlineLoading } = useInlineLoaders();
  const allBasePog = useAtomValue(filterConfigBasePogsAtom);
  const [poggerizeResult, setPoggerizeResult] = useAtom(poggerizeResultAtom);
  const { allStep2 } = usePlaceVariantFilters();
  const { resetStorePropData } = useResetMerchFlowPage();
  const { callGetStoreLevelPog } = useMerchandiseApi();
  const setPoggerResult = useSetAtom(poggerResultAtom);
  const setSavedPogAtom = useSetAtom(savedPogAtomAtom);
  const setSelectedTemplate = useSetAtom(selectedTemplateIdAtom);
  const setIsDetailView = useSetAtom(isViewDetailAtom);
  const setSolutionIndex = useSetAtom(viewDetailIndex);
  const setPage = useSetAtom(pageAtom);
  const { addLoader, removeLoader } = useLoaders();
  const [searchParams] = useSearchParams();
  const { toast } = useToasts();

  const findAndSetS2AndPogData = (
    basePogId: number,
    newBasePogList: AllBasePogResponse[],
  ) => {
    const selected = newBasePogList.find((b) => b.id === basePogId);

    if (!selected) return { error: "Cant find base pog data" };

    const selectedSolution = allStep2.find(
      (s2) => s2.solution_id === selected.solution_id,
    );

    if (!selectedSolution)
      return { error: `Cant find step 2 solution of base pog ${basePogId}` };

    const s2Index = allStep2
      .filter((s2) => s2.templateId === selectedSolution.templateId)
      .findIndex((s2) => s2.solution_id === selected.solution_id);

    if (s2Index === -1)
      return { error: `Cant find step 2 solution of base pog ${basePogId}` };

    setSelectedTemplate(selectedSolution.templateId);
    setIsDetailView(true);
    setSolutionIndex(s2Index);
    setPage(s2Index + 1);

    const { pog_data, pogger_response_path, poggerize_response_path } =
      selected;

    setSavedPogAtom(
      omniAtom({
        key: `basepog-${basePogId}`,
        value: adapterPogNonCircularToPog(pog_data),
      }),
    );

    setPoggerizeResult({
      ...poggerize_response_path,
      base_pog_id: basePogId,
    });

    setPoggerResult(pogger_response_path.data);
    return { error: null };
  };

  const onChangeVersion = async (basePogId: number) => {
    if (basePogId === poggerizeResult?.base_pog_id) return;

    const { error } = findAndSetS2AndPogData(basePogId, allBasePog);

    if (error) {
      toast({ title: error });
      return;
    }

    resetStorePropData();
    addLoader("loadStorePropForNewBasePog");
    await callGetStoreLevelPog(0, basePogId);
    removeLoader("loadStorePropForNewBasePog");
  };

  useEffect(() => {
    loadAllBasePog().then((newBasePogList) => {
      const base_pog_id = searchParams.get("base_pog_id");

      if (base_pog_id) {
        findAndSetS2AndPogData(parseInt(base_pog_id, 10), newBasePogList);
      } else {
        findAndSetS2AndPogData(
          poggerizeResult ? poggerizeResult.base_pog_id : allBasePog[0].id,
          newBasePogList,
        );
      }
    });
  }, []);

  const options = useMemo(() => {
    const validBasePog = allBasePog.filter(
      (d) =>
        Boolean(d.pog_data) &&
        Boolean(d.pogger_response_path) &&
        !JSON.stringify(d.pogger_response_path.data)
          .toLowerCase()
          .includes("error"),
    );

    return validBasePog.map((b) => ({
      label: `${(b as any).template_id} - ${b.id}`,
      value: b.id,
    }));
  }, [allBasePog]);

  return (
    <>
      <Text variant="h6" color={Color.primary}>
        Planogram Version:
      </Text>
      <Select
        options={options}
        isLoading={isInlineLoading("loadAllBasePogVersion")}
        placeholder="version"
        setValue={(v) => onChangeVersion(v)}
        value={poggerizeResult?.base_pog_id || 0}
      />
    </>
  );
};
