import { PoggerResponse } from "../../../types/algo/pogger";

export const UrlMerchFlowPoggerPoggerResponse = "/pogger/pogger_response";

export interface ParamsMerchFlowPoggerPoggerResponse {
  base_pog_id: number;
}

export interface ResponseMerchFlowPoggerPoggerResponse {
  merchflowBuildSyncContext: {
    owaMerchantId: number;
    filterConfigId: number;
    templateId: string;
    reqUrl: string;
    basePogId: number;
  };
  spanId: string;
  authToken: string;
  lambdaName: string;
  lambdaResource: string;
  azureContainer: string;
  requestPath: string;
  responsePath: string;
  lambdaVersion: string;
  status: string;
  data: PoggerResponse;
}
