import React, { useRef } from "react";
import styled from "styled-components";

import { Flex } from "../Box/Box";
import { Color } from "../Color/Color";
import { Icon } from "../Icon/Icon";
import { useHoverClickable } from "src/utils/hoverClickable";

const SelectionSelectable = styled.div<{
  isSelected: boolean;
  isHovered: boolean;
}>`
  position: absolute;
  top: -10px;
  right: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ isSelected, isHovered }) =>
    (isSelected && Color.primary) ||
    (isHovered && Color.greenSmoke) ||
    Color.white};
  width: 24px;
  height: 24px;
  border: 1px solid ${Color.primary};
  border-radius: 50%;
  cursor: pointer;
  user-select: none;
  z-index: 1;

  &:hover {
    background-color: ${({ isSelected }) =>
      (isSelected && Color.primary) || Color.greenSmoke};
  }
`;

const SelectionElement = styled(Flex)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

interface Props<T> {
  id: T;
  isAllSelected: boolean;
  selection: T[];
  setSelection: (selection: T[]) => void;
}

export const Selection = <T extends number | string>({
  id,
  isAllSelected,
  selection,
  setSelection,
}: Props<T>) => {
  const isSelected = isAllSelected
    ? selection.includes(id)
      ? false
      : true
    : selection.includes(id);

  const refContainer = useRef<HTMLDivElement>(null);
  const refSelect = useRef<HTMLDivElement>(null);

  const { isHovered: isContainerHovered } = useHoverClickable(refContainer);
  const { isHovered: isSelectHovered } = useHoverClickable(refSelect);

  if (id === undefined) return null;

  const toggleSelect = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (selection.includes(id)) {
      setSelection(selection.filter((s) => s !== id));
    } else {
      setSelection([...selection, id]);
    }
  };

  return (
    <SelectionElement ref={refContainer}>
      {(isContainerHovered || isSelected) && (
        <SelectionSelectable
          ref={refSelect}
          isHovered={isSelectHovered}
          isSelected={isSelected}
          onClick={toggleSelect}
        >
          <Icon
            name="checkmark"
            size={18}
            color={isSelected ? Color.white : Color.primary}
          />
        </SelectionSelectable>
      )}
    </SelectionElement>
  );
};
