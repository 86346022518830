import { atom as jotaiAtom } from "jotai";

export const omniAtom = <T>({
  prefix,
  key,
  value,
}: {
  prefix?: string;
  key: string;
  value: T;
}) => {
  const atom = jotaiAtom(value);
  atom.debugLabel = (prefix ? prefix + "/" : "") + key;
  return atom;
};
