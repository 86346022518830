import { Container } from "src/components/Planogram/store/types";
import {
  FreezerS1InputContainer,
  FreezerWithItems,
} from "src/omni-common/types/algo/freezer";
import { ProductMaster } from "src/omni-common/types/backend/productMaster";
import { colorObj } from "src/pages/merchandise/store/atoms";
import { objectValues } from "src/pages/merchandise/store/utils";
import { uuid } from "src/utils";

export const algoStep1ConvertFreezerWithItemsToContainers = (
  freezer: FreezerWithItems,
) => {
  const containers: Container[] = [];

  freezer.sections.forEach((section) =>
    section.items.forEach((item) => {
      const variants = item.item_id.includes(",")
        ? item.item_id.split(", ")
        : [item.item_id];

      containers.push({
        uniqueId: uuid(),
        label: item.item_id,
        //@ts-ignore
        color: colorObj[variants[0]],
        xLeft: item.start_x,
        xRight: item.start_x + item.width,
        yBottom: item.start_y,
        yTop: item.start_y + item.height,
      });
    }),
  );

  return containers;
};

export const convertPlanogramContainersToS1Containers = (
  containers: Container[],
  cumulative_shelf_heights: number[],
  pm: ProductMaster[],
  isSourceTemplate = false,
) => {
  const inputContainers: FreezerS1InputContainer[] = [];

  containers.forEach((container) => {
    const variants = container.label.split(", ");

    inputContainers.push({
      id: container.label,
      variants,
      width: container.xRight - container.xLeft,
      height: container.yTop - container.yBottom,
      // @ts-expect-error TODO clean up this later
      start_x: container.xLeft,
      end_x: container.xRight,
      start_y: container.yBottom,
      end_y: container.yTop,
      xLeft: container.xLeft,
      xRight: container.xRight,
      yTop: container.yTop,
      yBottom: container.yBottom,
      shelfHeights: cumulative_shelf_heights.toString(),
      core_range_size: container.algoMinTargetWidth,
      min_width_per_variants: pm
        .filter((pmItem) => variants.includes(pmItem.variant))
        .reduce(
          (maxVariantItemWidth, pmItem) =>
            Math.max(maxVariantItemWidth, Number(pmItem.unit_width)),
          0,
        ),
      target_width: Number(
        isSourceTemplate
          ? container.algoCurrentTargetWidth!
          : container.algoTargetWidth,
      ),
    });
  });

  return inputContainers;
};

export const applyMinWidthPriority = ({
  inputContainers,
  minWidthPerVariant,
  priorityByVariant,
  productCountByVariant,
  totalFixtureWidth,
  isSourceTemplate,
}: {
  inputContainers: FreezerS1InputContainer[];
  minWidthPerVariant: any;
  priorityByVariant: any;
  productCountByVariant: any;
  totalFixtureWidth: number;
  isSourceTemplate: boolean;
}) => {
  return inputContainers.map((container) => {
    const containerWidth = container.xRight - container.xLeft;

    const rawMinWidth = Math.max(
      container.variants.reduce((t, v) => minWidthPerVariant[v] + t, 0),
      (isSourceTemplate ? containerWidth : 0) * 0.9,
    );

    const min_width = Math.min(rawMinWidth, totalFixtureWidth);

    const anchorVariantCount = Math.max(
      ...container.variants.map((v) => productCountByVariant[v] || 0),
    );
    const anchorVariantPriority = container.variants
      .filter((v) => (productCountByVariant[v] || 0) === anchorVariantCount)
      .map((v) => {
        if (!priorityByVariant[v]) {
          const newVariantPriority =
            Math.max(...objectValues(priorityByVariant)) + 1;
          priorityByVariant[v] = newVariantPriority;
        }
        return priorityByVariant[v];
      });

    return {
      ...container,
      min_width,
      priority: Math.max(...anchorVariantPriority),
    };
  });
};

export const calculateVariantPriority = ({
  containers,
  totalFixtureWidth,
  totalFixtureHeight,
}: any) => {
  const priorityPointByContainer = containers.reduce(
    (t: any, c: any) => ({
      ...t,
      [JSON.stringify(c.variants)]:
        c.xLeft / totalFixtureWidth + (20 * c.yBottom) / totalFixtureHeight,
    }),
    {},
  );
  const priorityPointToIndices = Object.values(priorityPointByContainer).sort(
    (a: number, b: number) => a - b,
  );
  const priorityByVariant = Object.keys(priorityPointByContainer).reduce(
    (acc, n) => {
      let index =
        priorityPointToIndices.findIndex(
          (v) => v === priorityPointByContainer[n],
        ) + 1;

      while (Object.values(acc).includes(index)) {
        index++; // dupplicate priority point, fallback to next position in the arr indice
      }
      const variants = JSON.parse(n);
      variants.forEach((v: any) => {
        //@ts-ignore
        acc[v] = index;
      });
      return acc;
    },
    {},
  );
  return priorityByVariant;
};
