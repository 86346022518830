import { PayloadPutBasePogSelectedStatus } from "@CommonApi/pogger/base-pog/status";
import { useAtom, useAtomValue } from "jotai";
import { useParams } from "react-router-dom";
import { useModals } from "src/components/Modals";
import { Text } from "src/elements";
import { useBasePogApi } from "src/hooks";
import {
  basePogTemplateAtoms,
  pogTemplateAtoms,
  selectBasePogIndexAtom,
} from "src/pages/MerchflowTemplateBasePogs/store/atom";
import { emptyContainersAtom } from "src/pages/merchandise/store/atoms";
import { pages } from "src/utils";
import { routeToUrl } from "src/utils/axios";
import { useNavigation } from "src/utils/navigation";
import { ReviewActionModal } from "./ReviewActionModal";

export const TemplateReviewAction = () => {
  const { closeModal } = useModals();
  const basePogIndex = useAtomValue(selectBasePogIndexAtom);
  const [basePogs, setBasePogs] = useAtom(basePogTemplateAtoms);
  const { merchflowId: _merchflowId, templateId: _templateId } = useParams<{
    merchflowId: string;
    templateId: string;
  }>();
  const pogTemplate = useAtomValue(pogTemplateAtoms);
  const { callUpdateBasePogStatusApi } = useBasePogApi();
  const { navigate } = useNavigation();
  const { openModal } = useModals();

  const merchflowId = Number(_merchflowId);
  const templateId = _templateId!;

  const showWarningBasePogs = basePogs.reduce((result, basePog) => {
    if (basePog.is_favourite) {
      //@ts-ignore
      result[basePog.id] = true;
    }
    return result;
  }, {});

  const onClose = () => {
    closeModal("TemplateReviewAction");
  };

  const onChangeFavorite = async (payload: PayloadPutBasePogSelectedStatus) => {
    const { error } = await callUpdateBasePogStatusApi(payload);
    if (!error) {
      setBasePogs((cur) => {
        cur[basePogIndex].is_favourite = !cur[basePogIndex].is_favourite;
        return cur;
      });
      onClose();
    }
  };
  const navigateToBasePog = () => {
    onClose();
    navigate(
      routeToUrl(pages.merchflows_merchflowId_BasePog_basePogId_, {
        merchflowId,
        basePogId: basePogs[basePogIndex].id!,
      }),
    );
  };

  const navigateToS2QuadrantView = () => {
    onClose();
    navigate(
      routeToUrl(pages.merchflows_merchflowId_S2Templates_templateId_, {
        merchflowId,
        templateId,
      }),
    );
  };

  const options = [
    !basePogs[basePogIndex].is_favourite
      ? {
          label: "Favorite",
          onClick: () =>
            onChangeFavorite({ is_favourite: [basePogs[basePogIndex].id] }),
        }
      : {
          label: "Unfavorite",
          onClick: () =>
            onChangeFavorite({ is_not_favourite: [basePogs[basePogIndex].id] }),
        },
    { label: "Edit Template Planogram", onClick: navigateToBasePog },
    {
      label: "Create New Template Planogram",
      onClick: navigateToS2QuadrantView,
    },
    {
      label: "View Assigned Stores",
      onClick: () => openModal("AssignedStoreTemplate"),
    },
  ];
  return (
    <ReviewActionModal
      hasNavigation
      modalName="TemplateReviewAction"
      dropdown={{ label: "TEMPLATE", options }}
      numberOfPog={basePogs.length}
      containersAtom={emptyContainersAtom}
      pogAtom={pogTemplate[basePogIndex]}
      indexAtom={selectBasePogIndexAtom}
      footer={<Text>Base Pog {basePogs[basePogIndex].id}</Text>}
      showWarning={
        Object.values(showWarningBasePogs).length > 1 &&
        //@ts-ignore
        showWarningBasePogs[basePogs[basePogIndex].id]
      }
    />
  );
};
