import { Pog } from "@CommonTypes/merchflow/pog/pog";
import { useAtom } from "jotai";
import { Container } from "src/components/Planogram/store/types";
import { omniAtom } from "src/utils/atoms";

const prefix = "playground";

const containersAtom = omniAtom({
  prefix,
  key: "containers",
  value: [] as Container[],
});

const pogAtom = omniAtom({
  prefix,
  key: "pog",
  value: null as Pog | null,
});

export const usePlayground = () => {
  const [containers, setContainers] = useAtom(containersAtom);
  const [pog, setPog] = useAtom(pogAtom);

  return {
    containersAtom,
    pogAtom,
    containers,
    setContainers,
    pog,
    setPog,
  };
};
