import moment from "moment";
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { useModals } from "src/components/Modals";
import {
  Button,
  Color,
  Flex,
  Icon,
  Pagination,
  Scroller,
  SubgridTable,
  Text,
} from "src/elements";
import { useNewFlowReview } from "src/modals/NewFlowReview/store/api";

import { ResponseMerchFlowDetails } from "@CommonApi/merchandise";
import { SubgridColumn } from "src/elements/SubgridTable/store/types";
import { useMerchflowPSAReviewModal } from "src/modals/MerchflowPSAReview/store/hooks";
import { useMerchflowReviewModal } from "src/modals/MerchflowReview/store/hooks";
import { useMerchflowReviewUpdateModal } from "src/modals/MerchflowReviewUpdate/store/hooks";
import { pages } from "src/utils";
import { routeToUrl } from "src/utils/axios";
import { useNavigation } from "src/utils/navigation";
import { usePaginationPage } from "src/elements/Pagination";
import {
  ResponseGetMerchflowsReviews,
  RouteGetMerchflowsReviews,
} from "@CommonApi/merchflows/reviews";
import { useInlineLoaders } from "src/components/InlineLoader";
import { PageStatus, Tooltip } from "src/components";
import { useApi } from "src/api";
import { useMenu } from "src/components/Menu";
import { useViolationsReportModal } from "src/modals/ViolationsReport/store/hooks";
import { ReviewStatus } from "@CommonTypes/merchflow/ReviewStatus";
import { useMerchflowReviewUpdateModalV1 } from "src/modals/MerchflowReviewUpdateV1/store/hooks";
import { useIsFeatureFlagEnabled } from "src/components/FeatureFlagProvider/store/utils";
import { useHoverClickable } from "src/utils/hoverClickable";
import { useMerchflowNotesModal } from "src/modals/MerchflowNotes/store/hooks";

const NotesCell = ({
  merchflowId,
  notes,
  setData,
}: {
  merchflowId: number;
  notes: string;
  setData: Dispatch<SetStateAction<ResponseGetMerchflowsReviews | null>>;
}) => {
  const refCell = useRef(null);

  const { isHovered } = useHoverClickable(refCell);
  const { openMerchflowNotesModal: _openMerchflowNotesModal } =
    useMerchflowNotesModal();

  const openMerchflowNotesModal = () => {
    _openMerchflowNotesModal({
      merchflowId,
      notes,
      onSave: updateMerchflowNotesAfterSuccessfulSave,
    });
  };

  const updateMerchflowNotesAfterSuccessfulSave = (notes: string) => {
    setData((data) =>
      data
        ? {
            ...data,
            data: data.data.map((merchflowRow) =>
              merchflowRow.id === merchflowId
                ? { ...merchflowRow, notes: notes }
                : merchflowRow,
            ),
          }
        : null,
    );
  };

  return (
    <Flex width="100%" position="relative" ref={refCell}>
      <Text variant="small2">{notes}</Text>

      {isHovered && (
        <Flex position="absolute" right="0" onClick={openMerchflowNotesModal}>
          <Icon name="edit" />
        </Flex>
      )}
    </Flex>
  );
};

const getColumns = (
  setData: Dispatch<SetStateAction<ResponseGetMerchflowsReviews | null>>,
) =>
  [
    {
      id: "id",
      header: "ID",
    },
    {
      id: "name",
      header: "Name",
    },
    {
      id: "retailer_category_code",
      header: "Category",
    },
    {
      id: "category_code",
      header: "Sub-Category",
    },
    {
      id: "go_live_date",
      header: "Go-Live Date",
    },
    {
      id: "deactivation_date",
      header: "Deactivation Date",
    },
    {
      id: "status",
      header: "Status",
    },
    {
      id: "created_at",
      header: "Date Created",
    },
    {
      id: "updated_at",
      header: "Date Modified",
    },
    {
      id: "notes",
      header: "Notes",
      renderer: (data) => (
        <NotesCell
          merchflowId={data.row.id}
          notes={data.value}
          setData={setData}
        />
      ),
    },
    {
      id: "core_range",
      header: "Core range",
    },
    {
      id: "actions",
      header: "Actions",
    },
  ] satisfies SubgridColumn<any>[];

export const ListFlowReview = () => {
  const { openMenu } = useMenu();
  const { getMerchflowsReviewsApi } = useApi();
  const { setDetails } = useNewFlowReview();
  const { openModal } = useModals();
  const { navigate } = useNavigation();
  const { openMerchflowReviewModal } = useMerchflowReviewModal();
  const { openMerchflowPSAReviewModal } = useMerchflowPSAReviewModal();
  const { isInlineLoading } = useInlineLoaders();
  const { openViolationsReportModal } = useViolationsReportModal();

  const { openMerchflowReviewUpdateModalV1 } =
    useMerchflowReviewUpdateModalV1();
  const { openMerchflowReviewUpdateModal } = useMerchflowReviewUpdateModal();

  const [data, setData] = useState<ResponseGetMerchflowsReviews | null>(null);
  const [page, setPage] = usePaginationPage();
  const isLoadingFlows = isInlineLoading(RouteGetMerchflowsReviews);
  const featureFlagUpdateApiV2 = useIsFeatureFlagEnabled("updateApiV2");

  useEffect(() => {
    loadFlows();
  }, [page]);

  const loadFlows = async () => {
    setData(await getMerchflowsReviewsApi({ page, size: 20 }));
  };

  const viewTemplates = (merchflow_id: number) => {
    navigate(`/merchflows/${merchflow_id}/templates`);
  };

  const viewStores = (merchflowId: number) => {
    navigate(
      routeToUrl(pages.merchflows_merchflowId_StoresAll, { merchflowId }),
    );
  };

  const viewConfig = (merchflow: ResponseMerchFlowDetails) => {
    setDetails(merchflow);
    openModal("ChangeFilterConfigModal");
  };

  const continueMerchflow = (merchflow: ResponseMerchFlowDetails) => {
    if (
      (merchflow.status === ReviewStatus.DRAFT ||
        merchflow.status === ReviewStatus.BASE_POG_GENERATED) &&
      merchflow.latest_base_pog_id
    ) {
      navigate(
        routeToUrl(pages.merchflows_merchflowId_BasePog_basePogId_, {
          merchflowId: merchflow.id,
          basePogId: merchflow.latest_base_pog_id,
        }),
      );
    } else if (
      merchflow.status === ReviewStatus.STORE_POG_GENERATED ||
      merchflow.status === ReviewStatus.STORE_POG_EXPORTED
    ) {
      viewStores(merchflow.id);
    } else {
      window.open(
        routeToUrl(pages.merchandiseDetail, {
          merchFlowId: merchflow.id,
        }),
      );
    }
  };

  const handleUpdateFlowReview = (review: ResponseMerchFlowDetails) => {
    if (featureFlagUpdateApiV2) {
      openMerchflowReviewUpdateModal({ merchflowId: review.id });
    } else {
      openMerchflowReviewUpdateModalV1({ merchflowId: review.id });
    }
  };

  const openMerchflowMoreMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    merchflow: ResponseMerchFlowDetails,
  ) => {
    openMenu({
      isRelativeToElement: true,
      event,
      menu: {
        options: [
          ...(merchflow.status === ReviewStatus.STORE_POG_GENERATED ||
          merchflow.status === ReviewStatus.STORE_POG_EXPORTED
            ? [
                {
                  label: "Update",
                  onClick: () => handleUpdateFlowReview(merchflow),
                },
              ]
            : []),
          { label: "View Stores", onClick: () => viewStores(merchflow.id) },
          {
            label: "View Templates",
            onClick: () => viewTemplates(merchflow.id),
          },
          {
            label: "Violation Report",
            onClick: () => openViolationsReportModal(merchflow.id),
          },
          { label: "View Config", onClick: () => viewConfig(merchflow) },
          { label: "Continue", onClick: () => continueMerchflow(merchflow) },
        ],
      },
    });
  };

  return (
    <Flex column gap="12px" height="100%">
      <Flex justify="left" align="center" gap="10px">
        <Flex flexGrow={1} justify="right" gap="8px">
          <Button
            color="greenSmoke"
            iconRight={{ name: "plus" }}
            onClick={openMerchflowReviewModal}
          >
            Create
          </Button>

          <Button
            color="greenSmoke"
            iconRight={{ name: "plus" }}
            onClick={openMerchflowPSAReviewModal}
          >
            Create from PSA
          </Button>
        </Flex>
      </Flex>

      <Flex grow>
        {isLoadingFlows && (
          <PageStatus label="Loading flows..." icon="spinner" />
        )}

        {!isLoadingFlows && data && (
          <Scroller>
            <SubgridTable
              columns={getColumns(setData)}
              data={data.data.map((review) => ({
                id: review.id,
                name: review.name,
                retailer_category_code: review.retailer_category_code,
                category_code: review.category_code,
                go_live_date: review.go_live_date
                  ? moment(review.go_live_date).format("DD/MM/YYYY")
                  : "",
                deactivation_date: review.deactivation_date
                  ? moment(review.deactivation_date).format("DD/MM/YYYY")
                  : "",
                status:
                  (review.status === "BASE_POG_GENERATED" &&
                    "Base POG generated") ||
                  (review.status === "STORE_POG_GENERATED" &&
                    "Store POG generated") ||
                  (review.status === "STORE_POG_EXPORTED" &&
                    "Store POG exported") ||
                  "DRAFT",
                created_at: moment(review.created_at).format("DD/MM/YYYY"),
                updated_at: moment(review.updated_at).format("DD/MM/YYYY"),
                notes: review.notes,
                core_range: review.config.option,
                actions: (
                  <Flex gap="8px" align="center">
                    <Button
                      size="small"
                      variant="borderless"
                      iconRight={{ name: "more" }}
                      onClick={(event) => openMerchflowMoreMenu(event, review)}
                    />

                    {review.is_violated && (
                      <Flex
                        onClick={() => openViolationsReportModal(review.id)}
                      >
                        <Icon name="alert" color={Color.red} size={14} />

                        <Tooltip>Planogram violation detected</Tooltip>
                      </Flex>
                    )}

                    {review.need_update && (
                      <Flex onClick={() => handleUpdateFlowReview(review)}>
                        <Icon name="alert" color={Color.yellow} size={14} />

                        <Tooltip>Planogram update needed</Tooltip>
                      </Flex>
                    )}
                  </Flex>
                ),
              }))}
            />
          </Scroller>
        )}
      </Flex>

      <Pagination
        page={page}
        setPage={setPage}
        totalPages={data?.totalPages || 1}
        isUsingInput
      />
    </Flex>
  );
};
