import { ResponseMerchFlowApplyFilters } from "@CommonApi/merchandise";
import { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Flex,
  Grid,
  HorizontalDivider,
  Radios,
  Scroller,
} from "src/elements";
import { StoreSelection } from "../store/types";
import { Panel } from "src/components";

interface Props {
  currentStoreCode?: string;
  availableStores: ResponseMerchFlowApplyFilters["filter_config"];
  filteredStores: ResponseMerchFlowApplyFilters["filter_config"]["stores"];
  setFilteredStores: (
    stores: ResponseMerchFlowApplyFilters["filter_config"]["stores"],
  ) => void;
  storeSelection: StoreSelection;
  setStoreSelection: (storeSelection: StoreSelection) => void;
  selectedFilters: ResponseMerchFlowApplyFilters["filter_config"];
  setSelectedFilters: (
    filters: ResponseMerchFlowApplyFilters["filter_config"],
  ) => void;
}

const tabs: ("Attributes" | "Stores")[] = ["Attributes", "Stores"];

export const NewProductStores = ({
  currentStoreCode,
  availableStores,
  filteredStores,
  setFilteredStores,
  storeSelection,
  setStoreSelection,
  selectedFilters,
  setSelectedFilters,
}: Props) => {
  const [panelTab, setPanelTab] = useState<(typeof tabs)[number]>("Attributes");

  useEffect(() => {
    setFilteredStores(
      availableStores.stores.filter((store) => {
        if (
          selectedFilters.baySizes.length === 0 &&
          selectedFilters.clusters.length === 0 &&
          selectedFilters.states.length === 0 &&
          selectedFilters.fixtures.section_height.length === 0 &&
          selectedFilters.fixtures.section_width.length === 0
        ) {
          return false;
        }

        if (
          (selectedFilters.baySizes.length > 0 &&
            !selectedFilters.baySizes.includes(store.bay_size)) ||
          (selectedFilters.clusters.length > 0 &&
            !selectedFilters.clusters.includes(store.cluster)) ||
          (selectedFilters.states.length > 0 &&
            !selectedFilters.states.includes(store.state)) ||
          (selectedFilters.fixtures.section_height.length > 0 &&
            !selectedFilters.fixtures.section_height.includes(
              store.section_height,
            )) ||
          (selectedFilters.fixtures.section_width.length > 0 &&
            !selectedFilters.fixtures.section_width.includes(
              store.section_width,
            ))
        ) {
          return false;
        }

        return true;
      }),
    );
  }, [availableStores, selectedFilters]);

  return (
    <Flex flexGrow={1} minHeight="0" column gap="20px">
      <Radios
        label="Which stores should the new SKU be ranged in?"
        direction="column"
        options={[
          {
            label: "All Stores",
            value: StoreSelection.ALL_STORES,
          },
          {
            label: "Current Store",
            value: StoreSelection.CURRENT_STORE,
            isDisabled: !currentStoreCode,
          },
          {
            label: "Specific Stores",
            value: StoreSelection.SPECIFIC_STORES,
          },
        ]}
        value={storeSelection}
        setValue={setStoreSelection}
      />

      <HorizontalDivider />

      {storeSelection === "SPECIFIC_STORES" && (
        <Panel tabs={tabs} tab={panelTab} setTab={setPanelTab}>
          <Scroller>
            <Flex padding="10px" column gap="20px">
              {panelTab === "Attributes" && (
                <>
                  {availableStores.baySizes.length > 0 && (
                    <Flex column gap="10px">
                      <Checkbox
                        label="BAY SIZES"
                        isChecked={
                          selectedFilters.baySizes.length ===
                          availableStores.baySizes.length
                        }
                        setIsChecked={(isChecked) =>
                          setSelectedFilters({
                            ...selectedFilters,
                            baySizes: isChecked ? availableStores.baySizes : [],
                          })
                        }
                        isCheckboxLeft
                        isHeader
                      />

                      <Flex flexWrap="wrap" gap="10px">
                        {availableStores.baySizes
                          .sort((baySize1, baySize2) =>
                            baySize1 < baySize2 ? -1 : 1,
                          )
                          .map((baySize, i) => {
                            const isSelected =
                              selectedFilters.baySizes.includes(baySize);
                            return (
                              <Button
                                key={i}
                                variant={isSelected ? "default" : "inverted"}
                                onClick={() => {
                                  setSelectedFilters({
                                    ...selectedFilters,
                                    baySizes: isSelected
                                      ? selectedFilters.baySizes.filter(
                                          (_baySize) => _baySize !== baySize,
                                        )
                                      : [...selectedFilters.baySizes, baySize],
                                  });
                                }}
                              >
                                {baySize}
                              </Button>
                            );
                          })}
                      </Flex>
                    </Flex>
                  )}

                  {availableStores.states.length > 0 && (
                    <>
                      <HorizontalDivider />

                      <Flex column gap="10px">
                        <Checkbox
                          label="STATES"
                          isChecked={
                            selectedFilters.states.length ===
                            availableStores.states.length
                          }
                          setIsChecked={(isChecked) =>
                            setSelectedFilters({
                              ...selectedFilters,
                              states: isChecked ? availableStores.states : [],
                            })
                          }
                          isCheckboxLeft
                          isHeader
                        />

                        <Flex flexWrap="wrap" gap="10px">
                          {availableStores.states.map((state, i) => {
                            const isChecked =
                              selectedFilters.states.includes(state);

                            return (
                              <Checkbox
                                key={i}
                                label={state}
                                width="min-content"
                                isChecked={isChecked}
                                setIsChecked={(isChecked) => {
                                  setSelectedFilters({
                                    ...selectedFilters,
                                    states: isChecked
                                      ? [...selectedFilters.states, state]
                                      : selectedFilters.states.filter(
                                          (_state) => _state !== state,
                                        ),
                                  });
                                }}
                                isCheckboxLeft
                              />
                            );
                          })}
                        </Flex>
                      </Flex>
                    </>
                  )}

                  {availableStores.fixtures.section_height.length > 0 && (
                    <>
                      <HorizontalDivider />

                      <Flex column gap="10px">
                        <Checkbox
                          label="FIXTURE HEIGHTS"
                          isChecked={
                            selectedFilters.fixtures.section_height.length ===
                            availableStores.fixtures.section_height.length
                          }
                          setIsChecked={(isChecked) => {
                            setSelectedFilters({
                              ...selectedFilters,
                              fixtures: {
                                section_height: isChecked
                                  ? availableStores.fixtures.section_height
                                  : [],
                                section_width:
                                  selectedFilters.fixtures.section_width,
                              },
                            });
                          }}
                          isCheckboxLeft
                          isHeader
                        />

                        <Flex flexWrap="wrap" gap="10px">
                          {availableStores.fixtures.section_height
                            .sort((fixture1, fixture2) =>
                              fixture1 < fixture2 ? -1 : 1,
                            )
                            .map((fixture, i) => (
                              <Checkbox
                                key={i}
                                label={`section_height_${fixture}`}
                                width="min-content"
                                isChecked={selectedFilters.fixtures.section_height.includes(
                                  fixture,
                                )}
                                setIsChecked={(isChecked) => {
                                  setSelectedFilters({
                                    ...selectedFilters,
                                    fixtures: {
                                      section_height: isChecked
                                        ? [
                                            ...selectedFilters.fixtures
                                              .section_height,
                                            fixture,
                                          ]
                                        : selectedFilters.fixtures.section_height.filter(
                                            (_fixture) => _fixture !== fixture,
                                          ),
                                      section_width:
                                        selectedFilters.fixtures.section_width,
                                    },
                                  });
                                }}
                                isCheckboxLeft
                              />
                            ))}
                        </Flex>
                      </Flex>
                    </>
                  )}

                  {availableStores.fixtures.section_width.length > 0 && (
                    <>
                      <HorizontalDivider />

                      <Flex column gap="10px">
                        <Checkbox
                          label="FIXTURE WIDTHS"
                          isChecked={
                            selectedFilters.fixtures.section_width.length ===
                            availableStores.fixtures.section_width.length
                          }
                          setIsChecked={(isChecked) => {
                            setSelectedFilters({
                              ...selectedFilters,
                              fixtures: {
                                section_width: isChecked
                                  ? availableStores.fixtures.section_width
                                  : [],
                                section_height:
                                  selectedFilters.fixtures.section_height,
                              },
                            });
                          }}
                          isCheckboxLeft
                          isHeader
                        />

                        <Flex flexWrap="wrap" gap="10px">
                          {availableStores.fixtures.section_width
                            .sort((fixture1, fixture2) =>
                              fixture1 < fixture2 ? -1 : 1,
                            )
                            .map((fixture, i) => (
                              <Checkbox
                                key={i}
                                label={`section_width_${fixture}`}
                                width="min-content"
                                isChecked={selectedFilters.fixtures.section_width.includes(
                                  fixture,
                                )}
                                setIsChecked={(isChecked) => {
                                  setSelectedFilters({
                                    ...selectedFilters,
                                    fixtures: {
                                      section_width: isChecked
                                        ? [
                                            ...selectedFilters.fixtures
                                              .section_width,
                                            fixture,
                                          ]
                                        : selectedFilters.fixtures.section_width.filter(
                                            (_fixture) => _fixture !== fixture,
                                          ),
                                      section_height:
                                        selectedFilters.fixtures.section_height,
                                    },
                                  });
                                }}
                                isCheckboxLeft
                              />
                            ))}
                        </Flex>
                      </Flex>
                    </>
                  )}

                  {availableStores.clusters.length > 0 && (
                    <>
                      <HorizontalDivider />

                      <Checkbox
                        label="CLUSTERS"
                        isChecked={
                          selectedFilters.clusters.length ===
                          availableStores.clusters.length
                        }
                        setIsChecked={(isChecked) => {
                          setSelectedFilters({
                            ...selectedFilters,
                            clusters: isChecked ? availableStores.clusters : [],
                          });
                        }}
                        isCheckboxLeft
                        isHeader
                      />

                      <Flex flexWrap="wrap" gap="10px">
                        {availableStores.clusters.sort().map((cluster, i) => (
                          <Checkbox
                            key={i}
                            label={cluster}
                            width="auto"
                            isChecked={selectedFilters.clusters.includes(
                              cluster,
                            )}
                            setIsChecked={(isChecked) => {
                              setSelectedFilters({
                                ...selectedFilters,
                                clusters: isChecked
                                  ? [...selectedFilters.clusters, cluster]
                                  : selectedFilters.clusters.filter(
                                      (_cluster) => _cluster !== cluster,
                                    ),
                              });
                            }}
                            isCheckboxLeft
                          />
                        ))}
                      </Flex>
                    </>
                  )}
                </>
              )}

              {panelTab === "Stores" && (
                <Grid gap="10px" justify="evenly" columns="repeat(7, 1fr)">
                  {availableStores.stores
                    .sort((store1, store2) =>
                      store1.store < store2.store ? -1 : 1,
                    )
                    .map((store, i) => {
                      const isChecked =
                        Boolean(
                          filteredStores.find(
                            (_store) => _store.store === store.store,
                          ),
                        ) || store.store === currentStoreCode;

                      return (
                        <Button
                          key={i}
                          variant={isChecked ? "default" : "inverted"}
                          isDisabled={store.store === currentStoreCode}
                          onClick={() => {
                            setFilteredStores(
                              isChecked
                                ? filteredStores.filter(
                                    (_store) => _store.store !== store.store,
                                  )
                                : [...filteredStores, store],
                            );
                          }}
                        >
                          {store.store}
                        </Button>
                      );
                    })}
                </Grid>
              )}
            </Flex>
          </Scroller>
        </Panel>
      )}
    </Flex>
  );
};
