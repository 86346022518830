import {
  Pagination,
  RequiredPaginationQueryParams,
} from "@CommonTypes/backend/pagination";
import { Product } from "../../../types/owa-db/merchantSchema";

export const RouteGetInputsProductsAll = "/inputs/products/all";

export type ParamsGetInputsProductsAll = RequiredPaginationQueryParams & {
  is_oos?: boolean;
};

export type ResponseGetInputsProductsAll = Pagination<Product>;
