import { useRef } from "react";
import styled from "styled-components";

import { selectHeight } from "../store/constants";
import { Transition } from "src/utils";
import { Color, Flex, Icon, Text } from "src/elements";
import { IconType } from "src/elements/Icon";
import { useHoverClickable } from "src/utils/hoverClickable";

const OptionElement = styled.div<{
  isHovered: boolean;
  isActive: boolean;
  isSelected: boolean;
  isSelectable: boolean;
  isOpener: boolean;
}>`
  display: flex;
  gap: 5px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: ${selectHeight - 2}px;
  padding: 7px 10px;
  user-select: none;
  cursor: pointer;
  transition: background-color ${Transition.fast};

  pointer-events: ${({ isSelectable }) => !isSelectable && "none"};
  background-color: ${({ isHovered, isActive, isSelected, isOpener }) =>
    (isOpener && Color.transparent) ||
    (isSelected && Color.greenSmoke) ||
    (isActive && Color.greenSmokeHover) ||
    (isHovered && Color.lightGray)};
`;

interface Props<T> {
  label: string;
  value?: T;
  icon?: {
    name: IconType;
    color?: string;
  };
  isSelected?: boolean;
  isSelectable?: boolean;
  isPlaceholder?: boolean;
  isDisabled?: boolean;
  isOpener?: boolean;
  onSelect?: (value: T) => void;
}

export const Option = <T extends any>({
  label,
  value,
  icon,
  isSelected = false,
  isSelectable = true,
  isPlaceholder = false,
  isDisabled = false,
  isOpener = false,
  onSelect,
}: Props<T>) => {
  const refOption = useRef<HTMLDivElement>(null);
  const { isHovered, isActive } = useHoverClickable(refOption);

  const onClick = () => {
    if (onSelect && value !== undefined) {
      onSelect(value);
    }
  };

  return (
    <OptionElement
      ref={refOption}
      isHovered={isHovered}
      isActive={isActive}
      isSelected={isSelected}
      isSelectable={isSelectable}
      isOpener={isOpener}
      onClick={onClick}
    >
      <Text
        whiteSpace={isOpener ? "nowrap" : "wrap"}
        textOverflow={isOpener ? "ellipsis" : undefined}
        variant={isSelected ? "body1" : "body3"}
        color={
          (isDisabled && Color.textDisabled) ||
          (isPlaceholder && Color.spaceGray) ||
          (!isSelectable && Color.spaceGray) ||
          (isSelected && Color.primaryActive) ||
          (isActive && Color.primaryActive) ||
          (isHovered && Color.textMain) ||
          Color.textMain
        }
      >
        {label}
      </Text>

      {icon && (
        <Flex minWidth="14px">
          <Icon name={icon.name} size={14} color={icon.color} />
        </Flex>
      )}
    </OptionElement>
  );
};
