import { useAtomValue, useSetAtom } from "jotai";
import { detailsAtom } from "src/modals/NewFlowReview/store/atoms";
import { stepAtom } from "src/pages/merchandise/store/atoms";
import { MerchandiseStep } from "src/pages/merchandise/store/types";
import { pages } from "src/utils";
import { useNavigation } from "src/utils/navigation";

export const useMerchflowNavigation = () => {
  const { navigate } = useNavigation();
  const setStep = useSetAtom(stepAtom);
  const details = useAtomValue(detailsAtom);

  const helper = {
    navigateToStoreProps: (query?: string) => {
      navigate(
        `${pages.merchandise}/${details?.id}/store_level_pog${query ?? ""}`,
      );
      setStep(MerchandiseStep.STORE_LEVEL_POG);
    },
    navigateToApplyToBase: (query?: string) => {
      navigate(
        `${pages.merchandise}/${details?.id}/apply_to_base${query ?? ""}`,
      );
      setStep(MerchandiseStep.APPLY_TO_BASE);
    },
    navigateToSelectBase: () => {
      navigate(`${pages.merchandise}/${details?.id}`);
      setStep(MerchandiseStep.SELECT_BASE);
    },
  };

  return helper;
};
