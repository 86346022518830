import { useState } from "react";

import { Modal, Button, Input, Flex } from "src/elements";
import { useModals } from "src/components/Modals";
import { useInlineLoaders } from "src/components/InlineLoader";
import { useApi } from "src/api";
import { RoutePostStores } from "@CommonApi/stores";
import { useStoresPage } from "src/pages/Stores/store/hooks";

export const AddStoreModal = () => {
  const { closeModal } = useModals();
  const { isInlineLoading } = useInlineLoaders();
  const { postStoresApi } = useApi();
  const { loadStores } = useStoresPage();

  const [storeCode, setStoreCode] = useState("");

  const isSaveLoading = isInlineLoading(RoutePostStores);

  const addNewStore = async () => {
    if (storeCode) {
      const response = await postStoresApi({ code: storeCode });

      if (response) {
        loadStores();
        closeModal("AddStoreModal");
      }
    }
  };

  return (
    <Modal name="AddStoreModal" title="Add New Store" width="300px">
      <Flex gap="20px" padding="20px" column grow>
        <Input title="Store name" value={storeCode} setValue={setStoreCode} />

        <Flex justify="center" gap="30px">
          <Button
            width="100%"
            isDisabled={!storeCode}
            isLoading={isSaveLoading}
            onClick={addNewStore}
          >
            Add New Store
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};
