import { useSetAtom } from "jotai";
import { useCallback } from "react";
import { highlightsModeAtom } from "src/components/Planogram/store/atoms";
import { s2FiltersAtom } from "src/modals/AppliedFlowFilterOptions/atoms";
import { useNewFlowReview } from "src/modals/NewFlowReview/store/api";
import { NewFlowReviewStep } from "src/modals/NewFlowReview/store/types";
import {
  allPreStep0DataAtom,
  filterConfigBasePogsAtom,
  isViewDetailAtom,
  pageAtom,
  poggerResultAtom,
  poggerizeRequestAtom,
  poggerizeResultAtom,
  s2FilterResultAtom,
  savedPogAtomAtom,
  savedS0Atom,
  selectedPreStep0DataAtom,
  selectedTemplateIdAtom,
  step0GroupsAtoms,
  step1OriginalResponseAtom,
  step1RequestAtom,
  step1ResponsesAtom,
  step2DataAtom,
  storeLevelPogPaginationAtom,
  storeLevelPogStatusRowsAtom,
} from "src/pages/merchandise/store/atoms";
import { StoreLevelPogPagination } from "src/pages/merchandise/store/types";

export const useResetMerchFlowPage = () => {
  const setPage = useSetAtom(pageAtom);
  const setHighlightsMode = useSetAtom(highlightsModeAtom);
  const setIsDetailView = useSetAtom(isViewDetailAtom);
  const setS2FiltersAtom = useSetAtom(s2FiltersAtom);
  const setS2FilterResultsAtom = useSetAtom(s2FilterResultAtom);
  const setGroups = useSetAtom(step0GroupsAtoms);
  const setSelectedPreStep0Data = useSetAtom(selectedPreStep0DataAtom);
  const setAllPreStep0 = useSetAtom(allPreStep0DataAtom);
  const setSavedS0 = useSetAtom(savedS0Atom);
  const setSelectedTemplateId = useSetAtom(selectedTemplateIdAtom);
  const setStep1Request = useSetAtom(step1RequestAtom);
  const setStep2Data = useSetAtom(step2DataAtom);
  const setStep1OriginalResponse = useSetAtom(step1OriginalResponseAtom);
  const setStep1Response = useSetAtom(step1ResponsesAtom);

  const setPoggerResult = useSetAtom(poggerResultAtom);
  const setPoggerizeRequest = useSetAtom(poggerizeRequestAtom);
  const setPoggerizeResult = useSetAtom(poggerizeResultAtom);
  const setStoreLevelPogStatusRows = useSetAtom(storeLevelPogStatusRowsAtom);
  const setStoreLevelPogPaginations = useSetAtom(storeLevelPogPaginationAtom);
  const setSavedPogAtom = useSetAtom(savedPogAtomAtom);
  const setFilterConfigBasePogs = useSetAtom(filterConfigBasePogsAtom);
  const {
    setDetails,
    setName,
    setNotes,
    setCategory,
    setSubCategory,
    setFiltersAvailable,
    setFiltersSelectedBaySizes,
    setFiltersSelectedClusters,
    setFiltersSelectedHeights,
    setFiltersSelectedStates,
    setFiltersSelectedWidths,
    setFiltersApplied,
    setSetupStep,
    setVariantsAvailable,
    setMergeRules,
  } = useNewFlowReview();

  const resetMerchflow = useCallback(() => {
    setHighlightsMode(false);

    setDetails(null);
    setName("");
    setNotes("");
    setCategory(null);
    setSubCategory(null);
    setFiltersAvailable(null);
    setFiltersSelectedBaySizes([]);
    setFiltersSelectedClusters([]);
    setFiltersSelectedHeights([]);
    setFiltersSelectedStates([]);
    setFiltersSelectedWidths([]);
    setVariantsAvailable(null);
    setMergeRules(null);
    setFiltersApplied(null);
    setSetupStep(NewFlowReviewStep.STEP_1_1_INPUTS);

    resetStep2Solution();

    setGroups([]);
    setSelectedPreStep0Data([]);
    setAllPreStep0([]);
    setSavedS0(null);
    setSelectedTemplateId("");
    setStep1Request([]);
    setStep2Data([]);
    setStep1OriginalResponse([]);
    setStep1Response([]);

    setPoggerResult(null);
    setPoggerizeRequest(null);
    setPoggerizeResult(null);
    setStoreLevelPogStatusRows([]);
    setStoreLevelPogPaginations({} as StoreLevelPogPagination);
    setSavedPogAtom(null);
    setFilterConfigBasePogs([]);
  }, []);

  const resetStep2Solution = useCallback(() => {
    setPage(1);
    setIsDetailView(false);
    setS2FiltersAtom([]);
    setS2FilterResultsAtom([]);
  }, []);

  const resetStorePropData = useCallback(() => {
    setStoreLevelPogStatusRows([]);
    setStoreLevelPogPaginations({} as StoreLevelPogPagination);
  }, []);

  return {
    resetMerchflow,
    resetStep2Solution,
    resetStorePropData,
  };
};
