import { StorePogAllResponseRow } from "@CommonApi/store-pog/all";
import {
  Planogram as PlanogramType,
  Pog,
} from "@CommonTypes/merchflow/pog/pog";
import hexToRgba from "hex-to-rgba";
import { PrimitiveAtom, useAtom } from "jotai";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { algoStep2ConvertFreezerWithItemsToContainers } from "src/adapters/algoStep2";
import { Metric } from "src/components";
import { MenuOption, useMenu } from "src/components/Menu";
import { Planogram } from "src/components/Planogram/Planogram";
import { Color, Flex, Spinner, Text } from "src/elements";
import { useStorePogApi } from "src/hooks/useStorePogApi";
import { refetchIdAtom } from "src/modals/EditStorePogModal/atoms";
import { useNewFlowReview } from "src/modals/NewFlowReview/store/api";
import { adapterPogNonCircularToPog } from "src/omni-common/adapters/pog";
import { emptyContainersAtom } from "src/pages/merchandise/store/atoms";
import { getOneStorePogData } from "src/pages/merchandise/store/requests";
import { StoreLevelPogRow } from "src/pages/merchandise/store/types";
import { createPog } from "src/pages/merchandise/store/utils";
import { omniAtom } from "src/utils/atoms";
import styled from "styled-components";

import { usePowerBiReportModal } from "src/modals/PowerBiReportModal/hooks";
import { Selectable } from "./components/Selectable";
import { selectedStorePogsAtom } from "./store/atoms";
import { useHoverClickable } from "src/utils/hoverClickable";

const StorePogElement = styled.div`
  position: relative;
  display: flex;
  width: 280px;
  height: 160px;
  border-radius: 3px;
  gap: 10px;
`;

const Status = styled.div<{ isApproved: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ isApproved }) =>
    isApproved && hexToRgba(Color.primary, 0.2)};
  border: 1px solid
    ${({ isApproved }) => (isApproved && Color.primary) || Color.spaceGray};
  border-radius: 3px;
`;

const StoreLabel = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(3px);
  padding: 5px;
  border-radius: 3px 0 4px 4px;
`;

export const StorePropPOG = ({
  row,
  s2Planogram,
}: {
  row: StorePogAllResponseRow;
  s2Planogram: PlanogramType;
}) => {
  const { openMenu } = useMenu();
  const { details } = useNewFlowReview();
  const { exportFile, openLogs, openEdit } = useStorePogApi();

  const [selectedStorePogs, setSelectedStorePogs] = useAtom(
    selectedStorePogsAtom,
  );

  const [fetchedData, setFetchedData] = useState<StoreLevelPogRow | null>(null);

  const { openBasePogReport } = usePowerBiReportModal();
  const isSelected =
    selectedStorePogs.filter((store) => store.id === row.id).length > 0;

  const refStorePog = useRef<HTMLDivElement>(null);
  const { isHovered } = useHoverClickable(refStorePog);
  const [refetchId, setRefetchId] = useAtom(refetchIdAtom);

  const onClickLogs = () => {
    openLogs(row.id);
  };

  const onEdit = () => {
    openEdit(row.id);
  };

  const onClickExport = (type: "psa" | "pln" | "pdf") => {
    exportFile(type, row);
  };

  const onClickReport = () => {
    if (!details) return;

    const values = { ...row, merchflow_id: details?.id };
    openBasePogReport(
      ["merchflow_id", "filter_config_id", "template_id", "store_id"].map(
        (key) => ({
          table: "merchflow_base_pog_data",
          column: key,
          //@ts-ignore
          value: [values[key]],
        }),
      ),
    );
  };

  const onStorePogMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    const options: MenuOption[] = [
      {
        label: "Logs",
        onClick: onClickLogs,
      },
      {
        label: "Export as PSA",
        onClick: () => onClickExport("psa"),
      },
      {
        label: "Export as PLN",
        onClick: () => onClickExport("pln"),
      },
      {
        label: "View Report",
        onClick: () => onClickReport(),
      },
    ];
    if (row.status === "DONE") {
      options.unshift({
        label: "Edit",
        onClick: onEdit,
      });
    }
    openMenu({
      event,
      menu: {
        title: "Actions",
        options,
      },
    });
  };

  useEffect(() => {
    if (!fetchedData && ["ERROR", "DONE"].includes(row.status)) {
      getOneStorePogData(row.id).then((result) => {
        setFetchedData(result.rows[0] as any);
      });
    }
  }, [row.status]);

  useEffect(() => {
    if (refetchId && refetchId === row.id) {
      setFetchedData(null);
      getOneStorePogData(row.id).then((result) => {
        setFetchedData(result.rows[0] as any);
        setRefetchId(null);
      });
    }
  }, [refetchId]);

  const containerS2Atom = useMemo(() => {
    if (!fetchedData || row.status !== "ERROR") return null;

    const obj = {
      container: omniAtom({
        key: "single/step2container",
        value: algoStep2ConvertFreezerWithItemsToContainers(
          fetchedData.base_pog_place_variants_response_json.planogram?.freezer,
        ),
      }),
      pog: omniAtom({
        key: "single/step2Pog",
        value: createPog({
          planogram: s2Planogram,
          unrangedItems: [],
        }),
      }),
    };
    return obj;
  }, [fetchedData]);

  const pogAtom = useMemo(() => {
    return omniAtom({
      prefix: `storeLevelPog/${row.store_code}`,
      key: "step4Pog/pogAtom",
      value:
        !fetchedData || row.status !== "DONE"
          ? null
          : adapterPogNonCircularToPog(fetchedData.pog),
    });
  }, [fetchedData]);

  const [pog] = useAtom(pogAtom);

  const selectStorePog = (isSelected: boolean) => {
    if (isSelected) {
      setSelectedStorePogs((selectedStorePogs) => [...selectedStorePogs, row]);
    } else {
      setSelectedStorePogs((selectedStorePogs) =>
        selectedStorePogs.filter(
          (selectedStorePog) => selectedStorePog.id !== row.id,
        ),
      );
    }
  };

  return (
    <StorePogElement onContextMenu={onStorePogMenu} ref={refStorePog}>
      {!["ERROR", "DONE"].includes(row.status) && (
        <>
          <Flex justify="center" align="center">
            store: {row.store_code} {row.status}
            <Spinner />
          </Flex>
        </>
      )}

      {fetchedData && row.status === "ERROR" && containerS2Atom && (
        <Planogram
          pogAtom={containerS2Atom.pog}
          containersAtom={containerS2Atom.container}
          isRenderingAsImage
        />
      )}

      {fetchedData && row.status === "DONE" && pog !== null && (
        <Flex width="100%" height="100%">
          <Planogram
            pogAtom={pogAtom as PrimitiveAtom<Pog>}
            containersAtom={emptyContainersAtom}
            isRenderingAsImage
          />

          <Flex
            minWidth="65px"
            direction="column"
            align="center"
            padding="10px"
            gap="10px"
          >
            <Metric height="10px" percent={pog.metrics?.returnOnSpace} />

            <Metric height="10px" percent={pog.metrics?.coreRange} />

            <Metric height="10px" percent={pog.metrics?.shelfAlignment} />

            <Metric height="10px" percent={pog.metrics?.dosMos} />
          </Flex>
        </Flex>
      )}

      <Status isApproved={row.is_approved === true} />

      {isHovered && (
        <StoreLabel>
          <Text color={Color.white}>Store ID: {row.store_id}</Text>
        </StoreLabel>
      )}

      {(isHovered || isSelected) && (
        <Selectable isSelected={isSelected} setIsSelected={selectStorePog} />
      )}
    </StorePogElement>
  );
};
