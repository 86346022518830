import { useState, useEffect, useRef } from "react";

export const useAsyncState = <T>(
  initialState: T | null | undefined = undefined,
) => {
  const [state, setStateUnsafe] = useState<T | null | undefined>(initialState);
  const refMounted = useRef(false);

  useEffect(() => {
    refMounted.current = true;

    return () => {
      refMounted.current = false;
    };
  }, []);

  const setState = async (statePromise: Promise<T> | T) => {
    if (refMounted.current) {
      setStateUnsafe(await statePromise);
    }
  };

  return [state, setState] as [
    T | null | undefined,
    (state: Promise<T | null> | T | null | undefined) => void,
  ];
};
