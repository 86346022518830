import React from "react";
import { Color, Flex, Text } from "src/elements";
import styled from "styled-components";

const StatItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;

  &:not(:first-child) {
    border-left: 2px solid ${Color.lightGrayHover};
    padding-left: 15px;
  }
`;

interface Props {
  stats: {
    label: string;
    value?: string | number;
  }[];
}

export const StatPanel: React.FC<Props> = ({ stats }) => (
  <Flex gap="15px">
    {stats.map(({ label, value }, index) => (
      <StatItem key={index}>
        <Text
          variant="caption1"
          color={Color.textSecondary}
          textTransform="uppercase"
        >
          {label}:
        </Text>

        <Text variant="h5">
          {value !== null && value !== undefined
            ? `${value}${typeof value === "number" ? "%" : ""}`
            : "-"}
        </Text>
      </StatItem>
    ))}
  </Flex>
);
