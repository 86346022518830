import { useAtom } from "jotai";
import type { ExtractAtomValue } from "jotai";
import { featureFlagsAtom, settingsAtom } from "./store/atoms";
import { ReactNode, useEffect } from "react";
import { AppConfigurationClient } from "@azure/app-configuration";
import { FeatureFlag, Setting } from "./store/types";
import { useUser } from "src/atoms/user";
import { env } from "src/utils/env";

interface Props {
  children: ReactNode;
}

const client = new AppConfigurationClient(env.VITE_FF_FLAG_STORE);

let loadedFeatureFlags: ExtractAtomValue<typeof featureFlagsAtom> | null = null;
let loadedSettings: ExtractAtomValue<typeof settingsAtom> | null = null;

const loadSettings = async (merchantId: string) => {
  if (!loadedSettings) {
    const clientSettings = client.listConfigurationSettings({
      labelFilter: `settings_${env.VITE_ENV}`,
    });

    const newSettings = {} as Record<Setting, string>;

    for await (const setting of clientSettings) {
      try {
        if (setting.value) {
          const settingValue = JSON.parse(setting.value) as {
            [merchantId: string]: string;
          };
          //@ts-ignore
          newSettings[setting.key] = settingValue[merchantId];
        }
      } catch {
        console.error(
          `Failed to parse setting ${setting.key} with value ${setting.value}`,
        );
      }
    }
    loadedSettings = newSettings;

    return newSettings;
  } else {
    return loadedSettings;
  }
};
const loadFeatureFlags = async () => {
  if (!loadedFeatureFlags) {
    const clientFeatureFlags = client.listConfigurationSettings({
      keyFilter: ".appconfig.featureflag*",
    });

    const newFeatureFlags = {} as Record<FeatureFlag, boolean>;

    for await (const featureFlag of clientFeatureFlags) {
      try {
        if (featureFlag.value) {
          const featureFlagValue = JSON.parse(featureFlag.value) as {
            id: string;
            enabled: boolean;
          };
          //@ts-ignore
          newFeatureFlags[featureFlagValue.id] =
            featureFlagValue.enabled &&
            featureFlag.tags![env.VITE_ENV] === "true";
        }
      } catch {
        console.error(
          `Failed to parse feature flag ${featureFlag.key} with value ${featureFlag.value}`,
        );
      }
    }
    loadedFeatureFlags = newFeatureFlags;
    return newFeatureFlags;
  } else {
    return loadedFeatureFlags;
  }
};

export const FeatureFlagProvider = ({ children }: Props) => {
  const [featureFlags, setFeatureFlags] = useAtom(featureFlagsAtom);
  const [settings, setSettings] = useAtom(settingsAtom);
  const { user } = useUser();

  useEffect(() => {
    if (user && settings == null) {
      loadSettings(user.merchantId.toString()).then(setSettings);
    }
  }, [user]);

  useEffect(() => {
    if (featureFlags === null) {
      loadFeatureFlags().then(setFeatureFlags);
    }
  }, []);

  return <>{children}</>;
};
