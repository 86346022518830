import { StoreLevelPogRow } from "src/pages/merchandise/store/types";
import { omniAtom } from "src/utils/atoms";

const prefix = "modals/editStorePog";

export const editStorePogAtom = omniAtom({
  prefix,
  key: "editStorePog",
  value: null as null | StoreLevelPogRow,
});

export const refetchIdAtom = omniAtom({
  prefix,
  key: "refetchId",
  value: null as null | number,
});
