import hexToRgba from "hex-to-rgba";
import React, { useMemo, useState } from "react";
import { Color, Flex, Scroller, Search, Spinner } from "src/elements";
import styled from "styled-components";

import { SelectOption } from "../store/types";
import { Option } from "./Option";

const DropdownElement = styled.div`
  width: 100%;
  background-color: ${Color.white};
  border: 1px solid ${Color.primary};
  border-radius: 3px;
  overflow: hidden;
  user-select: none;
  box-shadow: 0px 0px 7px ${hexToRgba(Color.primary, 0.3)};

  input {
    border: none;
  }
`;

const DropdownDivider = styled.div`
  height: 1px;
  width: calc(100% - 20px);
  background-color: ${Color.lightGray};
  margin: 0 10px;
`;

interface Props<T> {
  value: T;
  options: SelectOption<T>[];
  onSelect: (value: T) => void;
  isSearchable?: boolean;
  isLoading: boolean;
  closeDropdown: () => void;
}

export const Dropdown = <T extends any>({
  value,
  options,
  isSearchable = false,
  isLoading,
  onSelect,
  closeDropdown,
}: Props<T>) => {
  const [search, setSearch] = useState<string>("");

  const filteredOptions = useMemo(() => {
    return options
      .filter(
        (option) =>
          !search || option.label.toLowerCase().includes(search.toLowerCase()),
      )
      .sort((option1) => (option1.value === value ? -1 : 1));
  }, [options, search]);

  const onClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  return (
    <DropdownElement onClick={onClick}>
      {isSearchable && (
        <>
          <Search
            search={search}
            setSearch={setSearch}
            isBorderless
            isFocusedAutomatically
            rightIcon={
              !search && { name: "triangleUp", onClick: closeDropdown }
            }
          />
          <DropdownDivider />
        </>
      )}

      {!isLoading && (
        <Scroller size="thin" maxHeight="200px" gutter="auto">
          <Flex column>
            {filteredOptions.map((option, i) => {
              const firstUnsearchableOption = i === 0 && !isSearchable;

              return (
                <Option
                  key={i}
                  label={option.label}
                  value={option.value}
                  icon={
                    firstUnsearchableOption ? { name: "triangleUp" } : undefined
                  }
                  isSelected={value === option.value}
                  onSelect={onSelect}
                />
              );
            })}
          </Flex>

          {filteredOptions.length === 0 && (
            <Option label="No Results..." isSelectable={false} />
          )}
        </Scroller>
      )}

      {isLoading && (
        <Flex padding="10px" justify="center">
          <Spinner size="medium" />
        </Flex>
      )}
    </DropdownElement>
  );
};
