import { ResponseGetMerchflows_merchflowId_BasePog_basePogId_ } from "@CommonApi/merchflows/_merchflowId_/base-pog/_basePogId_";
import { Pog } from "@CommonTypes/merchflow/pog/pog";
import { pages } from "src/utils";
import { omniAtom } from "src/utils/atoms";

const prefix = pages.merchflows_merchflowId_BasePog_basePogId_;

export const merchflows_merchflowId_BasePog_basePogId_Atom = omniAtom({
  prefix,
  key: "data",
  value: undefined as
    | undefined
    | null
    | ResponseGetMerchflows_merchflowId_BasePog_basePogId_,
});

export const merchflows_merchflowId_BasePog_basePogId_PogAtom = omniAtom({
  prefix,
  key: "pog",
  value: null as null | Pog,
});
