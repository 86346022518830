import { PayloadPostMerchflows } from "@CommonApi/merchflows";
import { ResponseGetAnalyticsWorkaroundBeginUpload } from "@CommonApi/merchflows/analytics-workaround/begin-upload";
import { ResponsePostAnalyticsWorkaroundFinishUpload } from "@CommonApi/merchflows/analytics-workaround/finish-upload";
import {
  PayloadMerchFlowApplyMergeRules,
  PayloadMerchFlowLoadFilters,
  PayloadMerchFlowLoadVariants,
  ResponseMerchFlowApplyMergeRules,
  ResponseMerchFlowDetails,
  ResponseMerchFlowLoadCategories,
  ResponseMerchFlowLoadFilters,
  ResponseMerchFlowLoadVariants,
} from "src/omni-common/api/merchandise";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
  API,
} from "src/utils/axios";

export const getMerchFlowLoadCategoriesReq = () =>
  getRequest<ResponseMerchFlowLoadCategories>(`${API}/merchflows/categories`);

export const postMerchFlowNewFlowReviewReq = (payload: PayloadPostMerchflows) =>
  postRequest<ResponseMerchFlowDetails>(`${API}/merchflows`, { payload });

export const putMerchFlowUpdateFlowReviewReq = (
  merchFlowId: number,
  payload: PayloadPostMerchflows,
) =>
  putRequest<ResponseMerchFlowDetails>(`${API}/merchflows/${merchFlowId}`, {
    payload,
  });

export const getMerchFlowLoadFiltersReq = (
  queryParams: PayloadMerchFlowLoadFilters,
) =>
  getRequest<ResponseMerchFlowLoadFilters>(`${API}/merchflows/filters`, {
    queryParams,
  });

export const getMerchFlowLoadVariantsReq = (
  queryParams: PayloadMerchFlowLoadVariants,
) =>
  getRequest<ResponseMerchFlowLoadVariants>(`${API}/merchflows/variants`, {
    queryParams,
  });

export const putMerchFlowApplyMergeRulesReq = (
  merchFlowId: number,
  payload: PayloadMerchFlowApplyMergeRules,
) =>
  putRequest<ResponseMerchFlowApplyMergeRules>(
    `${API}/merchflows/${merchFlowId}/merge-rules`,
    { payload },
  );

export const deleteOldMerchflowData = (queryParams: {
  filter_config_id: number;
}) =>
  deleteRequest(`${API}/frogger/group_and_size_variants`, {
    queryParams: {
      filter_config_id: queryParams.filter_config_id,
    },
  });

export const uploadPsaFile = async (uploadFile: File) => {
  const { data } = await getRequest<ResponseGetAnalyticsWorkaroundBeginUpload>(
    `${API}/merchflows/analytics-workaround/begin-upload`,
  );

  const { fileName } = data;

  // await putPresignedFileUploadReq(url, uploadFile)

  const { data: resultData } =
    await postRequest<ResponsePostAnalyticsWorkaroundFinishUpload>(
      `${API}/merchflows/analytics-workaround/finish-upload`,
      {
        payload: {
          fileName,
          label: uploadFile.name,
        },
      },
    );
  return resultData || null;
};
