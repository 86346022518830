import { Color, Flex, Icon, Select, Text, Toggle } from "src/elements";

import { S2Filter } from "../atoms";
import { blockOptions, cdtTypeOptions, locationOptions } from "../constants";
import { SubgridCell } from "src/elements/SubgridTable/components/SubgridCell";

type FilterRowProps = Partial<S2Filter> & {
  result: number;
  cdtOptions: any[];
  updateRuleData: (key: any, value: any) => void;
  deleteFilter: () => void;
  toggleFilter: () => void;
};

export const FilterRow = ({
  filterNo,
  cdt,
  checked,
  label,
  blockingType,
  location,
  cdtOptions,
  result,
  deleteFilter,
  updateRuleData,
  toggleFilter,
}: FilterRowProps) => (
  <>
    <SubgridCell justify="center" align="center">
      {filterNo}
    </SubgridCell>

    <SubgridCell align="center">
      <Toggle isChecked={Boolean(checked)} setIsChecked={toggleFilter} />
    </SubgridCell>

    <SubgridCell>
      <Select
        setValue={(v) => updateRuleData("cdt", v)}
        value={cdt}
        options={cdtTypeOptions}
        placeholder="type"
      />
    </SubgridCell>

    <SubgridCell>
      <Select
        setValue={(v) => updateRuleData("label", v)}
        value={label}
        options={cdtOptions}
        placeholder="label"
      />
    </SubgridCell>

    <SubgridCell>
      <Select
        setValue={(v) => updateRuleData("blockingType", v)}
        value={blockingType}
        options={blockOptions}
        placeholder="block type"
      />
    </SubgridCell>

    <SubgridCell>
      <Select
        setValue={(v) => updateRuleData("location", v)}
        value={location}
        options={locationOptions}
        placeholder="block location"
      />
    </SubgridCell>

    <SubgridCell align="center">
      <Text color={Color.primary}>{result} flow</Text>
    </SubgridCell>

    <SubgridCell justify="center" align="center">
      <Flex onClick={deleteFilter}>
        <Icon name="delete" color={Color.red} />
      </Flex>
    </SubgridCell>
  </>
);
