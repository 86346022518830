import {
  AlgoGetFeaturesContainer,
  ResponseAlgoGetFeaturesContainer,
} from "@CommonTypes/algo/get_features";
import {
  AlgoS1FreezerInput,
  AlgoS1Parameters,
  AlgoS1Solution,
} from "@CommonTypes/algo/s1";

export const RouteS1PlaceContainersAllTemplates =
  "/frogger/place_containers_all_templates";

export interface ParamsS1PlaceContainersAllTemplates {
  frogger_version: string | undefined;
}

export type PayloadS1PlaceContainersOneTemplate = {
  filter_config_id: number;
  merchflow_id: number;
  template_id: string;
} & {
  freezer: AlgoS1FreezerInput;
  parameters: AlgoS1Parameters;
  containers: AlgoGetFeaturesContainer[];
};

export type PayloadS1PlaceContainersAllTemplates =
  PayloadS1PlaceContainersOneTemplate[];

export type ResponseS1PlaceContainersAllTemplates = {
  getFeaturesResponseStatus: number;
  getFeaturesResponseBody: ResponseAlgoGetFeaturesContainer;
  placeContainerResponseStatus: number;
  placeContainerResponses: ResponseS1PlaceContainers[];
};

export interface ResponseS1PlaceContainers {
  planogram_responses: S1SolutionDB[];
  template_id: string;
  error_details: string | undefined;
  status: "SUCCESS" | "NONE";
}

export type S1SolutionDB = AlgoS1Solution & {
  step1_solution_id: number;
};
