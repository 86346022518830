import {
  PayloadPutInputsProducts,
  RoutePutInputsProducts,
} from "@CommonApi/inputs/products";
import {
  ParamsGetInputsProductsAll,
  ResponseGetInputsProductsAll,
  RouteGetInputsProductsAll,
} from "@CommonApi/inputs/products/all";
import { useInlineLoaders } from "src/components/InlineLoader";
import { useToasts } from "src/components/Toasts";
import { SortingType } from "src/types/Sorting";
// Input Data
import { getRequest, postRequest, putRequest, API } from "src/utils/axios";
export interface InputsListReqPayload {
  uploadId: string | number;
  storeId?: string | number;
  cdt1?: string;
  cdt2?: string;
  page?: number;
  size?: number;
  sortingColumn?: string;
  sortingType?: SortingType;
}
export type GetAllInputReqPayload = {
  pmUploadIds: number[];
  ffUploadIds: number[];
  posUploadIds: number[];
};

export const putPresignedFileUploadReq = (url: string, binaryFile: any) =>
  putRequest<any>(url, {
    contentType: "text/csv",
    xMsBlobType: "BlockBlob",
    payload: binaryFile,
  });

export const postBeginInputsUploadReq = (catId: number) =>
  postRequest<any>(`${API}/inputs/begin-upload`, {
    payload: {
      catId,
    },
  });

export const postFinishInputsUploadReq = (payload: {
  files: [{ label: string; fileName: string }];
  createNewStores?: boolean;
  isValidationRequired?: boolean;
  pmUploadId?: number;
}) =>
  postRequest<any>(`${API}/inputs/finish-upload`, {
    payload,
  });

export const getAllInputsReq = (payload: GetAllInputReqPayload) =>
  postRequest<any>(`${API}/merchflows/merge-rules-input`, { payload });

const getInputsProductsAllReq = (queryParams: ParamsGetInputsProductsAll) => {
  return getRequest<ResponseGetInputsProductsAll>(
    `${API}${RouteGetInputsProductsAll}`,
    {
      queryParams,
    },
  );
};

const putInputsProductsReq = (payload: PayloadPutInputsProducts) => {
  return putRequest<unknown>(`${API}${RoutePutInputsProducts}`, {
    payload,
  });
};

export const useInputsApi = () => {
  const { addInlineLoader, removeInlineLoader } = useInlineLoaders();
  const { toast } = useToasts();

  const getInputsProductsAllApi = async (
    queryParams: ParamsGetInputsProductsAll,
  ) => {
    let response: ResponseGetInputsProductsAll | null = null;
    addInlineLoader(RouteGetInputsProductsAll);

    try {
      response = (await getInputsProductsAllReq(queryParams)).data;
    } catch (error) {
      toast({
        title: "Failed to load products",
        error,
      });
    }

    removeInlineLoader(RouteGetInputsProductsAll);
    return response;
  };

  const putInputsProductsApi = async (payload: PayloadPutInputsProducts) => {
    let response = false;
    addInlineLoader(RoutePutInputsProducts);

    try {
      await putInputsProductsReq(payload);
      response = true;
    } catch (error) {
      toast({
        title: "Failed to update products",
        error,
      });
    }

    removeInlineLoader(RoutePutInputsProducts);
    return response;
  };

  return { getInputsProductsAllApi, putInputsProductsApi };
};
