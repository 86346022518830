import React from "react";
import { Color, Grid } from "src/elements";
import styled from "styled-components";

const RowElement = styled(Grid)`
  grid-column: 1 / -1;
  grid-template-columns: subgrid;
  top: 0;

  &:nth-of-type(even) {
    background-color: ${Color.lightGray};
  }
`;

interface Props {
  children: React.ReactNode;
}

export const SubgridRow = ({ children }: Props) => (
  <RowElement>{children}</RowElement>
);
