import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useUser } from "src/atoms/user";
import {
  getLoginToken,
  pages,
  removeLoginToken,
  setRedirectUrl,
} from "src/utils";
import { useNavigation } from "src/utils/navigation";
import { Modals } from "../Modals/Modals";
import { useApi } from "src/api";

export const IsAuthorized = () => {
  const { navigate } = useNavigation();
  const { getVerifyUserApi } = useApi();
  const { user, setUser } = useUser();

  useEffect(() => {
    if (!user) {
      verifyUser();
    }
  }, []);

  const verifyUser = async () => {
    // Login token not found.
    if (!getLoginToken()) {
      setRedirectUrl(window.location.pathname);
      navigate(pages.login);
      return;
    }

    // Login token found.
    const response = await getVerifyUserApi();

    // Login token expired.
    if (!response) {
      removeLoginToken();
      navigate(pages.login);
      return;
    }

    // Login token used and user verified.
    setUser(response);
  };

  // Pages needing authentication are rendered empty until user is verified.
  if (!user) {
    return null;
  }

  // Page content is only rendered once user is authorized.
  return (
    <>
      <Outlet />
      <Modals />
    </>
  );
};
