import { Transition } from "src/utils";
import styled from "styled-components";

import { getProgressColor } from "./store/utils";
import { Color, Flex, Skeleton, Text } from "src/elements";

const ProgressBarElement = styled.div<{
  percent: number;
  height: string;
  color: string;
}>`
  width: ${({ percent }) => `${percent}%`};
  display: flex;
  height: 100%;
  background-color: ${({ color }) => color};
  border-radius: ${({ height }) => `0 ${height} ${height} 0`};
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: width ${Transition.slow};
`;

interface Props {
  percent?: number | null;
  color?: string;
  height?: string;
  isInline?: boolean;
  isLoading?: boolean;
}

export const ProgressBar = ({
  percent,
  color: defaultColor,
  height = "12px",
  isInline,
  isLoading,
}: Props) => {
  if (isLoading) {
    return (
      <Flex
        width="100%"
        height={height}
        borderRadius={height}
        overflow="hidden"
      >
        <Skeleton width="100%" height="100%" />
      </Flex>
    );
  }

  const color =
    (defaultColor !== undefined && defaultColor) || getProgressColor(percent);

  return (
    <Flex width="100%" height={height} borderRadius={height} overflow="hidden">
      <Flex
        width="100%"
        height={height}
        borderRadius={height}
        background={Color.textDisabled}
        overflow="hidden"
      >
        <ProgressBarElement
          percent={percent || 0}
          height={height}
          color={color}
        >
          {isInline && (
            <Text variant="caption2" color={Color.white}>
              {percent ? `${percent.toFixed(2)}%` : "-"}
            </Text>
          )}
        </ProgressBarElement>
      </Flex>
    </Flex>
  );
};
