import { Flex, Input } from "src/elements";

import { froggerVersionAtom } from "../../store/atoms";
import { useAtom } from "jotai";
import { isDev } from "src/utils/env";

export const MerchandiseSettings = () => {
  const [froggerVersion, setFroggerVersion] = useAtom(froggerVersionAtom);

  return (
    <Flex margin="10px 0 5px 0">
      {isDev() && (
        <Input
          width="120px"
          title="Frogger version"
          value={froggerVersion}
          setValue={setFroggerVersion}
        />
      )}
    </Flex>
  );
};
