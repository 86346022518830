import { PayloadAlgoS2 } from "@CommonTypes/algo/s2";

export const UrlS2PlaceVariantsBatchStart =
  "/frogger/place_variants/batch/start";

export interface ParamsS2PlaceVariantsBatchStart {
  frogger_version: string;
}

export type PayloadS2PlaceVariantsBatchStart =
  PayloadS2PlaceVariantsBatchStartObject[];

export type PayloadS2PlaceVariantsBatchStartObject = {
  filter_config_id: number;
  merchflow_id: number;
  template_id: string;
  step1_solution_id: number;
} & PayloadAlgoS2;

export type ResponseS2PlaceVariantsBatchStart = {
  merchflow_template_place_variants_id: string;
}[];
