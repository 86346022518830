import { useNavigate } from "react-router-dom";
import { routeToUrl } from "./axios";

export const useNavigation = () => {
  const reactRouterDomNavigate = useNavigate();

  const navigate = (
    url: string,
    params?: { [key: string]: string | number },
  ) => {
    reactRouterDomNavigate(routeToUrl(url, params));
  };

  const replaceUrlWithoutNavigation = (url: string) => {
    window.history.replaceState(window.history.state, "", url);
  };

  return {
    navigate,
    replaceUrlWithoutNavigation,
  };
};
