import { useAtomValue } from "jotai";
import React from "react";
import styled, { StyledComponent } from "styled-components";

import { useUser } from "src/atoms/user";
import { planogramImagesModeAtom } from "src/components/PlanogramExtensions/store/atoms";
import { env } from "src/utils/env";

interface ImageProps extends Props {
  merchantId: number;
  imagesMode: boolean;
  size?: "small" | "original";
}

const ImageElement: StyledComponent<"div", any, ImageProps, never> =
  styled.div.attrs<ImageProps>(({ merchantId, productCode, size }) => ({
    style: {
      backgroundImage: `url(${
        env.VITE_IMAGE_BASE_URL
      }/merchants/${merchantId}/product-images${
        size === "small" ? "/100x100" : ""
      }/${productCode}.jpg)`,
    },
  }))`
    width: ${({ fixedSize }: ImageProps) => fixedSize || "100%"};
    min-width: ${({ fixedSize }: ImageProps) => fixedSize || "100%"};
    height: ${({ fixedSize }: ImageProps) => fixedSize || "100%"};
    min-height: ${({ fixedSize }: ImageProps) => fixedSize || "100%"};
    background-size: ${({ fixedSize }) =>
      fixedSize ? "contain" : "100% 100%"};
    background-position: center;
    background-repeat: no-repeat;
    visibility: ${({ imagesMode }) => !imagesMode && "hidden"};
  `;

interface Props {
  productCode: string;
  fixedSize?: string;
  size?: "small" | "original";
}

export const ProductImage: React.FC<Props> = ({
  productCode,
  fixedSize,
  size = "original",
}) => {
  const { user } = useUser();

  const imagesMode = useAtomValue(planogramImagesModeAtom);

  return (
    <ImageElement
      merchantId={user.merchantId}
      productCode={productCode}
      fixedSize={fixedSize}
      imagesMode={imagesMode}
      size={size}
    />
  );
};
