import {
  PayloadPostMerchflowApplyToBases,
  ResponsePostMerchflowApplyToBases,
  RoutePostMerchflowApplyToBases,
} from "@CommonApi/merchflow/apply-to-bases";
import {
  ResponseGetMerchflowStoreProp_storePropagationId_,
  RouteGetMerchflowStoreProp_storePropagationId_,
} from "@CommonApi/merchflow/store-prop/_storePropagationId_";
import {
  ResponseGetMerchflows_MerchflowId_Stores_StoreCode_,
  RouteGetMerchflows_MerchflowId_Stores_StoreCode_,
} from "@CommonApi/merchflows/_merchflowId_/stores/_storeCode_";
import { RequiredPaginationQueryParams } from "@CommonTypes/backend/pagination";
import { useInlineLoaders } from "src/components/InlineLoader";
import { useToasts } from "src/components/Toasts";
import { API, getRequest, postRequest } from "src/utils/axios";

export const postMerchflowStartStorePlanogramsGenerationReq = (
  payload: PayloadPostMerchflowApplyToBases,
) => {
  return postRequest<ResponsePostMerchflowApplyToBases>(
    `${API}${RoutePostMerchflowApplyToBases}`,
    {
      payload,
    },
  );
};

export const getMerchflows_MerchflowId_StoresReq = (
  merchflowId: number,
  queryParams: RequiredPaginationQueryParams,
) => {
  return getRequest<ResponseGetMerchflowStoreProp_storePropagationId_>(
    `${API}${RouteGetMerchflowStoreProp_storePropagationId_}`,
    {
      params: {
        merchflowId,
      },
      queryParams,
    },
  );
};

export const getMerchflows_MerchflowId_Stores_StoreCode_Req = (
  merchflowId: number,
  storeCode: string,
  queryParams: RequiredPaginationQueryParams,
) => {
  return getRequest<ResponseGetMerchflows_MerchflowId_Stores_StoreCode_>(
    `${API}${RouteGetMerchflows_MerchflowId_Stores_StoreCode_}`,
    {
      params: {
        merchflowId,
        storeCode,
      },
      queryParams,
    },
  );
};

export const useMerchflowStoresApi = () => {
  const { toast } = useToasts();
  const { addInlineLoader, removeInlineLoader } = useInlineLoaders();

  const postMerchflowStartStorePlanogramsGenerationApi = async (
    payload: PayloadPostMerchflowApplyToBases,
  ) => {
    addInlineLoader(RoutePostMerchflowApplyToBases);
    let result = null;

    try {
      const {
        data: { store_pog_trigger_id },
      } = await postMerchflowStartStorePlanogramsGenerationReq(payload);
      result = store_pog_trigger_id;
    } catch (error) {
      toast({
        title: "Failed to start store planograms generation",
        error,
      });
    }

    removeInlineLoader(RoutePostMerchflowApplyToBases);
    return result;
  };

  const getMerchflowStoresApi = async (
    setData: (
      data: ResponseGetMerchflowStoreProp_storePropagationId_ | null,
    ) => void,
    merchflowId: number,
    queryParams: RequiredPaginationQueryParams,
  ) => {
    addInlineLoader("merchflow/:merchflowId/stores");

    try {
      const { data } = await getMerchflows_MerchflowId_StoresReq(
        merchflowId,
        queryParams,
      );

      setData(data);
    } catch (error) {
      toast({
        title: "Failed to load stores",
        error,
      });
      setData(null);
    }

    removeInlineLoader("merchflow/:merchflowId/stores");
  };

  const getMerchflowStorePogsApi = async ({
    merchflowId,
    storeCode,
    pagination,
    setData,
  }: {
    merchflowId: number;
    storeCode: string;
    pagination: RequiredPaginationQueryParams;
    setData: (
      data: ResponseGetMerchflows_MerchflowId_Stores_StoreCode_ | null,
    ) => void;
  }) => {
    addInlineLoader("merchflows/:merchflowId/stores/:storeCode");

    try {
      const { data } = await getMerchflows_MerchflowId_Stores_StoreCode_Req(
        merchflowId,
        storeCode,
        pagination,
      );
      setData(data);
    } catch (error) {
      toast({
        title: "Failed to load store pogs",
        error,
      });
      setData(null);
    }

    removeInlineLoader("merchflows/:merchflowId/stores/:storeCode");
  };

  return {
    postMerchflowStartStorePlanogramsGenerationApi,
    getMerchflowStoresApi,
    getMerchflowStorePogsApi,
  };
};
