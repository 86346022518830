import { useSetAtom } from "jotai";
import { useModals } from "src/components/Modals";
import { storePlanogramReviewerAtom } from "./atoms";
import { StoreVersionsView } from "@CommonApi/merchflows/_merchflowId_/stores/_storeCode_";

export const useStorePlanogramReviewerModal = () => {
  const { openModal } = useModals();
  const setData = useSetAtom(storePlanogramReviewerAtom);

  const openStorePlanogramReviewerModal = (data: {
    merchflowId: number;
    storePlanograms: StoreVersionsView[];
    planogramIndex: number;
  }) => {
    setData(data);
    openModal("StorePlanogramReviewerModal");
  };

  return { openStorePlanogramReviewerModal };
};
