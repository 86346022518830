import { useRef } from "react";

import { InnerInput } from "./components/InnerInput";
import { InputContainer } from "./components/InputContainer";
import { InputError } from "./components/InputError";
import { InputIcon } from "./components/InputIcon";
import { InputOuterContainer } from "./components/InputOuterContainer";
import { InputTitle } from "./components/InputTitle";
import { CommonProps, IconProps } from "./store/types";
import { useHoverClickable } from "src/utils/hoverClickable";

interface InputProps extends CommonProps {
  value: string;
  setValue: (value: string) => void;
  type?: "text" | "password";
  rightIcon?: IconProps | false;
}

export const Input = ({
  value,
  setValue,
  title,
  type = "text",
  isRequired = false,
  isDisabled = false,
  isBorderless = false,
  isFocusedAutomatically = false,
  isSpellchecked,
  error: _error = false,
  placeholder,
  width = "100%",
  leftIcon,
  rightIcon,
  refInput: refInputForwarded,
}: InputProps) => {
  const refInput = useRef<HTMLInputElement>(null);
  const { isHovered, isFocused } = useHoverClickable(
    refInputForwarded || refInput,
  );

  const error =
    _error || (isRequired && value === "" && `${title} is required`);

  return (
    <InputOuterContainer width={width}>
      <InputContainer
        width={width}
        isHovered={isHovered}
        isFocused={isFocused}
        isDisabled={isDisabled}
        isBorderless={isBorderless}
      >
        <InputTitle
          isActive={isFocused}
          isRequired={isRequired}
          isDisabled={isDisabled}
          label={title}
          error={error}
        />

        <InputIcon side="left" icon={leftIcon} />

        <InnerInput
          refInput={refInputForwarded || refInput}
          type={type}
          value={value}
          setValue={setValue}
          placeholder={placeholder}
          isDisabled={isDisabled}
          isFocusedAutomatically={isFocusedAutomatically}
          isSpellchecked={isSpellchecked}
          paddingLeft={Boolean(leftIcon)}
          paddingRight={Boolean(rightIcon)}
        />

        <InputIcon side="right" icon={rightIcon} />
      </InputContainer>

      <InputError error={error} />
    </InputOuterContainer>
  );
};
