import { adapterPogNonCircularToPog } from "@CommonAdapters/pog";
import { atom, useAtomValue } from "jotai";
import noop from "lodash/noop";
import { useMemo } from "react";
import { useInlineLoaders } from "src/components/InlineLoader";
import { useModals } from "src/components/Modals";
import { Flex, Spinner, Text } from "src/elements";
import { emptyPOGAtom } from "src/pages/MerchflowTemplateBasePogs/store/atom";
import {
  merchflows_merchflowId_StoresAllAtom,
  Stores_StoreAll_storePogIndexAtom,
} from "src/pages/Merchflows_merchflowId_StoresAll/store/atoms";
import { emptyContainersAtom } from "src/pages/merchandise/store/atoms";
import { pages } from "src/utils";
import { routeToUrl } from "src/utils/axios";
import { useNavigation } from "src/utils/navigation";
import { ReviewActionModal } from "./ReviewActionModal";
import {
  OneStoreView,
  OneStoreViewStatus,
} from "@CommonTypes/merchflow/OneStoreView";
import { RoutePutMerchflows_MerchflowId_StoresApprovals } from "@CommonApi/merchflows/_merchflowId_/stores/approvals";
import { useStores_AllStoreData } from "src/pages/Merchflows_merchflowId_StoresAll/store/hooks";
import { getStoresPerRequest } from "src/pages/Merchflows_merchflowId_StoresAll/store/utils";
import { StoresViewMode } from "src/pages/Merchflows_merchflowId_StoresAll/store/types";

const generateDropdownOptions = ({
  navigateToBasePogPage,
  navigateToTemplatePage,
  navigateToCreateTemplatePlanogram,
  view,
}: {
  navigateToEditMerchflow: () => void;
  navigateToBasePogPage: () => void;
  navigateToEditStorePog: () => void;
  navigateToTemplatePage: (templateId: string) => void;
  navigateToCreateTemplatePlanogram: () => void;
  view: OneStoreView;
}) => {
  if (view.status === OneStoreViewStatus.NONE) {
    return [
      {
        label: "Create Merch flow",
        onClick: noop,
      },
      {
        label: "Create Template",
        onClick: noop,
      },
    ];
  }
  if (view.status === OneStoreViewStatus.S2) {
    return [
      // {
      //   label: "Edit Merch flow",
      //   onClick: noop,
      // },
      {
        label: "Create Template Planogram",
        onClick: navigateToCreateTemplatePlanogram,
      },
    ];
  }

  if (view.status === OneStoreViewStatus.BASE_POG) {
    return [
      {
        label: "Favorite",
        onClick: noop,
      },
      {
        label: "Unfavorite",
        onClick: noop,
      },
      // {
      //   label: "Edit Merch flow",
      //   onClick: noop,
      // },
      {
        label: "Edit Template Planogram",
        onClick: navigateToBasePogPage,
      },
      {
        label: "Create Template Planogram",
        onClick: navigateToCreateTemplatePlanogram,
      },
      {
        label: "View All Template Versions",
        onClick: () => navigateToTemplatePage(view.template_id),
      },
    ];
  }
  return [];
};

const generatePogAtom = (view: OneStoreView) => {
  if (
    [
      OneStoreViewStatus.MULTIPLE_STORE_POGS_APPROVED,
      OneStoreViewStatus.NO_STORE_POGS_APPROVED,
      OneStoreViewStatus.ONE_STORE_POG_APPROVED,
    ].includes(view.status)
  ) {
    return atom(adapterPogNonCircularToPog(view.store_pog?.store_pog_data!));
  }
  if (view.status === OneStoreViewStatus.BASE_POG) {
    return atom(adapterPogNonCircularToPog(view.base_pog?.base_pog_data!));
  }
  if (view.status === OneStoreViewStatus.S2) {
    return atom(
      adapterPogNonCircularToPog(view.s2_solution?.s2_solution_data.pog!),
    );
  }
  return emptyPOGAtom;
};

export const StoreAll_StoreReviewAction = () => {
  const { closeModal } = useModals();
  const storePropApiResult = useAtomValue(merchflows_merchflowId_StoresAllAtom);
  const storePogIndex = useAtomValue(Stores_StoreAll_storePogIndexAtom);
  const { navigate } = useNavigation();
  const { isInlineLoading } = useInlineLoaders();
  const { merchflowId, storeCode } = useStores_AllStoreData({
    size: getStoresPerRequest(StoresViewMode.GRID),
  });

  const storePropData = storePropApiResult?.data || [];
  const allStorePogAtom = useMemo(() => storePropData.map(generatePogAtom), []);

  const currentStorePog = storePropData[storePogIndex];
  const containerAtom =
    currentStorePog.status === OneStoreViewStatus.S2
      ? atom(
          storePropData[storePogIndex].s2_solution?.s2_solution_data
            .containers!,
        )
      : emptyContainersAtom;

  const onClose = () => {
    closeModal("StoreAll_StoreReviewAction");
  };
  const navigateToEditMerchflow = () => {
    // TODO wait for BE to provide basepog ID
    window.open(`${pages.merchandise}/${merchflowId}`);
    onClose();
  };

  const navigateToBasePogPage = () => {
    onClose();
    navigate(
      routeToUrl(pages.merchflows_merchflowId_BasePog_basePogId_, {
        merchflowId: merchflowId!,
        basePogId: currentStorePog.base_pog?.base_pog_id!,
      }),
    );
  };

  const navigateToTemplatePage = (templateId: string) => {
    navigate(
      routeToUrl(pages.merchflowTemplateBasePogs, {
        merchflowId: merchflowId!,
        templateId,
      }),
    );
    onClose();
  };
  const navigateToEditStorePog = () => {
    navigate(
      routeToUrl(
        pages.merchflows_merchflowId_Stores_storeCode_Pogs_storePogId_,
        {
          merchflowId: merchflowId!,
          storeCode: storeCode!,
          storePogId: storePropData[storePogIndex].store_pog?.store_pog_id!,
        },
      ),
    );
    onClose();
  };

  const navigateToCreateTemplatePlanogram = () => {
    closeModal("StoreAll_StoreReviewAction");
    navigate(
      routeToUrl(pages.merchflows_merchflowId_S2Templates_templateId_, {
        merchflowId,
        templateId: storePropData[storePogIndex].template_id,
      }),
    );
  };

  const dropdown = generateDropdownOptions({
    navigateToBasePogPage,
    navigateToEditMerchflow,
    navigateToEditStorePog,
    view: currentStorePog,
    navigateToTemplatePage,
    navigateToCreateTemplatePlanogram,
  });

  return (
    <>
      {isInlineLoading(RoutePutMerchflows_MerchflowId_StoresApprovals) && (
        <Spinner isFullscreen />
      )}
      <ReviewActionModal
        modalName="StoreAll_StoreReviewAction"
        dropdown={{
          options: dropdown,
          label: "STORE OPTIONS",
        }}
        containersAtom={containerAtom}
        pogAtom={allStorePogAtom[storePogIndex]}
        numberOfPog={storePropData.length}
        footer={
          <Flex gap="5px">
            <Text>Store: {currentStorePog?.store}</Text>

            {currentStorePog.base_pog?.base_pog_id && (
              <Text>
                | Base Planogram ID: {currentStorePog.base_pog.base_pog_id}
              </Text>
            )}

            <Text>| TemplateID: {currentStorePog?.template_id}</Text>
          </Flex>
        }
      />
    </>
  );
};
