import { useAtom } from "jotai";
import {
  Button,
  Color,
  Flex,
  Modal,
  Scroller,
  SubgridTable,
} from "src/elements";
import { violationReportModalMerchflowIdAtom } from "./store/atoms";
import { useEffect, useState } from "react";
import {
  ResponseGetViolationMerchflow_merchflowId_,
  RouteGetViolationMerchflow_merchflowId_,
} from "@CommonApi/violation/merchflow/_merchflowId_";
import { useApi } from "src/api";
import { useInlineLoaders } from "src/components/InlineLoader";
import { PageStatus } from "src/components";
import { useMerchflowReviewUpdateModal } from "../MerchflowReviewUpdate/store/hooks";
import { useModals } from "src/components/Modals";
import { useMerchflowReviewUpdateModalV1 } from "../MerchflowReviewUpdateV1/store/hooks";
import { useIsFeatureFlagEnabled } from "src/components/FeatureFlagProvider/store/utils";

const columns = [
  { id: "violation_type", header: "Violation Type" },
  { id: "violation_message", header: "Violation" },
  { id: "stores_affected", header: "Stores Affected" },
];

export const ViolationsReportModal = () => {
  const [merchflowId, setMerchflowId] = useAtom(
    violationReportModalMerchflowIdAtom,
  );

  useEffect(() => {
    return () => {
      setMerchflowId(null);
    };
  }, []);

  return merchflowId ? (
    <ViolationReportModalInner merchflowId={merchflowId} />
  ) : null;
};

interface Props {
  merchflowId: number;
}

const ViolationReportModalInner = ({ merchflowId }: Props) => {
  const { getViolationReportApi } = useApi();
  const { isInlineLoading } = useInlineLoaders();
  const { openMerchflowReviewUpdateModalV1 } =
    useMerchflowReviewUpdateModalV1();
  const { openMerchflowReviewUpdateModal } = useMerchflowReviewUpdateModal();
  const { closeModal } = useModals();
  const featureFlagUpdateApiV2 = useIsFeatureFlagEnabled("updateApiV2");

  const [violationReport, setViolationReport] =
    useState<ResponseGetViolationMerchflow_merchflowId_ | null>(null);
  const isViolationReportLoading = isInlineLoading(
    RouteGetViolationMerchflow_merchflowId_,
  );

  useEffect(() => {
    loadViolationsReport();
  }, []);

  const loadViolationsReport = async () => {
    setViolationReport(await getViolationReportApi(merchflowId));
  };

  const openUpdateModal = () => {
    closeModal("ViolationsReportModal");

    setTimeout(() => {
      if (featureFlagUpdateApiV2) {
        openMerchflowReviewUpdateModal({
          merchflowId,
          violations: violationReport || undefined,
        });
      } else {
        openMerchflowReviewUpdateModalV1({
          merchflowId,
          violations: violationReport || undefined,
        });
      }
    }, 500);
  };

  return (
    <Modal title="Violation Report" name="ViolationsReportModal" width="500px">
      <Flex column gap="20px" padding="20px 10px" grow>
        <Flex height="300px">
          {isViolationReportLoading && (
            <PageStatus icon="spinner" label="Loading violations report..." />
          )}

          {!isViolationReportLoading && !violationReport && (
            <PageStatus
              icon="alert"
              label="Violation Report failed to load..."
              color={Color.red}
            />
          )}

          {violationReport && (
            <Scroller gutter="stable both-edges">
              <SubgridTable
                columns={columns}
                data={violationReport.data}
                isSortable
              />
            </Scroller>
          )}
        </Flex>

        <Flex gap="20px" width="100%" justify="center">
          <Button onClick={openUpdateModal}>Update</Button>
        </Flex>
      </Flex>
    </Modal>
  );
};
