import { Button, Flex, Spinner } from "src/elements";
import { MerchflowReviewStep } from "../store/types";
import { StoresSelector } from "src/components";
import { useState } from "react";
import { StoresSelectorStore } from "src/components/StoresSelector/store/types";
import {
  ResponseGetMerchflowsFilters,
  RouteGetMerchflowsFilters,
} from "@CommonApi/merchflows/filters";
import { useInlineLoaders } from "src/components/InlineLoader";
import { ResponsePostMerchflows } from "@CommonApi/merchflows";
import { useNavigation } from "src/utils/navigation";
import { routeToUrl } from "src/utils/axios";
import { pages } from "src/utils";
import { useModals } from "src/components/Modals";
import { useMerchflowsApi } from "src/api/merchflows";
import { RoutePutMerchflowsFilterConfig_filterConfigId_ } from "@CommonApi/merchflows/filter-config/_filterConfigId_";

interface Props {
  setStep: (step: MerchflowReviewStep) => void;
  startFlowResponse: ResponsePostMerchflows | null;
  availableFilters: ResponseGetMerchflowsFilters | null;
}

export const Step2Stores = ({
  // setStep,
  startFlowResponse,
  availableFilters,
}: Props) => {
  const { isInlineLoading } = useInlineLoaders();
  const { putMerchflowsFilterConfig_filterConfigIdApi } = useMerchflowsApi();
  const { navigate } = useNavigation();
  const { closeModal } = useModals();

  const isFiltersLoading = isInlineLoading(RouteGetMerchflowsFilters);

  const [selectedStores, setSelectedStores] = useState<StoresSelectorStore[]>(
    [],
  );

  if (isFiltersLoading || !startFlowResponse) {
    return (
      <Flex grow>
        <Spinner size="big" label="Stores are loading..." isCentered />
      </Flex>
    );
  }

  const toBasePogPage = () => {
    if (!startFlowResponse || !startFlowResponse.base_pog_id) return;

    navigate(
      routeToUrl(pages.merchflows_merchflowId_BasePog_basePogId_, {
        merchflowId: startFlowResponse.id,
        basePogId: startFlowResponse.base_pog_id,
      }),
    );
    closeModal("ModalMerchflowPSAReview");
  };

  const saveFilterConfig = async () => {
    if (!availableFilters || !startFlowResponse.filter_config_id) return;

    const selectedStoreIds = selectedStores.map((store) => store.store);
    const stores = availableFilters.stores.filter((store) =>
      selectedStoreIds.includes(store.store),
    );

    await putMerchflowsFilterConfig_filterConfigIdApi(
      Number(startFlowResponse.filter_config_id),
      {
        filter_config: {
          stores,
          baySizes: [],
          clusters: [],
          states: [],
          cdt: {},
          hierarchy: {},
          fixtures: {
            section_height: [],
            section_width: [],
          },
        },
      },
    );
    toBasePogPage();
  };

  return (
    <Flex column grow>
      <Flex grow>
        {availableFilters && (
          <StoresSelector
            availableStores={availableFilters.stores.map((s) => ({
              store: s.store,
              bay_size: s.bay_size,
              business_type: s.business_type,
              activity_type: s.activity_type,
            }))}
            selectedStores={selectedStores}
            setSelectedStores={setSelectedStores}
          />
        )}
      </Flex>

      <Flex gap="10px" justify="right">
        <Button
          isDisabled={selectedStores.length === 0}
          isLoading={isInlineLoading(
            RoutePutMerchflowsFilterConfig_filterConfigId_,
          )}
          onClick={saveFilterConfig}
        >
          Next ({selectedStores.length}
          {selectedStores.length === 1 ? " store" : " stores"})
        </Button>
      </Flex>
    </Flex>
  );
};
