import { PlanogramItem } from "@CommonTypes/merchflow/pog/pog";
import hexToRgba from "hex-to-rgba";
import {
  getPlanogramItemHeight,
  getPlanogramItemUnitsWide,
  getPlanogramItemWidth,
  getPlanogramitemUnitsHigh,
} from "src/omni-common/utils/pog/item";
import styled from "styled-components";

import { ProductImage } from "./Image";
import { Grid } from "src/elements";

const FacingElement = styled.div<{
  width: number;
  height: number;
  scaleX: number;
  scaleY: number;
}>`
  display: grid;
  width: ${({ width, scaleX }) => `calc(${width}px * ${scaleX})`};
  height: ${({ height, scaleY }) => `calc(${height}px * ${scaleY})`};
  background-color: ${hexToRgba("#e2dcf3", 1)};
  border-radius: 2px;
  pointer-events: none;
  overflow: hidden;

  > * {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }
`;

interface Props {
  item: PlanogramItem;
  scaleX: number;
  scaleY: number;
}

export const Facing = ({ item, scaleX, scaleY }: Props) => {
  const width = getPlanogramItemWidth(item);
  const height = getPlanogramItemHeight(item);
  const unitsWide = getPlanogramItemUnitsWide(item);
  const unitsHigh = getPlanogramitemUnitsHigh(item);

  return (
    <FacingElement
      width={width}
      height={height}
      scaleX={scaleX}
      scaleY={scaleY}
    >
      <Grid
        columns={`repeat(${unitsWide}, 1fr)`}
        rows={`repeat(${unitsHigh}, 1fr)`}
      >
        {Array.from(Array(unitsWide * unitsHigh)).map((_row, i) => (
          <ProductImage key={i} productCode={item.productCode} size="small" />
        ))}
      </Grid>
    </FacingElement>
  );
};
