import { Page, Planogram } from "src/components";
import { MerchflowHeader } from "../../components/merchflows/MerchflowHeader/MerchflowHeader";
import { useParams } from "react-router-dom";
import { MerchflowStepsNavigator } from "../../components/merchflows/MerchflowStepsNavigator/MerchflowStepsNavigator";
import {
  merchflows_merchflowId_S2_s2SolutionId_ContainersAtom,
  merchflows_merchflowId_S2_s2SolutionId_PogAtom,
} from "./store/atoms";
import { PrimitiveAtom, useAtom } from "jotai";
import { useEffect, useRef } from "react";
import { useMerchflowS2Api } from "src/api/merchflows/s2";
import { useInlineLoaders } from "src/components/InlineLoader";

import { Flex, Spinner, Color, Text, Button, Icon } from "src/elements";
import {
  adapterPogNonCircularToPog,
  adapterPogToPogNonCircular,
} from "@CommonAdapters/pog";
import { Pog } from "@CommonTypes/merchflow/pog/pog";
import { PlanogramContainer } from "@CommonTypes/merchflow/pog/container";
import { useNavigation } from "src/utils/navigation";
import { routeToUrl } from "src/utils/axios";
import { pages } from "src/utils";
import { PlanogramMetrics } from "src/components/PlanogramExtensions/PlanogramMetrics";
import { PlanogramHovererDetails } from "src/components/PlanogramExtensions/PlanogramHovererDetails/PlanogramHovererDetails";
import {
  ResponseGetMerchflowsS2_s2SolutionId_,
  RouteGetMerchflowsS2_s2SolutionId_,
} from "@CommonApi/merchflows/s2/_s2SolutionId_";
import { useApi } from "src/api";
import { useAsyncState } from "src/hooks/useAsyncState";

export const PageMerchflows_merchflowId_S2_s2SolutionId_ = () => {
  const { merchflowId: _merchflowId, s2SolutionId: _s2SolutionId } = useParams<{
    merchflowId: string;
    s2SolutionId: string;
  }>();
  const merchflowId = Number(_merchflowId);
  const s2SolutionId = Number(_s2SolutionId);

  const { getMerchflowsS2SolutionApi } = useApi();
  const { postMerchflowCdt2MudmapApi } = useMerchflowS2Api();
  const { isInlineLoading } = useInlineLoaders();
  const { navigate } = useNavigation();

  const [data, setData] =
    useAsyncState<ResponseGetMerchflowsS2_s2SolutionId_>();
  const dataRef = useRef(data);

  const [s2Pog, setS2Pog] = useAtom(
    merchflows_merchflowId_S2_s2SolutionId_PogAtom,
  );
  const [s2Containers, setS2Containers] = useAtom(
    merchflows_merchflowId_S2_s2SolutionId_ContainersAtom,
  );

  const isS2SolutionLoading =
    data === undefined || isInlineLoading(RouteGetMerchflowsS2_s2SolutionId_);
  const isBasePlanogramGenerationLoading = isInlineLoading(
    "POST_merchflow/cdt2-mudmap",
  );

  // Load S2 solution.
  useEffect(() => {
    setData(getMerchflowsS2SolutionApi(s2SolutionId));

    // Clear S2 solution.
    return () => {
      setS2Pog(null);
      setS2Containers(null);
    };
  }, []);

  // Save POG and containers once to atom.
  useEffect(() => {
    if (
      data?.solution_fe_format &&
      (dataRef.current === null || s2Pog === null)
    ) {
      setS2Pog(adapterPogNonCircularToPog(data.solution_fe_format.pog));
      setS2Containers(data.solution_fe_format.containers);
      dataRef.current = data;
    }
  }, [data]);

  const onGenerateBasePlanogram = async () => {
    if (s2Pog && s2Containers) {
      const response = await postMerchflowCdt2MudmapApi({
        merchflow_id: merchflowId,
        solution_id: s2SolutionId,
        containers: s2Containers,
        pog_fixtures: adapterPogToPogNonCircular(s2Pog),
      });

      if (response?.basePogId) {
        navigate(
          routeToUrl(pages.merchflows_merchflowId_BasePog_basePogId_, {
            merchflowId,
            basePogId: response.basePogId,
          }),
        );
      }
    }
  };

  const backToQuadView = () => {
    if (!data) return;
    navigate(
      routeToUrl(pages.merchflows_merchflowId_S2Templates_templateId_, {
        merchflowId,
        templateId: data.template_id,
      }),
    );
  };

  return (
    <Page>
      <MerchflowHeader
        merchflowId={merchflowId}
        templateId={data?.template_id || null}
      />

      <MerchflowStepsNavigator activeStep="S2" merchflowId={merchflowId} />

      <Flex minHeight="0" grow>
        {isS2SolutionLoading && (
          <Spinner size="big" label="Mudmap is loading..." isCentered />
        )}

        {data === null && (
          <Flex
            justify="center"
            align="center"
            column
            gap="10px"
            width="100%"
            height="100%"
          >
            <Icon name="alert" color={Color.yellow} size={40} />

            <Text variant="h3" color={Color.yellow}>
              Mudmap not found
            </Text>
          </Flex>
        )}

        {data?.solution_fe_format && s2Pog && s2Containers && (
          <Planogram
            pogAtom={
              merchflows_merchflowId_S2_s2SolutionId_PogAtom as unknown as PrimitiveAtom<Pog>
            }
            containersAtom={
              merchflows_merchflowId_S2_s2SolutionId_ContainersAtom as unknown as PrimitiveAtom<
                PlanogramContainer[]
              >
            }
            isEditable
            merchflowId={merchflowId}
            isWithBorder
            isContainersFullDetails
            isMetricsRecalculated={false}
          />
        )}
      </Flex>

      <PlanogramHovererDetails />

      <Flex justify="between" align="center">
        <PlanogramMetrics pog={s2Pog} isLoading={isS2SolutionLoading} />

        <Flex gap="10px">
          <Button
            color="greenSmoke"
            variant="inverted"
            onClick={backToQuadView}
            isLoading={isS2SolutionLoading}
            isDisabled={isS2SolutionLoading}
          >
            Back
          </Button>

          <Button
            color="primary"
            onClick={onGenerateBasePlanogram}
            isEnabled={Boolean(data)}
            isLoading={isBasePlanogramGenerationLoading}
          >
            Generate Base Planogram
          </Button>
        </Flex>
      </Flex>
    </Page>
  );
};
