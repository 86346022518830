import { useAtom } from "jotai";
import { useEffect, useState } from "react";

import { useMerchflowStoresApi } from "src/api/merchflows/stores";
import { useModals } from "src/components/Modals";
import { PlanogramRules } from "src/components/PlanogramRules/PlanogramRules";
import { Button, Flex, Modal, Scroller } from "src/elements";
import { defaultPspRules } from "src/pages/Merchflows_merchflowId_BasePog_basePogId_/store/types";
import { pages } from "src/utils";
import { useNavigation } from "src/utils/navigation";
import { storePlanogramRulesDataAtom } from "./store/atoms";
import { useApi } from "src/api";
import { routeToUrl } from "src/utils/axios";

interface Props {
  merchflowId: number;
  basePogId: number;
  isBasePogFavorited: boolean;
}

export const ModalStorePlanogramRules = () => {
  const [data, setData] = useAtom(storePlanogramRulesDataAtom);

  useEffect(() => {
    return () => {
      setData(null);
    };
  }, []);

  if (!data) return null;

  return (
    <ModalStorePlanogramRulesInner
      merchflowId={data.merchflowId}
      basePogId={data.basePogId}
      isBasePogFavorited={data.isBasePogFavorited}
    />
  );
};

const ModalStorePlanogramRulesInner = ({
  merchflowId,
  basePogId,
  isBasePogFavorited,
}: Props) => {
  const { postMerchflowStartStorePlanogramsGenerationApi } =
    useMerchflowStoresApi();
  const { closeModal: _closeModal, openConfirmModal } = useModals();
  const { navigate } = useNavigation();
  const { putMerchflowBasePog_basePogId_StatusApi } = useApi();

  const [pspRules, setPspRules] = useState(defaultPspRules);

  const startGeneratingStorePlanograms = async () => {
    closeModal();

    const storePogTriggerId =
      await postMerchflowStartStorePlanogramsGenerationApi({
        base_pog_id: basePogId,
        psp_rules: pspRules,
      });

    if (!storePogTriggerId) {
      return;
    }

    navigate(
      routeToUrl(
        pages.merchflows_merchflowId_StoresAll,
        {
          merchflowId,
        },
        {
          store_pog_trigger_id: storePogTriggerId,
        },
      ),
    );
  };

  const checkFavoriteAndStartGeneratingStorePlanograms = () => {
    if (isBasePogFavorited) {
      startGeneratingStorePlanograms();
    } else {
      openConfirmModal({
        title: "Start Generating Store Planograms?",
        description:
          "Only store planograms of favourited base planograms are available for approval in the Store Review step of your flow review.",
        buttons: [
          { label: "Back", color: "primary", variant: "inverted" },
          {
            label: "Only Generate",
            color: "yellow",
            onClick: startGeneratingStorePlanograms,
          },
          {
            label: "Favorite and Generate",
            color: "primary",
            onClick: favoriteAndStartGeneratingStorePlanograms,
          },
        ],
      });
    }
  };

  const favoriteAndStartGeneratingStorePlanograms = () => {
    putMerchflowBasePog_basePogId_StatusApi({
      is_favourite: [basePogId],
    });
    startGeneratingStorePlanograms();
  };

  const closeModal = () => {
    _closeModal("ModalStorePlanogramRules");
  };

  return (
    <Modal
      title="Store Planogram Rules"
      name="ModalStorePlanogramRules"
      width="500px"
    >
      <Flex minHeight="0" flexGrow={1} column gap="20px" padding="20px 10px">
        <Scroller gutter="stable both-edges">
          <PlanogramRules pspRules={pspRules} setPspRules={setPspRules} />
        </Scroller>

        <Flex width="100%" justify="center">
          <Button onClick={checkFavoriteAndStartGeneratingStorePlanograms}>
            Generate Store Planograms
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};
