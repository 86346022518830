import { PlanogramContainer } from "@CommonTypes/merchflow/pog/container";
import { Pog } from "@CommonTypes/merchflow/pog/pog";
import { pages } from "src/utils";
import { omniAtom } from "src/utils/atoms";

const prefix = pages.merchflows_merchflowId_S2_s2SolutionId_;

export const merchflows_merchflowId_S2_s2SolutionId_PogAtom = omniAtom({
  prefix,
  key: "pog",
  value: null as null | Pog,
});

export const merchflows_merchflowId_S2_s2SolutionId_ContainersAtom = omniAtom({
  prefix,
  key: "containers",
  value: null as null | PlanogramContainer[],
});
