import {
  OptionalPaginationQueryParams,
  Pagination,
} from "@CommonTypes/backend/pagination";
import { OneStoreView } from "@CommonTypes/merchflow/OneStoreView";

export const RouteGetMerchflows_MerchflowId_StoresAll =
  "/merchflows/:merchflowId/stores/all";

export type ParamsGetMerchflows_MerchflowId_StoresAll =
  OptionalPaginationQueryParams & {
    store_pog_trigger_id?: number;
  };
export type ResponseGetMerchflows_MerchflowId_StoresAll =
  Pagination<OneStoreView>;
