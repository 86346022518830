import { useSetAtom } from "jotai";
import { useModals } from "src/components/Modals";
import { Button } from "src/elements";
import { dynamicLogAtom } from "src/modals/DynamicLog/store/atom";

export const DynamicLogButton = ({
  dataFetcher,
}: {
  dataFetcher: () => object;
}) => {
  const { openModal } = useModals();
  const setDynamicLog = useSetAtom(dynamicLogAtom);

  const onOpenLog = () => {
    const data = dataFetcher();

    setDynamicLog(data);
    openModal("DynamicLog");
  };
  return (
    <Button variant="inverted" color="primary" onClick={onOpenLog}>
      Logs
    </Button>
  );
};
