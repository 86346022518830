import React, { useEffect, useRef } from "react";
import { Color } from "src/elements";
import { ConfirmModalProps } from "src/modals/ModalConfirm/store/types";
import { Transition } from "src/utils";
import { zIndexLevel } from "src/utils/zIndexLevels";
import styled from "styled-components";

import { useModals } from "../store/hooks";
import { ModalTitlebar } from "./ModalTitlebar";
import { useAtomValue } from "jotai";
import { modalsAtom } from "../store/atoms";
import { Modals } from "src/modals";

const ModalOuterContainer = styled.div<{ isConfirmModal: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: ${({ isConfirmModal }) => isConfirmModal && "center"};
  z-index: ${zIndexLevel.MODALS};
  transition: opacity ${Transition.fast};

  &[data-open],
  &[data-close] {
    pointer-events: none;
    opacity: 0;

    .modal {
      transform: scale(0.9);
    }
  }
`;

const ModalDimmer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  user-select: none;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(1px);
`;

const ModalElement = styled.div<{
  width: string;
  height: string;
  level: number;
  isConfirmModal?: boolean;
}>`
  margin-top: ${({ level, isConfirmModal }) =>
    !isConfirmModal && `${level * 10}vh`};
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  max-height: 80vh;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  transition: transform ${Transition.fast};
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Color.white};
  min-height: 0;
  flex-grow: 1;
`;

interface Props {
  name: Modals;
  title?: string;
  width?: string;
  height?: string;
  confirmModal?: ConfirmModalProps | (() => void) | false;
  isConfirmModal?: boolean;
  level?: number;
  onClose?: () => void;
  children?: React.ReactNode;
}

export const Modal = ({
  name,
  title,
  width = "60vw",
  height = "min-content",
  confirmModal,
  isConfirmModal = false,
  level = 1,
  onClose,
  children,
}: Props) => {
  const { closeModal: _closeModal, openConfirmModal } = useModals();

  const modals = useAtomValue(modalsAtom);

  const refContainer = useRef<HTMLDivElement>(null);

  const isModalOpen = modals.includes(name);

  // Effect to show modal open transition.
  useEffect(() => {
    if (isModalOpen) {
      refContainer.current?.removeAttribute("data-open");
    }
  }, [isModalOpen]);

  const closeModal = () => {
    if (confirmModal) {
      if (typeof confirmModal === "function") {
        confirmModal();
      } else {
        openConfirmModal(confirmModal);
      }
    } else {
      _closeModal(name);
    }

    if (onClose) {
      onClose();
    }
  };

  return (
    <ModalOuterContainer
      ref={refContainer}
      data-modal={name}
      data-open=""
      isConfirmModal={isConfirmModal}
    >
      <ModalDimmer className="dimmer" />

      <ModalElement
        className="modal"
        width={width}
        height={height}
        isConfirmModal={isConfirmModal}
        level={level}
        // Stop click event propagation to prevent triggering confirm modal when clicking inside modal.
        onClick={(event) => event.stopPropagation()}
      >
        {!isConfirmModal && (
          <ModalTitlebar title={title} closeModal={closeModal} />
        )}

        <ModalContent>{children}</ModalContent>
      </ModalElement>
    </ModalOuterContainer>
  );
};
