import { useEffect, useState } from "react";
import {
  Checkbox,
  Flex,
  Pagination,
  Scroller,
  SubgridTable,
} from "src/elements";

import {
  ResponseGetAllStoreSummary,
  RouteGetAllStoreSummary,
} from "@CommonApi/store-summary";
import { useApi } from "src/api";
import { PageStatus } from "src/components";
import { useInlineLoaders } from "src/components/InlineLoader";
import { usePaginationPage } from "src/elements/Pagination";
import { SubgridColumn } from "src/elements/SubgridTable/store/types";
import { StoreSummary } from "@CommonTypes/owa-db/merchantSchema";
import moment from "moment";

const columns: SubgridColumn<StoreSummary>[] = [
  { id: "id", header: "Id" },
  { id: "upload_id", header: "Upload Id" },
  { id: "retailer_category_code", header: "Retailer Cat.Code" },
  { id: "category_id", header: "Cat.Id" },
  { id: "category_code", header: "Cat.Code" },
  { id: "pog_id", header: "Pog Id" },
  { id: "store", header: "Store" },
  { id: "base_store_code", header: "Base Store Code", width: "200px" },
  { id: "store_code", header: "Store Code", width: "200px" },
  { id: "state", header: "State" },
  { id: "template_id", header: "Template Id" },
  { id: "cluster", header: "Cluster" },
  { id: "bay", header: "Bay" },
  { id: "total_bays", header: "Total Bays" },
  { id: "section_height", header: "Section Height" },
  { id: "section_width", header: "Section Width" },
  { id: "template", header: "Template", width: "150px" },
  { id: "exclude", header: "Exclude" },
  { id: "store_name", header: "Store Name", width: "200px" },
  {
    id: "created_at",
    header: "Created At",
    renderer: (data) => moment(data.row.created_at).format("DD/MM/YYYY HH:MM"),
  },
  {
    id: "status",
    header: "Status",
    renderer: (data) => (
      <Flex align="top">
        <Checkbox
          isChecked={Boolean(data.row.status)}
          setIsChecked={() => null}
        />
      </Flex>
    ),
  },
  { id: "filename", header: "Filename", width: "250px" },
  {
    id: "base_pog_flag",
    header: "Base Pog Flag",
    renderer: (data) => (
      <Flex align="top">
        <Checkbox
          isChecked={Boolean(data.row.base_pog_flag)}
          setIsChecked={() => null}
        />
      </Flex>
    ),
  },
  { id: "template_type", header: "Template Type" },
  { id: "extra_info_1", header: "Extra Info 1" },
  { id: "extra_info_2", header: "Extra Info 2" },
];

export const StoreSummaryList = () => {
  const [page, setPage] = usePaginationPage();
  const { getAllStoreSummaryApi } = useApi();
  const { isInlineLoading } = useInlineLoaders();

  const [data, setData] = useState<ResponseGetAllStoreSummary | null>(null);
  const isLoadingData = isInlineLoading(RouteGetAllStoreSummary);

  useEffect(() => {
    loadStoreSummary();
  }, [page]);

  const loadStoreSummary = async () => {
    setData(await getAllStoreSummaryApi({ page, size: 40 }));
  };
  return (
    <Flex column gap="12px" height="100%">
      <Flex grow>
        {isLoadingData && (
          <PageStatus label="Loading store summary..." icon="spinner" />
        )}

        {!isLoadingData && data && (
          <Scroller>
            <SubgridTable columns={columns} data={data.data} />
          </Scroller>
        )}
      </Flex>

      <Pagination
        page={page}
        setPage={setPage}
        totalPages={data?.totalPages || 1}
        isUsingInput
      />
    </Flex>
  );
};
