import { Product } from "@CommonTypes/owa-db/merchantSchema";
import { Checkbox } from "src/elements";
import { SubgridColumn } from "src/elements/SubgridTable/store/types";

export const getProductsTableColumns = ({
  modifiedProducts,
  setModifiedProducts,
}: {
  modifiedProducts: { [key: string]: Product };
  setModifiedProducts: (products: { [key: string]: Product }) => void;
}) => {
  const columns: SubgridColumn<Product>[] = [
    {
      id: "product_id",
      header: "Product ID",
      justify: "right",
    },
    {
      id: "upload_id",
      header: "Upload ID",
      justify: "right",
    },
    {
      id: "upc",
      header: "UPC",
      justify: "right",
    },
    {
      id: "product_code",
      header: "Product Code",
    },
    {
      id: "name",
      header: "Name",
    },
    {
      id: "size",
      header: "Size",
      justify: "right",
    },
    {
      id: "brand",
      header: "Brand",
    },
    {
      id: "category",
      header: "Category",
    },
    {
      id: "subcategory",
      header: "Sub-Category",
    },
    {
      id: "is_oos",
      header: "Is In Stock",
      justify: "center",
      renderer: ({ value, row }) => {
        const modifiedProduct = modifiedProducts[row.product_id];

        return (
          <Checkbox
            width="min-content"
            isChecked={
              !(modifiedProduct ? modifiedProduct.is_oos === true : value)
            }
            setIsChecked={(isChecked) => {
              setModifiedProducts({
                ...modifiedProducts,
                [row.product_id]: {
                  ...(modifiedProduct || row),
                  is_oos: !isChecked,
                },
              });
            }}
          />
        );
      },
    },
    {
      id: "is_core_range",
      header: "In Core Range",
      justify: "center",
      renderer: ({ value, row }) => {
        const modifiedProduct = modifiedProducts[row.product_id];

        return (
          <Checkbox
            width="min-content"
            isChecked={
              modifiedProduct ? modifiedProduct.is_core_range === true : value
            }
            setIsChecked={(isChecked) => {
              setModifiedProducts({
                ...modifiedProducts,
                [row.product_id]: {
                  ...(modifiedProduct || row),
                  is_core_range: isChecked,
                },
              });
            }}
          />
        );
      },
    },
    {
      id: "unit_width",
      header: "Unit Width",
      justify: "right",
    },
    {
      id: "unit_height",
      header: "Unit Height",
      justify: "right",
    },
    {
      id: "unit_depth",
      header: "Unit Depth",
      justify: "right",
    },
    {
      id: "tray_width",
      header: "Tray Width",
      justify: "right",
    },
    {
      id: "tray_height",
      header: "Tray Height",
      justify: "right",
    },
    {
      id: "tray_depth",
      header: "Tray Depth",
      justify: "right",
    },
    {
      id: "case_width",
      header: "Case Width",
      justify: "right",
    },
    {
      id: "case_height",
      header: "Case Height",
      justify: "right",
    },
    {
      id: "case_depth",
      header: "Case Depth",
      justify: "right",
    },
    {
      id: "display_width",
      header: "Display Width",
      justify: "right",
    },
    {
      id: "display_height",
      header: "Display Height",
      justify: "right",
    },
    {
      id: "display_depth",
      header: "Display Depth",
      justify: "right",
    },
    {
      id: "tray_number_wide",
      header: "Tray Number Wide",
      justify: "right",
    },
    {
      id: "tray_number_deep",
      header: "Tray Number Deep",
      justify: "right",
    },
    {
      id: "tray_number_high",
      header: "Tray Number High",
      justify: "right",
    },
    {
      id: "tray_total_number",
      header: "Tray Total Number",
      justify: "right",
    },
    {
      id: "case_number_wide",
      header: "Case Number Wide",
      justify: "right",
    },
    {
      id: "case_number_deep",
      header: "Case Number Deep",
      justify: "right",
    },
    {
      id: "case_number_high",
      header: "Case Number High",
      justify: "right",
    },
    {
      id: "case_total_number",
      header: "Case Total Number",
      justify: "right",
    },
    {
      id: "color",
      header: "Color",
    },
    {
      id: "sub_commodity",
      header: "Sub Commodity",
    },
    {
      id: "internal_comments",
      header: "Internal Comments",
    },
    {
      id: "is_new_item",
      header: "Is New Item",
    },
    {
      id: "is_disco_item",
      header: "Is Disco Item",
    },
    {
      id: "shape_id",
      header: "Shape ID",
    },
    {
      id: "manufacturer",
      header: "Manufacturer",
    },
  ];

  return columns;
};
