import { PlanogramShelf } from "@CommonTypes/merchflow/pog/shelf";
import { useAtom, useAtomValue } from "jotai";
import isEmpty from "lodash/isEmpty";
import { useMemo } from "react";
import { algoStep0ConvertGroupsToContainers } from "src/adapters/algoStep0";
import { DynamicLogButton } from "src/components/DynamicLogButton";
import { useModals } from "src/components/Modals";
import { Planogram } from "src/components/Planogram/Planogram";
import { PlanogramFixturesMode } from "src/components/PlanogramExtensions/PlanogramFixturesMode";
import { PlanogramHovererDetails } from "src/components/PlanogramExtensions/PlanogramHovererDetails/PlanogramHovererDetails";
import { Button, Flex, InlineMessage, Text, Textarea } from "src/elements";
import { useMerchandiseApi } from "src/pages/merchandise/store/api";
import {
  savedS0Atom,
  selectedPreStep0DataAtom,
  step0GroupsAtoms,
  step0RequestAtoms,
  step1ResponsesAtom,
} from "src/pages/merchandise/store/atoms";
import { createPog } from "src/pages/merchandise/store/utils";
import { omniAtom } from "src/utils/atoms";

export const GenerateBase = () => {
  const { callAlgoStep1Step2, callFixtureModified, callContainerModified } =
    useMerchandiseApi();
  const { openModal } = useModals();

  const step0Groups = useAtomValue(step0GroupsAtoms);
  const [savedS0, setSavedS0] = useAtom(savedS0Atom);
  const step0Requests = useAtomValue(step0RequestAtoms);
  const s1Response = useAtomValue(step1ResponsesAtom);
  const [preStep0Data, setPreStep0Data] = useAtom(selectedPreStep0DataAtom);

  const [, containersAtom, planogramAtom] = useMemo(() => {
    const firstTemplateContainers = algoStep0ConvertGroupsToContainers(
      step0Groups[0],
      preStep0Data[0].planogram,
    );

    if (savedS0) {
      return [
        firstTemplateContainers,
        savedS0.containersAtom,
        savedS0.planogramAtom,
      ];
    }

    return [
      firstTemplateContainers,
      omniAtom({
        key: "step0ContainersAtom",
        value: firstTemplateContainers,
      }),
      omniAtom({
        key: "step0PlanogramAtom",
        value: createPog({
          planogram: preStep0Data[0].planogram,
          unrangedItems: [],
        }),
      }),
    ];
  }, [step0Groups, savedS0]);

  const editedPlanogram = useAtomValue(planogramAtom);
  const editedContainers = useAtomValue(containersAtom);

  const invalidContainers = editedContainers.filter(
    (container) =>
      container.algoCurrentTargetWidth !== undefined &&
      container.algoMinTargetWidth !== undefined &&
      container.algoCurrentTargetWidth < container.algoMinTargetWidth,
  );
  const areContainersInvalid = invalidContainers.length > 0;

  const areShelvesInvalid = () => {
    for (const bay of editedPlanogram.planogram.bays) {
      for (let i = 0; i < bay.shelves.length; i++) {
        const shelf = bay.shelves[i];
        const topShelf: PlanogramShelf | undefined = bay.shelves[i + 1];

        if (shelf.mergedRight) {
          const rightShelfTopShelf: PlanogramShelf | undefined =
            shelf.mergedRight.bay.shelves[
              shelf.mergedRight.bay.shelves.findIndex(
                (s) => s.uniqueId === shelf.mergedRight?.uniqueId,
              ) + 1
            ];

          if (topShelf?.notchNo !== rightShelfTopShelf?.notchNo) {
            return true;
          }
        }
      }
    }

    return false;
  };

  const fetchLog = () => {
    return {
      s0: preStep0Data
        .map((preS0) => preS0.templateId)
        .reduce((total, templateId, index) => {
          //@ts-ignore
          total[templateId] = {
            responses: step0Groups[index],
            request: step0Requests[index],
          };
          return total;
        }, {}),
    };
  };

  const saveDnD = () => {
    callContainerModified(editedContainers);
    callFixtureModified(editedPlanogram);
  };

  const generateBases = async () => {
    saveDnD();

    callAlgoStep1Step2(editedPlanogram.planogram, editedContainers);

    const newPreStep0 = preStep0Data.map((data, index) => {
      if (index === 0) {
        return {
          ...data,
          planogram: editedPlanogram.planogram,
        };
      }
      return data;
    });

    setPreStep0Data(newPreStep0);

    if (!savedS0) {
      setSavedS0({
        containersAtom,
        planogramAtom,
      });
    }
  };

  const viewResults = () => {
    openModal("SelectBaySize");
  };

  return (
    <Flex column gap="10px" grow>
      <Flex gap="10px" justify="between">
        <Text>Store ID: {preStep0Data[0].storeId}</Text>

        <PlanogramFixturesMode />
      </Flex>

      <Flex grow borderRadius="3px" overflow="hidden">
        <Planogram
          containersAtom={containersAtom}
          isEditable
          isPanelVisible
          pogAtom={planogramAtom}
          isWithBorder
        />
      </Flex>

      <PlanogramHovererDetails />

      <InlineMessage status="error">
        {(areShelvesInvalid() &&
          "Shelves with different heights can not be merged, please unmerge them") ||
          (areContainersInvalid &&
            `Following containers do not meet the required minimum widths: ${invalidContainers
              .map((container) => container.label)
              .join(", ")}`)}
      </InlineMessage>

      <Flex>
        <Textarea title="Comment" value="" setValue={() => {}} />

        <Flex width="50%" justify="right" gap="12px" align="center">
          <DynamicLogButton dataFetcher={fetchLog} />

          {isEmpty(s1Response) ? (
            <Button
              color={areContainersInvalid ? "red" : "primary"}
              isDisabled={areContainersInvalid}
              onClick={generateBases}
            >
              Generate Base
            </Button>
          ) : (
            <Button onClick={viewResults}>Results</Button>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};
