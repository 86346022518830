import { adapterPogNonCircularToPog } from "@CommonAdapters/pog";
import { PoggerResponse } from "@CommonTypes/algo/pogger";
import { PoggerizeResponse } from "@CommonTypes/algo/poggerize";
import { Pog } from "@CommonTypes/merchflow/pog/pog";
import { Step1Step2Solution } from "@CommonTypes/step2fixtures";
import { PrimitiveAtom } from "jotai";
import { calculatePrestep0Data } from "src/adapters/algoPreStep0";
import { WithDebugInfo } from "src/pages/merchandise/steps/Step2Fixtures/store/types";
import {
  MerchandiseStep,
  ResponseGetMerchFlowTemplateTable,
} from "src/pages/merchandise/store/types";
import { omniAtom } from "src/utils/atoms";

type ResultType = (
  | {
      base_pog: ResponseGetMerchFlowTemplateTable["rows"][0]["base_pog"];
      error: null;
    }
  | {
      base_pog: null;
      error: Error;
    }
) & {
  step: keyof typeof MerchandiseStep;
};

export const checkAndSetPlanogramStepData = ({
  merchFlowTemplateTableData,
  step2Results,
  setSolutionIndex,
  setSelectedTemplateId,
  setS2ViewSingle,
  setPoggerizeResult,
  setPoggerizeRequest,
  setPoggerResult,
  selectedPreStep0Data,
  setSavedPogAtom,
  currentStep,
  basePogId,
}: {
  merchFlowTemplateTableData: ResponseGetMerchFlowTemplateTable;
  step2Results: WithDebugInfo<Step1Step2Solution>[];
  setSolutionIndex: (p: number) => void;
  setSelectedTemplateId: (t: string) => void;
  setS2ViewSingle: (b: boolean) => void;
  setPoggerizeResult: (p: PoggerizeResponse) => void;
  setPoggerizeRequest: (p: any) => void;
  setPoggerResult: (p: PoggerResponse) => void;
  selectedPreStep0Data: ReturnType<typeof calculatePrestep0Data>;
  setSavedPogAtom: (p: PrimitiveAtom<Pog>) => void;
  currentStep: keyof typeof MerchandiseStep;
  basePogId?: number;
}): ResultType => {
  let selectedTemplate;
  if (basePogId) {
    selectedTemplate = merchFlowTemplateTableData.rows.find(
      (template) => basePogId === template?.base_pog?.id,
    );
  }
  if (!selectedTemplate) {
    selectedTemplate = merchFlowTemplateTableData.rows.find((template) =>
      Boolean(template.base_pog),
    );
  }

  if (selectedTemplate) {
    const { base_pog, template_id } = selectedTemplate;

    const {
      pogger_response_path,
      poggerize_response_path,
      poggerize_request_path,
      pog_data,
    } = base_pog || {};

    if (!pogger_response_path) {
      return {
        error: Error("Error getting pogger data"),
        step: currentStep,
        base_pog: null,
      };
    }

    const solutionIndex = step2Results
      .filter((s2) => s2.templateId === template_id)
      .findIndex((s2) => s2.solution_id === base_pog.solution_id);

    setSolutionIndex(solutionIndex);
    setSelectedTemplateId(template_id);
    setS2ViewSingle(true);
    setPoggerizeResult({
      ...poggerize_response_path,
      base_pog_id: base_pog.id,
    });
    setPoggerResult(pogger_response_path.data);
    setPoggerizeRequest(poggerize_request_path);

    const founded = selectedPreStep0Data.find(
      (d) => d.templateId === template_id,
    );

    if (!founded) {
      return {
        error: Error("Not Found matched ffData and pmData for pog"),
        step: currentStep,
        base_pog: null,
      };
    }

    setSavedPogAtom(
      omniAtom({
        key: "savedPogAtom",
        value: adapterPogNonCircularToPog(pog_data),
      }),
    );

    return {
      step: MerchandiseStep.APPLY_TO_BASE,
      base_pog,
      error: null,
    };
  }

  return {
    step: currentStep,
    base_pog: null,
    error: Error("No base pog data, fallback to SelectBase"),
  };
};
