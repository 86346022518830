import {
  MerchFlowCoreRange,
  MerchFlowFlowHierarchy,
  ResponseMerchFlowApplyFilters,
  ResponseMerchFlowDetails,
  ResponseMerchFlowLoadCategories,
  ResponseMerchFlowLoadFilters,
} from "src/omni-common/api/merchandise";
import { omniAtom } from "src/utils/atoms";

import { Flows, MergeRule, NewFlowReviewStep, OverrideCDT } from "./types";

const prefix = "modals/newFlowReview";

export const filtersFlowsAtom = omniAtom({
  prefix,
  key: NewFlowReviewStep.STEP_1_2_FILTERS + "/flows",
  value: Flows.UNASSIGNED_FLOWS,
});

export const setupStepAtom = omniAtom({
  prefix,
  key: "setup/step",
  value: NewFlowReviewStep.STEP_1_1_INPUTS,
});

export const categoriesAtom = omniAtom({
  prefix,
  key: "setup/details/categories",
  value: null as ResponseMerchFlowLoadCategories | null,
});

export const nameAtom = omniAtom({
  prefix,
  key: "setup/details/name",
  value: "",
});

export const notesAtom = omniAtom({
  prefix,
  key: "setup/details/notes",
  value: "",
});

export const categoryAtom = omniAtom({
  prefix,
  key: "setup/details/category",
  value: null as string | null,
});

export const subCategoryAtom = omniAtom({
  prefix,
  key: "setup/details/subCategory",
  value: null as string | null,
});

export const coreRangeAtom = omniAtom({
  prefix,
  key: "setup/details/coreRange",
  value: {
    option: MerchFlowCoreRange.PARTIAL,
    uploadId: null as number | null,
  },
});

export const detailsAtom = omniAtom({
  prefix,
  key: "setup/details",
  value: null as ResponseMerchFlowDetails | null,
});

export const filtersAvailableAtom = omniAtom({
  prefix,
  key: "setup/filters/available",
  value: null as ResponseMerchFlowLoadFilters | null,
});

export const filtersBaySizesAtom = omniAtom({
  prefix,
  key: "setup/filters/selected/baySizes",
  value: [] as number[],
});

export const filtersStatesAtom = omniAtom({
  prefix,
  key: "setup/filters/selected/states",
  value: [] as string[],
});

export const filtersFixturesHeightsAtom = omniAtom({
  prefix,
  key: "setup/filters/selected/heights",
  value: [] as number[],
});

export const filtersFixturesWidthsAtom = omniAtom({
  prefix,
  key: "setup/filters/selected/widths",
  value: [] as number[],
});

export const filtersClustersAtom = omniAtom({
  prefix,
  key: "setup/filters/selected/clusters",
  value: [] as string[],
});

export const filtersOverrideCDTAtom = omniAtom({
  prefix,
  key: "setup/filters/overrideCDT",
  value: OverrideCDT.NO,
});

export const filtersAppliedAtom = omniAtom({
  prefix,
  key: "setup/filters/applied",
  value: null as ResponseMerchFlowApplyFilters | null,
});

export const mergeRulesVariantsAvailableAtom = omniAtom({
  prefix,
  key: "setup/mergeRules/variants",
  value: null as string[] | null,
});

export const mergeRulesAtom = omniAtom({
  prefix,
  key: "setup/mergeRules",
  value: null as MergeRule[] | null,
});

export const mergeRulesFlowHierarchyAtom = omniAtom({
  prefix,
  key: "setup/mergeRules/flowHierarchy",
  value: [
    MerchFlowFlowHierarchy.CORE_RANGE,
    MerchFlowFlowHierarchy.DOS,
    MerchFlowFlowHierarchy.MOS,
  ],
});
