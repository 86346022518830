import { Page, PageStatus } from "src/components";
import { MerchflowHeader } from "../../components/merchflows/MerchflowHeader/MerchflowHeader";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useApi } from "src/api";
import { useAsyncState } from "src/hooks/useAsyncState";
import { sleep } from "src/utils/sleep";
import { MerchflowStepsNavigator } from "src/components/merchflows/MerchflowStepsNavigator/MerchflowStepsNavigator";
import { Color, Flex, Link, Text } from "src/elements";
import { PogOverview } from "../Merchflows_merchflowId_Stores_storeCode/components/PogOverview";
import { pages } from "src/utils";
import { routeToUrl } from "src/utils/axios";
import styled from "styled-components";
import { OneStoreViewStatus } from "@CommonTypes/merchflow/OneStoreView";
import { ResponseGetMercflows_merchflowId_S1_s1UserTriggerId } from "@CommonApi/merchflows/_merchflowId_/s1/_s1UserTriggerId_";

const PogOverviewContainer = styled(Flex)`
  flex-direction: column;
  gap: 5px;
  * {
    pointer-events: none;
  }
`;

export const PageS1 = () => {
  const { merchflowId: _merchflowId, s1UserTriggerId: _s1UserTriggerId } =
    useParams();
  const merchflowId = Number(_merchflowId);
  const s1UserTriggerId = Number(_s1UserTriggerId);

  const { getMerchflowS1SolutionsApi } = useApi();

  const [data, setData] =
    useAsyncState<ResponseGetMercflows_merchflowId_S1_s1UserTriggerId>();

  useEffect(() => {
    loadS1Solutions();
  }, []);

  const loadS1Solutions = async () => {
    const response = await getMerchflowS1SolutionsApi(
      merchflowId,
      s1UserTriggerId,
    );

    setData(response);

    if (
      response?.find(
        (s1Solution) =>
          s1Solution.status === "NOT_STARTED" ||
          s1Solution.status === "IN_PROGRESS",
      )
    ) {
      await sleep(10_000);
      loadS1Solutions();
    }
  };

  return (
    <Page>
      <MerchflowHeader
        merchflowId={merchflowId}
        s1UserTriggerId={s1UserTriggerId}
      />

      <MerchflowStepsNavigator merchflowId={merchflowId} activeStep="S1" />

      {data === undefined && (
        <PageStatus label="Loading CDT1 mudmaps..." icon="spinner" />
      )}

      {data && (
        <Flex flexWrap="wrap" gap="20px">
          {data.map((s1Solution, i) => (
            <Link
              key={i}
              to={routeToUrl(
                pages.merchflows_merchflowId_S2_s1UserTriggerId_Templates_templateId_,
                {
                  merchflowId,
                  s1UserTriggerId,
                  templateId: s1Solution.template_id,
                },
              )}
            >
              <PogOverviewContainer>
                <Text variant="body1" color={Color.primary}>
                  {s1Solution.template_id} (0 solutions)
                </Text>

                <PogOverview
                  loadingMessage={"Loading mudmap..."}
                  status={
                    s1Solution.status === "NOT_STARTED" ||
                    s1Solution.status === "IN_PROGRESS"
                      ? OneStoreViewStatus.GENERATING_STORE_POG
                      : undefined
                  }
                  pog={
                    s1Solution.status === "DONE" && {
                      planogram: s1Solution.solution.planogram,
                      unrangedItems: [],
                      deletedItems: [],
                      version: "",
                      metrics: {
                        returnOnSpace: null,
                        shelfAlignment: null,
                        coreRange: null,
                        dosMos: null,
                      },
                    }
                  }
                  containers={
                    s1Solution.status === "DONE" &&
                    s1Solution.solution.containers
                  }
                  isShowMetrics={false}
                  isRenderingAsImage={false}
                />
              </PogOverviewContainer>
            </Link>
          ))}
        </Flex>
      )}
    </Page>
  );
};
