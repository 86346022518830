import { useAtom, useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Page, StepHeader } from "src/components";
import { useModals } from "src/components/Modals";
import { Flex, Render } from "src/elements";
import { useContinueMerchFlow, useMerchflowNavigation } from "src/hooks";
import { useNewFlowReview } from "src/modals/NewFlowReview/store/api";
import { filtersAppliedAtom } from "src/modals/NewFlowReview/store/atoms";
import { ResponseMerchFlowDetails } from "src/omni-common/api/merchandise";

import {
  ApplyToBase,
  ControlPanel,
  GenerateBase,
  ListFlowReview,
  SelectBase,
  StoreLevelPog,
} from "./components";
import { merchandiseSteps } from "./steps/store/data";
import {
  selectedPreStep0DataAtom,
  selectedTemplateIdAtom,
  stepAtom,
} from "./store/atoms";
import {
  getFlowReviewFilterConfigReq,
  getMerchFlowDetails,
} from "./store/requests";
import { MerchandiseStep } from "./store/types";
import { useNavigation } from "src/utils/navigation";
import { routeToUrl } from "src/utils/axios";
import { pages } from "src/utils/pages";

const steps = {
  [MerchandiseStep.GENERATE_BASE]: 2,
  [MerchandiseStep.SELECT_BASE]: 3,
  [MerchandiseStep.APPLY_TO_BASE]: 4,
  [MerchandiseStep.STORE_LEVEL_POG]: 5,
};

const MerchandisePage = () => {
  const { navigate } = useNavigation();
  const { details } = useNewFlowReview();
  const { continueMerchFlow } = useContinueMerchFlow();
  const { merchFlowId, merchFlowStep } = useParams<{
    merchFlowId: string;
    merchFlowStep: string;
  }>();
  const [isLoading, setIsLoading] = useState(true);
  const { openModal } = useModals();
  const [step, setStep] = useAtom(stepAtom);
  const preStep0 = useAtomValue(selectedPreStep0DataAtom);
  const selectedTemplate = useAtomValue(selectedTemplateIdAtom);
  const filterApplied = useAtomValue(filtersAppliedAtom);
  const { navigateToApplyToBase } = useMerchflowNavigation();

  const sourceTemplate = preStep0[0]?.templateId;

  const headerHandle = {
    onReviewNameClick: () => {},
    onCreateBaseClick: () => setStep(MerchandiseStep.GENERATE_BASE),
    onSelectBaseClick: () => openModal("SelectBaySize"),
    onEditPlanogramClick: () => navigateToApplyToBase(),
    onFilterConfigClick: () => openModal("ChangeFilterConfigModal"),
    onStoresClick: () =>
      navigate(
        routeToUrl(pages.merchflows_merchflowId_StoresAll, {
          merchflowId: details!.id,
        }),
      ),
  };

  const loadContinueMerchFlow = async (id: string) => {
    const allFilterConfig = await getFlowReviewFilterConfigReq(Number(id)).then(
      (r) => r.data,
    );
    const merchFlowDetails = await getMerchFlowDetails(id);

    // remove when api is updated
    const combinedDetails: ResponseMerchFlowDetails = {
      ...merchFlowDetails,
      latest_filter_config: allFilterConfig,
    };

    await continueMerchFlow(combinedDetails, merchFlowStep);
  };

  useEffect(() => {
    if (merchFlowId && Boolean(parseInt(merchFlowId, 10))) {
      setIsLoading(true);
      loadContinueMerchFlow(merchFlowId);
    }
    setIsLoading(false);
  }, [merchFlowId]);

  return (
    <Page>
      <ControlPanel />
      <Render when={!isLoading}>
        {
          //@ts-ignore
          details && filterApplied && steps[step] ? (
            <StepHeader
              //@ts-ignore
              step={steps[step]}
              steps={merchandiseSteps(
                details.id,
                filterApplied.id,
                sourceTemplate,
                selectedTemplate,
                headerHandle,
              )}
            />
          ) : null
        }

        <Flex column grow gap="10px" minHeight="0">
          {step === MerchandiseStep.GENERATE_BASE && <GenerateBase />}

          {step === MerchandiseStep.SELECT_BASE && <SelectBase />}
          {step === MerchandiseStep.APPLY_TO_BASE && <ApplyToBase />}
          {step === MerchandiseStep.STORE_LEVEL_POG && <StoreLevelPog />}

          {step === MerchandiseStep.LIST_FLOW_REVIEW && <ListFlowReview />}
        </Flex>
      </Render>
    </Page>
  );
};

export default MerchandisePage;
