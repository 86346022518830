import { PlanogramItem } from "../../types/merchflow/pog/pog";

const getPlanogramItemMerchDimensions = (item: PlanogramItem) => ({
  width:
    (item.merchandisingStyle === "TRAY" && item.trayWidth) ||
    (item.merchandisingStyle === "CASE" && item.caseWidth) ||
    item.unitWidth,
  height:
    (item.merchandisingStyle === "TRAY" && item.trayHeight) ||
    (item.merchandisingStyle === "CASE" && item.caseHeight) ||
    item.unitHeight,
  depth:
    (item.merchandisingStyle === "TRAY" && item.trayDepth) ||
    (item.merchandisingStyle === "CASE" && item.caseDepth) ||
    item.unitDepth,
});

export const getPlanogramItemHeight = (item: PlanogramItem) => {
  const { width, height, depth } = getPlanogramItemMerchDimensions(item);

  switch (item.orientation) {
    case "FRONT":
      return height;
    case "FRONT2":
      return width;
    case "SIDE":
      return height;
    case "SIDE2":
      return depth;
    case "TOP":
      return depth;
    case "TOP2":
      return width;
  }
};

export const getPlanogramItemWidth = (item: PlanogramItem) => {
  const { width, height, depth } = getPlanogramItemMerchDimensions(item);

  switch (item.orientation) {
    case "FRONT":
      return width;
    case "FRONT2":
      return height;
    case "SIDE":
      return depth;
    case "SIDE2":
      return height;
    case "TOP":
      return width;
    case "TOP2":
      return depth;
  }
};

export const getPlanogramItemDepth = (item: PlanogramItem) => {
  const { width, height, depth } = getPlanogramItemMerchDimensions(item);

  switch (item.orientation) {
    case "FRONT":
      return depth;
    case "FRONT2":
      return depth;
    case "SIDE":
      return width;
    case "SIDE2":
      return width;
    case "TOP":
      return height;
    case "TOP2":
      return height;
  }
};

export const getPlanogramItemCapacity = (item: PlanogramItem) => {
  if (!item.shelf) return NaN;

  const looseProducts =
    getPlanogramItemUnitsWide(item) *
    getPlanogramitemUnitsHigh(item) *
    Math.floor(
      (item.shelf.depth -
        getPlanogramItemDepth(item) *
          Math.floor(item.shelf.depth / getPlanogramItemDepth(item))) /
        item.unitDepth,
    );

  return (
    item.facings *
    (Math.floor(item.shelf.depth / getPlanogramItemDepth(item)) *
      getPlanogramItemUnits(item) +
      (item.zCappingFlag ? looseProducts : 0))
  );
};

export const getPlanogramItemDaysOfSupply = (item: PlanogramItem) => {
  if (!item.shelf) return NaN;

  return (getPlanogramItemCapacity(item) / item.quantity) * 7;
};

export const getPlanogramItemDaysOfSupplyPercentage = (item: PlanogramItem) => {
  if (!item.shelf) return NaN;
  return (getPlanogramItemDaysOfSupply(item) / item.minDaysOfSupply) * 100;
};

export const getPlanogramItemMOS = (item: PlanogramItem) => {
  if (!item.shelf) return NaN;

  return getPlanogramItemCapacity(item) / item.minOnShelf;
};

export const getPlanogramItemMOSPercentage = (item: PlanogramItem) => {
  if (!item.shelf) return NaN;

  return getPlanogramItemMOS(item) * 100;
};

export const getPlanogramItemHorizontalFacings = (item: PlanogramItem) =>
  Math.ceil(item.facings / item.facingsRows);

export const getPlanogramItemUnitsWide = (item: PlanogramItem) => {
  switch (item.merchandisingStyle) {
    case "UNIT":
      return 1;
    case "CASE":
      return item.orientation === "SIDE"
        ? item.unitsCaseDeep
        : item.unitsCaseWide;
    case "TRAY":
      return item.orientation === "SIDE"
        ? item.unitsTrayDeep
        : item.unitsTrayWide;
  }
};

export const getPlanogramitemUnitsHigh = (item: PlanogramItem) => {
  switch (item.merchandisingStyle) {
    case "UNIT":
      return 1;
    case "CASE":
      return item.unitsCaseHigh;
    case "TRAY":
      return item.unitsTrayHigh;
  }
};

export const getPlanogramItemUnits = (item: PlanogramItem) => {
  switch (item.merchandisingStyle) {
    case "UNIT":
      return 1;
    case "CASE":
      return item.noOfUnitsInCase;
    case "TRAY":
      return item.noOfUnitsInTray;
  }
};
