import { PlanogramContainer } from "@CommonTypes/merchflow/pog/container";
import { PogNonCircular } from "@CommonTypes/merchflow/pog/pog";

export const RouteGetMerchflowsS2_s2SolutionId_ =
  "/merchflows/s2/:s2SolutionId";

export interface ResponseGetMerchflowsS2_s2SolutionId_ {
  solution_fe_format: {
    pog: PogNonCircular;
    containers: PlanogramContainer[];
  };
  template_id: string;
}
