import { Button, Flex, Grid } from "src/elements";
import styled from "styled-components";
import { SectionHeader } from "./SectionHeader";
import {
  FiltersSidebarFilterButtonsSection,
  FiltersSidebarFilterSection,
} from "../store/types";

const SectionElement = styled(Flex)`
  width: 100%;
  flex-direction: column;
  gap: 10px;
`;

export interface FilterWithButtons {
  id: string;
  title: string;
  options: string[];
  isExpanded: boolean;
  value: string[];
}

interface Props<T> {
  id: string;
  title: string;
  filters: FiltersSidebarFilterSection<T>[];
  setFilters: (filters: FiltersSidebarFilterSection<T>[]) => void;
}

export const ButtonsSection = <T extends object>({
  id,
  title,
  filters,
  setFilters,
}: Props<T>) => {
  const filtersSection = filters.find(
    (filter) => filter.id === id,
  ) as unknown as FiltersSidebarFilterButtonsSection<T>;

  const setIsExpanded = (isExpanded: boolean) => {
    setFilters(
      filters.map((filterSection) =>
        filterSection.id === id
          ? { ...filterSection, isExpanded }
          : filterSection,
      ),
    );
  };

  const selectOption = (option: string) => {
    if (filtersSection.value?.includes(option)) {
      filtersSection.value = filtersSection.value.filter(
        (value) => value !== option,
      );
    } else {
      filtersSection.value = [...(filtersSection.value || []), option];
    }
    setFilters([...filters]);
  };

  return (
    <SectionElement>
      <SectionHeader
        title={title}
        isExpanded={filtersSection.isExpanded || false}
        setIsExpanded={setIsExpanded}
      />

      {filtersSection.isExpanded && (
        <Grid columns="repeat(5, 1fr)" gap="5px">
          {filtersSection.filters.map((option, i) => {
            const isSelected = filtersSection.value?.includes(option.value);

            return (
              <Button
                key={i}
                size="small"
                variant={isSelected ? "default" : "inverted"}
                onClick={() => {
                  selectOption(option.value);
                }}
              >
                {option.label}
              </Button>
            );
          })}
        </Grid>
      )}
    </SectionElement>
  );
};
