import { Legend } from "src/components";
import { Color } from "src/elements";
import { lightenDarkenColor } from "src/utils";

export const StoresListLegend = () => (
  <Legend
    legends={[
      { label: "Store Approved", icon: { name: "checkmarkCircle" } },
      {
        label: "Multiple Approved Stores",
        icon: { name: "alert", color: Color.red },
      },
    ]}
  />
);

export const StoresGridLegend = () => (
  <Legend
    legends={[
      {
        label: "Template",
        background: Color.planogram,
        borderColor: lightenDarkenColor(Color.planogram, -20),
      },
      {
        label: "Store Unapproved",
        background: Color.planogram,
        borderColor: Color.textMain,
      },
      {
        label: "Store Approved",
        background: Color.greenSmoke,
        borderColor: Color.primary,
      },
      {
        label: "Multiple Approved Stores",
        background: Color.redDisabled,
        borderColor: Color.red,
      },
    ]}
  />
);
