import { Color } from "src/elements";

export const textVariants: {
  [variant: string]: {
    fontSize: string;
    fontWeight: number;
    lineHeight: string;
    color?: string;
    textTransform?: "uppercase";
    letterSpacing?: string;
  };
} = {
  h2: {
    fontSize: "25px",
    fontWeight: 900,
    lineHeight: "31px",
  },
  h3: {
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "25px",
  },
  h4: {
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "23px",
  },
  h5: {
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "20px",
  },
  h6: {
    fontSize: "12px",
    fontWeight: 600,
    lineHeight: "15px",
  },

  subtitle1: {
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "20px",
  },

  subtitle2: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "20px",
  },

  body1: {
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "18px",
  },

  body2: {
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "18px",
  },

  body3: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "18px",
    color: Color.textMain,
  },

  small1: {
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "15px",
    color: Color.textSecondary,
  },

  small2: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "15px",
  },

  small3: {
    fontSize: "8px",
    fontWeight: 500,
    lineHeight: "10px",
    textTransform: "uppercase",
    letterSpacing: "0.02em",
  },

  small4: {
    fontSize: "7px",
    fontWeight: 500,
    lineHeight: "10px",
    letterSpacing: "0.02em",
  },

  caption1: {
    fontSize: "10px",
    fontWeight: 600,
    lineHeight: "13px",
  },

  caption2: {
    fontSize: "8px",
    fontWeight: 700,
    lineHeight: "10px",
    textTransform: "uppercase",
    letterSpacing: "0.02em",
  },

  caption3: {
    fontSize: "10px",
    fontWeight: 400,
    lineHeight: "13px",
  },
};
