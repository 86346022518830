import { useSetAtom } from "jotai";
import { calculatePrestep0Data } from "src/adapters/algoPreStep0";
import { useLoaders } from "src/components/Loaders";
import { useModals } from "src/components/Modals";
import { useToasts } from "src/components/Toasts";
import { useNewFlowReview } from "src/modals/NewFlowReview/store/api";
import { mergeRulesAtom } from "src/modals/NewFlowReview/store/atoms";
import { getMerchFlowLoadFiltersReq } from "src/modals/NewFlowReview/store/requests";
import { NewFlowReviewStep } from "src/modals/NewFlowReview/store/types";
import { adapterPogNonCircularToPog } from "src/omni-common/adapters/pog";
import { ResponseMerchFlowDetails } from "src/omni-common/api/merchandise";
import {
  ResponseAlgoStep0,
  ResponseAlgoStep1,
} from "src/pages/merchandise/steps/Step2Fixtures/store/types";
import {
  allPreStep0DataAtom,
  isViewDetailAtom,
  poggerResultAtom,
  poggerizeRequestAtom,
  poggerizeResultAtom,
  savedPogAtomAtom,
  savedS0Atom,
  selectedPreStep0DataAtom,
  selectedTemplateIdAtom,
  step0GroupsAtoms,
  step1OriginalResponseAtom,
  step1RequestAtom,
  step1ResponsesAtom,
  step2DataAtom,
  stepAtom,
  storeLevelPogPaginationAtom,
  storeLevelPogStatusRowsAtom,
  viewDetailIndex,
} from "src/pages/merchandise/store/atoms";
import {
  STORE_LEVEL_PAGE_SIZE,
  getAlgoStep2Solutions,
  getFlowReviewFilterConfigReq,
  getMerchFlowTemplateTable,
  getStoreLevelPogStatus,
} from "src/pages/merchandise/store/requests";
import {
  MerchandiseStep,
  StoreLevelPogPagination,
} from "src/pages/merchandise/store/types";
import { omniAtom } from "src/utils/atoms";

import { checkAndSetPlanogramStepData } from "./helpers";
import { useBasePogApi } from "./useBasePogApi";
import { useSearchParams } from "react-router-dom";
import { PayloadS1PlaceContainersAllTemplates } from "@CommonApi/frogger/place_containers_all_templates";
import { isEmpty } from "src/utils/isEmpty";

const StepPointMapping = {
  [MerchandiseStep.GENERATE_BASE]: 1,
  [MerchandiseStep.SELECT_BASE]: 2,
  [MerchandiseStep.APPLY_TO_BASE]: 3,
  [MerchandiseStep.STORE_LEVEL_POG]: 4,
};

export const useContinueMerchFlow = () => {
  const setMergeRules = useSetAtom(mergeRulesAtom);
  const { addLoader, removeLoader } = useLoaders();
  const {
    setDetails,
    setName,
    setNotes,
    setCategory,
    setSubCategory,
    filtersAvailable,
    setFiltersAvailable,
    setFiltersSelectedBaySizes,
    setFiltersSelectedClusters,
    setFiltersSelectedHeights,
    setFiltersSelectedStates,
    setFiltersSelectedWidths,
    setFiltersApplied,
    loadMergeRulesApi,
    setSetupStep,
  } = useNewFlowReview();
  const { toast } = useToasts();
  const { openModal } = useModals();

  // TODO - clean these up, these should be probably not needed, we should use prebuilt pogs/containers instead
  const setGroups = useSetAtom(step0GroupsAtoms);
  const setMerchFlowStep = useSetAtom(stepAtom);
  const setSelectedPreStep0Data = useSetAtom(selectedPreStep0DataAtom);
  const setAllPreStep0 = useSetAtom(allPreStep0DataAtom);
  const setSavedS0 = useSetAtom(savedS0Atom);
  const setSelectedTemplateId = useSetAtom(selectedTemplateIdAtom);
  const setSolutionIndex = useSetAtom(viewDetailIndex);
  const setS2ViewSingle = useSetAtom(isViewDetailAtom);
  // const setStep0Request = useSetAtom(step0RequestAtoms)
  const setStep1Request = useSetAtom(step1RequestAtom);
  // const setStep2Request = useSetAtom(step2RequestAtom)
  const setStep2Data = useSetAtom(step2DataAtom);
  const setStep1OriginalResponse = useSetAtom(step1OriginalResponseAtom);
  const setStep1Response = useSetAtom(step1ResponsesAtom);

  const setPoggerResult = useSetAtom(poggerResultAtom);
  const setPoggerizeRequest = useSetAtom(poggerizeRequestAtom);
  const setPoggerizeResult = useSetAtom(poggerizeResultAtom);
  const setStoreLevelPogStatusRows = useSetAtom(storeLevelPogStatusRowsAtom);
  const setStoreLevelPogPaginations = useSetAtom(storeLevelPogPaginationAtom);
  const setSavedPogAtom = useSetAtom(savedPogAtomAtom);
  const { loadAllBasePog } = useBasePogApi();
  const [searchParams] = useSearchParams();

  const navigateToCorrectStep = (currentStep: string, stepFromUrl?: string) => {
    //@ts-ignore
    const point = stepFromUrl ? StepPointMapping[stepFromUrl] || 0 : 0;

    let decisionStep =
      //@ts-ignore
      StepPointMapping[currentStep] > point ? stepFromUrl : currentStep;

    // if point === 0 mean step is not correct, fallback to current step
    if (point === 0) {
      decisionStep = currentStep;
    }

    switch (decisionStep) {
      case MerchandiseStep.LIST_FLOW:
        break;
      case MerchandiseStep.GENERATE_BASE:
      case MerchandiseStep.APPLY_TO_BASE:
      case MerchandiseStep.STORE_LEVEL_POG:
        setMerchFlowStep(decisionStep);
        break;
      case MerchandiseStep.SELECT_BASE:
        setMerchFlowStep(
          decisionStep === MerchandiseStep.SELECT_BASE
            ? MerchandiseStep.GENERATE_BASE
            : decisionStep,
        );
        openModal("SelectBaySize");
        break;
    }
  };
  const continueMerchFlow = async (
    details: ResponseMerchFlowDetails,
    stepFromUrl?: string,
    selectFilterConfig?: number,
  ) => {
    addLoader("continueMerchFlow");
    let currentStep: keyof typeof MerchandiseStep = MerchandiseStep.LIST_FLOW;

    try {
      setDetails(details);
      setName(details.name);
      setNotes(details.notes);
      setCategory(details.retailer_category_code);
      setSubCategory(details.category_code);

      let appliedFilters = details.latest_filter_config[0];

      if (selectFilterConfig) {
        const filterConfigList = await getFlowReviewFilterConfigReq(
          details.id,
        ).then((r) => r.data);
        appliedFilters =
          filterConfigList.find((f) => f.id === selectFilterConfig) ||
          details.latest_filter_config[0];
      }

      const merge_rules = appliedFilters.filter_config.merge_rules;

      if (!filtersAvailable) {
        const { data: responseFiltersAvailable } =
          await getMerchFlowLoadFiltersReq({
            retailer_category_code: details.retailer_category_code!,
            category_code: details.category_code!,
          });

        setFiltersAvailable(responseFiltersAvailable);
      }

      setFiltersApplied(appliedFilters);

      setFiltersSelectedBaySizes(appliedFilters.filter_config.baySizes);
      setFiltersSelectedClusters(appliedFilters.filter_config.clusters);
      setFiltersSelectedStates(appliedFilters.filter_config.states);
      setFiltersSelectedWidths(
        appliedFilters.filter_config.fixtures.section_width,
      );
      setFiltersSelectedHeights(
        appliedFilters.filter_config.fixtures.section_height,
      );

      const { ffDataRequests, pmDataRequests } =
        await loadMergeRulesApi(appliedFilters);

      if (merge_rules) {
        setMergeRules(
          merge_rules.map((rule) => ({
            variants: rule.merged_variants,
          })),
        );
      }

      setSetupStep(
        merge_rules
          ? NewFlowReviewStep.STEP_1_4_MERGE_RULES
          : NewFlowReviewStep.STEP_1_2_FILTERS,
      );

      const [merchFlowTemplateTableData, step2Results] = await Promise.all([
        getMerchFlowTemplateTable(details.id),
        getAlgoStep2Solutions(appliedFilters.id),
        loadAllBasePog(appliedFilters.id),
      ]);

      if (!merchFlowTemplateTableData) {
        throw Error("No step0 data for this merchflow");
      }

      const allTemplateId = merchFlowTemplateTableData.rows.map(
        (r) => r.template_id,
      );

      const isContainDupplicate =
        new Set(allTemplateId).size < allTemplateId.length;

      if (isContainDupplicate) {
        throw Error("Cant open merchflow as it contain dupplicate data");
      }

      const s0Groups: ResponseAlgoStep0[] = [];
      const sourceTemplateRow = merchFlowTemplateTableData.rows.find((r) =>
        Boolean(r.group_and_size_variants_modified),
      );

      merchFlowTemplateTableData.rows.forEach((row) => {
        if (
          row.step0_response_path === null ||
          row.group_and_size_variants_modified
        ) {
          return;
        }

        s0Groups.push(row.step0_response_path);
      });

      if (
        !sourceTemplateRow ||
        !sourceTemplateRow.step0_response_path ||
        !sourceTemplateRow.group_and_size_variants_modified ||
        !sourceTemplateRow.fixture_modified
      ) {
        throw Error("Cant find source template");
      }

      if (
        sourceTemplateRow &&
        sourceTemplateRow.step0_response_path &&
        sourceTemplateRow.group_and_size_variants_modified &&
        sourceTemplateRow.fixture_modified
      ) {
        s0Groups.unshift(sourceTemplateRow.step0_response_path);
      }

      const allPreStep0 = calculatePrestep0Data({
        appliedFilters,
        ffDataRequests,
        pmDataRequests,
        coreRange: details.config.option,
        flow: details!,
      });

      const selectedStores = merchFlowTemplateTableData.rows.map(
        (r) => r.store_id,
      );

      const selectedPreStep0Data = allPreStep0.filter((storePrestep0) =>
        selectedStores.includes(storePrestep0.storeId),
      );

      setSavedS0({
        containersAtom: omniAtom({
          key: "step0ContainersAtom",
          value: sourceTemplateRow.group_and_size_variants_modified.containers,
        }),
        planogramAtom: omniAtom({
          key: "step0PlanogramAtom",
          value: adapterPogNonCircularToPog(sourceTemplateRow.fixture_modified),
        }),
      });

      setSelectedPreStep0Data(selectedPreStep0Data);
      setAllPreStep0(allPreStep0);

      setGroups(s0Groups.map((s) => s.groups));

      const step1RequestResponse = merchFlowTemplateTableData.rows.reduce(
        (total, r) => {
          if (r.step1_request_path) {
            const s0Index = selectedPreStep0Data.findIndex(
              (s0) => s0.templateId === r.template_id,
            );

            total[s0Index] = {
              request: r.step1_request_path,
              response: r.step1_response_path || { planogram_responses: [] },
              templateId: r.template_id,
            };
          }
          return total;
        },
        [] as {
          request: PayloadS1PlaceContainersAllTemplates[0];
          response: ResponseAlgoStep1;
          templateId: string;
        }[],
      );

      const step1Results = step1RequestResponse.reduce(
        (total, s1) => {
          if (s1.response.planogram_responses) {
            total = total.concat({
              templateId: s1.templateId,
              response: s1.response,
            });
          }
          return total;
        },
        [] as { templateId: string; response: ResponseAlgoStep1 }[],
      );

      const step1AllPogResponse = step1Results
        ?.map((d) =>
          d.response.planogram_responses.flat().map((res) => ({
            ...res,
            templateId: d.templateId,
          })),
        )
        .flat();

      if (!isEmpty(step1RequestResponse)) {
        setStep1Request(step1RequestResponse.map((i) => i.request));
        setStep1OriginalResponse(step1Results.map((s) => s.response));
        setStep1Response(step1AllPogResponse as any);
        currentStep = MerchandiseStep.SELECT_BASE;
      }

      if (!isEmpty(step2Results)) {
        setStep2Data(step2Results);
      }

      const base_pog_id = searchParams.get("base_pog_id");

      const { base_pog, step, error } = checkAndSetPlanogramStepData({
        merchFlowTemplateTableData,
        step2Results,
        setSolutionIndex,
        setSelectedTemplateId,
        setS2ViewSingle,
        setPoggerizeResult,
        setPoggerizeRequest,
        setPoggerResult,
        setSavedPogAtom,
        selectedPreStep0Data,
        currentStep,
        basePogId: parseInt(base_pog_id!, 10),
      });

      currentStep = step;
      if (error) {
        throw error;
      }

      if (base_pog) {
        const storePogResult = await getStoreLevelPogStatus({
          where: {
            filter_config_id: appliedFilters.id,
            is_latest_version: true,
            base_pog_id: base_pog.id,
          },
          offset: 0,
        }).then((rs) => rs.data);

        setStoreLevelPogStatusRows(storePogResult.rows);

        const pagination = Object.keys(storePogResult.pagination).reduce(
          (acc, value) => {
            //@ts-ignore
            acc[value] = Number(storePogResult?.pagination[value]);
            return acc;
          },
          {} as StoreLevelPogPagination,
        );

        pagination.totalPage = Math.ceil(
          pagination.total_rows_length / STORE_LEVEL_PAGE_SIZE,
        );

        pagination.currentPage = Math.ceil(
          pagination.offset / STORE_LEVEL_PAGE_SIZE + 1,
        );

        setStoreLevelPogPaginations(pagination);

        currentStep = MerchandiseStep.STORE_LEVEL_POG;
      }

      navigateToCorrectStep(currentStep, stepFromUrl?.toUpperCase());
    } catch (error) {
      navigateToCorrectStep(currentStep, stepFromUrl?.toUpperCase());
      if (error instanceof Error) {
        toast({ title: error.message, error });
      } else {
        toast({ title: JSON.stringify(error), error });
      }
    }

    removeLoader("continueMerchFlow");
    // openModal('NewFlowReview')
  };

  return {
    continueMerchFlow,
  };
};
