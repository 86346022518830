import {
  latestPogVersion,
  Pog,
  PogNonCircular,
} from "@CommonTypes/merchflow/pog/pog";
import { PrimitiveAtom, atom } from "jotai";
import { omniAtom } from "src/utils/atoms";

import { BasePogWithId } from "./types";

const prefix = "merchflow";

const data = {
  version: latestPogVersion,
  planogram: {
    bays: [],
    width: 1000,
    height: 1000,
  },
  unrangedItems: [],
  deletedItems: [],
  metrics: {
    returnOnSpace: null,
    shelfAlignment: null,
    coreRange: null,
    dosMos: null,
  },
};

export const emptyPog: Pog = {
  ...data,
  version: latestPogVersion,
};
export const emptyPogNonCircular: PogNonCircular = {
  ...data,
  version: latestPogVersion,
};

export const emptyPOGAtom = atom(emptyPog);

export const selectBasePogIndexAtom = omniAtom({
  prefix,
  key: "selectBasePogIndex",
  value: 0,
});

export const basePogTemplateAtoms = omniAtom({
  prefix,
  key: "templateBasePogs",
  value: [] as BasePogWithId[],
});

export const pogTemplateAtoms = omniAtom({
  prefix,
  key: "POGTemplate",
  value: [] as PrimitiveAtom<Pog>[],
});

export const selectedTemplateAtom = omniAtom({
  prefix,
  key: "selectedTemplate",
  value: "",
});
