import { useAtomValue } from "jotai";
import { Dispatch, SetStateAction } from "react";
import { Button, Flex, Scroller, SubgridTable } from "src/elements";
import { SectionItem } from "src/omni-common/types/algo/freezer";
import {
  selectedTemplateIdAtom,
  step2DataAtom,
} from "src/pages/merchandise/store/atoms";
import { uuid } from "src/utils";

import { S2Filter } from "../atoms";
import { columns } from "../constants";
import {
  buildFilterQuery,
  filterPogByQuery,
  mapItemToOptions,
} from "../helpers";
import { FilterRow } from "./FilterRow";

const FilterTable = ({
  dataFilters,
  setDataFilters,
}: {
  dataFilters: S2Filter[];
  setDataFilters: Dispatch<SetStateAction<S2Filter[]>>;
}) => {
  const selectedTemplateId = useAtomValue(selectedTemplateIdAtom);
  const step2Data = useAtomValue(step2DataAtom);
  const selectedTemplateStep2Data = step2Data.filter(
    (item) => item.templateId === selectedTemplateId,
  );

  const itemData = selectedTemplateStep2Data.reduce((total, s2) => {
    const freezer = s2.planogram.freezer;
    let items = [] as SectionItem[];
    freezer.sections.forEach((section) => {
      items = items.concat(section.items);
    });
    return total.concat(items);
  }, [] as SectionItem[]);

  const cdtObj = itemData.reduce(
    (obj, item) => {
      obj.cdt0.add(item.cdt0);
      obj.cdt1.add(item.cdt1);
      obj.cdt2.add(item.cdt2);
      return obj;
    },
    { cdt0: new Set(), cdt1: new Set(), cdt2: new Set() },
  );

  const cdtOptions = {
    cdt0: Array.from(cdtObj.cdt0).map(mapItemToOptions),
    cdt1: Array.from(cdtObj.cdt1).map(mapItemToOptions),
    cdt2: Array.from(cdtObj.cdt2).map(mapItemToOptions),
  };

  const addFilterLevel = () => {
    setDataFilters([
      ...dataFilters.map((item, index) => ({ ...item, filterNo: index + 1 })),
      {
        id: uuid(),
        filterNo: dataFilters.length + 1,
        checked: true,
        cdt: "",
        label: "",
        blockingType: "",
        location: "",
        pog: [],
      },
    ]);
  };

  const toggleFilter = (id: any) => () => {
    setDataFilters(
      dataFilters.map((item) =>
        item.id === id ? { ...item, checked: !item.checked } : item,
      ),
    );
  };

  const deleteFilterOptions = (id: any) => () => {
    setDataFilters(
      dataFilters
        .filter((item) => item.id !== id)
        .map((item, index) => ({ ...item, filterNo: index + 1 })),
    );
  };

  const updateRuleData = (id: any) => (key: any, value: any) => {
    const newData = dataFilters.map((item) =>
      item.id === id ? { ...item, [key]: value } : item,
    );
    setDataFilters(
      newData.map((i) => ({
        ...i,
        pog: selectedTemplateStep2Data.filter(
          filterPogByQuery(buildFilterQuery(i)),
        ),
      })),
    );
  };

  return (
    <>
      <Scroller maxHeight="300px">
        <SubgridTable
          columns={columns}
          data={dataFilters}
          rowRenderer={(data) => (
            <FilterRow
              filterNo={data.filterNo}
              checked={data.checked}
              cdt={data.cdt}
              label={data.label}
              blockingType={data.blockingType}
              location={data.location}
              result={data.pog.length}
              //@ts-ignore
              cdtOptions={cdtOptions[data.cdt] || []}
              updateRuleData={updateRuleData(data.id)}
              deleteFilter={deleteFilterOptions(data.id)}
              toggleFilter={toggleFilter(data.id)}
            />
          )}
        />
      </Scroller>

      <Flex minHeight="0">
        <Button
          iconLeft={{ name: "plus" }}
          color="greenSmoke"
          onClick={addFilterLevel}
        >
          Add Filter Level
        </Button>
      </Flex>
    </>
  );
};

export default FilterTable;
