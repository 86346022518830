import { pages } from "./pages";

const keyToken = "token";
const keyRedirectUrl = "redirect-url";

export const getLocalStorageItem = (key: string) => localStorage.getItem(key);

export const setLocalStorageItem = (key: string, value: string) =>
  localStorage.setItem(key, value);

export const removeLocalStorageItem = (key: string) =>
  localStorage.removeItem(key);

export const clearLocalStorage = () => {
  localStorage.clear();
};

export const setLoginToken = (token: string) =>
  setLocalStorageItem(keyToken, token);

export const getLoginToken = () => getLocalStorageItem(keyToken);

export const removeLoginToken = () => removeLocalStorageItem(keyToken);

export const getAndUnsetRedirectUrl = () => {
  const url = getLocalStorageItem(keyRedirectUrl) || pages.home;
  removeLocalStorageItem(keyRedirectUrl);
  return url;
};

export const setRedirectUrl = (url: string) =>
  setLocalStorageItem(keyRedirectUrl, url);
