import styled from "styled-components";
import {
  SubgridBodyColumn,
  SubgridColumn,
  SubgridSortedBy,
} from "./store/types";
import { Grid } from "../Box/Box";
import { SubgridHead } from "./components/SubgridHead";
import React, { useMemo, useState } from "react";
import { reduceSubgridTableColumnsToBodyColumns } from "./store/utils";
import { SubgridBody } from "./components/SubgridBody";

const TableElement = styled(Grid)<{ bodyColumns: SubgridBodyColumn<object>[] }>`
  width: 100%;
  grid-template-columns: ${({ bodyColumns }) =>
    bodyColumns
      .map((bodyColumn: object) =>
        "width" in bodyColumn ? bodyColumn.width : "auto",
      )
      .join(" ")};
`;

interface Props<T> {
  columns: SubgridColumn<T>[];
  data: T[];
  rowRenderer?: (row: T) => React.ReactNode;
  isSortable?: boolean;
  page?: number;
  rowsPerPage?: number;
}

export const SubgridTable = <T extends object>({
  columns,
  data,
  rowRenderer,
  isSortable = false,
  page,
  rowsPerPage,
}: Props<T>) => {
  const [sortedBy, setSortedBy] = useState<SubgridSortedBy>(null);

  const sortedData = useMemo(() => {
    if (!sortedBy) {
      return data;
    }

    return [...data].sort((dataRow1, dataRow2) => {
      //@ts-ignore
      const dataRow1Value = dataRow1[sortedBy.id];
      //@ts-ignore
      const dataRow2Value = dataRow2[sortedBy.id];
      const direction = sortedBy.direction;

      if (!dataRow1Value) {
        return 1;
      }

      if (!dataRow2Value) {
        return -1;
      }

      if (direction === "descending") {
        return dataRow1Value > dataRow2Value ? -1 : 1;
      }

      if (direction === "ascending") {
        return dataRow1Value < dataRow2Value ? -1 : 1;
      }

      return 0;
    });
  }, [data, sortedBy]);

  const filteredData = useMemo(() => {
    if (page === undefined || rowsPerPage === undefined) {
      return sortedData;
    }

    return sortedData.slice((page - 1) * rowsPerPage, page * rowsPerPage);
  }, [sortedData, page, rowsPerPage]);

  const bodyColumns = useMemo(() => {
    return reduceSubgridTableColumnsToBodyColumns(columns);
  }, [columns]);

  return (
    <TableElement bodyColumns={bodyColumns}>
      <SubgridHead
        columns={columns}
        isSortable={isSortable}
        sortedBy={sortedBy}
        setSortedBy={setSortedBy}
      />

      <SubgridBody
        bodyColumns={bodyColumns}
        data={filteredData}
        rowRenderer={rowRenderer}
      />
    </TableElement>
  );
};
