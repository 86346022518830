export interface Fixture {
  id: string;
  upload_id: string;
  store_id: number;
  category_id: string;
  store_code: string;
  category_code: string;
  section_num: number;
  shelf_num: number;
  section_width: number;
  section_height: number;
  shelf_depth: number;
  shelf_y: number;
  shelf_type: string;
  shelf_thickness: number;
  oos: string;
  variant_restriction: string;
  notch_spacing: number;
  notch_num: number;
  first_notch_y: number;
  peg_notch_spacing_x: number | null;
  peg_notch_spacing_y: number | null;
  grill_height: null;
  can_combine: FixtureCanCombine;
  created_by: string;
  created_at: string;
  shelf_slope: null;
  stopper_height: null;
  peg_notch_offset_x: number | null;
  peg_notch_offset_y: number | null;
  hang_cell_limit: null;
  textbox_x: null;
  textbox_y: null;
  textbox_height: null;
  textbox_width: null;
  textbox_text: null;
  fixture_id: string;
  assembly: number;
  max_notch_owa: number;
  planogram_height: number;
  notch_spacing_owa: number;
  planogram_base_height: number;
  segment_width: number;
  merchandisable_shelf: boolean;
  planogram_notch_offset: number;
  planogram_notch_spacing: number;
  shelf_adjustment_notch_offset_calc: number;
  others: {
    x: number;
    z: number;
    type: number;
    merch: number;
    scope: string;
    store: number;
    colour: number;
    pog_id: number;
    region: string;
    status: string;
    lead_in: string;
    storeId: string;
    filename: string;
    retailer: string;
    live_size: number;
    categoryId: string;
    shelf_name: string;
    total_bays: number;
    min_notch_owa: number;
    partition_key: string;
    splits_string: string;
    store_cluster: string;
    partition_size: string;
    planogram_name: string;
    planogram_depth: number;
    planogram_width: number;
    peg_holes_spacing_x: string;
    peg_holes_spacing_y: string;
    planogram_back_depth: number;
    planogram_base_depth: number;
    planogram_base_width: number;
    store_code_partition: string;
    planogram_notch_start: number;
    planogram_notch_width: number;
    planogram_shelf_width: number;
    retailer_category_code: string;
    // TODO remove the text fields from others. They already exist in upper level.
    //  we keep them for short time to support old uploads(uploads of ff that finished before this task deployed)
    fixture_id: number;
    max_notch_owa: number;
    planogram_height: number;
    notch_spacing_owa: number;
    planogram_base_height: number;
    merchandisable_shelf: boolean;
    planogram_notch_offset: number;
    planogram_notch_spacing: number;
    shelf_adjustment_notch_offset_calc: number;
    // TODO End of fields for removing
  };
  shelf_key: string;
  shelf_clone: string;
  shelf_display: boolean;
  section_multiplier: null;
  section_combine_key: null;
  lead_in_right: boolean;
  shelf_combine: string;
  peg_id: null;
  peg_start_x: null;
  peg_start_y: null;
  peg_end_x: null;
  peg_end_y: null;
}

export enum FixtureCanCombine {
  NONE = 0,
  BOTH = 1,
  LEFT = 2,
  RIGHT = 3,
}
