import {
  Pagination,
  RequiredPaginationQueryParams,
} from "@CommonTypes/backend/pagination";

export const RouteGetStoresList = "/stores/list";

export type ParamsGetStoresList = RequiredPaginationQueryParams;

export type ResponseGetStoresList = Pagination<{
  id: number;
  code: string;
  status: "ACTIVE" | "ARCHIVED";
}>;
