import { ResponseGetMerchflowStoreProp_storePropagationId_ } from "@CommonApi/merchflow/store-prop/_storePropagationId_";
import { omniAtom } from "src/utils/atoms";

const prefix = "merchflows/:merchflowId/stores";

export const merchflows_MerchflowId_StoresAtom = omniAtom({
  prefix,
  key: "data",
  value: undefined as
    | undefined
    | null
    | ResponseGetMerchflowStoreProp_storePropagationId_,
});

export const Stores__storePogIndexAtom = omniAtom({
  prefix,
  key: "storePogIndex",
  value: 0,
});
