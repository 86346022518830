import { ResponseMerchFlowDetails } from "@CommonApi/merchandise";
import {
  OptionalPaginationQueryParams,
  Pagination,
} from "@CommonTypes/backend/pagination";

export const RouteGetMerchflowsReviews = "/merchflows/reviews";

export type ParamsGetMerchflowsReviews = OptionalPaginationQueryParams;

export type ResponseGetMerchflowsReviews = Pagination<ResponseMerchFlowDetails>;
