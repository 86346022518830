export const Render = ({
  when,
  children,
}: {
  when: boolean;
  children: any;
}) => {
  if (!when) {
    return null;
  }

  return children;
};
