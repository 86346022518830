import {
  adapterPogNonCircularToPog,
  adapterPogToPogNonCircular,
} from "@CommonAdapters/pog";
import { Pog, PogNonCircular } from "@CommonTypes/merchflow/pog/pog";
import { PrimitiveAtom, useAtom } from "jotai";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import { useState } from "react";
import { emptyPog } from "src/pages/MerchflowTemplateBasePogs/store/atom";

export const useModifiedPog = (pogAtom: PrimitiveAtom<Pog | null>) => {
  const [pog, _setPog] = useAtom(pogAtom);
  const [originalPog, setOrignalPog] = useState<Pog | null>(null);

  const setPog = (pog: PogNonCircular | null) => {
    if (!pog) {
      _setPog(null);
      setOrignalPog(null);
      return;
    }
    setOrignalPog(cloneDeep(adapterPogNonCircularToPog(pog)));
    _setPog(adapterPogNonCircularToPog(pog));
  };

  const revertPog = () => {
    _setPog(originalPog ? cloneDeep(originalPog) : emptyPog);
  };

  const savePogAsOriginal = () => {
    if (!pog) return;

    setOrignalPog(cloneDeep(pog));
  };

  const isModified = () => {
    return (
      originalPog !== null &&
      !isEqual(
        adapterPogToPogNonCircular({
          ...(pog || emptyPog),
          deletedItems: pog?.deletedItems || [],
        }),
        adapterPogToPogNonCircular({
          ...originalPog,
          deletedItems: originalPog.deletedItems || [],
        }),
      )
    );
  };

  return {
    revertPog,
    setPog,
    savePogAsOriginal,
    pog,
    isModified: isModified(),
  };
};
