import { useAtom } from "jotai";
import { Search } from "src/elements";
import { planogramItemSearcherAtom } from "./store/atoms";

export const PlanogramItemSearcher = () => {
  const [search, setSearch] = useAtom(planogramItemSearcherAtom);

  return (
    <Search
      width="200px"
      placeholder="Search Item"
      search={search}
      setSearch={setSearch}
    />
  );
};
