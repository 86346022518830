import {
  Pog,
  Planogram,
  PlanogramItem,
  latestPogVersion,
} from "@CommonTypes/merchflow/pog/pog";
import cloneDeep from "lodash/cloneDeep";
import uniq from "lodash/uniq";
import uniqBy from "lodash/uniqBy";
import _groupBy from "lodash/groupBy";

import { Fixture } from "src/omni-common/types/backend/fixture";
import { Metrics } from "src/omni-common/types/step2fixtures";

export const createPog = ({
  planogram,
  unrangedItems,
  metrics,
}: {
  planogram: Planogram;
  unrangedItems: PlanogramItem[];
  metrics?: Metrics;
}): Pog => ({
  version: latestPogVersion,
  planogram: cloneDeep(planogram),
  unrangedItems,
  deletedItems: [],
  metrics: {
    returnOnSpace: parseFloat(metrics?.ros?.toFixed(2) || "0"),
    shelfAlignment: parseFloat(metrics?.shelf_alignment?.toFixed(2) || "0"),
    coreRange: null,
    dosMos: null,
  },
});

export function objectValues<T>(obj: T) {
  // @ts-ignore
  return Object.values(obj) as T[keyof T][];
}

// Helper function to convert RGB to HSL
function rgbToHsl(r: any, g: any, b: any) {
  r /= 255;
  g /= 255;
  b /= 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h: any, s: any;

  const l = (max + min) / 2;

  if (max === min) {
    // achromatic
    h = 0;
    s = 0;
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  return [h * 360, s * 100, l * 100];
}

// Helper function to convert HSL to RGB
function hslToRgb(h: any, s: any, l: any) {
  s /= 100;
  l /= 100;

  const c = (1 - Math.abs(2 * l - 1)) * s;
  const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
  const m = l - c / 2;
  let r, g, b;

  if (0 <= h && h < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (60 <= h && h < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (120 <= h && h < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (180 <= h && h < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (240 <= h && h < 300) {
    r = x;
    g = 0;
    b = c;
  } else {
    r = c;
    g = 0;
    b = x;
  }

  r = Math.round((r + m) * 255);
  g = Math.round((g + m) * 255);
  b = Math.round((b + m) * 255);

  return [r, g, b];
}

export const getRandomDarkerColor = (hexColor: string, amount: number) => {
  // Convert hex color to HSL components
  const r = parseInt(hexColor.slice(1, 3), 16);
  const g = parseInt(hexColor.slice(3, 5), 16);
  const b = parseInt(hexColor.slice(5, 7), 16);

  // Convert RGB to HSL
  const hsl = rgbToHsl(r, g, b);

  // Calculate the new lightness based on the given amount
  const newLightness = Math.max(0, hsl[2] - amount);

  // Convert HSL back to RGB
  const newRgb = hslToRgb(hsl[0], hsl[1], newLightness);

  // Convert the new RGB values to hex
  const newHexColor = `#${newRgb[0].toString(16).padStart(2, "0")}${newRgb[1]
    .toString(16)
    .padStart(2, "0")}${newRgb[2].toString(16).padStart(2, "0")}`;

  return newHexColor;
};

export const calculateMergeRulesFromFfAndPos = (
  fixtures: Fixture[],
  positions: Position[],
) => {
  const positionsGroupedByBayAndShelf = _groupBy(
    positions,
    ({ section_num, shelf_num }) => `${section_num}-${shelf_num}`,
  );

  const variantGroups = fixtures.map(({ shelf_num, section_num }) => {
    const variants =
      positionsGroupedByBayAndShelf[`${section_num}-${shelf_num}`]?.map(
        ({ variant }) => variant,
      ) ?? [];

    return uniq(variants.filter(Boolean)).sort();
  });

  return uniqBy(variantGroups, (variantGroup) => variantGroup.join(",")).filter(
    (variantGroup) => variantGroup.length > 1,
  );
};

export type Position = {
  store_code: number;
  input_file_name: string;
  product_code: number;
  x: number;
  y: number;
  z: number;
  shelf_num: number;
  section_num: number;
  position_num: number;
  dfacings: number;
  vfacings: number;
  hfacings: number;
  merch_style: number;
  pog_id: number;
  retailer: string;
  retailer_category_code: string;
  region: string;
  store: number;
  store_code_partition: string;
  status: string;
  variant: string;
};
