import { PspRules } from "@CommonTypes/backend/PspRules";

export const RoutePostMerchflow_merchflowId_FlowUpdateV1 =
  "/merchflow/:merchflowId/flow-update-v1";

export interface PayloadPostMerchflow_merchflowId_FlowUpdateV1 {
  products_to_insert: ProductToInsertV1[];
  product_codes_to_delete: string[];
  products_to_swap: ProductToSwapV1[];
  psp_rules: PspRules;
}

export interface ResponsePostMerchflow_merchflowId_FlowUpdateV1 {
  store_pog_trigger_id: number;
  done_count: number;
  in_progress_count: number;
  error_count: number;
  merchflow_id: number;
  created_at: string;
  finished_at?: string;
  payload?: object;
  user_email?: string;
  error_details?: object[];
}

export interface ProductToInsertV1 {
  product_code: string;
  adjacent_product_code: string;
  place_to: "LEFT" | "RIGHT";
}
export interface ProductToSwapV1 {
  product_code_to_add: string;
  product_code_to_remove: string;
}
