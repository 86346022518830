import React, { useEffect, useRef, useState } from "react";
import { AnchorLayerPosition, AnchorLayerState } from "./types";

interface Props {
  refTrigger?: React.RefObject<HTMLElement>;
  refAnchor?: React.RefObject<HTMLElement>;
  position?: AnchorLayerPosition;
  offset?: number;
  safeZone?: number;
  isUsingParent?: boolean;
  isUsingHover?: boolean;
  isUsingClick?: boolean;
  isCentered?: boolean;
  isTopOfParent?: boolean;
}

export const useAnchorLayer = ({
  refTrigger: providedRefTrigger,
  refAnchor,
  position = "bottom",
  offset = 0,
  safeZone = 5,
  isUsingParent,
  isUsingHover,
  isUsingClick,
  isCentered,
  isTopOfParent,
}: Props) => {
  const [isRendering, setIsRendering] = useState(false);
  const [layerState, setLayerState] = useState<AnchorLayerState>(null);

  const refLayer = useRef<HTMLDivElement>(null);
  const refEvent = useRef<React.MouseEvent<HTMLElement> | null>(null);
  const refTrigger = useRef<HTMLElement | null>(
    providedRefTrigger?.current || null,
  );

  // Effect to bind mouse listeners to trigger.
  useEffect(() => {
    const trigger = isUsingParent
      ? (providedRefTrigger || refTrigger).current?.parentElement!
      : (providedRefTrigger || refTrigger).current;
    if (!trigger) return;

    if (isUsingHover) {
      trigger.addEventListener("mouseenter", showAnchorLayer);
      trigger.addEventListener("mouseleave", hideAnchorLayer);
    }

    if (isUsingClick) {
      trigger.addEventListener("click", showAnchorLayer);
    }

    return () => {
      if (isUsingHover) {
        trigger.removeEventListener("mouseenter", showAnchorLayer);
        trigger.removeEventListener("mouseleave", hideAnchorLayer);
      }

      if (isUsingClick) {
        trigger.addEventListener("click", showAnchorLayer);
      }
    };
  }, [
    refTrigger,
    refTrigger.current,
    providedRefTrigger,
    providedRefTrigger?.current,
  ]);

  // Effect to calculate layer position from element.
  useEffect(() => {
    setTimeout(() => {
      if (
        isRendering &&
        !layerState &&
        (refAnchor?.current ||
          providedRefTrigger?.current ||
          refTrigger.current)
      ) {
        showAnchorLayerFromElement({
          position,
          anchor: (isUsingParent
            ? (
                refAnchor?.current ||
                providedRefTrigger?.current! ||
                refTrigger.current
              ).parentElement
            : refAnchor?.current ||
              providedRefTrigger?.current ||
              refTrigger.current)!,
        });
      }
    }, 0);
  }, [isRendering]);

  // Effect to calculate layer position from event.
  useEffect(() => {
    setTimeout(() => {
      if (isRendering && !layerState && refLayer.current && refEvent.current) {
        const { clientX: cursorXLeft, clientY: cursorYTop } = refEvent.current;

        setLayerState(
          findPositionForAnchorLayer({
            layerElement: refLayer.current,
            position,
            xLeftTrigger: cursorXLeft,
            yTopTrigger: cursorYTop,
            triggerWidth: 0,
            triggerHeight: 0,
          }),
        );
      }
    }, 0);
  }, [isRendering]);

  const showAnchorLayer = () => {
    setIsRendering(true);
  };

  const hideAnchorLayer = () => {
    setIsRendering(false);
    setLayerState(null);
    refEvent.current = null;
  };

  // Calculate correct position for absolute layer based on its dimensions.
  const showAnchorLayerFromElement = ({
    anchor,
    position: customPosition,
  }: {
    anchor: HTMLElement;
    position?: AnchorLayerPosition;
  }): void => {
    if (!refLayer.current) return;

    const {
      left: xLeftTrigger,
      top: yTopTrigger,
      height: triggerHeight,
      width: triggerWidth,
    } = anchor.getBoundingClientRect();

    setLayerState(
      findPositionForAnchorLayer({
        layerElement: refLayer.current,
        position: customPosition || position,
        xLeftTrigger,
        yTopTrigger,
        triggerWidth,
        triggerHeight,
      }),
    );
  };

  const showAnchorLayerFromEvent = (event: React.MouseEvent<HTMLElement>) => {
    refTrigger.current = null;
    refEvent.current = event;
    showAnchorLayer();
  };

  const showAnchorLayerFromEventTarget = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    refEvent.current = null;
    refTrigger.current = event.target as HTMLElement;
    showAnchorLayer();
  };

  const findPositionForAnchorLayer = ({
    layerElement,
    position,
    xLeftTrigger,
    yTopTrigger,
    triggerWidth,
    triggerHeight,
    firstAttempt = true,
  }: {
    layerElement: HTMLElement;
    position: AnchorLayerPosition;
    xLeftTrigger: number;
    yTopTrigger: number;
    triggerWidth: number;
    triggerHeight: number;
    firstAttempt?: boolean;
  }): AnchorLayerState => {
    const screenWidth = document.body.clientWidth;
    const screenHeight = document.body.clientHeight;

    const { offsetWidth: layerWidth, offsetHeight: layerHeight } = layerElement;

    // Calculate initial raw coordinates for the layer.
    let xLeft =
      ((position === "top" || position === "bottom") &&
        xLeftTrigger + (isCentered ? triggerWidth / 2 - layerWidth / 2 : 0)) ||
      (position === "left" &&
        xLeftTrigger - (isTopOfParent ? 0 : layerWidth) - offset) ||
      (position === "right" &&
        xLeftTrigger + (isTopOfParent ? 0 : triggerWidth) + offset) ||
      0;
    let yTop =
      (position === "bottom" &&
        yTopTrigger + (isTopOfParent ? 0 : triggerHeight) + offset) ||
      (position === "top" &&
        yTopTrigger +
          (isTopOfParent ? triggerHeight : 0) -
          layerHeight -
          offset) ||
      ((position === "left" || position === "right") &&
        yTopTrigger + (isCentered ? triggerHeight / 2 - layerHeight / 2 : 0)) ||
      0;
    let xRight = xLeft + layerWidth;
    let yBottom = yTop + layerHeight;
    let compensation = 0;

    // Swap positions if layer goes out of screen on main axis.
    if (firstAttempt) {
      if (position === "bottom" && yBottom > screenHeight - safeZone) {
        return findPositionForAnchorLayer({
          layerElement,
          xLeftTrigger,
          yTopTrigger,
          triggerWidth,
          triggerHeight,
          position: "top",
          firstAttempt: false,
        });
      }
      if (position === "top" && yTop < safeZone) {
        return findPositionForAnchorLayer({
          layerElement,
          xLeftTrigger,
          yTopTrigger,
          triggerWidth,
          triggerHeight,
          position: "bottom",
          firstAttempt: false,
        });
      }
      if (position === "right" && xRight > screenWidth - safeZone) {
        return findPositionForAnchorLayer({
          layerElement,
          xLeftTrigger,
          yTopTrigger,
          triggerWidth,
          triggerHeight,
          position: "left",
          firstAttempt: false,
        });
      }
      if (position === "left" && xLeft < safeZone) {
        return findPositionForAnchorLayer({
          layerElement,
          xLeftTrigger,
          yTopTrigger,
          triggerWidth,
          triggerHeight,
          position: "right",
          firstAttempt: false,
        });
      }
    }

    // Keep assigned position, but compensate in opposite axis.
    if (
      (position === "top" || position === "bottom") &&
      xRight > screenWidth - safeZone
    ) {
      compensation = xRight - (screenWidth - safeZone);
      xLeft -= xRight - xLeft - triggerWidth;
      xRight -= xRight - (screenWidth - safeZone);
    } else if (
      (position === "top" || position === "bottom") &&
      xLeft < safeZone
    ) {
      compensation = Math.abs(xLeft) - safeZone;
      xRight += Math.abs(xLeft) - safeZone;
      xLeft += Math.abs(xLeft) - safeZone;
    } else if (
      (position === "left" || position === "right") &&
      yTop < safeZone
    ) {
      compensation = Math.abs(yTop) - safeZone;
      yBottom += Math.abs(yTop) - safeZone;
      yTop += Math.abs(yTop) - safeZone;
    } else if (
      (position === "left" || position === "right") &&
      yBottom > screenHeight - safeZone
    ) {
      compensation = yBottom - (screenHeight - safeZone);
      yTop -= yBottom - (screenHeight - safeZone);
      yBottom -= yBottom - (screenHeight - safeZone);
    }

    return {
      parentWidth: getElementWidth(
        refAnchor?.current || providedRefTrigger?.current || refTrigger.current,
      ),
      xLeft,
      yTop,
      position,
      compensation,
    };
  };

  return {
    refTrigger,
    isRendering,
    isVisible: layerState !== null,
    isUsingClick,
    refLayer,
    layerState,
    showAnchorLayerFromElement,
    showAnchorLayerFromEvent,
    showAnchorLayerFromEventTarget,
    hideAnchorLayer,
  };
};

const getElementWidth = (element?: HTMLElement | null) => {
  return element ? `${element.getBoundingClientRect().width}px` : "auto";
};
