import { useEffect } from "react";
import { Page, PageStatus } from "src/components";
import { useInlineLoaders } from "src/components/InlineLoader";
import {
  Button,
  Color,
  Flex,
  Pagination,
  Scroller,
  SubgridTable,
  Text,
} from "src/elements";
import { usePaginationPage } from "src/elements/Pagination";

import {
  ResponseGetInputsMasterFixturesAll,
  RouteGetInputsMasterFixturesAll,
} from "@CommonApi/inputs/master-fixtures/all";
import { useApi } from "src/api";
import { useAsyncState } from "src/hooks/useAsyncState";
import { getFixturesTableColumns } from "./store/columns";

export const PageFixtures = () => {
  const { getFixturesApi } = useApi();
  const { isInlineLoading } = useInlineLoaders();

  const [page, setPage] = usePaginationPage();
  const [response, setResponse] = useAsyncState<
    ResponseGetInputsMasterFixturesAll | null | undefined
  >(undefined);

  const isFixturesLoading =
    response === undefined || isInlineLoading(RouteGetInputsMasterFixturesAll);

  useEffect(() => {
    setResponse(getFixturesApi({ page, size: 100 }));
  }, [page]);

  useEffect(() => {
    return () => {
      setResponse(undefined);
    };
  }, []);

  return (
    <Page>
      <Flex column grow gap="10px">
        <Flex padding="10px 0 0 0" justify="between" align="center">
          <Text variant="h3">Fixtures</Text>

          <Button width="120px" iconRight={{ name: "plus" }} isDisabled>
            Add Fixture
          </Button>
        </Flex>

        <Flex column grow gap="20px">
          {isFixturesLoading && (
            <PageStatus label="Loading Fixtures..." icon="spinner" />
          )}

          {!isFixturesLoading && !response && (
            <PageStatus
              label="Fixtures not found!"
              icon="alert"
              color={Color.red}
            />
          )}

          {!isFixturesLoading && response && (
            <Flex grow>
              <Scroller>
                <SubgridTable
                  columns={getFixturesTableColumns()}
                  data={response.data}
                />
              </Scroller>
            </Flex>
          )}
        </Flex>

        <Pagination
          page={page}
          setPage={setPage}
          totalPages={response?.totalPages || 1}
          isUsingInput
          isLoading={response === undefined}
        />
      </Flex>
    </Page>
  );
};
