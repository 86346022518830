import { Planogram } from "@CommonTypes/merchflow/pog/pog";
import { Container } from "src/components/Planogram/store/types";
import { FreezerS0Group } from "src/omni-common/types/algo/freezer";
import {
  getShelfXLeft,
  getShelfXRight,
  getShelfYBottom,
} from "src/omni-common/utils/pog/shelf";
import { colorObj } from "src/pages/merchandise/store/atoms";
import { uuid } from "src/utils";

export const algoStep0ConvertGroupsToContainers = (
  groups: FreezerS0Group[],
  planogram: Planogram,
) => {
  const containers: Container[] = groups
    .map((group) => {
      return {
        uniqueId: uuid(),
        label: group.variants.map((variant) => variant.name).join(", "),
        //@ts-ignore
        color: colorObj[group.variants[0].name] || "#00a3ff",
        xLeft: group.x,
        xRight: group.x + (group.width > 0 ? group.width : group.target_width),
        yBottom: group.y,
        yTop: group.y + group.height,
        algoMinTargetWidth: group.min_target_width,
        algoTargetWidth: group.target_width,
        algoCurrentTargetWidth: 0,
      };
    })
    .map((container) => ({
      ...container,
      algoCurrentTargetWidth: calculateContainerCurrentWidth(
        container,
        planogram,
      ),
    }));

  return containers;
};

export const algoStep0ConvertContainersToGroup = (
  containers: Container[],
  originalGroups: FreezerS0Group[],
  calculatedTargetWidth: any,
) => {
  const groups: FreezerS0Group[] = [];

  containers.forEach((container, index) => {
    const variantNames = originalGroups[index].variants
      .map((v) => v.name)
      .join(", ");

    groups.push({
      group_id: index.toString(),
      height: container.yTop - container.yBottom,
      width: container.xRight - container.xLeft,
      x: container.xLeft,
      y: container.yBottom,
      min_target_width: container.algoMinTargetWidth!,
      variants: originalGroups[index].variants,
      target_width: calculatedTargetWidth[variantNames].target_width,
    });
  });

  return groups;
};

export const calculateContainerCurrentWidth = (
  container: Container,
  planogram: Planogram,
) => {
  if (container.algoTargetWidth === undefined) {
    return undefined;
  }

  let currentTargetWidth = 0;

  for (const bay of planogram.bays) {
    for (const shelf of bay.shelves) {
      if (
        container.yBottom <= getShelfYBottom(shelf) &&
        container.yTop > getShelfYBottom(shelf)
      ) {
        const xLeft = Math.max(getShelfXLeft(shelf), container.xLeft);
        const xRight = Math.min(getShelfXRight(shelf), container.xRight);

        if (
          xLeft >= getShelfXLeft(shelf) &&
          xLeft <= getShelfXRight(shelf) &&
          xRight <= getShelfXRight(shelf) &&
          xRight >= getShelfXLeft(shelf)
        ) {
          currentTargetWidth += xRight - xLeft;
        }
      }
    }
  }

  return currentTargetWidth;
};
