import { Pog } from "@CommonTypes/merchflow/pog/pog";
import { getPogShelf } from "@CommonUtils/pog/pog";
import { PrimitiveAtom, useAtomValue, useSetAtom } from "jotai";
import { usePlanogramBayActions } from "src/components/Planogram/types";
import {
  planogramFixturesModeAtom,
  planogramSelectedShelfAtom,
} from "src/components/PlanogramExtensions/store/atoms";
import { Button, Color, Flex, Text } from "src/elements";

interface Props {
  pogAtom: PrimitiveAtom<Pog>;
}

export const NoShelf = ({ pogAtom }: Props) => {
  const { addNewShelf } = usePlanogramBayActions(pogAtom);

  const pog = useAtomValue(pogAtom);
  const setSelectedShelf = useSetAtom(planogramSelectedShelfAtom);
  const setFixturesMode = useSetAtom(planogramFixturesModeAtom);

  const startAddingNewFixture = () => {
    setFixturesMode(true);
    setSelectedShelf(getPogShelf(pog, addNewShelf(1)));
  };

  return (
    <Flex column grow>
      <Flex grow justify="center" align="center">
        <Text variant="h3" color={Color.spaceGray}>
          Add New Shelf
        </Text>
      </Flex>

      <Flex padding="10px">
        <Button width="100%" onClick={startAddingNewFixture} variant="inverted">
          Add New Shelf
        </Button>
      </Flex>
    </Flex>
  );
};
