import { useSetAtom } from "jotai";
import {
  Button,
  Color,
  Flex,
  HorizontalDivider,
  Text,
  VerticalDivider,
} from "src/elements";
import { useResetMerchFlowPage } from "src/hooks";
import { pages } from "src/utils";
import styled from "styled-components";

import { stepAtom } from "../../store/atoms";
import { MerchandiseStep } from "../../store/types";
import { MerchandiseSettings } from "../MerchandiseSettings";
import { useMenu } from "src/components/Menu";
import { useNavigation } from "src/utils/navigation";

const stats = [
  { label: "Retailer", value: "-" },
  { label: "Category", value: "-" },
];

const ControlPanelComponent = styled.div`
  width: 100%;
  min-height: 110px;
  border: 1px solid ${Color.primary};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
`;

const TopPanel = styled.div`
  width: 100%;
  display: flex;
  gap: 24px;
  justify-content: space-around;
  align-items: center;
`;

const Item = styled.div<{ isFlexColumn?: boolean }>`
  height: 100%;
  display: flex;
  gap: 5px;
  align-items: center;
  flex-direction: ${({ isFlexColumn }) => (isFlexColumn ? "column" : "row")};
`;

export const ControlPanel = () => {
  const { openMenu } = useMenu();
  const setStep = useSetAtom(stepAtom);
  const { navigate } = useNavigation();
  const { resetMerchflow } = useResetMerchFlowPage();

  const returnToHome = () => {
    setStep(MerchandiseStep.LIST_FLOW_REVIEW);
    navigate(pages.merchandise);
    resetMerchflow();
  };

  const openOldFlow = () => {
    setStep(MerchandiseStep.LIST_FLOW_REVIEW);
  };

  return (
    <ControlPanelComponent>
      <TopPanel>
        <Flex onClick={returnToHome}>
          <Text variant="body1" color={Color.primary}>
            Merch Flow Process Home
          </Text>
        </Flex>

        <Item>
          <VerticalDivider />

          <Button
            height="min-content"
            variant="borderless"
            onClick={(event) =>
              openMenu({
                event,
                menu: {
                  options: [
                    { label: "Continue Flow Review", onClick: openOldFlow },
                  ],
                },
              })
            }
          >
            Flow Review Process
          </Button>
        </Item>

        <Item>
          <VerticalDivider />

          <Button
            height="min-content"
            variant="borderless"
            onClick={(event) =>
              openMenu({
                event,
                menu: {
                  options: [{ label: "No recent item", onClick: () => {} }],
                },
              })
            }
          >
            Open Recent
          </Button>
        </Item>

        {stats.map(({ label, value }, index) => (
          <Item key={index}>
            <VerticalDivider />

            <Flex column margin="0 0 0 10px" justify="center">
              <Text
                variant="caption1"
                color={Color.textSecondary}
                textTransform="uppercase"
              >
                {label}:
              </Text>

              <Flex>
                <Text variant="h5">
                  {value === null && "-"}
                  {value && typeof value === "number" && `${value}%`}
                  {value && typeof value === "string" && `${value}`}
                </Text>
              </Flex>
            </Flex>
          </Item>
        ))}

        <Item>
          <MerchandiseSettings />
        </Item>
      </TopPanel>

      <HorizontalDivider />
    </ControlPanelComponent>
  );
};
