import React, { useRef } from "react";

import { Input } from "../Input/Input";
import { IconType } from "../Icon/Icon";

interface Props {
  search: string;
  setSearch: (search: string) => void;
  placeholder?: string;
  width?: string;
  isBorderless?: boolean;
  isFocusedAutomatically?: boolean;
  rightIcon?: { name: IconType; onClick?: () => void } | false;
  refSearch?: React.RefObject<HTMLInputElement>;
}

export const Search: React.FC<Props> = ({
  search,
  setSearch,
  placeholder = "Search",
  width,
  isBorderless,
  isFocusedAutomatically,
  rightIcon: rightIconForwarded,
  refSearch: refSearchForwarded,
}) => {
  const refSearch = useRef<HTMLInputElement>(null);

  const getRef = () => {
    return refSearchForwarded || refSearch;
  };

  const getPlaceholder = () => {
    return `${placeholder}`.replaceAll(".", "") + "...";
  };

  const getRightIcon = () => {
    return (
      rightIconForwarded ||
      (search && { name: "clear", onClick: clearSearch }) ||
      false
    );
  };

  const clearSearch = () => {
    setSearch("");
    getRef().current?.focus();
  };

  return (
    <Input
      value={search}
      setValue={setSearch}
      placeholder={getPlaceholder()}
      width={width}
      isBorderless={isBorderless}
      isFocusedAutomatically={isFocusedAutomatically}
      leftIcon={{ name: "search" }}
      rightIcon={getRightIcon()}
      refInput={refSearchForwarded || refSearch}
    />
  );
};
