import {
  Highlight,
  PayloadPutMerchflows_MerchflowId_HighlightsBasePogs,
  ResponseGetMerchflows_MerchflowId_HighlightsBasePogs,
  ResponsePutMerchflows_MerchflowId_HighlightsBasePogs,
  RouteGetMerchflows_MerchflowId_HighlightsBasePogs,
  RoutePutMerchflows_MerchflowId_HighlightsBasePogs,
} from "@CommonApi/merchflows/_merchflowId_/highlights/base-pogs";
import { useSetAtom } from "jotai";
import { baseHighlightsAtom } from "src/components/Planogram/store/atoms";
import { getRequest, putRequest, API } from "src/utils/axios";
import { v4 as uuid } from "uuid";

export const getMerchflows_MerchflowId_HighlightsBasePogsReq = (
  merchflowId: number,
) => {
  return getRequest<ResponseGetMerchflows_MerchflowId_HighlightsBasePogs>(
    `${API}${RouteGetMerchflows_MerchflowId_HighlightsBasePogs}`,
    {
      params: {
        merchflowId,
      },
    },
  );
};

export const putMerchflows_MerchflowId_HighlightsBasePogsReq = (
  merchflowId: number,
  payload: PayloadPutMerchflows_MerchflowId_HighlightsBasePogs,
) => {
  return putRequest<ResponsePutMerchflows_MerchflowId_HighlightsBasePogs>(
    `${API}${RoutePutMerchflows_MerchflowId_HighlightsBasePogs}`,
    {
      params: {
        merchflowId,
      },
      payload,
    },
  );
};

export const useHighlightsApi = () => {
  const setBaseHighlights = useSetAtom(baseHighlightsAtom);

  const getBaseHighlightsApi = async (merchflowId: number) => {
    try {
      const { data } =
        await getMerchflows_MerchflowId_HighlightsBasePogsReq(merchflowId);

      // OMF-797 casualty
      setBaseHighlights(
        data.length > 0
          ? data
          : [
              {
                id: uuid(),
                enabled: true,
                name: "",
                color: "#ff0000",
                formula: "dos < minDaysOfSupply",
              },
              {
                id: uuid(),
                enabled: true,
                name: "",
                color: "#0000ff",
                formula: "mos < minOnShelf",
              },
              {
                id: uuid(),
                enabled: true,
                name: "",
                color: "#ffff00",
                formula: "newItemFlag === true",
              },
            ],
      );
    } catch (error) {
      // toast({
      //   title: "Failed to load base highlights",
      //   error,
      // });
      setBaseHighlights(null);
    }
  };

  const putBaseHighlightsApi = async (
    merchflowId: number,
    highlights: Highlight[],
  ) => {
    try {
      await putMerchflows_MerchflowId_HighlightsBasePogsReq(
        merchflowId,
        highlights,
      );
    } catch (error) {
      // toast({
      //   title: "Failed to update base highlights",
      //   error,
      // });
    }
  };

  return {
    getBaseHighlightsApi,
    putBaseHighlightsApi,
  };
};
