import { PogNonCircular, Pog } from "@CommonTypes/merchflow/pog/pog";
import { PrimitiveAtom } from "jotai";
import { Container } from "src/components/Planogram/store/types";
import { OwaPagination } from "src/omni-common/api/_common/sqlThings";
import { PoggerResponse } from "src/omni-common/types/algo/pogger";
import { PoggerizeResponse } from "src/omni-common/types/algo/poggerize";
import { Fixture } from "src/omni-common/types/backend/fixture";
import { ProductMaster } from "src/omni-common/types/backend/productMaster";
import { Step1Step2Solution } from "src/omni-common/types/step2fixtures";

import {
  PayloadAlgoStep0,
  ResponseAlgoStep0,
  ResponseAlgoStep1,
} from "../steps/Step2Fixtures/store/types";
import { PayloadS1PlaceContainersAllTemplates } from "@CommonApi/frogger/place_containers_all_templates";

export const enum MerchandiseStep {
  STEP_1_HOME = "STEP_1_HOME",
  LIST_FLOW_REVIEW = "LIST_FLOW_REVIEW",
  LIST_FLOW = "LIST_FLOW",
  VIEW_TEMPLATES = "VIEW_TEMPLATES",
  SELECT_BASE = "SELECT_BASE",
  GENERATE_BASE = "GENERATE_BASE",
  APPLY_TO_BASE = "APPLY_TO_BASE",
  STORE_LEVEL_POG = "STORE_LEVEL_POG",
}

export const enum MerchandiseViewStyle {
  SINGLE = "SINGLE",
  DOUBLE_VERTICAL = "DOUBLE_VERTICAL",
  DOUBLE_HORIZONTAL = "DOUBLE_HORIZONTAL",
  QUADRANT = "QUADRANT",
}

export const enum MerchandiseFavoritesAmount {
  DEFAULT = 4,
  MORE = 8,
}

export interface Flow {
  name: string;
  return_on_space: number;
  shelves_alignment: number;
  shoppability: number;
  dos_mos: number;
  favorite?: boolean;
  compare?: boolean;
  comments: string;
}

export type MerchFlowCategories = {
  [catKey: string]: string[];
};

export type NewReviewFlowPayload = {
  category: string;
  subCategory: string;
  name: string;
  notes: string;
  coreRange: string;
  coreRangeUploadId?: string;
};

export type MerchFlowStore = {
  bay_size: number;
  state: string;
  section_height: number;
  section_width: number;
  cluster: string;
  retailer_category_code: string;
  category_code: string;
  store: number;
};

export type MerchFlowFilter = {
  baySizes: number[];
  states: string[];
  clusters: string[];
  fixtures: {
    section_height: number[];
    section_width: number[];
  };
  stores: MerchFlowStore[];
};

export type FilterInputPayload = {
  name: string;
  value: boolean;
};

export type FilterInputFixturePayload = FilterInputPayload & {
  originalValue: string | number;
  type: string;
};

export type StoreLevelPogStatus =
  | "NOT_STARTED"
  | "IN_PROGRESS"
  | "ERROR"
  | "DONE";

export type StoreLevelPogRow = {
  created_at: string;
  ff_path: string;
  filter_config_id: number;
  id: number;
  is_latest_version: boolean;
  pm_path: string;
  pog: PogNonCircular & { poggerize: any };
  pog_raw_data: {
    ff: Fixture[];
    pm: ProductMaster[];
    pogger_response: PoggerResponse;
    poggerize_response: PoggerizeResponse;
  };
  pogger_request_path: string;
  pogger_response_path: string;
  poggerize_request_path: string;
  poggerize_response_path: string;
  pos_path: string;
  status: StoreLevelPogStatus;
  base_pog_place_variants_response_json: Step1Step2Solution;
  store: string;
  store_code: string;
  store_id: number;
  template_id: string;
};

export type StoreLevelPogPagination = {
  current_rows_length: number;
  total_rows_length: number;
  limit: number;
  offset: number;
  totalPage: number;
  currentPage: number;
};

export type WithPagination<T> = {
  pagination: {
    current_rows_length: string;
    total_rows_length: string;
    limit: string;
    offset: string;
  };
  rows: T[];
};
export type ResponseStoreLevelRows = WithPagination<StoreLevelPogRow>;

export type OldPagination = {
  totalRows: number;
  totalPages: number;
  page: number;
  size: number;
};

export type WithOldPagination<T> = OldPagination & {
  data: T[];
};

export type Filter = {
  table: string;
  column: string;
  value: (number | string)[];
};

export type SavedS0Atom = {
  containersAtom: PrimitiveAtom<Container[]>;
  planogramAtom: PrimitiveAtom<Pog>;
};

export type MerchFlowTemplateTable = {
  base_pog: {
    created_at: string;
    filter_config_id: number;
    highlights: any;
    id: number;
    merchflow_id: number;
    parent_base_pog_id: any;
    pog_data: any;
    poggerize_request_path: null;
    poggerize_response_path: PoggerizeResponse;
    pogger_request_path: null;
    pogger_response_path: { data: PoggerResponse } | null;
    solution_id: number;
  };
  step2_request_path: null;
  step2_response_path: null;
  step1_ff_request_path: null;
  step1_ff_response_path: null;
  group_and_size_variants_modified: { containers: Container[] } | null;
  fixture_modified: PogNonCircular | null;
  poggerize_request_path: null;
  poggerize_response_path: PoggerizeResponse;
  pogger_request_path: null;
  pogger_response_path: { data: PoggerResponse } | null;
  pogger_modified: null;
  filter_config_id: number;
  store_id: number;
  id: number;
  merchflow_id: number;
  template_id: string;
  parent_id: null;
  step0_request_path: PayloadAlgoStep0 | null;
  step0_response_path: ResponseAlgoStep0 | null;
  step1_request_path: PayloadS1PlaceContainersAllTemplates[0] | null;
  step1_response_path: ResponseAlgoStep1 | null;
};

export type ResponseGetMerchFlowTemplateTable = {
  pagination: OwaPagination;
  rows: MerchFlowTemplateTable[];
};

export type ResponseGetAlgoStep2Solution = {
  totalRows: number;
  totalPages: number;
  page: number;
  size: number;
  solutions: {
    solution_id: number;
    template_id: string;
    solution: Step1Step2Solution;
    order_in_response: number;
  }[];
  logs: {
    request_path: any;
    response_path: any;
    solution_ids: number[];
  }[];
};
