import { useAtom, useAtomValue } from "jotai";
import React, { useEffect, useRef } from "react";
import { useHighlightsApi } from "src/api/merchflows/highlights";
import { DynamicLogButton } from "src/components/DynamicLogButton";
import { Planogram } from "src/components/Planogram/Planogram";
import { Button, Flex } from "src/elements";
import {
  detailsAtom,
  filtersAppliedAtom,
} from "src/modals/NewFlowReview/store/atoms";
import { adapterPlanogramToPlanogramNonCircular } from "src/omni-common/adapters/pog";
import { useMerchandiseApi } from "src/pages/merchandise/store/api";
import {
  emptyContainersAtom,
  poggerizeResultAtom,
  poggerResultAtom,
  savedPogAtomAtom,
  selectedPreStep0DataAtom,
  selectedTemplateIdAtom,
  storeLevelPogStatusRowsAtom,
} from "src/pages/merchandise/store/atoms";
import styled from "styled-components";

import { useMerchflowNavigation } from "src/hooks";
import { usePowerBiReportModal } from "src/modals/PowerBiReportModal/hooks";
import { emptyPOGAtom } from "src/pages/MerchflowTemplateBasePogs/store/atom";
import { BasePogVersion } from "../../BasePogVersion";
import { PlanogramFixturesMode } from "src/components/PlanogramExtensions/PlanogramFixturesMode";
import { PlanogramMetrics } from "src/components/PlanogramExtensions/PlanogramMetrics";
import { PlanogramItemSearcher } from "src/components/PlanogramExtensions/PlanogramItemSearcher";
import { PlanogramHovererDetails } from "src/components/PlanogramExtensions/PlanogramHovererDetails/PlanogramHovererDetails";
import { isEmpty } from "src/utils/isEmpty";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  flex-grow: 1;
  min-height: 0;
`;

export const ApplyToBase = () => {
  const { callPogModified, callPogModifiedToViewReport, exportPSA, importPsa } =
    useMerchandiseApi();
  const { getBaseHighlightsApi } = useHighlightsApi();
  const details = useAtomValue(detailsAtom);

  const pogger = useAtomValue(poggerResultAtom);
  const poggerize = useAtomValue(poggerizeResultAtom);
  const storePropagationResults = useAtomValue(storeLevelPogStatusRowsAtom);
  const preStep0Data = useAtomValue(selectedPreStep0DataAtom);
  const selectedTemplateId = useAtomValue(selectedTemplateIdAtom);
  const appliedFilter = useAtomValue(filtersAppliedAtom);
  const [savedPogAtom, setSavedPogAtom] = useAtom(savedPogAtomAtom);
  const { navigateToSelectBase, navigateToStoreProps } =
    useMerchflowNavigation();

  const realPogAtom = savedPogAtom ? savedPogAtom : emptyPOGAtom;
  const editedPog = useAtomValue(realPogAtom);

  const importPsaInputRef = useRef<HTMLInputElement>(null);
  const filterConfigId = appliedFilter?.id!;

  const { openBasePogReport } = usePowerBiReportModal();

  useEffect(() => {
    getBaseHighlightsApi(details!.id);
  }, []);

  const applyToBase = () => {
    // if (!isEqual(editedPog, pogOriginalValue)) {
    if (!poggerize) return;
    callPogModified({ poggerize, pog: editedPog });
    setSavedPogAtom(realPogAtom);
    // }
  };

  const dataFetcher = () => {
    // @ts-ignore
    const { pmData, ffData } = preStep0Data.find(
      (d) => d.templateId === selectedTemplateId,
    );
    return {
      poggerize_response: poggerize,
      pogger_response: pogger,
      pmData,
      ffData,
      pog_render_data: {
        planogram: adapterPlanogramToPlanogramNonCircular(editedPog.planogram),
        unrangedItems: editedPog.unrangedItems,
      },
    };
  };

  const onClickReport = () => {
    if (!poggerize) return;
    callPogModifiedToViewReport({ poggerize, pog: editedPog }).then(() => {
      openBasePogReport([
        {
          column: "filter_config_id",
          table: "gen_id_table",
          value: [filterConfigId],
        },
      ]);
    });
  };

  const onExportPSA = () => {
    if (!poggerize) return;
    exportPSA({ merchflowId: details!.id, poggerize, pog: editedPog });
  };

  const onImportPsa = () => importPsaInputRef.current?.click();

  const onImportPsaUploaded = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files || [];

    if (files.length > 0) {
      importPsa(files[0]);
    }

    event.target.files = null;
    event.target.value = "";
  };

  return (
    <>
      <Flex justify="between" align="center" height="40px">
        <Flex width="100%" gap="10px" justify="between" align="center">
          <Flex gap="10px">
            <PlanogramItemSearcher />
            <BasePogVersion />
          </Flex>

          <Flex>
            <PlanogramFixturesMode />
          </Flex>
        </Flex>
      </Flex>

      <Container>
        <Planogram
          key={realPogAtom.toString()}
          pogAtom={realPogAtom}
          containersAtom={emptyContainersAtom}
          isEditable
          isPog
          isPanelVisible
        />

        <PlanogramHovererDetails />

        <PlanogramMetrics pog={editedPog} />
      </Container>

      <Flex justify="right" gap="10px">
        <Flex justify="right" gap="20px">
          <Button
            variant="inverted"
            color="red"
            onClick={() => navigateToSelectBase()}
          >
            Back
          </Button>
        </Flex>

        <DynamicLogButton dataFetcher={dataFetcher} />

        <Button color="greenSmoke" variant="inverted" onClick={onClickReport}>
          View Report
        </Button>

        <Button color="greenSmoke" onClick={onExportPSA}>
          Export PSA
        </Button>

        <Button color="greenSmoke" onClick={onImportPsa}>
          Import PSA
        </Button>

        <Button onClick={applyToBase}>Apply to Bases</Button>

        {!isEmpty(storePropagationResults) && (
          <Button onClick={() => navigateToStoreProps()}>Next</Button>
        )}

        <input
          style={{ display: "none" }}
          type="file"
          ref={importPsaInputRef}
          onChange={onImportPsaUploaded}
        />
      </Flex>
    </>
  );
};
