import { useAtom, useAtomValue } from "jotai";
import _groupBy from "lodash/groupBy";
import { useCallback } from "react";
import { s2FiltersAtom } from "src/modals/AppliedFlowFilterOptions/atoms";
import {
  s2FilterResultAtom,
  selectedTemplateIdAtom,
  step2DataAtom,
} from "src/pages/merchandise/store/atoms";
import { isEmpty } from "src/utils/isEmpty";

export const usePlaceVariantFilters = () => {
  const step2Data = useAtomValue(step2DataAtom);
  const selectedTemplatedId = useAtomValue(selectedTemplateIdAtom);
  const [step2FilterResult, setStep2FilterResult] = useAtom(s2FilterResultAtom);
  const [s2Filters, setS2Filters] = useAtom(s2FiltersAtom);

  const selectedStep2 =
    isEmpty(step2FilterResult) && isEmpty(s2Filters)
      ? step2Data.filter((pog) => pog.templateId === selectedTemplatedId)
      : step2FilterResult;

  const s2TemplateOptions = Object.keys(
    _groupBy(step2Data, (i) => i.templateId),
  ).map((v) => ({ value: v, label: v }));

  const clearFilters = useCallback(() => {
    setStep2FilterResult([]);
    setS2Filters([]);
  }, []);

  return {
    selectedStep2,
    s2TemplateOptions,
    clearFilters,
    allStep2: step2Data,
  };
};
