import { ResponseGetMerchflows_MerchflowId_StoresAll } from "@CommonApi/merchflows/_merchflowId_/stores/all";
import { omniAtom } from "src/utils/atoms";

const prefix = "merchflows/:merchflowId/stores/all";

export const merchflows_merchflowId_StoresAllAtom = omniAtom({
  prefix,
  key: "data",
  value: undefined as
    | undefined
    | null
    | ResponseGetMerchflows_MerchflowId_StoresAll,
});

export const merchflows_merchflowId_StoresAllHistoryAtom = omniAtom({
  prefix,
  key: "history",
  value: {} as { [merchflowId: number]: string },
});

export const Stores_StoreAll_storePogIndexAtom = omniAtom({
  prefix,
  key: "storePogIndex",
  value: 0,
});
