import { AlgoS2Candidate } from "@CommonTypes/algo/s2";
import { Planogram } from "@CommonTypes/merchflow/pog/pog";
import countBy from "lodash/countBy";
import { buildPlanogramFromFixtures } from "src/omni-common/adapters/pog";
import {
  MerchFlowCoreRange,
  ResponseMerchFlowApplyFilters,
  ResponseMerchFlowDetails,
} from "src/omni-common/api/merchandise";
import { Fixture } from "src/omni-common/types/backend/fixture";
import { ProductMaster } from "src/omni-common/types/backend/productMaster";
import { getShelfYBottom, getShelfYTop } from "src/omni-common/utils/pog/shelf";
import { AlgoFreezer, AlgoFreezerSection } from "src/types/algo";

export const algoPreStep0ConvertPlanogramToFreezer = (
  planogram: Planogram,
  premerge: boolean,
) => {
  const freezer: AlgoFreezer = {
    freezer_id: "owa",
    width: planogram.width,
    height: planogram.height,
    notch_spacing_x: 1,
    notch_spacing_y: 1,
    notch_offset_x: 0,
    notch_offset_y: 0,
    finger_space: 0,
    sections: planogram.bays.map((bay) => ({
      section_id: bay.uniqueId,
      start_x: bay.xLeft,
      start_y: bay.yBottom,
      width: bay.xRight - bay.xLeft,
      height: bay.yTop - bay.yBottom,
      depth: bay.depth,
      num_shelves: bay.shelves.length,
      cumulative_shelf_heights: [
        ...bay.shelves.map((shelf) => getShelfYBottom(shelf)),
        bay.yTop,
      ],
      shelves: bay.shelves.map((shelf) => ({
        shelf_id: shelf.uniqueId,
        start_y: getShelfYBottom(shelf),
        end_y: getShelfYTop(shelf),
      })),
    })),
    merged: [],
  };

  if (!premerge) {
    for (const bay of planogram.bays) {
      for (const shelf of bay.shelves) {
        if (shelf.mergedRight) {
          for (const rightBay of planogram.bays) {
            for (const rightShelf of rightBay.shelves) {
              if (rightShelf.uniqueId === shelf.mergedRight.uniqueId) {
                freezer.merged.push({
                  left: {
                    section_id: bay.uniqueId,
                    shelf_id: shelf.uniqueId,
                  },
                  right: {
                    section_id: rightBay.uniqueId,
                    shelf_id: rightShelf.uniqueId,
                  },
                });
              }
            }
          }
        }
      }
    }
  }

  if (premerge) {
    let previousSection: AlgoFreezerSection | null = null;

    freezer.sections.forEach((section, sectionIndex) => {
      if (!previousSection) {
        previousSection = section;
      } else if (
        previousSection.width === section.width &&
        previousSection.height === section.height &&
        previousSection.start_y === section.start_y &&
        JSON.stringify(previousSection.cumulative_shelf_heights) ===
          JSON.stringify(section.cumulative_shelf_heights)
      ) {
        previousSection.width += section.width;
        // Brute assign null to section that should be removed later.
        freezer.sections[sectionIndex] = null as unknown as AlgoFreezerSection;
      } else {
        previousSection = section;
      }
    });

    freezer.sections = freezer.sections.filter((section) => section !== null);
  }

  return freezer;
};

export const algoPreStep0ConvertProductMasterToCandidate = (
  productMaster: ProductMaster,
  coreRange: MerchFlowCoreRange,
) => {
  const candidate: AlgoS2Candidate = {
    item_id: productMaster.product_code,
    variant: productMaster.variant || "Others",
    segment: productMaster.cdt1,
    block: productMaster.cdt2,
    block_sort_order: "",
    value: 1,
    width:
      (productMaster.merchandising_style === "CASE" &&
        Number(productMaster.case_width)) ||
      (productMaster.merchandising_style === "TRAY" &&
        Number(productMaster.tray_width)) ||
      Number(productMaster.unit_width),
    height:
      (productMaster.merchandising_style === "CASE" &&
        Number(productMaster.case_height)) ||
      (productMaster.merchandising_style === "TRAY" &&
        Number(productMaster.tray_height)) ||
      Number(productMaster.unit_height),
    depth:
      (productMaster.merchandising_style === "CASE" &&
        Number(productMaster.case_depth)) ||
      (productMaster.merchandising_style === "TRAY" &&
        Number(productMaster.tray_depth)) ||
      Number(productMaster.unit_depth),
    quantity: Number(productMaster.quantity),
    min_days_of_supply: Number(productMaster.min_days_of_supply) || 1,
    min_on_shelf: productMaster.min_on_shelf || 1,
    min_facings: productMaster.min_facings || 1,
    max_facings: productMaster.max_facings || productMaster.min_facings || 100,
    notch_offset_x: 0,
    notch_offset_y: 0,
    // @ts-ignore
    valid_orientation: productMaster.valid_orientations.map((orientation) =>
      orientation.trim().toLowerCase(),
    ),
    variant_priority: 0,
    segment_priority: Number(productMaster.cdt1_priority),
    block_priority: Number(productMaster.cdt2_priority),
    is_in_core_range:
      (coreRange === MerchFlowCoreRange.ALL && true) ||
      (coreRange === MerchFlowCoreRange.NONE && false) ||
      (coreRange === MerchFlowCoreRange.PARTIAL &&
        productMaster.others.core_range === "True"),
    merchandising_style: productMaster.merchandising_style,
    no_of_units_in_case: productMaster.no_of_units_in_case,
    no_of_units_in_tray: productMaster.no_of_units_in_tray,
    tray_depth: Number(productMaster.tray_depth),
    tray_width: Number(productMaster.tray_width),
    tray_height: Number(productMaster.tray_height),
    case_depth: Number(productMaster.case_depth),
    case_width: Number(productMaster.case_width),
    case_height: Number(productMaster.case_height),
    unit_depth: Number(productMaster.unit_depth),
    unit_width: Number(productMaster.unit_width),
    unit_height: Number(productMaster.unit_height),
    order_num: Number(productMaster.others.order_num || 0),
    units_case_wide: Number(productMaster.units_case_wide),
    units_case_high: Number(productMaster.units_case_high),
    units_case_deep: Number(productMaster.units_case_deep),
    units_tray_wide: Number(productMaster.units_tray_wide),
    units_tray_high: Number(productMaster.units_tray_high),
    units_tray_deep: Number(productMaster.units_tray_deep),
  };

  return candidate;
};

export const calculatePrestep0Data = ({
  appliedFilters,
  ffDataRequests,
  pmDataRequests,
  coreRange,
  flow,
}: {
  appliedFilters: ResponseMerchFlowApplyFilters;
  ffDataRequests: Fixture[][];
  pmDataRequests: ProductMaster[][];
  coreRange: MerchFlowCoreRange;
  flow: ResponseMerchFlowDetails;
}) => {
  return appliedFilters.filter_config.stores.map((store, storeIndex) => {
    const pogFixture = buildPlanogramFromFixtures(ffDataRequests[storeIndex]);
    const storeId = ffDataRequests[storeIndex][0].store_id;
    const storeCode = ffDataRequests[storeIndex][0].store_code;
    const freezer = algoPreStep0ConvertPlanogramToFreezer(pogFixture, true);
    const candidates = pmDataRequests[storeIndex].map((pm) =>
      algoPreStep0ConvertProductMasterToCandidate(pm, coreRange),
    );
    const totalFixtureWidth = freezer.sections.reduce(
      (total, section) => section.width + total,
      0,
    );
    const totalFixtureHeight = freezer.sections[0].height;

    const maxItemWidthByVariant = candidates.reduce((acc, item) => {
      if (flow.config.option === "none") {
        return {
          ...acc,
          [item.variant]: 0,
        };
      }
      if (flow.config.option === "partial" && item.is_in_core_range) {
        return {
          ...acc,
          // @ts-ignore
          [item.variant]: Math.max(item.width, acc[item.variant] ?? 0),
        };
      }

      return {
        ...acc,
        // @ts-ignore
        [item.variant]: Math.max(item.width, acc[item.variant] ?? 0),
      };
    }, {});

    const productCountByVariant = countBy(
      pmDataRequests[storeIndex],
      (i) => i.variant,
    );

    return {
      planogram: pogFixture,
      freezer,
      candidates,
      templateId: store.template_id,
      pmData: pmDataRequests[storeIndex],
      totalFixtureWidth,
      totalFixtureHeight,
      maxItemWidthByVariant,
      productCountByVariant,
      cumulative_shelf_heights: freezer.sections[0].cumulative_shelf_heights,
      ffData: ffDataRequests[storeIndex],
      storeId,
      storeCode,
    };
  });
};
