import { useAtom, useSetAtom } from "jotai";
import { useEffect, useMemo, useState } from "react";
import { useModals } from "src/components/Modals";
import { Button, Color, Flex, Grid, Modal, Search, Text } from "src/elements";

import { newMergeRuleDataAtom, newMergeRuleToAddAtom } from "./store/atoms";
import { Legend } from "src/components";
import { LegendEntry } from "src/components/Legend/Legend";

const legends: LegendEntry[] = [
  {
    label: "New selected",
    labelColor: Color.primary,
    background: Color.primary,
  },
  {
    label: "Assigned",
    labelColor: Color.primary,
    borderColor: Color.primary,
  },
  {
    label: "Previously assigned selected",
    labelColor: Color.yellow,
    background: Color.yellow,
  },
  {
    label: "Assigned multiple times",
    labelColor: Color.yellow,
    borderColor: Color.yellow,
  },
  {
    label: "Unassigned",
    labelColor: Color.red,
    borderColor: Color.red,
  },
];

interface Props {
  availableVariants: string[];
  mergeRules: string[][];
}

const ModalNewMergeRuleInner = ({ availableVariants, mergeRules }: Props) => {
  const { closeModal: _closeModal, openConfirmModal: _openConfirmModal } =
    useModals();

  const setNewMergeRuleToAdd = useSetAtom(newMergeRuleToAddAtom);

  const [search, setSearch] = useState("");
  const [mergeRule, setMergeRule] = useState<string[]>([]);

  const isUnsafeToExit = mergeRule.length > 0;

  const filteredVariants = useMemo(() => {
    return availableVariants.filter((variant) =>
      variant.toLowerCase().includes(search.toLowerCase()),
    );
  }, [availableVariants, search]);

  const newMergeRuleId = useMemo(() => {
    return mergeRules.length + 1;
  }, [mergeRules]);

  const closeModal = () => {
    _closeModal("ModalNewMergeRule");
  };

  const openConfirmModal = () => {
    _openConfirmModal({
      title: "Discard Created Merge Rule?",
      description: "Are you sure you want to discard created merge rule?",
      buttons: [
        { label: "Keep Editing", variant: "inverted", color: "primary" },
        { label: "Discard Merge Rule", color: "red", onClick: closeModal },
      ],
    });
  };

  const addNewMergeRule = () => {
    setNewMergeRuleToAdd(mergeRule);
    closeModal();
  };

  const toggleMergeRuleVariant = (clickedVariant: string) => {
    setMergeRule(
      mergeRule.includes(clickedVariant)
        ? mergeRule.filter((variant) => variant !== clickedVariant)
        : [...mergeRule, clickedVariant],
    );
  };

  return (
    <Modal
      title="Add New Merge Rule"
      name="ModalNewMergeRule"
      confirmModal={isUnsafeToExit && openConfirmModal}
      width="750px"
    >
      <Flex minHeight="0" flexGrow={1} column padding="20px" gap="10px">
        <Flex justify="between" align="center">
          <Flex gap="10px">
            <Text variant="body1" color={Color.primaryActive}>
              Rule ID {newMergeRuleId}:
            </Text>

            <Text variant="body1" color={Color.textMain}>
              Please Select Container Variants...
            </Text>
          </Flex>

          <Search
            search={search}
            setSearch={setSearch}
            placeholder="Variants"
            width="200px"
          />
        </Flex>

        <Flex
          minHeight="30vh"
          flexGrow={1}
          background={Color.lightGray}
          borderColor={Color.lightGrayHover}
          borderRadius="3px"
        >
          <Grid
            width="100%"
            height="min-content"
            padding="10px"
            gap="10px"
            columns="repeat(5, 1fr)"
          >
            {filteredVariants.map((variant, i) => {
              const usedMergeRules = mergeRules.filter((mergeRule) =>
                mergeRule.includes(variant),
              );
              const isNotUsed = usedMergeRules.length === 0;
              const isUsedMultiple = usedMergeRules.length > 1;
              const isUsed = usedMergeRules.length > 0;
              const isSelected = mergeRule.includes(variant);

              return (
                <Button
                  key={i}
                  size="small"
                  iconRight={{ name: "plus" }}
                  color={
                    (isNotUsed && isSelected && "primary") ||
                    (isUsedMultiple && "yellow") ||
                    (isUsed && isSelected && "yellow") ||
                    (isUsed && "primary") ||
                    "red"
                  }
                  variant={(isSelected && "default") || "inverted"}
                  onClick={() => toggleMergeRuleVariant(variant)}
                >
                  {variant}
                </Button>
              );
            })}
          </Grid>
        </Flex>

        <Legend legends={legends} />

        <Flex margin="10px 0 0 0" justify="between">
          {isUnsafeToExit ? (
            <Button variant="inverted" color="red" onClick={openConfirmModal}>
              Cancel
            </Button>
          ) : (
            <Button variant="inverted" onClick={closeModal}>
              Back
            </Button>
          )}

          <Button isDisabled={mergeRule.length === 0} onClick={addNewMergeRule}>
            Add New Merge Rule
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};

export const ModalNewMergeRule = () => {
  const [data, setData] = useAtom(newMergeRuleDataAtom);

  useEffect(() => {
    return () => {
      setData(null);
    };
  }, []);

  if (!data) return null;
  return (
    <ModalNewMergeRuleInner
      availableVariants={data.availableVariants}
      mergeRules={data.mergeRules}
    />
  );
};
