import { useAtom } from "jotai";
import React, { useEffect, useRef } from "react";
import { useHighlightsApi } from "src/api/merchflows/highlights";
import {
  baseHighlightsAtom,
  highlightsModeAtom,
} from "src/components/Planogram/store/atoms";
import {
  Button,
  Color,
  Flex,
  HorizontalDivider,
  Scroller,
  Text,
  Toggle,
} from "src/elements";
import styled from "styled-components";

import { Highlight } from "./components/Highlight";
import { useHighlightModal } from "src/modals/Highlight/store/hooks";
import { EditableHighlight } from "src/modals/Highlight/store/types";
import { savedHighlightAtom } from "src/modals/Highlight/store/atoms";
import { planogramImagesModeAtom } from "src/components/PlanogramExtensions/store/atoms";

const HighlightsBlock = styled(Flex)`
  flex-direction: column;
  gap: 5px;
  min-height: 0;
`;

const HighlightsContent = styled(Flex)`
  flex-direction: column;
  background-color: ${Color.lightGray};
  border: 1px solid ${Color.lightGrayHover};
  border-radius: 3px;
  min-height: 0;
`;

const NoHighlights = ({ label }: { label: string }) => (
  <Flex width="100%" padding="20px" justify="center" pointerEvents="none">
    <Text variant="body2" color={Color.spaceGray}>
      No {label} Highlights
    </Text>
  </Flex>
);

export const Highlights = ({ merchflowId }: { merchflowId?: number }) => {
  const { openHighlightModal } = useHighlightModal();
  const { putBaseHighlightsApi } = useHighlightsApi();

  const [highlightsMode, setHighlightsMode] = useAtom(highlightsModeAtom);
  const [imagesMode, setImagesMode] = useAtom(planogramImagesModeAtom);
  const [baseHighlights, setBaseHighlights] = useAtom(baseHighlightsAtom);
  const [savedHighlight, setSavedHighlight] = useAtom(savedHighlightAtom);

  const savable = useRef(false);

  useEffect(() => {
    if (baseHighlights && savable.current && merchflowId) {
      putBaseHighlightsApi(merchflowId, baseHighlights);
    }

    savable.current = true;
  }, [baseHighlights]);

  useEffect(() => {
    if (!savedHighlight) return;

    if (baseHighlights && savedHighlight.level === "BASE") {
      // Overwrite previous base highlight.
      if (
        baseHighlights.filter(
          (baseHighlight) => baseHighlight.id === savedHighlight.highlight?.id,
        ).length > 0
      ) {
        setBaseHighlights((highlights) =>
          (highlights || []).map((highlight) =>
            highlight.id === savedHighlight.highlight?.id
              ? savedHighlight.highlight
              : highlight,
          ),
        );

        // New base highlight.
      } else {
        setBaseHighlights((highlights) => [
          ...(highlights || []),
          savedHighlight.highlight!,
        ]);
      }
    }

    setSavedHighlight(null);
  }, [savedHighlight]);

  const newHighlight = (level: EditableHighlight["level"]) => {
    openHighlightModal({ highlight: null, level });
  };

  return (
    <>
      <Flex column width="100%" gap="10px" minHeight="0" padding="10px">
        <Flex column gap="10px">
          <Flex width="100%" justify="between">
            <Text>Highlights Enabled</Text>

            <Toggle
              isChecked={highlightsMode}
              setIsChecked={setHighlightsMode}
            />
          </Flex>

          <Flex width="100%" justify="between">
            <Text>Product Images Enabled</Text>

            <Toggle isChecked={imagesMode} setIsChecked={setImagesMode} />
          </Flex>
        </Flex>

        {baseHighlights && (
          <>
            <Flex>
              <HorizontalDivider />
            </Flex>

            <HighlightsBlock>
              <Flex justify="space-between" align="center">
                <Text variant="h5" color={Color.textSecondary}>
                  Base Highlights
                </Text>

                <Button
                  size="small"
                  variant="inverted"
                  onClick={() => newHighlight("BASE")}
                >
                  New Highlight
                </Button>
              </Flex>

              <Scroller gutter="auto">
                <HighlightsContent>
                  {baseHighlights.length > 0 &&
                    baseHighlights.map((highlight, i) => (
                      <React.Fragment key={i}>
                        {i !== 0 && (
                          <HorizontalDivider color={Color.lightGrayHover} />
                        )}

                        <Highlight
                          highlight={highlight}
                          level="BASE"
                          highlightsAtom={baseHighlightsAtom}
                        />
                      </React.Fragment>
                    ))}

                  {baseHighlights.length === 0 && <NoHighlights label="Base" />}
                </HighlightsContent>
              </Scroller>
            </HighlightsBlock>
          </>
        )}
      </Flex>
    </>
  );
};
