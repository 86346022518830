import { StorePogAllResponseRow } from "@CommonApi/store-pog/all";
import { PoggerizeResponse } from "@CommonTypes/algo/poggerize";
import { AlgoS1FreezerOutput } from "@CommonTypes/algo/s1";
import { Pog } from "@CommonTypes/merchflow/pog/pog";
import { PrimitiveAtom } from "jotai";
import { Container } from "src/components/Planogram/store/types";
import {
  AllBasePogResponse,
  ResponseMerchFlowDetails,
} from "src/omni-common/api/merchandise";
import { FreezerS0Group } from "src/omni-common/types/algo/freezer";
import { PoggerResponse } from "src/omni-common/types/algo/pogger";
import { Fixture } from "src/omni-common/types/backend/fixture";
import { ProductMaster } from "src/omni-common/types/backend/productMaster";
import { Step1Step2Solution } from "src/omni-common/types/step2fixtures";
import { omniAtom } from "src/utils/atoms";

import {
  PayloadAlgoStep0,
  PreStep0Data,
  WithDebugInfo,
} from "../steps/Step2Fixtures/store/types";
import {
  MerchandiseStep,
  OldPagination,
  SavedS0Atom,
  StoreLevelPogPagination,
} from "./types";
import { PayloadS1PlaceContainersAllTemplates } from "@CommonApi/frogger/place_containers_all_templates";
import { ReportData } from "src/hooks/usePowerBiReport";
import { env } from "src/utils/env";

const prefix = "merchandise";

export const stepAtom = omniAtom({
  prefix,
  key: "step",
  value: MerchandiseStep.LIST_FLOW_REVIEW,
});

export const ffDataRequestsAtom = omniAtom({
  prefix,
  key: "requests/ff",
  value: [] as Fixture[][],
});

export const posDataRequestsAtom = omniAtom({
  prefix,
  key: "requests/pos",
  value: [] as any[],
});

export const pmDataRequestsAtom = omniAtom({
  prefix,
  key: "requests/pm",
  value: [] as ProductMaster[][],
});

export const isViewDetailAtom = omniAtom({
  prefix,
  key: "isViewDetail",
  value: false,
});

export const pageAtom = omniAtom({
  prefix,
  key: "page",
  value: 1,
});

export const viewDetailIndex = omniAtom({
  prefix,
  key: "viewDetailIndex",
  value: 0,
});

export const selectedPreStep0DataAtom = omniAtom({
  prefix,
  key: "selectedPreStep0DataAtom",
  value: [] as PreStep0Data[],
});

export const allPreStep0DataAtom = omniAtom({
  prefix,
  key: "allPreStep0DataAtom",
  value: [] as PreStep0Data[],
});

export const step0GroupsAtoms = omniAtom({
  prefix,
  key: "groups",
  value: [] as FreezerS0Group[][],
});

export const step0RequestAtoms = omniAtom({
  prefix,
  key: "s0request",
  value: [] as {
    payload: PayloadAlgoStep0;
    froggerVersion: string;
  }[],
});

export const savedS0Atom = omniAtom({
  prefix,
  key: "savedS0Atom",
  value: null as null | SavedS0Atom,
});

export const step1RequestAtom = omniAtom({
  prefix,
  key: "step1Request",
  value: [] as PayloadS1PlaceContainersAllTemplates,
});

export const step2RequestAtom = omniAtom({
  prefix,
  key: "step2Request",
  value: [] as any[],
});

export const step1OriginalResponseAtom = omniAtom({
  prefix,
  key: "step1OriginalResponse",
  value: [] as any[],
});

export const step1ResponsesAtom = omniAtom({
  prefix,
  key: "step1ResponsesAtom",
  value: [] as Array<
    { planogram: { freezer: AlgoS1FreezerOutput } } & { templateId: string }
  >,
});

export const step2DataAtom = omniAtom({
  prefix,
  key: "step2DataAtom",
  value: [] as Array<WithDebugInfo<Step1Step2Solution>>,
});

export const s2FilterResultAtom = omniAtom({
  prefix,
  key: "s2FilterResult",
  value: [] as Array<WithDebugInfo<Step1Step2Solution>>,
});

export const selectedTemplateIdAtom = omniAtom({
  prefix,
  key: "selectedTemplateIdAtom",
  value: "",
});
export const poggerResultAtom = omniAtom({
  prefix,
  key: "poggerResultAtom",
  value: null as PoggerResponse | null,
});

export const poggerizeRequestAtom = omniAtom({
  prefix,
  key: "poggerizeRequest",
  value: null as any,
});

export const poggerizeResultAtom = omniAtom({
  prefix,
  key: "poggerizeResultAtom",
  value: null as PoggerizeResponse | null,
});

export let colorObj = {};

export const resetColor = () => {
  colorObj = {};
};

export const froggerVersionAtom = omniAtom({
  prefix,
  key: "froggerVersion",
  value: env.VITE_FROGGER_VERSION || "dev",
});

export const storeLevelPogStatusRowsAtom = omniAtom({
  prefix,
  key: "storeLevelPogStatusRows",
  value: [] as StorePogAllResponseRow[],
});

export const storeLevelPogPaginationAtom = omniAtom({
  prefix,
  key: "storeLevelPogPaginationAtom",
  value: {} as StoreLevelPogPagination,
});

export const flowReviewsAtom = omniAtom({
  prefix,
  key: "flowReviews",
  value: [] as ResponseMerchFlowDetails[],
});

export const flowReviewsPaginationAtom = omniAtom({
  prefix,
  key: "flowReviews",
  value: {
    page: 1,
    size: 20,
    totalPages: 1,
    totalRows: 1,
  } as OldPagination,
});

export const powerBiReportsAtom = omniAtom({
  prefix,
  key: "powerBiReports",
  value: {} as ReportData,
});

export const savedPogAtomAtom = omniAtom({
  prefix,
  key: "applyToBase/savedPogAtom",
  value: null as PrimitiveAtom<Pog> | null,
});
export const emptyContainersAtom = omniAtom({
  key: "step4Pog/containerAtom",
  value: [] as Container[],
});

export const filterConfigBasePogsAtom = omniAtom({
  key: "filterConfigBasePogs",
  value: [] as AllBasePogResponse[],
});
