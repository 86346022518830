import { PlanogramContainer } from "@CommonTypes/merchflow/pog/container";
import { PlanogramNonCircular } from "@CommonTypes/merchflow/pog/pog";

export const RouteGetMerchflows_merchflowId_S1_s1UserTriggerId_ =
  "/merchflows/:merchflowId/s1/:s1UserTriggerId";

export type ResponseGetMercflows_merchflowId_S1_s1UserTriggerId = ({
  template_id: string;
} & (
  | {
      status: "NOT_STARTED" | "IN_PROGRESS" | "ERROR";
    }
  | {
      s1_solution_id: number;
      status: "DONE";
      solution: {
        planogram: PlanogramNonCircular;
        containers: PlanogramContainer[];
      };
    }
))[];
