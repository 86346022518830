import { StorePogAllResponseRow } from "@CommonApi/store-pog/all";
import { Pog } from "@CommonTypes/merchflow/pog/pog";
import { useAtom, useSetAtom } from "jotai";
import { useLoaders } from "src/components/Loaders";
import { useModals } from "src/components/Modals";
import { useToasts } from "src/components/Toasts";
import { dynamicLogAtom } from "src/modals/DynamicLog/store/atom";
import {
  editStorePogAtom,
  refetchIdAtom,
} from "src/modals/EditStorePogModal/atoms";
import { useNewFlowReview } from "src/modals/NewFlowReview/store/api";
import { adapterPlanogramToPlanogramNonCircular } from "src/omni-common/adapters/pog";
import {
  getOneStorePogData,
  getStorePogReport,
  putEditStorePog,
} from "src/pages/merchandise/store/requests";

export const useStorePogApi = () => {
  const { details, filtersApplied } = useNewFlowReview();
  const { addLoader, removeLoader } = useLoaders();
  const { openModal } = useModals();
  const { toast } = useToasts();
  const setDynamicLog = useSetAtom(dynamicLogAtom);
  const [rowData, setRowData] = useAtom(editStorePogAtom);
  const setReFetchId = useSetAtom(refetchIdAtom);

  const loadRowData = async (id: number) => {
    addLoader("storeLevel/loadRowData");
    let result = null;
    try {
      result = await getOneStorePogData(id);
    } catch (error) {
      toast({ title: "Failed to load store row data", error });
    }
    removeLoader("storeLevel/loadRowData");

    return result;
  };

  const openLogs = async (id: number) => {
    const logs = await loadRowData(id);

    setDynamicLog(logs?.rows[0]);
    openModal("DynamicLog");
  };

  const openEdit = async (id: number) => {
    const result = await loadRowData(id);
    setRowData(result?.rows[0] as any);
    openModal("EditStorePogModal");
  };

  const exportFile = async (
    type: "psa" | "pln" | "pdf",
    store: StorePogAllResponseRow,
  ) => {
    if (!filtersApplied) return null;
    addLoader("storeLevel/export");

    try {
      const data = await getStorePogReport(type, {
        category_code: details!.category_code,
        filter_config_id: filtersApplied.id,
        store_code: store.store_code,
        template_id: store.template_id,
      }).then((d) => d.data);

      setDynamicLog(data);

      openModal("DynamicLog");
    } catch (error) {
      toast({ title: "Failed to load store pog report", error });
    }

    removeLoader("storeLevel/export");
  };

  const saveEditedPog = async (editedPog: Pog) => {
    if (!rowData) return;
    addLoader("storeLevel/saveEditedPog");
    try {
      const {
        filter_config_id,
        store_id,
        template_id,
        pog_raw_data: { poggerize_response: poggerize },
      } = rowData;

      await putEditStorePog({
        filter_config_id,
        pog_modified: {
          planogram: adapterPlanogramToPlanogramNonCircular(
            editedPog.planogram,
          ),
          unrangedItems: editedPog.unrangedItems,
        },
        poggerize,
        store_id,
        template_id,
      });
    } catch (error) {
      toast({ title: "Failed to save store pog", error });
    }
    setReFetchId(rowData.id);
    removeLoader("storeLevel/saveEditedPog");
  };

  return {
    openLogs,
    exportFile,
    openEdit,
    saveEditedPog,
  };
};
