import {
  Pagination,
  RequiredPaginationQueryParams,
} from "@CommonTypes/backend/pagination";
import { MasterFixture } from "@CommonTypes/owa-db/merchantSchema";

export const RouteGetInputsMasterFixturesAll = "/inputs/master-fixtures/all";

export type ParamsGetInputsMasterFixturesAll = RequiredPaginationQueryParams;

export type ResponseGetInputsMasterFixturesAll = Pagination<MasterFixture>;
