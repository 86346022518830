import { PlanogramContainer } from "@CommonTypes/merchflow/pog/container";
import { Pog } from "@CommonTypes/merchflow/pog/pog";
import { PrimitiveAtom } from "jotai";
import { Planogram } from "src/components/Planogram/Planogram";
import { Color, Flex, Link, Text } from "src/elements";
import { pages } from "src/utils";
import { routeToUrl } from "src/utils/axios";

interface Props {
  merchflowId: number;
  name: string;
  noSolutions: number;
  pogAtom: PrimitiveAtom<Pog>;
  containersAtom: PrimitiveAtom<PlanogramContainer[]>;
}

export const BaseSizeBlock = ({
  merchflowId,
  name,
  noSolutions,
  pogAtom,
  containersAtom,
}: Props) => {
  return (
    <Flex column align="center" position="relative">
      <Text color={Color.primary} variant="body1">
        {name}
      </Text>

      <Flex column align="center" gap="8px" margin="8px 0 0 0">
        <Flex width="300px" height="120px" padding="1px">
          <Planogram containersAtom={containersAtom} pogAtom={pogAtom} />
        </Flex>

        <Text color={Color.primary} variant="body1">
          View Flows ({noSolutions})
        </Text>
      </Flex>

      <Link
        to={routeToUrl(pages.merchflows_merchflowId_S2Templates_templateId_, {
          merchflowId,
          templateId: name,
        })}
        isAbsolute
        inNewTab
      />
    </Flex>
  );
};
