import { useEffect } from "react";

import { pages } from "src/utils";
import { useNavigation } from "src/utils/navigation";

export const PageHome = () => {
  const { navigate } = useNavigation();

  useEffect(() => {
    navigate(pages.merchandise);
  }, []);

  return null;
};
