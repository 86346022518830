import { Page, PageStatus, Planogram } from "src/components";
import { MerchflowHeader } from "../../components/merchflows/MerchflowHeader/MerchflowHeader";
import { useParams } from "react-router-dom";
import { MerchflowStepsNavigator } from "src/components/merchflows/MerchflowStepsNavigator/MerchflowStepsNavigator";
import { Button, Color, Flex } from "src/elements";
import { PlanogramFixturesMode } from "src/components/PlanogramExtensions/PlanogramFixturesMode";
import { useEffect } from "react";
import { useAtom } from "jotai";
import { useApi } from "src/api";
import { useAsyncState } from "src/hooks/useAsyncState";
import {
  adapterPlanogramToPlanogramNonCircular,
  adapterPogNonCircularToPog,
} from "@CommonAdapters/pog";
import { sleep } from "src/utils/sleep";
import { pagesS0PogAtom, pagesS0ContainersAtom } from "./store/atoms";
import { useNavigation } from "src/utils/navigation";
import { pages } from "src/utils";
import { useInlineLoaders } from "src/components/InlineLoader";
import { RoutePostMerchflows_merchflowId_S1 } from "@CommonApi/merchflows/_merchflowId_/s1";
import { ResponseGetMerchflows_merchflowId_S0_s0UserTriggerId_ } from "@CommonApi/merchflows/_merchflowId_/s0/_s0UserTriggerId_";
import { useMerchflowHeader } from "src/components/merchflows/MerchflowHeader/store/hooks";

export const PageS0 = () => {
  const { merchflowId: _merchflowId, s0UserTriggerId: _s0UserTriggerId } =
    useParams();
  const merchflowId = Number(_merchflowId);
  const s0UserTriggerId = Number(_s0UserTriggerId);

  const { getMerchflowS0Api, postStartMerchflowS1Api } = useApi();
  const { navigate } = useNavigation();
  const { isInlineLoading } = useInlineLoaders();
  const { froggerVersion } = useMerchflowHeader();

  const [data, setData] =
    useAsyncState<ResponseGetMerchflows_merchflowId_S0_s0UserTriggerId_>();
  const [pog, setPog] = useAtom(pagesS0PogAtom);
  const [containers, setContainers] = useAtom(pagesS0ContainersAtom);
  const isGeneratingS1 = isInlineLoading(RoutePostMerchflows_merchflowId_S1);
  const isLoading =
    data === undefined ||
    data?.find(
      (s0Solution) =>
        s0Solution.status === "NOT_STARTED" ||
        s0Solution.status === "IN_PROGRESS",
    );

  useEffect(() => {
    loadS0();

    return () => {
      setPog(null);
      setContainers(null);
    };
  }, []);

  const loadS0 = async () => {
    const response = await getMerchflowS0Api(merchflowId, s0UserTriggerId);
    setData(response);

    if (response && response[0].status === "DONE") {
      setPog(
        adapterPogNonCircularToPog({
          planogram: response[0].solution.planogram,
          unrangedItems: [],
          deletedItems: [],
          version: "",
          metrics: {
            returnOnSpace: null,
            shelfAlignment: null,
            coreRange: null,
            dosMos: null,
          },
        }),
      );
      setContainers(response[0].solution.containers);
    }

    // Keep checking for s0 after 5s cycles.
    if (
      response?.find(
        (s0Solution) =>
          s0Solution.status === "NOT_STARTED" ||
          s0Solution.status === "IN_PROGRESS",
      )
    ) {
      await sleep(5000);
      loadS0();
    }
  };

  const startGeneratingS1 = async () => {
    if (!pog || !containers || !data || data[0].status !== "DONE") return;

    const response = await postStartMerchflowS1Api(merchflowId, {
      s0_user_trigger_id: s0UserTriggerId,
      s0_solution_id: data[0].s0_solution_id,
      planogram: adapterPlanogramToPlanogramNonCircular(pog.planogram),
      containers,
      frogger_version: froggerVersion,
    });

    if (response) {
      navigate(`${pages.merchflows_merchflowId_S1_s1UserTriggerId_}`, {
        merchflowId,
        s1UserTriggerId: response.s1_user_trigger_id,
      });
    }
  };

  return (
    <Page>
      <MerchflowHeader
        merchflowId={merchflowId}
        s0UserTriggerId={s0UserTriggerId}
      />

      <MerchflowStepsNavigator
        merchflowId={merchflowId}
        s0UserTriggerId={s0UserTriggerId}
        activeStep="S0"
      />

      <Flex justify="right">
        <PlanogramFixturesMode />
      </Flex>

      <Flex grow>
        {data === null && (
          <PageStatus
            label="CDT0 mudmap not found"
            icon="alert"
            color={Color.red}
          />
        )}

        {isLoading && (
          <PageStatus label="Loading CDT0 mudmap..." icon="spinner" />
        )}

        {!isLoading && data && data[0].status === "ERROR" && (
          <PageStatus
            label="CDT0 mudmap failed to generate"
            icon="alert"
            color={Color.red}
          />
        )}

        {!isLoading && data && data[0].status === "DONE" && (
          <Planogram
            pogAtom={pagesS0PogAtom}
            containersAtom={pagesS0ContainersAtom}
            isEditable
            isPanelVisible
            isWithBorder
          />
        )}
      </Flex>

      <Flex justify="right">
        <Button
          isEnabled={!isLoading && data !== null && data[0].status === "DONE"}
          onClick={startGeneratingS1}
          isLoading={isGeneratingS1}
        >
          Generate Base
        </Button>
      </Flex>
    </Page>
  );
};
