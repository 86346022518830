export const merchandiseSteps = (
  flowId: string | number,
  filter_config_id: string | number,
  sourceTemplate: string,
  selectTemplate: string,
  handle: {
    onReviewNameClick: () => void;
    onCreateBaseClick: () => void;
    onSelectBaseClick: () => void;
    onEditPlanogramClick: () => void;
    onStoresClick: () => void;
    onFilterConfigClick: () => void;
  },
) => [
  {
    id: 1,
    title: `Review ${flowId}`,
    onClick: handle.onReviewNameClick,
  },
  {
    id: 1,
    title: `Filter ${filter_config_id}`,
    onClick: handle.onFilterConfigClick,
  },
  {
    id: 2,
    title: `Create Base ${sourceTemplate ? `(${sourceTemplate})` : ""}`,
    onClick: handle.onCreateBaseClick,
  },
  {
    id: 3,
    title: `Select Base ${selectTemplate ? `(${selectTemplate})` : ""}`,
    onClick: handle.onSelectBaseClick,
  },
  { id: 4, title: "Edit Planogram", onClick: handle.onEditPlanogramClick },
  { id: 5, title: "Generate Store Level", onClick: handle.onStoresClick },
];
