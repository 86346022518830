import { useRef } from "react";
import { Color, Flex, Text, Icon } from "src/elements";
import { useHoverClickable } from "src/utils/hoverClickable";

interface Props {
  title: string;
  isExpanded: boolean;
  setIsExpanded: (isExpanded: boolean) => void;
}

export const SectionHeader = ({ title, isExpanded, setIsExpanded }: Props) => {
  const ref = useRef(null);
  const { isHovered, isActive } = useHoverClickable(ref);

  const backgroundColor =
    (isExpanded && Color.primary) ||
    (isActive && Color.greenSmokeHover) ||
    (isHovered && Color.greenSmoke) ||
    Color.transparent;

  const textColor = (isExpanded && Color.white) || Color.primary;

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Flex
      ref={ref}
      padding="8px 5px"
      justify="between"
      align="center"
      borderRadius="3px"
      background={backgroundColor}
      onClick={toggleExpanded}
    >
      <Text variant="small1" color={textColor}>
        {title}
      </Text>

      <Icon
        name={isExpanded ? "triangleUp" : "triangleDown"}
        color={textColor}
      />
    </Flex>
  );
};
