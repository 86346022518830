import { Page, PageStatus } from "src/components";
import { MerchflowHeader } from "../../components/merchflows/MerchflowHeader/MerchflowHeader";
import { useParams } from "react-router-dom";
import { MerchflowStepsNavigator } from "../../components/merchflows/MerchflowStepsNavigator/MerchflowStepsNavigator";

import { useAsyncState } from "src/hooks/useAsyncState";
import {
  ResponseGetMerchflows_merchflowId_S2_s1UserTriggerId_Templates_templateId_,
  RouteGetMerchflows_merchflowId_S2_s1UserTriggerId_Templates_templateId_,
} from "@CommonApi/merchflows/_merchflowId_/s2/_s1UserTriggerId_/s2/_templateId_";
import { useEffect } from "react";
import { useApi } from "src/api";
import { useInlineLoaders } from "src/components/InlineLoader";
import { sleep } from "src/utils/sleep";
import styled from "styled-components";
import {
  Color,
  Flex,
  Grid,
  HorizontalDivider,
  Pagination,
  VerticalDivider,
} from "src/elements";
import { OneQuadrantView } from "./components/OneQuadrantView";
import { usePaginationPage } from "src/elements/Pagination";
import { PlanogramHovererDetails } from "src/components/PlanogramExtensions/PlanogramHovererDetails/PlanogramHovererDetails";

const QuadGrid = styled(Grid)`
  width: 100%;
  height: 100%;
  grid-template-areas:
    "top-left ver-divider1 top-right"
    "hor-divider hor-divider hor-divider"
    "bottom-left ver-divider2 bottom-right";
  grid-template-columns: calc(50% - 1px) auto calc(50% - 1px);
  grid-template-rows: 1fr auto 1fr;
  border: 1px solid ${Color.greenSmoke};
  border-radius: 3px;
`;

export const PageMerchflows_merchflowId_S2_s1UserTriggerId_Templates_templateId_ =
  () => {
    const {
      merchflowId: _merchflowId,
      s1UserTriggerId: _s1UserTriggerId,
      templateId: _templateId,
    } = useParams();
    const merchflowId = Number(_merchflowId);
    const s1UserTriggerId = Number(_s1UserTriggerId);
    const templateId = String(_templateId);

    const { getS2SolutionsApi } = useApi();
    const { isInlineLoading } = useInlineLoaders();

    const [data, setData] =
      useAsyncState<ResponseGetMerchflows_merchflowId_S2_s1UserTriggerId_Templates_templateId_>();
    const [page, setPage] = usePaginationPage();
    const isLoading =
      data === undefined ||
      isInlineLoading(
        RouteGetMerchflows_merchflowId_S2_s1UserTriggerId_Templates_templateId_,
      ) ||
      data?.find(
        (s2Solution) =>
          s2Solution.status === "NOT_STARTED" ||
          s2Solution.status === "IN_PROGRESS",
      );

    useEffect(() => {
      loadS2Solutions();
    }, []);

    const loadS2Solutions = async () => {
      const response = await getS2SolutionsApi({
        merchflowId,
        s1UserTriggerId,
        templateId,
      });

      setData(response);

      if (
        response?.find(
          (s2Solution) =>
            s2Solution.status === "NOT_STARTED" ||
            s2Solution.status === "IN_PROGRESS",
        )
      ) {
        await sleep(10_000);
        loadS2Solutions();
      }
    };

    const getPaginatedIndex = (index: number) => index + (page - 1) * 4;

    return (
      <Page>
        <MerchflowHeader
          merchflowId={merchflowId}
          s1UserTriggerId={s1UserTriggerId}
          templateId={templateId}
        />

        <MerchflowStepsNavigator
          activeStep="S2"
          merchflowId={merchflowId}
          s1UserTriggerId={s1UserTriggerId}
        />

        {isLoading && (
          <PageStatus label="Loading mudmap solutions..." icon="spinner" />
        )}

        {data && !isLoading && (
          <Flex column grow gap="10px">
            <QuadGrid>
              <OneQuadrantView
                merchflowId={merchflowId}
                s2Solution={data[getPaginatedIndex(0)]}
              />

              <VerticalDivider size={2} color={Color.greenSmoke} />

              <OneQuadrantView
                merchflowId={merchflowId}
                s2Solution={data[getPaginatedIndex(1)]}
              />

              <Flex gridArea="hor-divider">
                <HorizontalDivider size={2} color={Color.greenSmoke} />
              </Flex>

              <OneQuadrantView
                merchflowId={merchflowId}
                s2Solution={data[getPaginatedIndex(2)]}
              />

              <VerticalDivider size={2} color={Color.greenSmoke} />

              <OneQuadrantView
                merchflowId={merchflowId}
                s2Solution={data[getPaginatedIndex(3)]}
              />
            </QuadGrid>

            <PlanogramHovererDetails />

            <Pagination
              page={page}
              totalPages={data ? Math.ceil(data.length / 4) : 1}
              setPage={setPage}
              isUsingInput
              isLoading={isLoading}
            />
          </Flex>
        )}
      </Page>
    );
  };
