import { ResponseImportPsaBeginUpload } from "../../omni-common/api/import-psa/begin-upload";
import {
  PayloadImportPsaFinishUpload,
  ResponseImportPsaFinishUpload,
} from "../../omni-common/api/import-psa/finish-upload";
import { getRequest, postRequest, API } from "../../utils/axios";

export const getImportPsaBeginUploadReq = () =>
  getRequest<ResponseImportPsaBeginUpload>(`${API}/import-psa/begin-upload`);

export const postImportPsaFinishUploadReq = (
  payload: PayloadImportPsaFinishUpload,
) => {
  return postRequest<ResponseImportPsaFinishUpload>(
    `${API}/import-psa/finish-upload`,
    { payload },
  );
};
