import { useAtomValue } from "jotai";
import { createElement } from "react";
import * as AllModals from "src/modals";

import { modalsAtom } from "./store/atoms";

export const Modals = () => {
  const modals = useAtomValue(modalsAtom);

  return (
    <>{modals.map((modal, i) => createElement(AllModals[modal], { key: i }))}</>
  );
};
