import { PlanogramItem, Pog } from "@CommonTypes/merchflow/pog/pog";
import React, { useMemo } from "react";
import {
  NumberInput,
  Flex,
  ColorInput,
  Text,
  Input,
  Select,
} from "src/elements";
import { getNewProductPossibleData } from "../store/utils";

const CustomStringInput = ({
  id,
  title,
  product,
  setProduct,
  isRequired,
  isDisabled,
}: {
  id: string;
  title: string;
  product: PlanogramItem;
  setProduct: (product: PlanogramItem) => void;
  isRequired?: boolean;
  isDisabled?: boolean;
}) => (
  <Input
    title={title}
    placeholder={title}
    //@ts-ignore
    value={product[id]}
    setValue={(value) => {
      setProduct({ ...product, [id]: value });
    }}
    isRequired={isRequired}
    isDisabled={isDisabled}
  />
);

const CustomNumberInput = ({
  id,
  title,
  product,
  setProduct,
  isRequired,
  isDisabled,
  min,
  max,
  error,
}: {
  id: string;
  title: string;
  product: PlanogramItem;
  setProduct: (product: PlanogramItem) => void;
  isRequired?: boolean;
  isDisabled?: boolean;
  min?: number;
  max?: number;
  error?: false | string;
}) => (
  <NumberInput
    title={title}
    placeholder={title}
    //@ts-ignore
    value={product[id]}
    setValue={(value) => {
      setProduct({ ...product, [id]: value });
    }}
    isRequired={isRequired}
    isDisabled={isDisabled}
    min={min}
    max={max}
    error={error}
  />
);

interface Props {
  planogramItem: PlanogramItem;
  setPlanogramItem: React.Dispatch<React.SetStateAction<PlanogramItem>>;
  pog: Pog;
}

export const NewProductForm = ({
  planogramItem,
  setPlanogramItem,
  pog,
}: Props) => {
  const data = useMemo(() => getNewProductPossibleData(pog), [pog]);

  return (
    <Flex direction="column" gap="20px" padding="0 3px 3px 0">
      {/* Identifiers. */}
      <>
        <Flex>
          <Text variant="subtitle1">Identifiers</Text>
        </Flex>

        <Flex gap="20px">
          <CustomStringInput
            id="id"
            title="ID"
            product={planogramItem}
            setProduct={setPlanogramItem}
          />

          <CustomStringInput
            id="name"
            title="Name"
            product={planogramItem}
            setProduct={setPlanogramItem}
            isRequired
          />
        </Flex>

        <Flex gap="20px">
          <CustomStringInput
            id="brand"
            title="Brand"
            product={planogramItem}
            setProduct={setPlanogramItem}
          />

          <Select
            title="Variant"
            placeholder="Variant"
            options={data.variants.map((variant) => ({
              value: variant,
              label: variant,
            }))}
            value={planogramItem.variant}
            setValue={(variant) => {
              setPlanogramItem((planogramItem) => {
                planogramItem.variant = variant;
                return { ...planogramItem };
              });
            }}
            isRequired
            isSearchable
          />
        </Flex>

        <Flex gap="20px">
          <CustomStringInput
            id="categoryCode"
            title="Category Code"
            product={planogramItem}
            setProduct={setPlanogramItem}
          />

          <Select
            title="Sub-Category"
            placeholder="Sub-Category"
            options={data.subcategories.map((subCategory) => ({
              value: subCategory,
              label: subCategory,
            }))}
            value={planogramItem.subCategory}
            setValue={(subCategory) => {
              setPlanogramItem((planogramItem) => {
                planogramItem.subCategory = subCategory;
                return { ...planogramItem };
              });
            }}
            isRequired
            isSearchable
          />
        </Flex>

        <Flex gap="20px">
          <Input
            title="Product Code"
            value={planogramItem.productCode}
            setValue={(productCode) => {
              setPlanogramItem((planogramItem) => {
                planogramItem.productCode = productCode;
                planogramItem.upc = productCode;
                return { ...planogramItem };
              });
            }}
            isRequired
          />

          <CustomStringInput
            id="upc"
            title="UPC"
            product={planogramItem}
            setProduct={setPlanogramItem}
            isRequired
            isDisabled
          />
        </Flex>

        <Flex gap="20px">
          <CustomNumberInput
            id="size"
            title="Size"
            product={planogramItem}
            setProduct={setPlanogramItem}
            isRequired
          />

          <Select
            title="Merchandising Style"
            placeholder="Merchandising Style"
            options={["UNIT", "TRAY", "CASE"].map((merchandisingStyle) => ({
              value: merchandisingStyle,
              label: merchandisingStyle,
            }))}
            value={planogramItem.merchandisingStyle}
            setValue={(merchandisingStyle) => {
              setPlanogramItem((planogramItem) => {
                planogramItem.merchandisingStyle =
                  merchandisingStyle as PlanogramItem["merchandisingStyle"];
                return { ...planogramItem };
              });
            }}
            isRequired
          />

          <ColorInput
            variant="input"
            color={planogramItem.color}
            setColor={(color) => {
              setPlanogramItem((planogramItem) => ({
                ...planogramItem,
                color,
              }));
            }}
            isRequired
          />
        </Flex>
      </>

      {/* Orientation. */}
      <>
        <Flex margin="20px 0 0 0">
          <Text variant="subtitle1">Orientation</Text>
        </Flex>

        <Flex gap="20px">
          <Select
            title="Main Orientation"
            placeholder="Main Orientation"
            options={["FRONT", "FRONT2", "SIDE", "SIDE2", "TOP", "TOP2"].map(
              (orientation) => ({
                label: orientation,
                value: orientation,
              }),
            )}
            value={planogramItem.validOrientations[0]}
            setValue={(orientation) => {
              setPlanogramItem((planogramItem) => {
                planogramItem.orientation =
                  orientation as PlanogramItem["orientation"];
                planogramItem.validOrientations[0] =
                  orientation as PlanogramItem["orientation"];
                return { ...planogramItem };
              });
            }}
            isRequired
          />

          <Select
            title="Optional Orientation"
            placeholder="Optional Orientation"
            options={["FRONT", "FRONT2", "SIDE", "SIDE2", "TOP", "TOP2"]
              .map((orientation) => ({
                label: orientation,
                value: orientation,
              }))
              .filter(
                (orientation) =>
                  orientation.value !== planogramItem.validOrientations[0],
              )}
            value={planogramItem.validOrientations[1] || null}
            setValue={(orientation) => {
              setPlanogramItem((planogramItem) => {
                planogramItem.validOrientations[1] =
                  orientation as PlanogramItem["orientation"];
                return { ...planogramItem };
              });
            }}
          />
        </Flex>
      </>

      {/* Unit dimensions. */}
      <>
        <Flex margin="20px 0 0 0">
          <Text variant="subtitle1">Unit Dimensions</Text>
        </Flex>

        <Flex gap="20px">
          <CustomNumberInput
            id="unitWidth"
            title="Unit Width (cm)"
            product={planogramItem}
            setProduct={setPlanogramItem}
            isRequired
          />

          <CustomNumberInput
            id="unitHeight"
            title="Unit Height (cm)"
            product={planogramItem}
            setProduct={setPlanogramItem}
            isRequired
          />

          <CustomNumberInput
            id="unitDepth"
            title="Unit Depth (cm)"
            product={planogramItem}
            setProduct={setPlanogramItem}
            isRequired
          />
        </Flex>
      </>

      {/* Tray dimensions. */}
      <>
        <Flex margin="20px 0 0 0">
          <Text variant="subtitle1">Tray Dimensions</Text>
        </Flex>

        <Flex gap="20px">
          <CustomNumberInput
            id="trayWidth"
            title="Tray Width (cm)"
            product={planogramItem}
            setProduct={setPlanogramItem}
            isRequired={planogramItem.merchandisingStyle === "TRAY"}
          />

          <CustomNumberInput
            id="trayHeight"
            title="Tray Height (cm)"
            product={planogramItem}
            setProduct={setPlanogramItem}
            isRequired={planogramItem.merchandisingStyle === "TRAY"}
          />

          <CustomNumberInput
            id="trayDepth"
            title="Tray Depth (cm)"
            product={planogramItem}
            setProduct={setPlanogramItem}
            isRequired={planogramItem.merchandisingStyle === "TRAY"}
          />
        </Flex>

        <Flex gap="20px">
          <NumberInput
            title="Units Tray Wide"
            value={planogramItem.unitsTrayWide}
            setValue={(unitsTrayWide) => {
              setPlanogramItem((planogramItem) => {
                planogramItem.unitsTrayWide = unitsTrayWide;
                planogramItem.noOfUnitsInTray =
                  planogramItem.unitsTrayWide *
                  planogramItem.unitsTrayHigh *
                  planogramItem.unitsTrayDeep;
                return { ...planogramItem };
              });
            }}
            isRequired={planogramItem.merchandisingStyle === "TRAY"}
          />

          <NumberInput
            title="Units Tray Deep"
            value={planogramItem.unitsTrayDeep}
            setValue={(unitsTrayDeep) => {
              setPlanogramItem((planogramItem) => {
                planogramItem.unitsTrayDeep = unitsTrayDeep;
                planogramItem.noOfUnitsInTray =
                  planogramItem.unitsTrayWide *
                  planogramItem.unitsTrayHigh *
                  planogramItem.unitsTrayDeep;
                return { ...planogramItem };
              });
            }}
            isRequired={planogramItem.merchandisingStyle === "TRAY"}
          />

          <NumberInput
            title="Units Tray High"
            value={planogramItem.unitsTrayHigh}
            setValue={(unitsTrayHigh) => {
              setPlanogramItem((planogramItem) => {
                planogramItem.unitsTrayHigh = unitsTrayHigh;
                planogramItem.noOfUnitsInTray =
                  planogramItem.unitsTrayWide *
                  planogramItem.unitsTrayHigh *
                  planogramItem.unitsTrayDeep;
                return { ...planogramItem };
              });
            }}
            isRequired={planogramItem.merchandisingStyle === "TRAY"}
          />
        </Flex>

        <CustomNumberInput
          id="noOfUnitsInTray"
          title="No of Units in Tray"
          product={planogramItem}
          setProduct={setPlanogramItem}
          isRequired={planogramItem.merchandisingStyle === "TRAY"}
          isDisabled
        />
      </>

      {/* Case dimensions. */}
      <>
        <Flex margin="20px 0 0 0">
          <Text variant="subtitle1">Case Dimensions</Text>
        </Flex>

        <Flex gap="20px">
          <CustomNumberInput
            id="caseWidth"
            title="Case Width (cm)"
            product={planogramItem}
            setProduct={setPlanogramItem}
            isRequired={planogramItem.merchandisingStyle === "CASE"}
          />

          <CustomNumberInput
            id="caseHeight"
            title="Case Height (cm)"
            product={planogramItem}
            setProduct={setPlanogramItem}
            isRequired={planogramItem.merchandisingStyle === "CASE"}
          />

          <CustomNumberInput
            id="caseDepth"
            title="Case Depth (cm)"
            product={planogramItem}
            setProduct={setPlanogramItem}
            isRequired={planogramItem.merchandisingStyle === "CASE"}
          />
        </Flex>

        <Flex gap="20px">
          <NumberInput
            title="Units Case Wide"
            value={planogramItem.unitsCaseWide}
            setValue={(unitsCaseWide) => {
              setPlanogramItem((planogramItem) => {
                planogramItem.unitsCaseWide = unitsCaseWide;
                planogramItem.noOfUnitsInCase =
                  planogramItem.unitsCaseWide *
                  planogramItem.unitsCaseHigh *
                  planogramItem.unitsCaseDeep;
                return { ...planogramItem };
              });
            }}
            isRequired={planogramItem.merchandisingStyle === "CASE"}
          />

          <NumberInput
            title="Units Case Deep"
            value={planogramItem.unitsCaseDeep}
            setValue={(unitsCaseDeep) => {
              setPlanogramItem((planogramItem) => {
                planogramItem.unitsCaseDeep = unitsCaseDeep;
                planogramItem.noOfUnitsInCase =
                  planogramItem.unitsCaseWide *
                  planogramItem.unitsCaseHigh *
                  planogramItem.unitsCaseDeep;
                return { ...planogramItem };
              });
            }}
            isRequired={planogramItem.merchandisingStyle === "CASE"}
          />

          <NumberInput
            title="Units Case High"
            value={planogramItem.unitsCaseHigh}
            setValue={(unitsCaseHigh) => {
              setPlanogramItem((planogramItem) => {
                planogramItem.unitsCaseHigh = unitsCaseHigh;
                planogramItem.noOfUnitsInCase =
                  planogramItem.unitsCaseWide *
                  planogramItem.unitsCaseHigh *
                  planogramItem.unitsCaseDeep;
                return { ...planogramItem };
              });
            }}
            isRequired={planogramItem.merchandisingStyle === "CASE"}
          />
        </Flex>

        <CustomNumberInput
          id="noOfUnitsInCase"
          title="No of Units in Case"
          product={planogramItem}
          setProduct={setPlanogramItem}
          isRequired={planogramItem.merchandisingStyle === "CASE"}
          isDisabled
        />
      </>

      {/* Display dimensions. */}
      <>
        <Flex margin="20px 0 0 0">
          <Text variant="subtitle1">Display Dimensions</Text>
        </Flex>

        <Flex gap="20px">
          <CustomNumberInput
            id="displayWidth"
            title="Display Width"
            product={planogramItem}
            setProduct={setPlanogramItem}
          />

          <CustomNumberInput
            id="displayHeight"
            title="Display Height"
            product={planogramItem}
            setProduct={setPlanogramItem}
          />

          <CustomNumberInput
            id="displayDepth"
            title="Display Depth"
            product={planogramItem}
            setProduct={setPlanogramItem}
          />
        </Flex>
      </>

      {/* PCT dimensions. */}
      <>
        <Flex margin="20px 0 0 0">
          <Text variant="subtitle1">PCT Dimensions</Text>
        </Flex>

        <Flex gap="20px">
          <CustomNumberInput
            id="squeezeWidthPct"
            title="Squeeze Width"
            product={planogramItem}
            setProduct={setPlanogramItem}
          />

          <CustomNumberInput
            id="squeezeHeightPct"
            title="Squeeze Height"
            product={planogramItem}
            setProduct={setPlanogramItem}
          />

          <CustomNumberInput
            id="squeezeDepthPct"
            title="Squeeze Depth"
            product={planogramItem}
            setProduct={setPlanogramItem}
          />
        </Flex>

        <Select
          title="Overhang Squeeze Depth Flag"
          placeholder="Overhang Squeeze Depth Flag"
          options={[
            {
              label: "true",
              value: true,
            },
            {
              label: "false",
              value: false,
            },
          ]}
          value={planogramItem.overhangSqueezeDepthFlag}
          setValue={(overhang) => {
            setPlanogramItem((planogramItem) => {
              planogramItem.overhangSqueezeDepthFlag = overhang;
              return { ...planogramItem };
            });
          }}
        />
      </>

      {/* CDT. */}
      <>
        <Flex margin="20px 0 0 0">
          <Text variant="subtitle1">CDT</Text>
        </Flex>

        <Flex gap="20px">
          <Select
            title="CDT 1"
            placeholder="CDT 1"
            options={Object.keys(data.cdt1).map((cdt1) => ({
              value: cdt1,
              label: cdt1,
            }))}
            value={planogramItem.cdt1}
            setValue={(cdt1) => {
              setPlanogramItem((planogramItem) => {
                planogramItem.cdt1 = cdt1;
                //@ts-ignore
                planogramItem.cdt1Priority = data.cdt1[cdt1];
                return { ...planogramItem };
              });
            }}
            isRequired
            isSearchable
          />

          <CustomNumberInput
            id="cdt1Priority"
            title="CDT 1 Priority"
            product={planogramItem}
            setProduct={setPlanogramItem}
            isDisabled
          />
        </Flex>

        <Flex gap="20px">
          <Select
            title="CDT 2"
            placeholder="CDT 2"
            options={Object.keys(data.cdt2).map((cdt2) => ({
              value: cdt2,
              label: cdt2,
            }))}
            value={planogramItem.cdt2}
            setValue={(cdt2) => {
              setPlanogramItem((planogramItem) => {
                planogramItem.cdt2 = cdt2;
                //@ts-ignore
                planogramItem.cdt2Priority = data.cdt2[cdt2];
                return { ...planogramItem };
              });
            }}
            isRequired
            isSearchable
          />

          <CustomNumberInput
            id="cdt2Priority"
            title="CDT 2 Priority"
            product={planogramItem}
            setProduct={setPlanogramItem}
            isDisabled
          />
        </Flex>

        <Flex gap="20px">
          <Select
            title="CDT 3"
            placeholder="CDT 3"
            options={Object.keys(data.cdt3).map((cdt3) => ({
              value: cdt3,
              label: cdt3,
            }))}
            value={planogramItem.cdt3}
            setValue={(cdt3) => {
              setPlanogramItem((planogramItem) => {
                planogramItem.cdt3 = cdt3;
                //@ts-ignore
                planogramItem.cdt3Priority = data.cdt3[cdt3];
                return { ...planogramItem };
              });
            }}
            isRequired
            isSearchable
          />

          <CustomNumberInput
            id="cdt3Priority"
            title="CDT 3 Priority"
            product={planogramItem}
            setProduct={setPlanogramItem}
            isDisabled
          />
        </Flex>
      </>

      {/* Facings. */}
      <>
        <Flex margin="20px 0 0 0">
          <Text variant="subtitle1">Facings</Text>
        </Flex>

        <Flex gap="20px">
          <CustomNumberInput
            id="facings"
            title="Facings"
            product={planogramItem}
            setProduct={setPlanogramItem}
            min={1}
          />

          <CustomNumberInput
            id="minFacings"
            title="Min Facings"
            product={planogramItem}
            setProduct={setPlanogramItem}
            min={1}
          />

          <CustomNumberInput
            id="maxFacings"
            title="Max Facings"
            product={planogramItem}
            setProduct={setPlanogramItem}
            error={
              planogramItem.maxFacings < planogramItem.minFacings &&
              "Max facings is smaller than min facings"
            }
            min={planogramItem.minFacings + 1}
          />
        </Flex>

        <Flex gap="20px">
          <CustomNumberInput
            id="facingsRows"
            title="Facings Rows"
            product={planogramItem}
            setProduct={setPlanogramItem}
            min={planogramItem.minFacingsRows}
            max={planogramItem.maxFacingsRows}
          />

          <CustomNumberInput
            id="minFacingsRows"
            title="Min Facings Rows"
            product={planogramItem}
            setProduct={setPlanogramItem}
            min={1}
          />

          <CustomNumberInput
            id="maxFacingsRows"
            title="Max Facings Rows"
            product={planogramItem}
            setProduct={setPlanogramItem}
            min={planogramItem.minFacingsRows}
          />
        </Flex>
      </>

      {/* Quantities. */}
      <>
        <Flex margin="20px 0 0 0">
          <Text variant="subtitle1">Quantities</Text>
        </Flex>

        <Flex gap="20px">
          <CustomNumberInput
            id="minDaysOfSupply"
            title="Min Days of Supply"
            product={planogramItem}
            setProduct={setPlanogramItem}
          />

          <CustomNumberInput
            id="maxDaysOfSupply"
            title="Max Days of Supply"
            product={planogramItem}
            setProduct={setPlanogramItem}
            error={
              planogramItem.maxDaysOfSupply < planogramItem.minDaysOfSupply &&
              "Max DOS is smaller than min DOS"
            }
          />
        </Flex>

        <Flex gap="20px">
          <CustomNumberInput
            id="minOnShelf"
            title="Min On Shelf"
            product={planogramItem}
            setProduct={setPlanogramItem}
          />

          <CustomNumberInput
            id="quantity"
            title="Quantity"
            product={planogramItem}
            setProduct={setPlanogramItem}
          />
        </Flex>

        <Flex gap="20px">
          <CustomNumberInput
            id="price"
            title="Price"
            product={planogramItem}
            setProduct={setPlanogramItem}
            isRequired
          />

          <CustomNumberInput
            id="salesAmount"
            title="Sales Amount"
            product={planogramItem}
            setProduct={setPlanogramItem}
          />
        </Flex>

        <Flex gap="20px">
          <CustomNumberInput
            id="profit"
            title="Profit"
            product={planogramItem}
            setProduct={setPlanogramItem}
            isRequired
          />
        </Flex>
      </>
    </Flex>
  );
};
