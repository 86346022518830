import { Color, Flex, Icon, Text } from "src/elements";
import styled from "styled-components";

import { paginationSize } from "../store/constants";

const PaginationArrowElement = styled(Flex)<{
  isDisabled: boolean;
}>`
  position: relative;
  min-height: ${paginationSize};
  min-width: ${paginationSize};
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  pointer-events: ${({ isDisabled }) => isDisabled && "none"};

  &:hover {
    background-color: ${Color.greenSmoke};
  }

  &:hover:active {
    background-color: ${Color.greenSmokeActive};
  }
`;

export const PaginationArrow = ({
  side,
  totalPages,
  isDisabled,
  onClick,
}: {
  side: "left" | "right";
  isDisabled: boolean;
  totalPages: number;
  onClick: () => void;
}) => (
  <PaginationArrowElement isDisabled={isDisabled} onClick={onClick}>
    <Flex visibility="hidden">
      <Text>{totalPages}</Text>
    </Flex>

    <Flex
      position="absolute"
      width="100%"
      height="100%"
      align="center"
      justify="center"
    >
      <Icon
        name={side === "left" ? "paginationLeft" : "paginationRight"}
        color={isDisabled ? Color.spaceGray : Color.primary}
      />
    </Flex>
  </PaginationArrowElement>
);
