import { useEffect, useState } from "react";
import { Button, Color, Flex, SubgridTable, Text } from "src/elements";
import { SubgridColumn } from "src/elements/SubgridTable/store/types";
import { MerchflowReviewUpdateStep } from "../store/types";
import {
  ResponseGetViolationMerchflow_merchflowId_,
  RouteGetViolationMerchflow_merchflowId_,
  ViolationReport,
} from "@CommonApi/violation/merchflow/_merchflowId_";
import { useApi } from "src/api";
import { useInlineLoaders } from "src/components/InlineLoader";
import { PageStatus } from "src/components";

interface Props {
  merchflowId: number;
  setStep: (step: MerchflowReviewUpdateStep) => void;
  violations?: ResponseGetViolationMerchflow_merchflowId_;
}

export const Step1Violations = ({
  merchflowId,
  setStep,
  violations: _violations,
}: Props) => {
  const { getViolationReportApi } = useApi();
  const { isInlineLoading } = useInlineLoaders();

  const [violations, setViolations] =
    useState<ResponseGetViolationMerchflow_merchflowId_ | null>(
      _violations || null,
    );

  const isViolationsLoading = isInlineLoading(
    RouteGetViolationMerchflow_merchflowId_,
  );

  const columns: SubgridColumn<ViolationReport>[] = [
    { header: "Violation Type", id: "violation_type" },
    { header: "Violation", id: "violation_message" },
    { header: "Stores Affected", id: "stores_affected" },
  ];

  // Effect to load violations in if they were not passed already when opening the modal.
  useEffect(() => {
    if (!violations) {
      loadViolations();
    }
  }, []);

  const loadViolations = async () => {
    setViolations(await getViolationReportApi(merchflowId));
  };

  const nextStep = () => {
    setStep("STEP_3_PRODUCT_REVIEW");
  };

  return (
    <Flex column width="100%" grow justify="center">
      <Flex grow>
        {isViolationsLoading && (
          <PageStatus icon="spinner" label="Loading violations..." />
        )}

        {!isViolationsLoading && !violations && (
          <PageStatus
            icon="alert"
            color={Color.red}
            label="Failed to load violations"
          />
        )}

        {!isViolationsLoading && violations && (
          <>
            {violations.data.length > 0 && (
              <Flex gap="12px" margin="24px 0 0 0">
                <Flex column gap="12px">
                  <Text variant="h5" color={Color.primary}>
                    Here are the things that we will automatically do:
                  </Text>

                  <Text variant="body3" color={Color.primary}>
                    If total shelf space is &gt; 100% utilised, we will attempt
                    in this order to:
                    <ul>
                      <li>
                        decrease facings of products over minimum required dos
                        and mos
                      </li>

                      <li>
                        decrease facings of products such that we may dip under
                        dos
                      </li>

                      <li>
                        decrease facings of products such that we may dip under
                        mos and dos
                      </li>
                    </ul>
                    if total shelf space is &lt; 90% utilised, we will attempt
                    in this order to:
                    <ul>
                      <li>
                        increase facings of products already on shelf ranged
                        below mos and dos
                      </li>

                      <li>increase facings of products already on shelf</li>
                    </ul>
                  </Text>
                </Flex>

                <Flex column gap="12px" width="600px">
                  <Text variant="h5" color={Color.primary}>
                    The following violations exist for Flow {merchflowId}
                  </Text>

                  <SubgridTable
                    isSortable
                    columns={columns}
                    data={violations?.data || []}
                  />
                </Flex>
              </Flex>
            )}

            {violations.data.length === 0 && (
              <>
                <Flex direction="column">
                  <Text variant="h3" color={Color.primary}>
                    Have any product swaps or assortment changes you wish to
                    make to your planograms?
                  </Text>

                  <Text variant="h3" color={Color.primary}>
                    You're in the right place. Click Next to continue the update
                    wizard.
                  </Text>
                </Flex>
              </>
            )}
          </>
        )}
      </Flex>

      <Flex justify="right" width="100%">
        <Button isDisabled={violations === null} onClick={nextStep}>
          Next
        </Button>
      </Flex>
    </Flex>
  );
};
