import {
  PayloadPostCategories,
  PayloadPutCategories,
  ResponsePostCategories,
  ResponsePutCategories,
  RoutePostCategories,
  RoutePutCategories,
} from "@CommonApi/categories";
import {
  ParamsGetCategoriesList,
  ResponseGetCategoriesList,
  RouteGetCategoriesList,
} from "@CommonApi/categories/list";
import {
  ParamsGetMerchflowsReviews,
  ResponseGetMerchflowsReviews,
  RouteGetMerchflowsReviews,
} from "@CommonApi/merchflows/reviews";
import {
  PayloadPostUsers,
  ResponsePostUsers,
  RoutePostUsers,
} from "@CommonApi/users";
import { ResponseGetUsersList, RouteGetUsersList } from "@CommonApi/users/list";
import {
  ResponseGetViolationMerchflow_merchflowId_,
  RouteGetViolationMerchflow_merchflowId_,
} from "@CommonApi/violation/merchflow/_merchflowId_";
import {
  OptionalPaginationQueryParams,
  RequiredPaginationQueryParams,
} from "@CommonTypes/backend/pagination";
import { useInlineLoaders } from "src/components/InlineLoader";
import { useToasts } from "src/components/Toasts";
import { getRequest } from "src/utils";
import { API, postRequest, putRequest, routeToUrl } from "src/utils/axios";
import {
  PayloadPostMerchflow_merchflowId_FlowUpdate,
  ResponsePostMerchflow_merchflowId_FlowUpdate,
  RoutePostMerchflow_merchflowId_FlowUpdate,
} from "@CommonApi/merchflow/_merchflowId_/flow-update";
import {
  ResponseGetMerchflow_merchflowId_FlowUpdateProducts,
  RouteGetMerchflow_merchflowId_FlowUpdateProducts,
} from "@CommonApi/merchflow/_merchflowId_/flow-update/products";
import {
  ParamsGetInputsMasterFixturesAll,
  ResponseGetInputsMasterFixturesAll,
  RouteGetInputsMasterFixturesAll,
} from "@CommonApi/inputs/master-fixtures/all";
import {
  ResponseGetMerchflow_merchflowId_StorePogTriggerAllFinished,
  RouteGetMerchflow_merchflowId_StorePogTriggerAllFinished,
} from "@CommonApi/merchflow/_merchflowId_/store-pog-trigger/all-finished";
import {
  ParamsGetMerchflow_merchflowId_FlowUpdateChangeSummary,
  ResponseGetMerchflow_merchflowId_FlowUpdateChangeSummary,
  RouteGetMerchflow_merchflowId_FlowUpdateChangeSummary,
} from "@CommonApi/merchflow/_merchflowId_/flow-update/change-summary";
import {
  PayloadPostMerchflow_merchflowId_FlowUpdateV1,
  ResponsePostMerchflow_merchflowId_FlowUpdateV1,
  RoutePostMerchflow_merchflowId_FlowUpdateV1,
} from "@CommonApi/merchflow/_merchflowId_/flow-update-v1";
import { PayloadPostStores, RoutePostStores } from "@CommonApi/stores";
import {
  ParamsGetStoresCategoriesList,
  ResponseGetStoresCategoriesList,
  RouteGetStoresCategoriesList,
} from "@CommonApi/stores/categories/list";
import {
  ParamsGetStoresList,
  ResponseGetStoresList,
  RouteGetStoresList,
} from "@CommonApi/stores/list";
import {
  PayloadPutStores_storeId_BindAll,
  RoutePutStores_storeId_BindAll,
} from "@CommonApi/stores/_storeId_/bind-all";
import {
  PayloadPutStores_storeId_Status,
  RoutePutStores_storeId_Status,
} from "@CommonApi/stores/_storeId_/status";
import {
  PayloadPutCategories_categoryId_Status,
  RoutePutCategories_categoryId_Status,
} from "@CommonApi/categories/_categoryId_/status";
import {
  ResponseGetMerchflowStorePog_storePogId_,
  RouteGetMerchflowStorePog_storePogId_,
  PayloadPutMerchflowStorePog_storePogId_,
  ResponsePutMerchflowStorePog_storePogId_,
  RoutePutMerchflowStorePog_storePogId_,
} from "@CommonApi/merchflow/store/pog/_storePogId_";
import {
  PayloadPutMerchflowBasePog_basePogId_,
  ResponseGetMerchflowBasePog_basePogId_,
  ResponsePutMerchflowBasePog_basePogId_,
  RouteGetMerchflowBasePog_basePogId_,
  RoutePutMerchflowBasePog_basePogId_,
} from "@CommonApi/merchflow/base-pog/_basePogId_";
import {
  PayloadPutBasePogSelectedStatus,
  RoutePutBasePogSelectedStatus,
} from "@CommonApi/pogger/base-pog/status";
import {
  ResponseGetAllStoreSummary,
  RouteGetAllStoreSummary,
} from "@CommonApi/store-summary";
import {
  PayloadPostMerchflows_MerchflowId_ExportsBatch,
  ResponseGetMerchflows_MerchflowId_ExportsBatch,
  ResponsePostMerchflows_MerchflowId_ExportsBatch,
  RouteGetMerchflows_MerchflowId_ExportsBatch,
  RoutePostMerchflows_MerchflowId_ExportsBatch,
} from "@CommonApi/merchflows/_merchflowId_/exports/batch";
import {
  PayloadPostMerchflows_MerchflowId_ExportsSingle,
  ResponsePostMerchflows_MerchflowId_ExportsSingle,
  RoutePostMerchflows_MerchflowId_ExportsSingle,
} from "@CommonApi/merchflows/_merchflowId_/exports/single";
import { downloadFileFromUrl } from "src/utils/downloadFile";
import { sleep } from "src/utils/sleep";
import {
  PayloadPutMerchflows_MerchflowId_StoresApprovals,
  RoutePutMerchflows_MerchflowId_StoresApprovals,
} from "@CommonApi/merchflows/_merchflowId_/stores/approvals";
import {
  ResponseGetMerchflowsReviewFlows_merchflowId_,
  RouteGetMerchflowsReviewFlows_merchflowId_,
} from "@CommonApi/merchflows/review/flows/_merchflowId_";
import {
  PayloadPostMerchflowCreatePlanogramTemplate,
  RoutePostMerchflowCreatePlanogramTemplate,
} from "@CommonApi/merchflow/createPlanogramTemplate";
import {
  ResponseGetMerchflowsTemplateAll,
  RouteGetMerchflowsTemplateAll,
} from "./merchflows/template-all";
import {
  PayloadPutMerchflows_merchflowId_Notes,
  RoutePutMerchflows_merchflowId_Notes,
} from "@CommonApi/merchflows/_merchflowId_/notes";
import {
  RouteGetMerchflows_merchflowId_Details,
  ResponseGetMerchflows_merchflowId_Details,
} from "@CommonApi/merchflows/review/_merchflowId_";
import {
  ResponseGetMerchflowsS2_s2SolutionId_,
  RouteGetMerchflowsS2_s2SolutionId_,
} from "@CommonApi/merchflows/s2/_s2SolutionId_";
import {
  ResponseGetMerchflows_merchflowId_BasePog_basePogId_,
  RouteGetMerchflows_merchflowId_BasePog_basePogId_,
} from "@CommonApi/merchflows/_merchflowId_/base-pog/_basePogId_";

import {
  PayloadPutMerchflows_merchflowId_FilterConfigs_filterConfigId_MergeRulesV2,
  ResponsePutMerchflows_merchflowId_FilterConfigs_filterConfigId_MergeRulesV2,
  RoutePutMerchflows_merchflowId_FilterConfigs_filterConfigId_MergeRulesV2,
} from "@CommonApi/merchflows/_merchflowId_/filter-configs/_filterConfig_id/merge-rules-v2";
import {
  ParamsGetMerchflows_MerchflowId_StoresAll,
  ResponseGetMerchflows_MerchflowId_StoresAll,
  RouteGetMerchflows_MerchflowId_StoresAll,
} from "@CommonApi/merchflows/_merchflowId_/stores/all";
import {
  PayloadAuthLogin,
  ResponseAuthLogin,
  RouteAuthLogin,
} from "@CommonApi/auth/login";
import {
  ResponseGetAuthRenewToken,
  UrlAuthRenewToken,
} from "@CommonApi/auth/renew-token";
import { ResponseGetAuthUser, RouteAuthUser } from "@CommonApi/auth/user";
import {
  PayloadPostMercflows_merchflowId_S1,
  ResponsePostMercflows_merchflowId_S1,
  RoutePostMerchflows_merchflowId_S1,
} from "@CommonApi/merchflows/_merchflowId_/s1";

import {
  ResponseGetMerchflows_merchflowId_S0_s0UserTriggerId_,
  RouteGetMerchflows_merchflowId_S0_s0UserTriggerId_,
} from "@CommonApi/merchflows/_merchflowId_/s0/_s0UserTriggerId_";
import {
  ResponseGetMercflows_merchflowId_S1_s1UserTriggerId,
  RouteGetMerchflows_merchflowId_S1_s1UserTriggerId_,
} from "@CommonApi/merchflows/_merchflowId_/s1/_s1UserTriggerId_";
import {
  ResponseGetMerchflows_merchflowId_S2_s1UserTriggerId_Templates_templateId_,
  RouteGetMerchflows_merchflowId_S2_s1UserTriggerId_Templates_templateId_,
} from "@CommonApi/merchflows/_merchflowId_/s2/_s1UserTriggerId_/s2/_templateId_";
import {
  ResponseGetMerchflows_merchflowId_S2_s2SolutionId_,
  RouteGetMerchflows_merchflowId_S2_s2SolutionId_,
} from "@CommonApi/merchflows/_merchflowId_/s2/_s2SolutionId_";

export * from "./Categories";
export * from "./Inputs";
export * from "./Stores";

export const postAuthLoginReq = (payload: PayloadAuthLogin) =>
  postRequest<ResponseAuthLogin>(`${API}${RouteAuthLogin}`, {
    payload,
  });

export const getAuthUserReq = () =>
  getRequest<ResponseGetAuthUser>(`${API}${RouteAuthUser}`);

export const getAuthRenewTokenReq = () =>
  getRequest<ResponseGetAuthRenewToken>(`${API}${UrlAuthRenewToken}`);

const getMerchflowDetailsReq = (merchflowId: number) =>
  getRequest<ResponseGetMerchflows_merchflowId_Details>(
    `${API}${RouteGetMerchflows_merchflowId_Details}`,
    {
      params: {
        reviewId: merchflowId,
      },
    },
  );

const getCategoriesListReq = (queryParams?: ParamsGetCategoriesList) =>
  getRequest<ResponseGetCategoriesList>(`${API}${RouteGetCategoriesList}`, {
    queryParams,
  });

const putCategories_categoryId_StatusReq = (
  categoryId: number,
  payload: PayloadPutCategories_categoryId_Status,
) =>
  putRequest(
    routeToUrl(`${API}${RoutePutCategories_categoryId_Status}`, { categoryId }),
    {
      payload,
    },
  );

const getStoreCategoriesListReq = (
  queryParams: ParamsGetStoresCategoriesList,
) =>
  getRequest<ResponseGetStoresCategoriesList>(
    `${API}${RouteGetStoresCategoriesList}`,
    {
      queryParams,
    },
  );

const postCategoriesReq = (payload: PayloadPostCategories) =>
  postRequest<ResponsePostCategories>(`${API}${RoutePostCategories}`, {
    payload,
  });

const putCategoriesReq = (payload: PayloadPutCategories) =>
  putRequest<ResponsePutCategories>(`${API}${RoutePutCategories}`, {
    payload,
  });

const getUsersListReq = () =>
  getRequest<ResponseGetUsersList>(`${API}${RouteGetUsersList}`);

const postUsersReq = (payload: PayloadPostUsers) =>
  postRequest<ResponsePostUsers>(`${API}${RoutePostUsers}`, {
    payload,
  });

const postFlowUpdateV1Req = (
  merchflowId: number,
  payload: PayloadPostMerchflow_merchflowId_FlowUpdateV1,
) =>
  postRequest<ResponsePostMerchflow_merchflowId_FlowUpdateV1>(
    routeToUrl(`${API}${RoutePostMerchflow_merchflowId_FlowUpdateV1}`, {
      merchflowId,
    }),
    {
      payload,
    },
  );

const postFlowUpdateReq = (
  merchflowId: number,
  payload: PayloadPostMerchflow_merchflowId_FlowUpdate,
) =>
  postRequest<ResponsePostMerchflow_merchflowId_FlowUpdate>(
    routeToUrl(`${API}${RoutePostMerchflow_merchflowId_FlowUpdate}`, {
      merchflowId,
    }),
    {
      payload,
    },
  );

const getMerchflowsReviewsReq = (queryParams?: OptionalPaginationQueryParams) =>
  getRequest<ResponseGetMerchflowsReviews>(
    `${API}${RouteGetMerchflowsReviews}`,
    {
      queryParams,
    },
  );

const getAllStoreSummaryReq = (queryParams?: OptionalPaginationQueryParams) =>
  getRequest<ResponseGetAllStoreSummary>(`${API}${RouteGetAllStoreSummary}`, {
    queryParams,
  });

const getViolationReportReq = (merchflowId: number) =>
  getRequest<ResponseGetViolationMerchflow_merchflowId_>(
    routeToUrl(`${API}${RouteGetViolationMerchflow_merchflowId_}`, {
      merchflowId,
    }),
  );

const getMerchflow_merchflowId_FlowUpdateProductsReq = (merchflowId: number) =>
  getRequest<ResponseGetMerchflow_merchflowId_FlowUpdateProducts>(
    routeToUrl(`${API}${RouteGetMerchflow_merchflowId_FlowUpdateProducts}`, {
      merchflowId,
    }),
  );

const getMerchflowStorePogTriggerAllFinishedReq = (merchflowId: number) =>
  getRequest<ResponseGetMerchflow_merchflowId_StorePogTriggerAllFinished>(
    routeToUrl(
      `${API}${RouteGetMerchflow_merchflowId_StorePogTriggerAllFinished}`,
      { merchflowId },
    ),
  );

const getInputsFixturesReq = (queryParams: RequiredPaginationQueryParams) =>
  getRequest<ResponseGetInputsMasterFixturesAll>(
    `${API}${RouteGetInputsMasterFixturesAll}`,
    { queryParams },
  );

const getMerchflowFlowUpdateChangeSummaryReq = (
  merchflowId: number,
  queryParams: ParamsGetMerchflow_merchflowId_FlowUpdateChangeSummary,
) =>
  getRequest<ResponseGetMerchflow_merchflowId_FlowUpdateChangeSummary>(
    routeToUrl(
      `${API}${RouteGetMerchflow_merchflowId_FlowUpdateChangeSummary}`,
      { merchflowId },
    ),
    { queryParams },
  );

const getStoresListReq = (queryParams: ParamsGetStoresList) =>
  getRequest<ResponseGetStoresList>(`${API}${RouteGetStoresList}`, {
    queryParams,
  });

const putStores_storeId_StatusReq = (
  storeId: number,
  payload: PayloadPutStores_storeId_Status,
) =>
  putRequest(
    routeToUrl(`${API}${RoutePutStores_storeId_Status}`, { storeId }),
    {
      payload,
    },
  );

const putStores_storeId_CategoriesReq = (
  storeId: number,
  payload: PayloadPutStores_storeId_BindAll,
) =>
  putRequest(
    routeToUrl(`${API}${RoutePutStores_storeId_BindAll}`, { storeId }),
    {
      payload,
    },
  );

const postStoresReq = (payload: PayloadPostStores) =>
  postRequest(`${API}${RoutePostStores}`, {
    payload,
  });

export const getMerchflowStorePog_storePogId_Req = (storePogId: number) => {
  return getRequest<ResponseGetMerchflowStorePog_storePogId_>(
    `${API}${RouteGetMerchflowStorePog_storePogId_}`,
    {
      params: {
        storePogId,
      },
    },
  );
};

export const putMerchflowStorePog_storePogId_Req = (
  storePogId: number,
  payload: PayloadPutMerchflowStorePog_storePogId_,
) => {
  return putRequest<ResponsePutMerchflowStorePog_storePogId_>(
    `${API}${RoutePutMerchflowStorePog_storePogId_}`,
    {
      params: {
        storePogId,
      },
      payload,
    },
  );
};

export const getMerchflowBasePog_basePogId_Req = (basePogId: number) => {
  return getRequest<ResponseGetMerchflowBasePog_basePogId_>(
    `${API}${RouteGetMerchflowBasePog_basePogId_}`,
    {
      params: {
        basePogId,
      },
    },
  );
};

export const getMerchflows_merchflowId_BasePog_basePogId_Req = (
  merchflowId: number,
  basePogId: number,
) => {
  return getRequest<ResponseGetMerchflows_merchflowId_BasePog_basePogId_>(
    `${API}${RouteGetMerchflows_merchflowId_BasePog_basePogId_}`,
    {
      params: {
        merchflowId,
        basePogId,
      },
    },
  );
};

export const postMerchflowBasePogReq = (
  basePogId: number,
  payload: PayloadPutMerchflowBasePog_basePogId_,
) => {
  return putRequest<ResponsePutMerchflowBasePog_basePogId_>(
    `${API}${RoutePutMerchflowBasePog_basePogId_}`,
    {
      payload,
      params: {
        basePogId,
      },
    },
  );
};

export const putMerchflowBasePogStatusReq = (
  payload: PayloadPutBasePogSelectedStatus,
) => {
  return putRequest<unknown>(`${API}${RoutePutBasePogSelectedStatus}`, {
    payload,
  });
};

export const postMerchflows_MerchflowId_ExportsSingleReq = (
  merchflowId: number,
  payload: PayloadPostMerchflows_MerchflowId_ExportsSingle,
) => {
  return postRequest<ResponsePostMerchflows_MerchflowId_ExportsSingle>(
    `${API}${RoutePostMerchflows_MerchflowId_ExportsSingle}`,
    {
      payload,
      params: {
        merchflowId,
      },
    },
  );
};

export const postMerchflows_MerchflowId_ExportsBatchReq = (
  merchflowId: number,
  payload: PayloadPostMerchflows_MerchflowId_ExportsBatch,
) =>
  postRequest<ResponsePostMerchflows_MerchflowId_ExportsBatch>(
    `${API}${RoutePostMerchflows_MerchflowId_ExportsBatch}`,
    {
      payload,
      params: {
        merchflowId,
      },
    },
  );

export const getMerchflows_MerchflowId_ExportsBatchReq = (
  merchflowId: number,
  batchId: number,
) =>
  getRequest<ResponseGetMerchflows_MerchflowId_ExportsBatch>(
    `${API}${RouteGetMerchflows_MerchflowId_ExportsBatch}`,
    {
      params: {
        merchflowId,
        batchId,
      },
    },
  );

const getFlowReviewFilterConfigReq = (merchflowId: number) => {
  return getRequest<ResponseGetMerchflowsReviewFlows_merchflowId_>(
    `${API}${RouteGetMerchflowsReviewFlows_merchflowId_}`,
    { params: { merchflowId } },
  );
};

export const putMerchflows_MerchflowId_StoresApprovalsReq = (
  merchflowId: number,
  payload: PayloadPutMerchflows_MerchflowId_StoresApprovals,
) => {
  return putRequest<unknown>(
    `${API}${RoutePutMerchflows_MerchflowId_StoresApprovals}`,
    {
      payload,
      params: {
        merchflowId,
      },
    },
  );
};

export const getMerchflowsTemplateAllReq = (merchflow_id: number) =>
  getRequest<ResponseGetMerchflowsTemplateAll>(
    `${API}${RouteGetMerchflowsTemplateAll}`,
    {
      queryParams: { merchflow_id },
    },
  );

export const postMerchflowCreatePlanogramTemplateReq = (
  payload: PayloadPostMerchflowCreatePlanogramTemplate,
) => {
  return postRequest<unknown>(
    `${API}${RoutePostMerchflowCreatePlanogramTemplate}`,
    {
      payload,
    },
  );
};

export const putMerchflows_merchflowId_notesReq = (
  merchflowId: number,
  payload: PayloadPutMerchflows_merchflowId_Notes,
) => {
  return putRequest<unknown>(
    routeToUrl(`${API}${RoutePutMerchflows_merchflowId_Notes}`, {
      merchflowId,
    }),
    {
      payload,
    },
  );
};

export const getMerchflowS2_s2SolutionId_Req = (s2SolutionId: number) => {
  return getRequest<ResponseGetMerchflowsS2_s2SolutionId_>(
    `${API}${RouteGetMerchflowsS2_s2SolutionId_}`,
    {
      params: {
        s2SolutionId,
      },
    },
  );
};

const putMerchflows_merchflowId_MergeRulesReqV2 = (
  merchflowId: number,
  filterConfigId: number,
  payload: PayloadPutMerchflows_merchflowId_FilterConfigs_filterConfigId_MergeRulesV2,
) =>
  putRequest<ResponsePutMerchflows_merchflowId_FilterConfigs_filterConfigId_MergeRulesV2>(
    `${API}${RoutePutMerchflows_merchflowId_FilterConfigs_filterConfigId_MergeRulesV2}`,
    {
      payload,
      params: {
        merchflowId,
        filterConfigId,
      },
    },
  );

const getMerchflows_merchflowId_S0_s0UserTriggerIdReq = (
  merchflowId: number,
  s0UserTriggerId: number,
) =>
  getRequest<ResponseGetMerchflows_merchflowId_S0_s0UserTriggerId_>(
    `${API}${RouteGetMerchflows_merchflowId_S0_s0UserTriggerId_}`,
    {
      params: {
        merchflowId,
        s0UserTriggerId,
      },
    },
  );

const postMerchflows_merchflowId_S1Req = (
  merchflowId: number,
  payload: PayloadPostMercflows_merchflowId_S1,
) =>
  postRequest<ResponsePostMercflows_merchflowId_S1>(
    `${API}${RoutePostMerchflows_merchflowId_S1}`,
    {
      params: {
        merchflowId,
      },
      payload,
    },
  );

const getMerchflows_merchflowId_S1_s1UserTriggerId_Req = (
  merchflowId: number,
  s1UserTriggerId: number,
) =>
  getRequest<ResponseGetMercflows_merchflowId_S1_s1UserTriggerId>(
    `${API}${RouteGetMerchflows_merchflowId_S1_s1UserTriggerId_}`,
    {
      params: {
        merchflowId,
        s1UserTriggerId,
      },
    },
  );

const getMerchflows_MerchflowId_StoresAllReq = (
  merchflowId: number,
  queryParams: ParamsGetMerchflows_MerchflowId_StoresAll,
) => {
  return getRequest<ResponseGetMerchflows_MerchflowId_StoresAll>(
    `${API}${RouteGetMerchflows_MerchflowId_StoresAll}`,
    {
      params: {
        merchflowId,
      },
      queryParams,
    },
  );
};

const getMerchflows_merchflowId_S2_s1UserTriggerId_Templates_templateId_Req =
  (params: {
    merchflowId: number;
    s1UserTriggerId: number;
    templateId: string;
  }) => {
    return getRequest<ResponseGetMerchflows_merchflowId_S2_s1UserTriggerId_Templates_templateId_>(
      `${API}${RouteGetMerchflows_merchflowId_S2_s1UserTriggerId_Templates_templateId_}`,
      {
        params,
      },
    );
  };

const getMerchflows_merchflowId_S2_s2SolutionId_Req = (params: {
  merchflowId: number;
  s2SolutionId: number;
}) => {
  return getRequest<ResponseGetMerchflows_merchflowId_S2_s2SolutionId_>(
    `${API}${RouteGetMerchflows_merchflowId_S2_s2SolutionId_}`,
    {
      params,
    },
  );
};

export const useApi = () => {
  const { addInlineLoader, removeInlineLoader } = useInlineLoaders();
  const { toast } = useToasts();

  const getS2SolutionApi = async (payload: {
    merchflowId: number;
    s2SolutionId: number;
  }) => {
    addInlineLoader(RouteGetMerchflows_merchflowId_S2_s2SolutionId_);
    let response = null;

    try {
      response = (await getMerchflows_merchflowId_S2_s2SolutionId_Req(payload))
        .data;
    } catch (error) {
      toast({
        title: "Failed to get s2 solution",
        error,
      });
    }

    removeInlineLoader(RouteGetMerchflows_merchflowId_S2_s2SolutionId_);
    return response;
  };

  const getS2SolutionsApi = async (payload: {
    merchflowId: number;
    s1UserTriggerId: number;
    templateId: string;
  }) => {
    addInlineLoader(
      RouteGetMerchflows_merchflowId_S2_s1UserTriggerId_Templates_templateId_,
    );
    let response = null;

    try {
      response = (
        await getMerchflows_merchflowId_S2_s1UserTriggerId_Templates_templateId_Req(
          payload,
        )
      ).data;
    } catch (error) {
      toast({
        title: "Failed to get s2 solutions",
        error,
      });
    }

    removeInlineLoader(
      RouteGetMerchflows_merchflowId_S2_s1UserTriggerId_Templates_templateId_,
    );
    return response;
  };

  const postLoginApi = async (payload: PayloadAuthLogin) => {
    addInlineLoader(RouteAuthLogin);
    let response = null;

    try {
      response = (await postAuthLoginReq(payload)).data;
    } catch (error) {
      toast({
        title: "Failed to login",
        error,
      });
    }

    removeInlineLoader(RouteAuthLogin);
    return response;
  };

  const getVerifyUserApi = async () => {
    let response = null;

    try {
      response = (await getAuthUserReq()).data;
    } catch (error) {
      /* ignored */
    }

    return response;
  };

  const getRenewTokenApi = async () => {
    let response = null;

    try {
      response = (await getAuthRenewTokenReq()).data;
    } catch (error) {
      /* ignored */
    }

    return response;
  };

  const getMerchflowS0Api = async (
    merchflowId: number,
    s0UserTriggerId: number,
  ) => {
    addInlineLoader(RouteGetMerchflows_merchflowId_S0_s0UserTriggerId_);
    let response = null;

    try {
      response = (
        await getMerchflows_merchflowId_S0_s0UserTriggerIdReq(
          merchflowId,
          s0UserTriggerId,
        )
      ).data;
    } catch (error) {
      toast({
        title: "Failed to load s0 solution",
        error,
      });
    }

    removeInlineLoader(RouteGetMerchflows_merchflowId_S0_s0UserTriggerId_);
    return response;
  };

  const postStartMerchflowS1Api = async (
    merchflowId: number,
    payload: PayloadPostMercflows_merchflowId_S1,
  ) => {
    addInlineLoader(RoutePostMerchflows_merchflowId_S1);
    let response = null;

    try {
      response = (await postMerchflows_merchflowId_S1Req(merchflowId, payload))
        .data;
    } catch (error) {
      toast({
        title: "Failed to start generating s1",
        error,
      });
    }

    removeInlineLoader(RoutePostMerchflows_merchflowId_S1);
    return response;
  };

  const getMerchflowS1SolutionsApi = async (
    merchflowId: number,
    s1SolutionId: number,
  ) => {
    addInlineLoader(RouteGetMerchflows_merchflowId_S1_s1UserTriggerId_);
    let response = null;

    try {
      response = (
        await getMerchflows_merchflowId_S1_s1UserTriggerId_Req(
          merchflowId,
          s1SolutionId,
        )
      ).data;
    } catch (error) {
      toast({
        title: "Failed to get s1",
        error,
      });
    }

    removeInlineLoader(RouteGetMerchflows_merchflowId_S1_s1UserTriggerId_);
    return response;
  };

  const putMerchflows_merchflowId_MergeRulesApiV2 = async (
    merchflowId: number,
    filterConfigId: number,
    payload: PayloadPutMerchflows_merchflowId_FilterConfigs_filterConfigId_MergeRulesV2,
  ) => {
    addInlineLoader(
      RoutePutMerchflows_merchflowId_FilterConfigs_filterConfigId_MergeRulesV2,
    );
    let response = null;

    try {
      response = (
        await putMerchflows_merchflowId_MergeRulesReqV2(
          merchflowId,
          filterConfigId,
          payload,
        )
      ).data;
    } catch (error) {
      toast({
        title: "Failed to apply merge rules",
        error,
      });
    }

    removeInlineLoader(
      RoutePutMerchflows_merchflowId_FilterConfigs_filterConfigId_MergeRulesV2,
    );
    return response;
  };

  const getMerchflowsS2SolutionApi = async (s2SolutionId: number) => {
    addInlineLoader(RouteGetMerchflowsS2_s2SolutionId_);
    let response = null;

    try {
      response = (await getMerchflowS2_s2SolutionId_Req(s2SolutionId)).data;
    } catch (error) {
      toast({
        title: "Failed to load S2 solution",
        error,
      });
    }

    removeInlineLoader(RouteGetMerchflowsS2_s2SolutionId_);
    return response;
  };

  const getMerchflowDetailsApi = async (merchflowId: number) => {
    addInlineLoader(RouteGetMerchflows_merchflowId_Details);
    let response = null;

    try {
      response = (await getMerchflowDetailsReq(merchflowId)).data;
    } catch (error) {
      toast({
        title: "Failed to load merchflow details",
        error,
      });
    }

    removeInlineLoader(RouteGetMerchflows_merchflowId_Details);
    return response;
  };

  const getMerchflowBasePogApi = async (basePogId: number) => {
    let response = null;
    addInlineLoader(RouteGetMerchflowBasePog_basePogId_);

    try {
      response = (await getMerchflowBasePog_basePogId_Req(basePogId)).data;
    } catch (error) {
      toast({
        title: "Failed to get base planogram",
        error,
      });
    }

    removeInlineLoader(RouteGetMerchflowBasePog_basePogId_);
    return response;
  };

  const getMerchflowBasePogByMerchflowIdAndBasePogIdApi = async (
    merchflowId: number,
    basePogId: number,
  ) => {
    let response = null;
    addInlineLoader(RouteGetMerchflows_merchflowId_BasePog_basePogId_);

    try {
      response = (
        await getMerchflows_merchflowId_BasePog_basePogId_Req(
          merchflowId,
          basePogId,
        )
      ).data;
    } catch (error) {
      toast({
        title: "Failed to get base planogram",
        error,
      });
    }

    removeInlineLoader(RouteGetMerchflows_merchflowId_BasePog_basePogId_);
    return response;
  };

  const putMerchflowBasePogApi = async (
    basePogId: number,
    payload: PayloadPutMerchflowBasePog_basePogId_,
  ) => {
    let newBasePogId: number | null = null;
    addInlineLoader(RoutePutMerchflowBasePog_basePogId_);

    try {
      newBasePogId = (await postMerchflowBasePogReq(basePogId, payload)).data
        .base_pog_id;
    } catch (error) {
      toast({
        title: payload.save_as_new
          ? "Failed to save new version of base planogram"
          : "Failed to save base planogram",
        error,
      });
    }

    removeInlineLoader(RoutePutMerchflowBasePog_basePogId_);
    return newBasePogId;
  };

  const getMerchflowStorePogApi = async (storePogId: number) => {
    let response = null;
    addInlineLoader(RouteGetMerchflowStorePog_storePogId_);

    try {
      response = (await getMerchflowStorePog_storePogId_Req(storePogId)).data;
    } catch (error) {
      toast({
        title: "Failed to get store pog",
        error,
      });
    }

    removeInlineLoader(RouteGetMerchflowStorePog_storePogId_);
    return response;
  };

  const putMerchflowStorePogApi = async ({
    storePogId,
    payload,
  }: {
    storePogId: number;
    payload: PayloadPutMerchflowStorePog_storePogId_;
  }) => {
    let response = null;
    addInlineLoader(RoutePutMerchflowStorePog_storePogId_);

    try {
      response = (
        await putMerchflowStorePog_storePogId_Req(storePogId, payload)
      ).data.store_pog_id;
    } catch (error) {
      toast({
        title: "Failed to save store pog",
        error,
      });
    }

    removeInlineLoader(RoutePutMerchflowStorePog_storePogId_);
    return response;
  };

  const putMerchflowBasePog_basePogId_StatusApi = async (
    payload: PayloadPutBasePogSelectedStatus,
  ) => {
    let success = false;
    addInlineLoader(RoutePutBasePogSelectedStatus);

    try {
      await putMerchflowBasePogStatusReq(payload);
      success = true;
    } catch (error) {
      toast({
        title: "Failed to update base pog status",
        error,
      });
    }

    removeInlineLoader(RoutePutBasePogSelectedStatus);
    return success;
  };

  const getCategoriesListApi = async (
    queryParams?: ParamsGetCategoriesList,
  ) => {
    let response = null;
    addInlineLoader(RouteGetCategoriesList);

    try {
      response = (await getCategoriesListReq(queryParams)).data;
    } catch (error) {
      toast({ title: "Failed to load categories", error });
    }

    removeInlineLoader(RouteGetCategoriesList);
    return response;
  };

  const putCategories_categoryId_StatusApi = async (
    categoryId: number,
    payload: PayloadPutCategories_categoryId_Status,
  ) => {
    let response = null;
    addInlineLoader(RoutePutCategories_categoryId_Status);

    try {
      response = (await putCategories_categoryId_StatusReq(categoryId, payload))
        .data;
    } catch (error) {
      toast({ title: "Failed to update category status", error });
    }

    removeInlineLoader(RoutePutCategories_categoryId_Status);
    return response;
  };

  const getStoreCategoriesListApi = async (
    queryParams: ParamsGetStoresCategoriesList,
  ) => {
    let response = null;
    addInlineLoader(RouteGetStoresCategoriesList);

    try {
      response = (await getStoreCategoriesListReq(queryParams)).data;
    } catch (error) {
      toast({ title: "Failed to load store categories", error });
    }

    removeInlineLoader(RouteGetStoresCategoriesList);
    return response;
  };

  const postAddCategoryApi = async (payload: PayloadPostCategories) => {
    let response = null;
    addInlineLoader(RoutePostCategories);

    try {
      response = (await postCategoriesReq(payload)).data;
    } catch (error) {
      toast({ title: "Failed to add category", error });
    }

    removeInlineLoader(RoutePostCategories);
    return response;
  };

  const putUpdateCategoryApi = async (payload: PayloadPutCategories) => {
    let response = null;
    addInlineLoader(RoutePutCategories);

    try {
      response = (await putCategoriesReq(payload)).data;
    } catch (error) {
      toast({ title: "Failed to update category", error });
    }

    removeInlineLoader(RoutePutCategories);
    return response;
  };

  const getUsersListApi = async () => {
    let response = null;
    addInlineLoader(RouteGetUsersList);

    try {
      response = (await getUsersListReq()).data;
    } catch (error) {
      toast({ title: "Failed to load users", error });
    }

    removeInlineLoader(RouteGetUsersList);
    return response;
  };

  const postAddUserApi = async (payload: PayloadPostUsers) => {
    let response = null;
    addInlineLoader(RoutePostUsers);

    try {
      response = (await postUsersReq(payload)).data;
    } catch (error) {
      toast({ title: "Failed to add user", error });
    }

    removeInlineLoader(RoutePostUsers);
    return response;
  };

  const getMerchflowsReviewsApi = async (
    queryParams: ParamsGetMerchflowsReviews,
  ) => {
    let response = null;
    addInlineLoader(RouteGetMerchflowsReviews);

    try {
      response = (await getMerchflowsReviewsReq(queryParams)).data;
    } catch (error) {
      toast({ title: "Failed to load flows", error });
    }

    removeInlineLoader(RouteGetMerchflowsReviews);
    return response;
  };

  const getViolationReportApi = async (merchflowId: number) => {
    let response = null;
    addInlineLoader(RouteGetViolationMerchflow_merchflowId_);

    try {
      response = (await getViolationReportReq(merchflowId)).data;
    } catch (error) {
      toast({ title: "Failed to load violation report", error });
    }

    removeInlineLoader(RouteGetViolationMerchflow_merchflowId_);
    return response;
  };

  const postFlowUpdateV1Api = async (
    merchflowId: number,
    payload: PayloadPostMerchflow_merchflowId_FlowUpdateV1,
  ) => {
    let response = null;
    addInlineLoader(RoutePostMerchflow_merchflowId_FlowUpdateV1);

    try {
      response = (await postFlowUpdateV1Req(merchflowId, payload)).data;
    } catch (error) {
      toast({ title: "Failed to invoke FlowUpdate V1", error });
    }

    removeInlineLoader(RoutePostMerchflow_merchflowId_FlowUpdateV1);
    return response;
  };

  const postFlowUpdateApi = async (
    merchflowId: number,
    payload: PayloadPostMerchflow_merchflowId_FlowUpdate,
  ) => {
    let response = null;
    addInlineLoader(RoutePostMerchflow_merchflowId_FlowUpdate);

    try {
      response = (await postFlowUpdateReq(merchflowId, payload)).data;
    } catch (error) {
      toast({ title: "Failed to invoke FlowUpdate", error });
    }

    removeInlineLoader(RoutePostMerchflow_merchflowId_FlowUpdate);
    return response;
  };

  const getUpdateFlowProductsApi = async (merchflowId: number) => {
    let response = null;
    addInlineLoader(RouteGetMerchflow_merchflowId_FlowUpdateProducts);

    try {
      response = (
        await getMerchflow_merchflowId_FlowUpdateProductsReq(merchflowId)
      ).data;
    } catch (error) {
      toast({ title: "Failed to load update flow available products", error });
    }

    removeInlineLoader(RouteGetMerchflow_merchflowId_FlowUpdateProducts);
    return response;
  };

  const getFixturesApi = async (
    queryParams: ParamsGetInputsMasterFixturesAll,
  ) => {
    let response = null;
    addInlineLoader(RouteGetInputsMasterFixturesAll);

    try {
      response = (await getInputsFixturesReq(queryParams)).data;
    } catch (error) {
      toast({ title: "Failed to load fixtures", error });
    }

    removeInlineLoader(RouteGetInputsMasterFixturesAll);
    return response;
  };

  const getMerchflowStorePogTriggerAllFinishedApi = async (
    merchflowId: number,
  ) => {
    let response = null;
    addInlineLoader(RouteGetMerchflow_merchflowId_StorePogTriggerAllFinished);

    try {
      response = (await getMerchflowStorePogTriggerAllFinishedReq(merchflowId))
        .data;
    } catch (error) {
      toast({ title: "Failed to load store pog versions", error });
    }

    removeInlineLoader(
      RouteGetMerchflow_merchflowId_StorePogTriggerAllFinished,
    );
    return response;
  };

  const getMerchflowFlowUpdateChangeSummaryApi = async (
    merchflowId: number,
    queryParams: ParamsGetMerchflow_merchflowId_FlowUpdateChangeSummary,
  ) => {
    let response = null;
    addInlineLoader(RouteGetMerchflow_merchflowId_FlowUpdateChangeSummary);

    try {
      response = (
        await getMerchflowFlowUpdateChangeSummaryReq(merchflowId, queryParams)
      ).data;
    } catch (error) {
      toast({ title: "Failed to load change summary", error });
    }

    removeInlineLoader(RouteGetMerchflow_merchflowId_FlowUpdateChangeSummary);
    return response;
  };

  const getStoresListApi = async (queryParams: ParamsGetStoresList) => {
    let response = null;
    addInlineLoader(RouteGetStoresList);

    try {
      response = (await getStoresListReq(queryParams)).data;
    } catch (error) {
      toast({ title: "Failed to load stores", error });
    }

    removeInlineLoader(RouteGetStoresList);
    return response;
  };

  const getMerchflowReviewApi = async (merchflowId: number) => {
    let response = null;
    addInlineLoader(RouteGetMerchflowsReviewFlows_merchflowId_);

    try {
      response = (await getFlowReviewFilterConfigReq(merchflowId)).data[0];
    } catch (error) {
      toast({
        title: "Failed to load review details",
        error,
      });
    }

    removeInlineLoader(RouteGetMerchflowsReviewFlows_merchflowId_);
    return response;
  };

  const getMerchflowsTemplateAllApi = async (merchflowId: number) => {
    addInlineLoader(RouteGetMerchflowsTemplateAll);
    let result = null;

    try {
      result = (await getMerchflowsTemplateAllReq(merchflowId)).data;
    } catch (error) {
      toast({
        title: "Failed to load templates",
        error,
      });
    }

    removeInlineLoader(RouteGetMerchflowsTemplateAll);
    return result;
  };

  const putStores_storeId_StatusApi = async (
    storeId: number,
    payload: PayloadPutStores_storeId_Status,
  ) => {
    let response = null;
    addInlineLoader(RoutePutStores_storeId_Status);

    try {
      response = (await putStores_storeId_StatusReq(storeId, payload)).data;
    } catch (error) {
      toast({ title: "Failed to update store status", error });
    }

    removeInlineLoader(RoutePutStores_storeId_Status);
    return response;
  };

  const putStores_storeId_CategoriesApi = async (
    storeId: number,
    payload: PayloadPutStores_storeId_BindAll,
  ) => {
    let response = null;
    addInlineLoader(RoutePutStores_storeId_BindAll);

    try {
      response = (await putStores_storeId_CategoriesReq(storeId, payload)).data;
    } catch (error) {
      toast({ title: "Failed to update store categories", error });
    }

    removeInlineLoader(RoutePutStores_storeId_BindAll);
    return response;
  };

  const postStoresApi = async (payload: PayloadPostStores) => {
    let response = null;
    addInlineLoader(RoutePostStores);

    try {
      response = (await postStoresReq(payload)).data;
    } catch (error) {
      toast({ title: "Failed to add new store", error });
    }

    removeInlineLoader(RoutePostStores);
    return response;
  };

  const getAllStoreSummaryApi = async (
    queryParams?: OptionalPaginationQueryParams,
  ) => {
    let response = null;
    addInlineLoader(RouteGetAllStoreSummary);

    try {
      response = (await getAllStoreSummaryReq(queryParams)).data;
    } catch (error) {
      toast({ title: "Failed to load store summary", error });
    }

    removeInlineLoader(RouteGetAllStoreSummary);
    return response;
  };

  const postExportSinglePogApi = async (
    merchflowId: number,
    payload: PayloadPostMerchflows_MerchflowId_ExportsSingle,
  ) => {
    addInlineLoader(RoutePostMerchflows_MerchflowId_ExportsSingle);

    try {
      const {
        data: { download_url },
      } = await postMerchflows_MerchflowId_ExportsSingleReq(
        merchflowId,
        payload,
      );

      downloadFileFromUrl(download_url);
    } catch (error) {
      toast({
        title: "Failed to export POG",
        error,
      });
    }

    removeInlineLoader(RoutePostMerchflows_MerchflowId_ExportsSingle);
  };

  const postExportBatchPogsApi = async (
    merchflowId: number,
    payload: PayloadPostMerchflows_MerchflowId_ExportsBatch,
  ) => {
    let response = null;
    addInlineLoader(RoutePostMerchflows_MerchflowId_ExportsBatch);

    exporter: try {
      const {
        data,
        data: { batch_id },
      } = await postMerchflows_MerchflowId_ExportsBatchReq(
        merchflowId,
        payload,
      );

      if (data.url) {
        response = data;
      } else {
        // Best i can do is give backend 100 attempts to finish the batch exporting.
        let attempts = 100;
        while (attempts-- > 0) {
          await sleep(5000);

          const { data } = await getMerchflows_MerchflowId_ExportsBatchReq(
            merchflowId,
            batch_id,
          );

          if (data.status !== "IN_PROGRESS") {
            response = data;
            break exporter;
          }
        }
      }
    } catch (error) {
      toast({ title: "Failed to export store planograms", error });
    }

    if (response?.url) {
      downloadFileFromUrl(response.url);
    }

    removeInlineLoader(RoutePostMerchflows_MerchflowId_ExportsBatch);
    return response;
  };

  const postMerchflowCreatePlanogramTemplateApi = async (
    payload: PayloadPostMerchflowCreatePlanogramTemplate,
  ) => {
    let success = false;
    addInlineLoader(RoutePostMerchflowCreatePlanogramTemplate);

    try {
      await postMerchflowCreatePlanogramTemplateReq(payload);
      success = true;
    } catch (error) {
      toast({
        title: "Failed to create planogram template",
        error,
      });
    }

    removeInlineLoader(RoutePostMerchflowCreatePlanogramTemplate);
    return success;
  };

  const putMerchflowApprovalsApi = async (
    merchflowId: number,
    payload: PayloadPutMerchflows_MerchflowId_StoresApprovals,
  ) => {
    let success = false;

    addInlineLoader(RoutePutMerchflows_MerchflowId_StoresApprovals);

    try {
      await putMerchflows_MerchflowId_StoresApprovalsReq(merchflowId, payload);
      success = true;
    } catch (error) {
      toast({
        title: "Failed to update approvals",
        error,
      });
    }

    removeInlineLoader(RoutePutMerchflows_MerchflowId_StoresApprovals);
    return success;
  };

  const putMerchflowNotesApi = async (
    merchflowId: number,
    payload: PayloadPutMerchflows_merchflowId_Notes,
  ) => {
    let success = false;

    addInlineLoader(RoutePutMerchflows_merchflowId_Notes);

    try {
      await putMerchflows_merchflowId_notesReq(merchflowId, payload);
      success = true;
    } catch (error) {
      toast({
        title: "Failed to save merchflow notes",
        error,
      });
    }

    removeInlineLoader(RoutePutMerchflows_merchflowId_Notes);
    return success;
  };

  const getMerchflowStoresAllApi = async (
    merchflowId: number,
    queryParams: ParamsGetMerchflows_MerchflowId_StoresAll,
  ) => {
    let response = null;
    addInlineLoader(RouteGetMerchflows_MerchflowId_StoresAll);

    try {
      response = (
        await getMerchflows_MerchflowId_StoresAllReq(merchflowId, queryParams)
      ).data;
    } catch (error) {
      toast({
        title: "Failed to load all stores",
        error,
      });
    }

    removeInlineLoader(RouteGetMerchflows_MerchflowId_StoresAll);
    return response;
  };

  return {
    postLoginApi,
    getVerifyUserApi,
    getRenewTokenApi,
    getMerchflowS0Api,
    postStartMerchflowS1Api,
    getMerchflowS1SolutionsApi,
    getMerchflowsS2SolutionApi,
    getMerchflowDetailsApi,
    getCategoriesListApi,
    getStoreCategoriesListApi,
    getUsersListApi,
    getMerchflowsReviewsApi,
    getViolationReportApi,
    getUpdateFlowProductsApi,
    getFixturesApi,
    getMerchflowStorePogTriggerAllFinishedApi,
    getMerchflowFlowUpdateChangeSummaryApi,
    getStoresListApi,
    getMerchflowStorePogApi,
    getMerchflowBasePogApi,
    getMerchflowBasePogByMerchflowIdAndBasePogIdApi,
    getAllStoreSummaryApi,
    getMerchflowReviewApi,
    getMerchflowsTemplateAllApi,
    getMerchflowStoresAllApi,
    postExportSinglePogApi,
    postExportBatchPogsApi,
    postFlowUpdateApi,
    postFlowUpdateV1Api,
    postAddCategoryApi,
    postAddUserApi,
    postStoresApi,
    postMerchflowCreatePlanogramTemplateApi,
    putUpdateCategoryApi,
    putCategories_categoryId_StatusApi,
    putStores_storeId_StatusApi,
    putStores_storeId_CategoriesApi,
    putMerchflowStorePogApi,
    putMerchflowBasePogApi,
    putMerchflowBasePog_basePogId_StatusApi,
    putMerchflowApprovalsApi,
    putMerchflowNotesApi,
    putMerchflows_merchflowId_MergeRulesApiV2,
    getS2SolutionsApi,
    getS2SolutionApi,
  };
};
