import { PlanogramItem, Pog } from "@CommonTypes/merchflow/pog/pog";
import { newProductModalDataAtom } from "./atoms";
import { useSetAtom } from "jotai";
import { useModals } from "src/components/Modals";
import { getNewOwaShelfId } from "src/components/Planogram/types/PlanogramShelf";
import { getNewProductItemFromPog } from "./utils";
import { POG_NEW_PRODUCT_DIMENSION_MULTIPLIER_TO_CM } from "./constants";
import { ResponseMerchFlowApplyFilters } from "@CommonApi/merchandise";

export const usePogNewProductModal = () => {
  const { openModal } = useModals();
  const setNewProductModalData = useSetAtom(newProductModalDataAtom);

  const openPogNewProductModal = ({
    currentStoreCode,
    pog,
    planogramItem,
    availableStores,
  }: {
    currentStoreCode: string;
    pog: Pog;
    planogramItem?: PlanogramItem;
    availableStores: ResponseMerchFlowApplyFilters["filter_config"];
  }) => {
    setNewProductModalData({
      currentStoreCode,
      pog,
      availableStores,
      planogramItem: planogramItem
        ? {
            ...planogramItem,
            uniqueId: getNewOwaShelfId(),
            shelf: null,
            dndFlag: false,
            newItemFlag: true,
            unitWidth:
              planogramItem.unitWidth /
              POG_NEW_PRODUCT_DIMENSION_MULTIPLIER_TO_CM,
            unitHeight:
              planogramItem.unitHeight /
              POG_NEW_PRODUCT_DIMENSION_MULTIPLIER_TO_CM,
            unitDepth:
              planogramItem.unitDepth /
              POG_NEW_PRODUCT_DIMENSION_MULTIPLIER_TO_CM,
            trayWidth:
              planogramItem.trayWidth /
              POG_NEW_PRODUCT_DIMENSION_MULTIPLIER_TO_CM,
            trayHeight:
              planogramItem.trayHeight /
              POG_NEW_PRODUCT_DIMENSION_MULTIPLIER_TO_CM,
            trayDepth:
              planogramItem.trayDepth /
              POG_NEW_PRODUCT_DIMENSION_MULTIPLIER_TO_CM,
            caseWidth:
              planogramItem.caseWidth /
              POG_NEW_PRODUCT_DIMENSION_MULTIPLIER_TO_CM,
            caseHeight:
              planogramItem.caseHeight /
              POG_NEW_PRODUCT_DIMENSION_MULTIPLIER_TO_CM,
            caseDepth:
              planogramItem.caseDepth /
              POG_NEW_PRODUCT_DIMENSION_MULTIPLIER_TO_CM,
            validOrientations: planogramItem.validOrientations || [
              planogramItem.orientation,
            ],
          }
        : getNewProductItemFromPog(pog),
    });
    openModal("ModalPogNewProduct");
  };

  return { openPogNewProductModal };
};
