import { PayloadS2PlaceVariantsBatchStart } from "@CommonApi/frogger/place_variants/batch/start";
import { PayloadPutMerchflows_MerchflowId_StoresApprovals } from "@CommonApi/merchflows/_merchflowId_/stores/approvals";
import { Pog, Planogram } from "@CommonTypes/merchflow/pog/pog";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import lodashGet from "lodash/get";
import groupBy from "lodash/groupBy";
import { useMemo } from "react";
import {
  algoPreStep0ConvertPlanogramToFreezer,
  calculatePrestep0Data,
} from "src/adapters/algoPreStep0";
import { algoStep0ConvertGroupsToContainers } from "src/adapters/algoStep0";
import {
  applyMinWidthPriority,
  calculateVariantPriority,
  convertPlanogramContainersToS1Containers,
} from "src/adapters/algoStep1";
import { convertS1FreezerWithItemsToS2Containers } from "src/adapters/algoStep2";
import { useInlineLoaders } from "src/components/InlineLoader";
import { useLoaders } from "src/components/Loaders";
import { useModals } from "src/components/Modals";
import { Container } from "src/components/Planogram/store/types";
import { useToasts } from "src/components/Toasts";
import { useBasePogApi, useMerchflowNavigation } from "src/hooks";
import { useNewFlowReview } from "src/modals/NewFlowReview/store/api";
import { deleteOldMerchflowData } from "src/modals/NewFlowReview/store/requests";
import {
  adapterPogNonCircularToPog,
  buildPlanogramFromFixtures,
  adapterPlanogramToPlanogramNonCircular,
  adapterPogToPogNonCircular,
} from "src/omni-common/adapters/pog";
import { PoggerResponse } from "src/omni-common/types/algo/pogger";
import { PoggerizeResponse } from "src/omni-common/types/algo/poggerize";
import {
  ResponseAlgoStep2FromBeBatch,
  Step1Step2Solution,
} from "src/omni-common/types/step2fixtures";
import { downloadFileFromUrl } from "src/utils/downloadFile";
import { sleep } from "src/utils/sleep";

import { PayloadPostMerchflows_MerchflowId_ExportsBatch } from "@CommonApi/merchflows/_merchflowId_/exports/batch";
import { putPresignedFileUploadReq } from "../../../api";
import {
  getImportPsaBeginUploadReq,
  postImportPsaFinishUploadReq,
} from "../../../api/MerchFlow/importPsa";
import { HttpStatusCode } from "../../../omni-common/utils/HttpStatusCode";
import { omniAtom } from "../../../utils/atoms";
import {
  PayloadAlgoStep0,
  ResponseAlgoStep0,
} from "../steps/Step2Fixtures/store/types";
import {
  allPreStep0DataAtom,
  ffDataRequestsAtom,
  froggerVersionAtom,
  pageAtom,
  pmDataRequestsAtom,
  poggerResultAtom,
  poggerizeRequestAtom,
  poggerizeResultAtom,
  savedPogAtomAtom,
  savedS0Atom,
  selectedPreStep0DataAtom,
  selectedTemplateIdAtom,
  step0GroupsAtoms,
  step0RequestAtoms,
  step1OriginalResponseAtom,
  step1RequestAtom,
  step1ResponsesAtom,
  step2DataAtom,
  step2RequestAtom,
  stepAtom,
  storeLevelPogPaginationAtom,
  storeLevelPogStatusRowsAtom,
  viewDetailIndex,
} from "./atoms";
import {
  STORE_LEVEL_PAGE_SIZE,
  getAlgoStep2Solutions,
  getDownloadStorePogs,
  getStoreLevelPogStatus,
  postAlgoStep0Req,
  postAlgoStep1Req,
  postAlgoStep2ReqBatch,
  postApplyToBasesReq,
  postContainerModified,
  postDownloadStorePogs,
  postExportPlanogramPSA,
  postFixtureModified,
  postPogModifiedReq,
  postPoggerAsyncReq,
  postPoggerizeReq,
  postUpdateSolution,
} from "./requests";
import { MerchandiseStep, StoreLevelPogPagination } from "./types";
import { FreezerCandidate } from "@CommonTypes/algo/freezer";
import { useNavigation } from "src/utils/navigation";
import { routeToUrl } from "src/utils/axios";
import { pages } from "src/utils";
import { defaultPspRules } from "src/pages/Merchflows_merchflowId_BasePog_basePogId_/store/types";
import { isEmpty } from "src/utils/isEmpty";

export const useMerchandiseApi = () => {
  const { mergeRules, details, coreRange, filtersApplied } = useNewFlowReview();

  const { addLoader, removeLoader } = useLoaders();
  const { addInlineLoader, removeInlineLoader } = useInlineLoaders();
  const { closeModal, openModal } = useModals();
  const { toast } = useToasts();
  const { navigate } = useNavigation();

  const froggerVersion = useAtomValue(froggerVersionAtom);

  const ffDataRequests = useAtomValue(ffDataRequestsAtom);
  const pmDataRequests = useAtomValue(pmDataRequestsAtom);
  const selectedTemplateId = useAtomValue(selectedTemplateIdAtom);
  const [step0Groups, setStep0Groups] = useAtom(step0GroupsAtoms);
  const setStep0Request = useSetAtom(step0RequestAtoms);
  const setStep1Request = useSetAtom(step1RequestAtom);
  const setStep2Request = useSetAtom(step2RequestAtom);
  const setSavedS0 = useSetAtom(savedS0Atom);
  const setStep1OriginalResponse = useSetAtom(step1OriginalResponseAtom);
  const setStep1Response = useSetAtom(step1ResponsesAtom);
  const [step2Data, setStep2Data] = useAtom(step2DataAtom);
  const [selectedPreStep0, setSelectedPreStep0Data] = useAtom(
    selectedPreStep0DataAtom,
  );
  const setAllPreStep0 = useSetAtom(allPreStep0DataAtom);
  const setMerchFlowStep = useSetAtom(stepAtom);
  const setPoggerResult = useSetAtom(poggerResultAtom);
  const setPoggerizeRequest = useSetAtom(poggerizeRequestAtom);
  const [poggerizeResult, setPoggerizeResult] = useAtom(poggerizeResultAtom);
  const setStoreLevelPogStatusRows = useSetAtom(storeLevelPogStatusRowsAtom);
  const setStoreLevelPogPaginations = useSetAtom(storeLevelPogPaginationAtom);
  const setSavedPogAtom = useSetAtom(savedPogAtomAtom);
  const { loadAllBasePog } = useBasePogApi();
  const { navigateToStoreProps } = useMerchflowNavigation();
  const setSolutionIndex = useSetAtom(viewDetailIndex);
  const setPage = useSetAtom(pageAtom);

  const storeGroupByTemplate = useMemo(
    () =>
      // @ts-ignore
      groupBy(
        filtersApplied?.filter_config?.stores,
        (store) => store.template_id,
      ),
    [filtersApplied, selectedPreStep0],
  );
  const preStep0ByTemplate = useMemo(
    () => groupBy(selectedPreStep0, (s) => s.templateId),
    [selectedPreStep0],
  );

  const prepareAndCallStep0 = async () => {
    if (!filtersApplied) return;
    addLoader("merchandise/prepareAndCallStep0");

    try {
      const allPreStep0 = calculatePrestep0Data({
        appliedFilters: filtersApplied,
        ffDataRequests,
        pmDataRequests,
        coreRange: coreRange.option,
        flow: details!,
      });

      const selectedStores = Object.keys(storeGroupByTemplate).map(
        (templateId) => storeGroupByTemplate[templateId][0].store,
      );
      const selectedPreStep0Data = allPreStep0.filter((storePrestep0) =>
        selectedStores.includes(storePrestep0.storeCode),
      );

      const s0Request = [] as {
        payload: PayloadAlgoStep0;
        froggerVersion: string;
      }[];

      if (!isEmpty(step0Groups)) {
        await deleteOldMerchflowData({
          filter_config_id: filtersApplied.id,
        });
      }

      const failedTemplate: string[] = [];
      const step0: ResponseAlgoStep0[] = await Promise.all(
        selectedPreStep0Data.map((preStepData, index) => {
          const requestPayload = {
            froggerVersion,
            payload: {
              freezer: preStepData.freezer,
              merge_rules: mergeRules!,
              candidates: preStepData.candidates,

              merchflow_id: details!.id,
              template_id: preStepData.templateId,

              filter_config_id: filtersApplied.id,
              store_id: preStepData.storeId,

              parameters: {
                container_width_limit: {
                  min: 0,
                  max: 1000,
                  option: "min",
                },
                container_height_limit: {
                  min: 0,
                  max: 3,
                  option: "min",
                },
                extend_option: 1,
              },
              allocation_priority: "dos_mos",
            },
          };
          s0Request[index] = requestPayload;
          return postAlgoStep0Req(requestPayload)
            .then((rs) => {
              if (JSON.stringify(rs.data).toLowerCase().includes("error")) {
                toast({
                  title: `Error in step 0 of ${preStepData.templateId}`,
                  message: rs.data,
                  type: "error",
                });
                return { groups: [] };
              }
              return rs.data;
            })
            .catch(() => {
              failedTemplate.push(preStepData.templateId);
              delete s0Request[index];
              toast({
                title: `Error timeout for step 0 of ${preStepData.templateId}`,
                type: "error",
              });
              return { groups: [] };
            });
        }),
      );

      setSelectedPreStep0Data(
        selectedPreStep0Data.filter(
          (d) => !failedTemplate.includes(d.templateId),
        ),
      );
      setAllPreStep0(allPreStep0);

      setStep0Request(s0Request.filter((d) => d));
      setStep0Groups(
        step0.filter((d) => !isEmpty(d.groups)).map((d) => d.groups),
      );
      setSavedS0(null);

      setMerchFlowStep(MerchandiseStep.GENERATE_BASE);
      closeModal("ModalMerchflowReview");
    } catch (error) {
      toast({
        title: "Error in algo step0",
        error,
      });
    }

    removeLoader("merchandise/prepareAndCallStep0");
  };

  const callAlgoStep1Step2 = async (pog: any, containers: any) => {
    addLoader("merchandise/callAlgoStep1Step2");
    if (!filtersApplied) return;

    const cumulative_shelf_heights =
      selectedPreStep0[0].freezer.sections[0].cumulative_shelf_heights;

    const sourceTemplateContainers = convertPlanogramContainersToS1Containers(
      containers,
      cumulative_shelf_heights,
      selectedPreStep0[0].pmData,
      true,
    );

    const priorityByVariant = calculateVariantPriority({
      containers: sourceTemplateContainers,
      totalFixtureHeight: selectedPreStep0[0].totalFixtureHeight,
      totalFixtureWidth: selectedPreStep0[0].totalFixtureWidth,
    });

    const preparedStep1Data = selectedPreStep0.map((step, i) => {
      const isSourceTemplate = i === 0;
      const s1Containers = convertPlanogramContainersToS1Containers(
        algoStep0ConvertGroupsToContainers(
          step0Groups[i],
          selectedPreStep0[i].planogram,
        ),
        step.cumulative_shelf_heights,
        step.pmData,
        isSourceTemplate,
      );

      const s1ContainerAppliedMinWidth = applyMinWidthPriority({
        inputContainers: i === 0 ? sourceTemplateContainers : s1Containers,
        minWidthPerVariant: step.maxItemWidthByVariant,
        priorityByVariant,
        productCountByVariant: selectedPreStep0[i].productCountByVariant,
        totalFixtureWidth: step.totalFixtureWidth,
        isSourceTemplate,
      });

      return {
        templateId: step.templateId,
        containers: s1ContainerAppliedMinWidth,
        freezer:
          i === 0
            ? algoPreStep0ConvertPlanogramToFreezer(pog, false)
            : algoPreStep0ConvertPlanogramToFreezer(
                buildPlanogramFromFixtures(step.ffData),
                false,
              ),
      };
    });

    try {
      const s1RequestData = preparedStep1Data.map((oneStep1Payload) => {
        return {
          ...oneStep1Payload,
          filter_config_id: filtersApplied.id,
          merchflow_id: details!.id,
          template_id: oneStep1Payload.templateId,
          parameters: {
            width_max_deviation: 0.1,
            sequencing_y_weight: 20,
          },
          containers: oneStep1Payload.containers.map((container) => ({
            id: container.id,
            variants: container.variants,
            start_x: container.xLeft,
            width: container.xRight - container.xLeft,
            start_y: container.yBottom,
            height: container.yTop - container.yBottom,
            target_width: container.target_width,
            min_width_per_variants: container.min_width_per_variants,
            valid_shelves: [],
            core_range_size: container.core_range_size!,
          })),
        };
      });

      const { data: step1Results } = await postAlgoStep1Req({
        froggerVersion,
        payload: s1RequestData,
      });

      setStep1Request(s1RequestData);
      setStep1OriginalResponse(step1Results.placeContainerResponses);

      const step1AllPogResponse = step1Results.placeContainerResponses
        ?.map((d, i) =>
          d.planogram_responses.flat().map((res) => ({
            ...res,
            templateId: Object.keys(storeGroupByTemplate)[i],
          })),
        )
        .flat();

      setStep1Response(step1AllPogResponse);
      openModal("SelectBaySize");

      if (isEmpty(step1AllPogResponse)) {
        throw new Error("There are no place container result");
      }

      const payloadBatch: PayloadS2PlaceVariantsBatchStart =
        step1AllPogResponse.map((s1Response) => ({
          freezer: {
            ...preparedStep1Data.find(
              (step1Data) => step1Data.templateId === s1Response.templateId,
            )!.freezer,
            ...s1Response.planogram.freezer,
          },
          candidates: preStep0ByTemplate[s1Response.templateId][0].candidates,
          merchflow_id: details!.id,
          template_id: s1Response.templateId,
          filter_config_id: filtersApplied!.id,
          containers: convertS1FreezerWithItemsToS2Containers(
            s1Response.planogram.freezer,
          ),
          step1_solution_id: s1Response.step1_solution_id,
          parameters: {
            mudmap_early_stopping_timeout: 30,
            mudmap_time_limit: 180,
            lns_time_limit: 100,
            num_responses: 10,
            s1_objective: s1Response.meta.objective.total,
          },
        }));
      setStep2Request(payloadBatch);

      const step2ResponsesBatch: ResponseAlgoStep2FromBeBatch[] =
        await postAlgoStep2ReqBatch({ payload: payloadBatch, froggerVersion });

      const solutionsData = await getAlgoStep2Solutions(filtersApplied.id);

      step2ResponsesBatch.map((r, i) => {
        const payload = payloadBatch[i];
        const s1Response = step1AllPogResponse[i];
        if (JSON.stringify(r).toLowerCase().includes("error")) {
          toast({
            title: `Error in step 2 of ${s1Response.templateId}`,
            error: r,
          });

          return {
            templateId: s1Response.templateId,
            planogram_responses: [],
            request: payload,
          };
        }
        return Object.assign(
          { templateId: s1Response.templateId },
          {
            planogram_responses: r.planogram_responses,
            request: payload,
          },
        );
      });

      setStep2Data(solutionsData);
    } catch (error) {
      toast({
        title: "Error in algo step 1/2",
        error,
      });
    }

    removeLoader("merchandise/callAlgoStep1Step2");
  };

  const callPoggerizePogger = async (
    planogram: Planogram,
    freezer: any,
    candidates: any,
    solution_id: number,
  ) => {
    if (!filtersApplied) return;
    addLoader("merchandise/callPoggerizePogger");

    try {
      setPoggerizeResult(null);
      setPoggerResult(null);
      setSavedPogAtom(null);

      const poggerizePayload = {
        froggerVersion,
        payload: {
          freezer,
          candidates,
          solution_id,
          merchflow_id: details!.id,
          template_id: selectedTemplateId,
          filter_config_id: filtersApplied.id,
          passthrough: adapterPlanogramToPlanogramNonCircular(planogram),
        },
      };

      setPoggerizeRequest(poggerizePayload);

      const poggerizeResult = await postPoggerizeReq(poggerizePayload)
        .then((rs) => rs.data)
        .catch((e) => {
          setPoggerizeResult(e.toJSON());
          throw e;
        });

      setPoggerizeResult(poggerizeResult);

      const poggerResult: PoggerResponse = await postPoggerAsyncReq({
        ...poggerizeResult,
        merchflow_id: details!.id,
        template_id: selectedTemplateId,
        filter_config_id: filtersApplied.id,
        base_pog_id: poggerizeResult.base_pog_id,
      });

      setPoggerResult(poggerResult);

      if (JSON.stringify(poggerResult).toLowerCase().includes("error")) {
        throw Error(
          lodashGet(poggerResult, "detail") ?? "Cant generate planogram",
        );
      }

      setSavedPogAtom(null);
      navigate(
        routeToUrl(pages.merchflows_merchflowId_BasePog_basePogId_, {
          merchflowId: details!.id,
          basePogId: poggerizeResult.base_pog_id,
        }),
      );
    } catch (error) {
      toast({
        title: "Error in algo poggerize/pogger",
        error,
      });
    }

    removeLoader("merchandise/callPoggerizePogger");
  };

  const callPogModified = async ({
    poggerize,
    pog,
  }: {
    poggerize: PoggerizeResponse;
    pog: Pog;
  }) => {
    if (!filtersApplied) return;

    addLoader("merchandise/callPogModified");

    try {
      const data = await postPogModifiedReq({
        merchflow_id: details!.id,
        template_id: selectedTemplateId,
        filter_config_id: filtersApplied.id,
        store_id: preStep0ByTemplate[selectedTemplateId][0].storeId,
        poggerize,
        pog_modified: adapterPogToPogNonCircular(pog),
        base_pog_id: poggerize.base_pog_id,
      }).then((rs) => rs.data);

      await postApplyToBasesReq({
        froggerVersion,
        payload: {
          base_pog_id: data.id, // TODO - get base pog id from poggerize response
          psp_rules: defaultPspRules,
        },
      });

      if (data.id !== poggerize.base_pog_id) {
        await loadAllBasePog();
        setPoggerizeResult((s) => (s ? { ...s, base_pog_id: data.id } : s));
      }

      await callGetStoreLevelPog(0, data.id);
      navigateToStoreProps();
    } catch (error) {
      toast({
        title: "Failed to apply modified POG",
        error,
      });
    }

    removeLoader("merchandise/callPogModified");
  };

  const callPogModifiedToViewReport = async ({
    poggerize,
    pog,
  }: {
    poggerize: PoggerizeResponse;
    pog: Pog;
  }) => {
    if (!filtersApplied) return;

    addLoader("merchandise/callPogModified");

    try {
      const data = await postPogModifiedReq({
        merchflow_id: details!.id,
        template_id: selectedTemplateId,
        // @ts-ignore
        filter_config_id: filtersApplied.id,
        store_id: preStep0ByTemplate[selectedTemplateId][0].storeId,
        poggerize,
        pog_modified: adapterPogToPogNonCircular(pog),
        base_pog_id: poggerize.base_pog_id,
      }).then((rs) => rs.data);

      if (data.id !== poggerize.base_pog_id) {
        await loadAllBasePog();
        setPoggerizeResult((s) => (s ? { ...s, base_pog_id: data.id } : s));
      }
    } catch (error) {
      toast({
        title: "Failed to save modified POG for report",
        error,
      });
    }

    removeLoader("merchandise/callPogModified");
  };

  const getStoreLevelPogStatusAsyncReq = async (
    filter_config_id: number,
    offset = 0,
    base_pog_id?: number,
  ) => {
    if (!poggerizeResult) {
      throw new Error("Missing base_pog_id");
    }

    const timeoutMinutes = 20;
    const start = Date.now();

    while (Date.now() - start < timeoutMinutes * 60 * 1000) {
      await new Promise((rs) => setTimeout(() => rs(""), 5000));
      const rs = await getStoreLevelPogStatus({
        where: {
          filter_config_id,
          is_latest_version: true,
          base_pog_id: base_pog_id ? base_pog_id : poggerizeResult.base_pog_id,
        },
        offset,
      });
      if (rs.status === HttpStatusCode.OK) return rs.data;
      if (rs.status === HttpStatusCode.BACK_END_NOT_FINISHED) {
        throw new Error("PSP_ERROR");
      }
    }

    throw new Error("Timeout on getStoreLevelPogStatusAsyncReq");
  };

  const callGetStoreLevelPog = async (
    offset?: number,
    base_pog_id?: number,
  ) => {
    if (!filtersApplied) return [];

    addInlineLoader("loadNewStoreProp");

    try {
      const storeLevelData = await getStoreLevelPogStatusAsyncReq(
        filtersApplied.id,
        offset,
        base_pog_id,
      );

      setStoreLevelPogStatusRows(storeLevelData.rows);

      const pagination = Object.keys(storeLevelData.pagination).reduce(
        (acc, value) => {
          //@ts-ignore
          acc[value] = Number(storeLevelData?.pagination[value]);
          return acc;
        },
        {} as StoreLevelPogPagination,
      );

      pagination.totalPage = Math.ceil(
        pagination.total_rows_length / STORE_LEVEL_PAGE_SIZE,
      );

      pagination.currentPage = Math.ceil(
        pagination.offset / STORE_LEVEL_PAGE_SIZE + 1,
      );

      setStoreLevelPogPaginations(pagination);
      removeInlineLoader("loadNewStoreProp");
      return storeLevelData.rows;
    } catch (error) {
      toast({
        title: "Failed to get store level POGs",
        error,
      });
      removeInlineLoader("loadNewStoreProp");
      return [];
    }
  };

  const callContainerModified = async (editedContainer: Container[]) => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
      const { data } = await postContainerModified({
        template_id: selectedPreStep0[0].templateId,
        // @ts-ignore
        filter_config_id: filtersApplied.id,
        payload: {
          containers: editedContainer,
        },
      }).then((rs) => rs.data);
    } catch (error) {
      toast({
        title: "Failed to save modified containers",
        error,
      });
    }
  };

  const callFixtureModified = async (pog: Pog) => {
    try {
      await postFixtureModified({
        // @ts-ignore
        filter_config_id: filtersApplied.id,
        template_id: selectedPreStep0[0].templateId,
        payload: adapterPogToPogNonCircular(pog),
      }).then((rs) => rs.data);
    } catch (error) {
      toast({
        title: "Failed to save modified fixtures",
        error,
      });
    }
  };

  const exportPSA = async ({
    merchflowId,
    poggerize,
    pog,
  }: {
    merchflowId: number;
    poggerize: PoggerizeResponse;
    pog: Pog;
  }) => {
    addLoader("merchandise/exportPSA");

    try {
      await postPogModifiedReq({
        merchflow_id: details!.id,
        template_id: selectedTemplateId,
        // @ts-ignore
        filter_config_id: filtersApplied.id,
        store_id: preStep0ByTemplate[selectedTemplateId][0].storeId,
        poggerize,
        pog_modified: adapterPogToPogNonCircular(pog),
        base_pog_id: poggerize.base_pog_id,
      }).then((rs) => rs.data);

      await loadAllBasePog();
      setPoggerizeResult((s) =>
        s ? { ...s, base_pog_id: poggerize.base_pog_id } : s,
      );

      const {
        data: { download_url },
      } = await postExportPlanogramPSA(merchflowId, {
        base_pog_id: poggerize.base_pog_id,
        type: "psa",
      });

      downloadFileFromUrl(download_url);
    } catch (error) {
      toast({
        title: "Failed to export PSA",
        error,
      });
    }

    removeLoader("merchandise/exportPSA");
  };

  const importPsa = async (file: File) => {
    addInlineLoader("merchFlow/importPsa");

    try {
      const {
        data: { fileName, url },
      } = await getImportPsaBeginUploadReq();

      await putPresignedFileUploadReq(url, file);

      const { data } = await postImportPsaFinishUploadReq({
        fileName,
      });

      setSavedPogAtom(
        omniAtom({
          key: "applyToBase/savedPogAtom",
          value: adapterPogNonCircularToPog(data),
        }),
      );
    } catch (error) {
      toast({
        title: "Failed to upload PSA file",
        error,
      });
    }

    removeInlineLoader("merchFlow/importPsa");
  };

  const exportStorePogsApi = async (
    merchflowId: number,
    payload: PayloadPostMerchflows_MerchflowId_ExportsBatch,
  ) => {
    addInlineLoader("merchFlow/store-pogs/exports");

    try {
      const {
        data: { batch_id },
      } = await postDownloadStorePogs(merchflowId, payload);

      let inProgress = true;

      while (inProgress) {
        await sleep(5000);

        const {
          data: { status, url },
        } = await getDownloadStorePogs(merchflowId, batch_id);

        if (status === "CREATED" && url !== undefined) {
          inProgress = false;
          downloadFileFromUrl(url);
        }
      }
    } catch (error) {
      toast({
        title: "Failed to export store pogs",
        error,
      });
    }

    removeInlineLoader("merchFlow/store-pogs/exports");
  };

  const createNewS2Solution = async ({
    solution_id,
    modified_solution,
    parentS2Data,
  }: {
    solution_id: number;
    modified_solution: Step1Step2Solution;
    parentS2Data: {
      planogram: Planogram;
      candidates: FreezerCandidate[];
    };
  }) => {
    addLoader("merchandise/createNewS2");
    try {
      const newS2Solution = await postUpdateSolution({
        parent_solution_id: solution_id,
        modified_solution,
      });
      setStep2Data((cur) => {
        const parentSolution = cur.find(
          (s2) => s2.solution_id === newS2Solution.parent_solution_id,
        );

        if (!parentSolution) return cur;

        return cur.concat({
          ...newS2Solution.solution,
          solution_id: newS2Solution.id,
          templateId: parentSolution?.templateId,
        });
      });
      const curTemplateAllStep2 = step2Data.filter(
        (pog) => pog.templateId === selectedTemplateId,
      );
      setSolutionIndex(curTemplateAllStep2.length);
      setPage(curTemplateAllStep2.length + 1);
      await callPoggerizePogger(
        parentS2Data.planogram,
        modified_solution.planogram.freezer,
        parentS2Data.candidates,
        newS2Solution.id,
      );
    } catch (error) {
      toast({
        title: "Failed to create new step 2 solution",
        error,
      });
    }
    removeLoader("merchandise/createNewS2");
  };

  return {
    prepareAndCallStep0,
    callAlgoStep1Step2,
    callPoggerizePogger,
    callGetStoreLevelPog,
    storeGroupByTemplate,
    callPogModified,
    callContainerModified,
    callFixtureModified,
    callPogModifiedToViewReport,
    exportPSA,
    importPsa,
    exportStorePogsApi,
    createNewS2Solution,
  };
};
