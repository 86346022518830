import { Color, Flex, Icon, Text } from "src/elements";
import { Transition } from "src/utils";
import styled from "styled-components";

const TitlebarElement = styled.div`
  width: 100%;
  min-height: 49px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${Color.lightGray};
  border-bottom: 1px solid ${Color.lightGrayHover};
  padding: 0 10px 0 16px;
`;

const TitlebarButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 28px;
  min-height: 28px;
  border-radius: 3px;
  transition:
    box-shadow ${Transition.fast},
    transform ${Transition.fast};
  cursor: pointer;
  user-select: none;

  &:hover {
    box-shadow: rgba(157, 173, 184, 0.2) 0px 4px 7px;
    background-color: ${Color.white};
  }

  &:hover:active {
    transform: scale(0.9);
  }
`;

interface Props {
  title?: string;
  closeModal: () => void;
}

export const ModalTitlebar = ({ title, closeModal }: Props) => {
  return (
    <TitlebarElement>
      <Text variant="h4" color={Color.textSecondary} textTransform="capitalize">
        {title}
      </Text>

      <Flex align="center">
        <TitlebarButton onClick={closeModal}>
          <Icon name="close" color={Color.textSecondary} />
        </TitlebarButton>
      </Flex>
    </TitlebarElement>
  );
};
